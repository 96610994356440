import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { tiposNotas } from './tipo-de-nota';
import { empresas } from './empresas';
import { quantidadeItens } from './quantidadeItens';
import { pipe_fields_ids } from './pipe_fields_ids';
import { HttpErrorResponse } from '@angular/common/http';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { status } from '../../../consulta-de-chamados/status';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { lista_cnpjs_empresa_nutrien } from './lista_cnpjs_empresa_nutrien';
import { Validadores } from 'src/app/componentes/formMat/Validatores';
import { environment } from 'src/environments/environment';
import { estados } from "./estados";
import { parcelaAluguel } from './parcelaAluguel';
import { mesAluguel } from './mesAluguel';
import { anoAluguel } from './anoAluguel';
import { idLabels } from './idLabels';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-solicitacao-de-lancamentos-de-notas-ocr-new-teste',
  templateUrl: './solicitacao-de-lancamentos-de-notas-ocr-new-teste.component.html',
  styleUrls: ['./solicitacao-de-lancamentos-de-notas-ocr-new-teste.component.css']
})
export class SolicitacaoDeLancamentosDeNotasComponentOcrNewTeste implements OnInit {



  formulario!: UntypedFormGroup;
  arquivosDeRateio: any[] = [];
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []
  documentacaoSuporte: any[] = [];
  nfProdutoOriginal: any[] = []

  listaDeItensDaPo: any[] = [];
  riscoSacado: boolean = false;
  dacteCorreto: boolean = false;
  notaComRateios: boolean = false;
  empresas = empresas;
  quantidadeDeItens = quantidadeItens

  notaComRateioDeValoresString: string = ''
  riscoSacadoString: string = ''
  dacteCorretoString: string = ''

  arquivosDeRateioUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl: any[] = []
  documentacaoSuporteUrl: any[] = []
  nfProdutoOriginalUrl: any[] = []
  listaDeItensDaPoUrl: any[] = []

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  custoComplementar: boolean = false;
  debitoPosterior: boolean = false
  informacoesComplementaresPo: any[] = []

  listaEmpresasCnpjs = lista_cnpjs_empresa_nutrien;

  dataAtual: any = '';
  valorTotalNfAlterado: any = 0;

  estados = estados;
  parcelaAluguel = parcelaAluguel;
  mesAluguel = mesAluguel;
  anoAluguel = anoAluguel;

  horaInicioChamadaMost: string = ''
  horaFimChamadaMost: string = ''

  horaTrocaDaEmpresa: any
  horaCriacaoDoCard: any
  tempoDePreenchimentoDoFormulario: any

  trocouEmpresa: boolean = false

  horaTrocaDaEmpresaFormatada: any
  horaCriacaoDoCardFormatada: any

  list_anexo_para_pagamento_de_aut_nomo: any[] = [];
  list_anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  list_anexar_nota_emitida_na_prefeitura: any = [];

  anexo_para_pagamento_de_aut_nomo: any[] = [];
  anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  anexar_nota_emitida_na_prefeitura: any = [];

  list_url_anexo_para_pagamento_de_aut_nomo: any[] = [];
  list_url_anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  list_url_anexar_nota_emitida_na_prefeitura: any = [];


  registros: any[] = []
  registrosUrl: any[] = []

  contPopUp: number = 0;

  tipoNotas = tiposNotas
  listaFornecedores: any[] = [];
  listaFornecedoresOriginal: any[] = [];
  listaTomadores: any[] = [];
  listaTomadoresOriginal: any[] = [];
  valorFaturaAlterado: any = 0;
  fileName2: string = '';
  fileName3: string = '';


  inicioMostMilisegundos: any
  fimMostMiligsegundos: any
  diferencaMost: any
  chaveAcesso: string = '';
  json_most: any;
  camposErro: any

  idLabels = idLabels

  formularioInvalido: boolean = false
  camposComErro: any[] = []

  dataInvalida: boolean = false
  valorInvalido: boolean = false
  documentosInvalidos: boolean = true
  nomesInvalidos: boolean = false
  mostrarMensagemConferirCamposOcr = false
  mostrarMensagemConferirCamposSemOcr = false

  preenchimento_manual_do_formul_rio: string = 'Não'

  ocr_leitura: string = ''
  ocr_cnpj_fornecedor: string = ''
  ocr_cnpj_empresa_nutrien: string = ''
  ocr_nome_fornecedor: string = ''
  ocr_valor_total_da_nf: string = ''
  ocr_data_envio_nf: string = ''
  ocr_numero_da_nota: string = ''
  ocr_quantidade_de_campos_ocerizados: any

  ocr: boolean = false






  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }


  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required]],
      // risco_sacado: ['', []],
      qual_a_quantidade_de_cte: ['', []],
      tipo_de_nota: ['', [Validators.required]],
      conta_contabil: ['', []],
      documento_do_fornecedor: ['', []],
      cpf_do_fornecedor: ['', []],
      cnpj_fornecedor: ['', [Validadores.cnpj]],
      nome_do_fornecedor: ['', [Validators.required]],
      cnpj_da_empresa_nutrien: ['', [Validators.required, Validadores.cnpj]],
      nome_empresa_nutrien: ['', [Validators.required]],
      nro_nota: ['', []],
      // parcela_do_aluguel: ['', []],
      m_s_do_aluguel: ['', []],
      ano_do_aluguel: ['', []],
      data_de_envio_de_nf: ['', [Validators.required]],
      valor_total_da_nf: ['', [Validators.required]],
      nota_com_rasteio_de_valores: [, []],
      arquivo_de_rateio: ['', []],
      data_de_vencimento_da_nota: ['', [Validators.required]],
      departamento: ['', []],
      numero_po: ['', []],
      quantidade_de_itens_do_pedido: ['', []],
      numero_do_item_da_po: ['', []],
      lista_de_itens_da_po: ['', []],
      centro_de_custo: ['', []],
      anexo_nota_ou_boleto: ['', []],
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
      anexo_documentacao_suporte: ['', []],
      nota_para_pagamento_fora_da_politica: ['', []],
      nota_para_pagamento_urgente: ['', []],
      motivo_da_excecao: ['', []],
      observacao: ['', []],
      informacoes_complementares_da_po: ['', []],
      nf_do_produto_original: ['', []],
      tipo_de_custo_planejado: ['', []],
      n_mero_de_cte: ['', []],
      opex_e_capex: ['', []],
      tipo_de_importa_o: ['', []],
      tipo_de_pagamento: ['', []],
      n_mero_afe: ['', []],
      estado_de_origem: ['', []],
      estado_de_destino: ['', []],
      certeza_que_incluiu_o_arquivo_conforme_orienta_o: ['', []],
      grc: ['', []],
      confirma_entrega_ou_prestacao: ['', [Validators.required]],
      motivo_negacao: ['', []],
      anexo_para_pagamento_de_aut_nomo: ['', []],
      cont_m_registro_na_prefeitura: ['', []],
      anexar_nota_emitida_na_prefeitura: ['', []],
      anexar_registro_do_pagamento_de_aut_nomo: ['', []],

      // campos IPE

      natureza_da_opera_o: ['', []],
      cfop: ['', []],
      nr_de_serie: ['', []],
      nome_empresa_nutrien_ocr: ['', []],
      chave_de_acesso_portal: ['', []],
      n_mero_da_linha_do_pedido_1: ['', []],
      n_mero_da_linha_do_pedido_2: ['', []],
      n_mero_da_linha_do_pedido_3: ['', []],
      n_mero_da_linha_do_pedido_4: ['', []],
      n_mero_da_linha_do_pedido_5: ['', []],
      n_mero_da_linha_do_pedido_6: ['', []],
      n_mero_da_linha_do_pedido_7: ['', []],
      n_mero_da_linha_do_pedido_8: ['', []],
      n_mero_da_linha_do_pedido_9: ['', []],
      n_mero_da_linha_do_pedido_10: ['', []],
      lista_de_pedidos: ['', []],
      quantidade_de_pedidos: ['', []],
      categoria: ['', []],
      subcategoria_po: ['', []],
      tipo_de_opera_o: ['', []],
      migo_1: ['', []],
      origem_da_cria_o_do_card: ['', []],
      json_most: ['', []],
      tomador_ipe: ['', []],
      fornecedor_ipe: ['', []],
      n_mero_do_pedido_1_1: ['', []],
      n_mero_do_pedido_2_1: ['', []],
      n_mero_do_pedido_3: ['', []],
      n_mero_do_pedido_4: ['', []],
      n_mero_do_pedido_5: ['', []],
      n_mero_do_pedido_6: ['', []],
      n_mero_do_pedido_7: ['', []],
      n_mero_do_pedido_8: ['', []],
      n_mero_do_pedido_9: ['', []],
      n_mero_do_pedido_10: ['', []],
      tempo_ocr: ['', []],
      hora_inicio_chamada_most: ['', []],
      hora_fim_chamada_most: ['', []],

    });

    // if (environment.localhost || environment.dev) {
    //   this.formulario.valueChanges.subscribe(v => {
    //     let camposObrigatorios: any[] = [];
    //     this.camposErro = []
    //     Object.getOwnPropertyNames(v).forEach(id => {
    //       let nomeCampo = "";
    //       if (!!this.formulario.get(id)?.errors?.required) {
    //         let elements = [];
    //         elements = Array.from(document.getElementsByClassName("form-group") as HTMLCollection);
    //         elements.forEach(vv => {
    //           // input
    //           if (!!vv.getElementsByTagName("div")[0]?.getElementsByTagName("input")[0]
    //             && vv.getElementsByTagName("div")[0].getElementsByTagName("input")[0].attributes.getNamedItem('formcontrolname')?.textContent?.toString() == id) {
    //             nomeCampo = vv.getElementsByTagName("label")[0]?.textContent?.toString()!;
    //           }
    //           // textarea
    //           if (!!vv.getElementsByTagName("div")[0]?.getElementsByTagName("textarea")[0]
    //             && vv.getElementsByTagName("div")[0].getElementsByTagName("textarea")[0].attributes.getNamedItem('formcontrolname')?.textContent?.toString() == id) {
    //             nomeCampo = vv.getElementsByTagName("textarea")[0]?.textContent?.toString()!;
    //           }
    //           // select
    //           if (!!vv.getElementsByTagName("div")[0]?.getElementsByTagName("select")[0]
    //             && vv.getElementsByTagName("div")[0].getElementsByTagName("select")[0].attributes.getNamedItem('formcontrolname')?.textContent?.toString() == id) {
    //             nomeCampo = vv.getElementsByTagName("label")[0]?.textContent?.toString()!;
    //           }
    //           // radio e checkbox
    //           if (!!vv.getElementsByTagName("div")[0]?.getElementsByTagName("label")[0]
    //             && !!vv.getElementsByTagName("div")[0]?.getElementsByTagName("label")[0]?.getElementsByTagName("input")[0] && vv.getElementsByTagName("div")[0]?.getElementsByTagName("label")[0]?.getElementsByTagName("input")[0].attributes.getNamedItem('formcontrolname')?.textContent?.toString() == id) {
    //             nomeCampo = vv.getElementsByTagName("label")[0]?.textContent?.toString()!;
    //           }
    //           nomeCampo = nomeCampo == "" ? id : nomeCampo;
    //           nomeCampo = nomeCampo.replace("*", "").replace(":", "").replace("?", "").trim();
    //         });
    //         this.camposErro.push(nomeCampo);
    //       }
    //     });
    //     console.log("Campos obrigatorios:", this.camposErro)
    //   })
    // }

    this.formulario.valueChanges.subscribe(v => {
      this.camposComErro = []
      Object.keys(this.formulario.controls).forEach(key => {
        const control = this.formulario.get(key);
        console.log('id:', key)
        if (control?.hasError('required')) {
          let label = this.idLabels.find(label => label.id === key);
          console.log(label)
          console.log(`Label: ${label?.label}`)
          this.camposComErro.push
            ({
              campo: label?.label,
              erro: " - Campo de preenchimento obrigatório."
            })
        }

        console.log('Campos Com Erro', this.camposComErro)
      }

      )
      if (this.formulario.getRawValue().data_de_envio_de_nf > this.dataAtual) {
        this.dataInvalida = true
        this.formulario.controls['data_de_envio_de_nf'].setErrors({ 'incorrect': true })
        let label = this.idLabels.find(label => label.id === 'data_de_envio_de_nf');
        console.log(label)
        console.log(`Label: ${label?.label}`)
        this.camposComErro.push(
          {
            campo: label?.label,
            erro: ' - A data de emissão não pode ser futura.'
          })
      } else if (this.formulario.getRawValue().data_de_envio_de_nf <= this.dataAtual) {
        this.formulario.controls['data_de_envio_de_nf'].setErrors({ 'incorrect': false })

      }

      if (this.formulario.getRawValue().cnpj_fornecedor == this.formulario.getRawValue().cnpj_da_empresa_nutrien) {
        this.documentosInvalidos = true
        this.formulario.controls['cnpj_fornecedor'].setErrors({ 'incorrect': true })
        this.formulario.controls['cnpj_da_empresa_nutrien'].setErrors({ 'incorrect': true })
        let label = this.idLabels.find(label => label.id === 'data_de_envio_de_nf');
        console.log(label)
        console.log(`Label: ${label?.label}`)
        this.camposComErro.push(
          {
            campo: 'CNPJ do Fornecedor e CNPJ da Empresa Nutrien',
            erro: " - O CNPJ da empresa Nutrien e do Fornecedor não podem ser iguais."
          })
      } else if (this.formulario.getRawValue().cnpj_fornecedor != this.formulario.getRawValue().cnpj_da_empresa_nutrien) {
        this.formulario.controls['cnpj_fornecedor'].setErrors({ 'incorrect': false })
        this.formulario.controls['cnpj_da_empresa_nutrien'].setErrors({ 'incorrect': false })
      }

      if (this.valorTotalNfAlterado == '0,00' || this.valorTotalNfAlterado == '0.00' || this.valorTotalNfAlterado == '') {
        console.log("valor nf alterado", this.valorTotalNfAlterado)
        console.log(this.formulario.getRawValue().valor_total_da_nf)
        this.valorInvalido = true
        this.formulario.controls['valor_total_da_nf'].setErrors({ 'incorrect': true })
        this.camposComErro.push({
          campo: 'Valor Total da NF',
          erro: " - O Valor total da nota fiscal não pode ser zerado."
        })
      } else if (this.valorTotalNfAlterado != '0,00' || this.valorTotalNfAlterado != '0.00') {
        this.formulario.controls['valor_total_da_nf'].setErrors({ 'incorrect': false })

      }

      if (this.formulario.getRawValue().nome_do_fornecedor.toString().toLowerCase() == this.formulario.getRawValue().nome_empresa_nutrien.toString().toLowerCase()) {

        this.formulario.controls['nome_do_fornecedor'].setErrors({ 'incorrect': true })
        this.formulario.controls['nome_empresa_nutrien'].setErrors({ 'incorrect': true })
        this.nomesInvalidos = true
        this.camposComErro.push({
          campo: 'Nome da Empresa Nutrien e Nome do Fornecedor',
          erro: " - O Nome da empresa Nutrien o nome do Fornecedor não podem ser iguais."
        })
      } else if (this.formulario.getRawValue().nome_do_fornecedor !== this.formulario.getRawValue().nome_empresa_nutrien) {
        this.formulario.controls['nome_do_fornecedor'].setErrors({ 'incorrect': false })
        this.formulario.controls['nome_empresa_nutrien'].setErrors({ 'incorrect': false })
      }
    })






    this.emailApi = this.userInfo.getUserEmail()
    this.userNameApi = this.userInfo.getName()

    this.emailApi = !!this.userInfo.getUserEmail() ? this.userInfo.getUserEmail() : this.formulario.get('email')?.value;
    this.userNameApi = !!this.userInfo.getName() ? this.userInfo.getName() : this.formulario.get('nome')?.value;

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)


    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }

    this.getDataAtual();
    this.formataValorDecimal('0');
    this.getUserEmail();
  }

  enviarFormulario() {

    this.ocr = false
    this.spinner.show()




    if (this.camposComErro.length > 0) {
      console.log("formularioInvalido", this.formulario.invalid)
      this.formularioInvalido = true
      window.scrollTo(0, 0)
      Object.keys(this.formulario.controls).forEach(key => {
        const control = this.formulario.get(key);
        if (control?.hasError('required')) {
          this.formulario.controls[key].markAsTouched()
        }
      })
      this.spinner.hide()
      return
    }




    this.horaCriacaoDoCard = Date.now()
    this.horaCriacaoDoCardFormatada = new Date()
    this.horaCriacaoDoCardFormatada = this.horaCriacaoDoCardFormatada.getHours() + ':' + this.horaCriacaoDoCardFormatada.getMinutes() + ':' + this.horaCriacaoDoCardFormatada.getSeconds()
    // console.log("hora criação do card", this.horaCriacaoDoCard)

    this.getTotalTempoAberturaDoCard(this.horaTrocaDaEmpresa, this.horaCriacaoDoCard)

    this.trocouEmpresa = false



    const criarBody = async () => {



      await this.getAnexoRegistroDePagamentoDeAutonomo(this.list_anexar_registro_do_pagamento_de_aut_nomo);
      if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        while (this.list_anexar_registro_do_pagamento_de_aut_nomo.length != this.list_anexar_registro_do_pagamento_de_aut_nomo.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        var list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = ''
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = '['
        for (var i = 0; i < this.list_anexar_registro_do_pagamento_de_aut_nomo.length; i++) {
          list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2 + '"' + this.list_anexar_registro_do_pagamento_de_aut_nomo[i] + '",'
        }
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2.slice(0, -1)
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2 + "]"

      } else {
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = ''
      }


      await this.getAnexoParaPagamentoDeAutonomo(this.list_anexo_para_pagamento_de_aut_nomo);
      if (this.list_anexo_para_pagamento_de_aut_nomo.length > 0) {
        while (this.list_anexo_para_pagamento_de_aut_nomo.length != this.list_url_anexo_para_pagamento_de_aut_nomo.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexo_para_pagamento_de_aut_nomo.length > 0) {
        var list_anexo_para_pagamento_de_aut_nomoUrl2 = ''
        list_anexo_para_pagamento_de_aut_nomoUrl2 = '['
        for (var i = 0; i < this.list_url_anexo_para_pagamento_de_aut_nomo.length; i++) {
          list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2 + '"' + this.list_url_anexo_para_pagamento_de_aut_nomo[i] + '",'
        }
        list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2.slice(0, -1)
        list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2 + "]"

      } else {
        list_anexo_para_pagamento_de_aut_nomoUrl2 = ''
      }


      await this.getAnexoNotaEmitidaPrefeituraParaPagamento(this.list_anexar_nota_emitida_na_prefeitura);
      if (this.list_anexar_nota_emitida_na_prefeitura.length > 0) {
        while (this.list_anexar_nota_emitida_na_prefeitura.length != this.list_url_anexar_nota_emitida_na_prefeitura.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexar_nota_emitida_na_prefeitura.length > 0) {
        var list_anexar_nota_emitida_na_prefeituraUrl2 = ''
        list_anexar_nota_emitida_na_prefeituraUrl2 = '['
        for (var i = 0; i < this.list_url_anexar_nota_emitida_na_prefeitura.length; i++) {
          list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2 + '"' + this.list_url_anexar_nota_emitida_na_prefeitura[i] + '",'
        }
        list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2.slice(0, -1)
        list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2 + "]"

      } else {
        list_anexar_nota_emitida_na_prefeituraUrl2 = ''
      }



      await this.getAnexoRegistroDePagamentoDeAutonomo2(this.anexar_registro_do_pagamento_de_aut_nomo);

      console.log(this.anexar_registro_do_pagamento_de_aut_nomo)
      console.log(this.anexar_registro_do_pagamento_de_aut_nomo.length)

      if (this.anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        while (this.anexar_registro_do_pagamento_de_aut_nomo.length != this.list_url_anexar_registro_do_pagamento_de_aut_nomo.length) {
          console.log("esperando2....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }


      if (this.anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        var anexarRegistrosPagamentoAutonomoeUrl2 = ''
        anexarRegistrosPagamentoAutonomoeUrl2 = '['
        for (var i = 0; i < this.list_url_anexar_registro_do_pagamento_de_aut_nomo.length; i++) {
          anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2 + '"' + this.list_url_anexar_registro_do_pagamento_de_aut_nomo[i] + '",'
        }
        anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2.slice(0, -1)
        anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2 + "]"

      } else {
        anexarRegistrosPagamentoAutonomoeUrl2 = ''
      }

      console.log(anexarRegistrosPagamentoAutonomoeUrl2)



      await this.getPipesUrlArquivoRateio(this.arquivosDeRateio);
      if (this.arquivosDeRateio.length > 0) {
        while (this.arquivosDeRateio.length != this.arquivosDeRateioUrl.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.arquivosDeRateio.length > 0) {
        var arquivosDeRateioUrl2 = ''
        arquivosDeRateioUrl2 = '['
        for (var i = 0; i < this.arquivosDeRateioUrl.length; i++) {
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + '"' + this.arquivosDeRateioUrl[i] + '",'
        }
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2.slice(0, -1)
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + "]"

      } else {
        arquivosDeRateioUrl2 = ''
      }

      await this.getPipesUrlNota(this.notas);
      // console.log(this.notas)
      // console.log(this.notas.length)
      // console.log(this.notasUrl)

      if (this.notas.length > 0) {
        while (this.notas.length != this.notasUrl.length) {
          console.log(this.notas.length)
          console.log(this.notasUrl.length)
          console.log("esperando2....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }


      if (this.notas.length > 0) {
        var notasUrl2 = ''
        notasUrl2 = '['
        for (var i = 0; i < this.notasUrl.length; i++) {
          notasUrl2 = notasUrl2 + '"' + this.notasUrl[i] + '",'
        }
        notasUrl2 = notasUrl2.slice(0, -1)
        notasUrl2 = notasUrl2 + "]"

      } else {
        notasUrl2 = ''
      }

      await this.getPipesUrlBoleto(this.boletos);
      // console.log(this.boletos)
      // console.log(this.boletos.length)
      // console.log(this.boletosUrl)

      if (this.boletos.length > 0) {
        while (this.boletos.length != this.boletosUrl.length) {
          console.log("esperando3....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.boletos.length > 0) {
        var boletosUrl2 = ''
        boletosUrl2 = '['
        for (var i = 0; i < this.boletosUrl.length; i++) {
          boletosUrl2 = boletosUrl2 + '"' + this.boletosUrl[i] + '",'
        }
        boletosUrl2 = boletosUrl2.slice(0, -1)
        boletosUrl2 = boletosUrl2 + "]"

      } else {
        boletosUrl2 = ''
      }

      await this.getPipesUrlXML(this.xml);
      // console.log(this.xml)
      // console.log(this.xml.length)
      // console.log(this.xmlUrl)

      if (this.xml.length > 0) {
        while (this.xml.length != this.xmlUrl.length) {
          console.log("esperando4....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.xml.length > 0) {
        var xmlUrl2 = ''
        xmlUrl2 = '['
        for (var i = 0; i < this.xmlUrl.length; i++) {
          xmlUrl2 = xmlUrl2 + '"' + this.xmlUrl[i] + '",'
        }
        xmlUrl2 = xmlUrl2.slice(0, -1)
        xmlUrl2 = xmlUrl2 + "]"

      } else {
        xmlUrl2 = ''
      }


      await this.getPipesUrlDocumentacaoSuporte(this.documentacaoSuporte);
      if (this.documentacaoSuporte.length > 0) {
        while (this.documentacaoSuporte.length != this.documentacaoSuporteUrl.length) {
          console.log("esperando5....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.documentacaoSuporte.length > 0) {
        var documentacaoSuporteUrl2 = ''
        documentacaoSuporteUrl2 = '['
        for (var i = 0; i < this.documentacaoSuporteUrl.length; i++) {
          documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + '"' + this.documentacaoSuporteUrl[i] + '",'
        }
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2.slice(0, -1)
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + "]"

      } else {
        documentacaoSuporteUrl2 = ''
      }



      await this.getPipesUrlRegistro(this.registros);
      console.log(this.registros)
      console.log(this.registros.length)
      if (this.registros.length > 0) {
        while (this.registros.length != this.registrosUrl.length) {
          console.log("esperando registros....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.registros.length > 0) {

        var registrosUrl2 = ''
        registrosUrl2 = '['
        for (var i = 0; i < this.registrosUrl.length; i++) {
          registrosUrl2 = registrosUrl2 + '"' + this.registrosUrl[i] + '",'
        }
        registrosUrl2 = registrosUrl2.slice(0, -1)
        registrosUrl2 = registrosUrl2 + "]"

      } else {
        registrosUrl2 = ''
      }

      await this.getPipesUrlNfProdutoOriginal(this.nfProdutoOriginal);
      if (this.nfProdutoOriginal.length > 0) {
        while (this.nfProdutoOriginal.length != this.nfProdutoOriginalUrl.length) {
          console.log("esperando6....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.nfProdutoOriginal.length > 0) {
        var nfProdutoOriginalUrl2 = ''
        nfProdutoOriginalUrl2 = '['
        for (var i = 0; i < this.nfProdutoOriginalUrl.length; i++) {
          nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + '"' + this.nfProdutoOriginalUrl[i] + '",'
        }
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2.slice(0, -1)
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + "]"

      } else {
        nfProdutoOriginalUrl2 = ''
      }

      await this.getPipesUrlListaItensDaPo(this.listaDeItensDaPo);
      if (this.listaDeItensDaPo.length > 0) {
        while (this.listaDeItensDaPo.length != this.listaDeItensDaPoUrl.length) {
          console.log("esperando7....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.listaDeItensDaPo.length > 0) {
        var listaDeItensDaPoUrl2 = ''
        listaDeItensDaPoUrl2 = '['
        for (var i = 0; i < this.listaDeItensDaPoUrl.length; i++) {
          listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + '"' + this.listaDeItensDaPoUrl[i] + '",'
        }
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2.slice(0, -1)
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + "]"

      } else {
        listaDeItensDaPoUrl2 = ''
      }

      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email) + '@nutrien.com';
      // let risco_sacado = this.trataInput.removeCaracters(this.formulario.getRawValue().risco_sacado)
      let tipo_de_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_nota)
      let qual_a_quantidade_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().qual_a_quantidade_de_cte)
      let conta_contabil = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_contabil)
      let documento_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().documento_do_fornecedor)
      let cpf_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf_do_fornecedor)
      let cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj_fornecedor)
      let nome_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_fornecedor)
      let cnpj_da_empresa_nutrien = this.formulario.getRawValue().cnpj_da_empresa_nutrien.replaceAll('.', '').replaceAll("/", "").replaceAll("-", "");
      let nome_empresa_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_empresa_nutrien)
      let nro_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().nro_nota)
      let data_de_envio_de_nf = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_envio_de_nf)
      let valor_total_da_nf = this.valorTotalNfAlterado;
      let data_de_vencimento_da_nota = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento_da_nota))
      let departamento = this.trataInput.removeCaracters(this.formulario.getRawValue().departamento)
      let numero_po = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_po)
      let quantidade_de_itens_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_de_itens_do_pedido)
      let numero_do_item_da_po = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_item_da_po)

      let centro_de_custo = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_custo)
      let nota_para_pagamento_fora_da_politica = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_fora_da_politica)
      let nota_para_pagamento_urgente = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_urgente)
      let motivo_da_excecao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_excecao)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
      let informacoes_complementares_da_po = '[' + this.informacoesComplementaresPo + ']'

      let tipo_de_custo_planejado = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_custo_planejado)
      let n_mero_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_de_cte)
      let opex_e_capex = this.trataInput.removeCaracters(this.formulario.getRawValue().opex_e_capex)
      let tipo_de_importa_o = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_importa_o)
      let tipo_de_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_pagamento)

      let n_mero_afe = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_afe)
      let estado_de_origem = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_origem)
      let estado_de_destino = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_destino)
      let grc = this.trataInput.removeCaracters(this.formulario.getRawValue().grc)
      // let parcela_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().parcela_do_aluguel)
      let m_s_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().m_s_do_aluguel)
      let ano_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().ano_do_aluguel)
      let confirma_entrega_ou_prestacao = this.trataInput.removeCaracters(this.formulario.getRawValue().confirma_entrega_ou_prestacao)
      let motivo_negacao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_negacao)

      let anexo_para_pagamento_de_aut_nomo = this.trataInput.removeCaracters(this.formulario.getRawValue().anexo_para_pagamento_de_aut_nomo);
      let cont_m_registro_na_prefeitura = this.trataInput.removeCaracters(this.formulario.getRawValue().cont_m_registro_na_prefeitura);

      // console.log('departamento', departamento)

      let pipe_fields = pipe_fields_ids
      let fields_values = [
        nome,
        email,
        // this.riscoSacadoString,
        tipo_de_nota,
        qual_a_quantidade_de_cte,
        conta_contabil,
        documento_do_fornecedor,
        cpf_do_fornecedor,
        cnpj_fornecedor,
        nome_do_fornecedor,
        cnpj_da_empresa_nutrien,
        nome_empresa_nutrien,
        nro_nota,
        data_de_envio_de_nf,
        valor_total_da_nf,
        this.notaComRateioDeValoresString,
        arquivosDeRateioUrl2,
        data_de_vencimento_da_nota,
        departamento,
        numero_po,
        quantidade_de_itens_do_pedido,
        numero_do_item_da_po,
        listaDeItensDaPoUrl2,
        centro_de_custo,
        notasUrl2,
        this.dacteCorretoString,
        documentacaoSuporteUrl2,
        nota_para_pagamento_fora_da_politica,
        nota_para_pagamento_urgente,
        motivo_da_excecao,
        observacao,
        informacoes_complementares_da_po,
        boletosUrl2,
        xmlUrl2,
        nfProdutoOriginalUrl2,
        tipo_de_custo_planejado,
        n_mero_de_cte,
        opex_e_capex,
        tipo_de_importa_o,
        tipo_de_pagamento,
        n_mero_afe,

        // this.arquivosDeRateioUrl,
        // this.listaDeItensDaPoUrl,
        // this.notasUrl,
        // this.documentacaoSuporteUrl,
        // this.xmlUrl,
        // this.boletosUrl,
        // this.nfProdutoOriginalUrl,
        1,
        estado_de_origem,
        estado_de_destino,
        grc,
        // parcela_do_aluguel,
        m_s_do_aluguel,
        ano_do_aluguel,
        confirma_entrega_ou_prestacao,
        motivo_negacao,
        this.horaTrocaDaEmpresaFormatada,
        this.horaCriacaoDoCardFormatada,
        this.tempoDePreenchimentoDoFormulario,
        list_anexo_para_pagamento_de_aut_nomoUrl2,
        cont_m_registro_na_prefeitura,
        registrosUrl2,
        list_anexar_nota_emitida_na_prefeituraUrl2,
        this.preenchimento_manual_do_formul_rio,
        this.ocr_leitura,
        this.ocr_quantidade_de_campos_ocerizados,
        this.ocr_valor_total_da_nf,
        this.ocr_nome_fornecedor,
        this.ocr_cnpj_empresa_nutrien,
        this.ocr_cnpj_fornecedor,
        this.ocr_data_envio_nf,
        this.ocr_numero_da_nota,
        this.diferencaMost
      ]


      this.pipefy.criaBody(pipe_fields, fields_values, '302487539')


      this.pipefy.createCard2(this.pipefy.criaBody(pipe_fields, fields_values, '302487539')).subscribe((res) => {
        console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          }).then(() => {
            this.resetform()
            this.formularioInvalido = false
            this.camposComErro = []
            this.mostrarMensagemConferirCamposOcr = false
            this.mostrarMensagemConferirCamposSemOcr = false
            window.location.reload()
          })
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
          this.list_url_anexar_nota_emitida_na_prefeitura = []
          this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          this.list_anexo_para_pagamento_de_aut_nomo = []
          this.registros = []


          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi, this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
          this.list_url_anexar_nota_emitida_na_prefeitura = []
          this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          this.list_anexo_para_pagamento_de_aut_nomo = []
          this.registros = []
        }
      })

    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  saveArquivoRateio(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosDeRateio.push(files[i])
    }
  }

  removeFileArquivoRateio(file: File) {
    const index: number = this.arquivosDeRateio.indexOf(file);
    this.arquivosDeRateio.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.arquivosDeRateio.length == 0) {
      this.formulario.controls['arquivo_de_rateio'].markAsTouched()
      this.formulario.get('arquivo_de_rateio')?.setValue('')
    }
  }

  async getPipesUrlArquivoRateio(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.arquivosDeRateioUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosDeRateioUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoNotaEmitidaPrefeituraParaPagamento(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.list_url_anexar_nota_emitida_na_prefeitura.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexar_nota_emitida_na_prefeitura = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoParaPagamentoDeAutonomo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.list_url_anexo_para_pagamento_de_aut_nomo.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexo_para_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoRegistroDePagamentoDeAutonomo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);
        this.list_url_anexar_registro_do_pagamento_de_aut_nomo.push(fileUrlTemp)

      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoRegistroDePagamentoDeAutonomo2(files: any) {
    console.log("entreiiiiiiiiii")
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);
        console.log("ieieieie", fileUrlTemp)

        this.list_anexar_registro_do_pagamento_de_aut_nomo.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexo_para_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoNota(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.notas.push(files[i])
    }
  }

  removeFileNota(file: File) {
    const index: number = this.notas.indexOf(file);
    this.notas.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.notas.length == 0) {
      this.formulario.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_ou_boleto')?.setValue('')

    }
  }

  async getPipesUrlNota(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)
        // let url = res.data.attachments_url_pipefy[0]
        // this.arquivosNfBoletoUrl.push(url)
        console.log(urlResposta)
        // urlResposta = urlResposta.body.data.createPresignedUrl.url

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.notasUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.notasUrl = []
          }


        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.boletos.push(files[i])
    }
  }

  // save_anexar_nota_emitida_na_prefeitura(event: any): void {
  //   var files = event.target.files;

  //   for (var i = 0; i < files.length; i++) {
  //     this.list_anexar_nota_emitida_na_prefeitura.push(files[i])
  //   }
  // }

  // remove_anexar_nota_emitida_na_prefeitura(file: File) {
  //   const index: number = this.list_anexar_nota_emitida_na_prefeitura.indexOf(file);
  //   this.list_anexar_nota_emitida_na_prefeitura.splice(index, 1)
  //   Swal.fire({
  //     position: 'top-end',
  //     icon: 'success',
  //     title: 'Arquivo Removido com Sucesso.',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   if (this.list_anexar_nota_emitida_na_prefeitura.length == 0) {
  //     this.formulario.controls['anexar_nota_emitida_na_prefeitura'].markAsTouched()
  //     this.formulario.get('anexar_nota_emitida_na_prefeitura')?.setValue('')
  //   }
  // }


  save_anexar_registro_do_pagamento_de_aut_nomo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.list_anexar_registro_do_pagamento_de_aut_nomo.push(files[i])
    }
    console.log(this.list_anexar_registro_do_pagamento_de_aut_nomo)
  }

  remove_anexar_registro_do_pagamento_de_aut_nomo(file: File) {
    const index: number = this.list_anexar_registro_do_pagamento_de_aut_nomo.indexOf(file);
    this.list_anexar_registro_do_pagamento_de_aut_nomo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length == 0) {
      this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
      this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
    }
  }


  save_anexo_para_pagamento_de_aut_nomo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.list_anexo_para_pagamento_de_aut_nomo.push(files[i])
    }

    Object.getOwnPropertyNames(this.formulario.getRawValue()).forEach(v => {
      console.log(v, this.formulario.get(v)?.errors)
    })
    // console.log(this.formulario.getRawValue());
  }

  remove_anexo_para_pagamento_de_aut_nomo(file: File) {
    const index: number = this.list_anexo_para_pagamento_de_aut_nomo.indexOf(file);
    this.list_anexo_para_pagamento_de_aut_nomo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.list_anexo_para_pagamento_de_aut_nomo.length == 0) {
      this.formulario.controls['anexo_para_pagamento_de_aut_nomo'].markAsTouched()
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.setValue('')
    }
  }

  removeFileBoleto(file: File) {
    const index: number = this.boletos.indexOf(file);
    this.boletos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.boletos.length == 0) {
      this.formulario.controls['anexo_boleto'].markAsTouched()
      this.formulario.get('anexo_boleto')?.setValue('')
    }
  }

  async getPipesUrlBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.boletosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.boletosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoXML(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.xml.push(files[i])
    }
  }

  removeFileXML(file: File) {
    const index: number = this.xml.indexOf(file);
    this.xml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.xml.length == 0) {
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlXML(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.xmlUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.xmlUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoDocumentacaoSuporte(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.documentacaoSuporte.push(files[i])
    }
  }

  removeFilDocumentacaoSuporte(file: File) {
    const index: number = this.documentacaoSuporte.indexOf(file);
    this.documentacaoSuporte.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.documentacaoSuporte.length == 0) {
      this.formulario.controls['anexo_documentacao_suporte'].markAsTouched()
      this.formulario.get('anexo_documentacao_suporte')?.setValue('')
    }
  }

  async getPipesUrlDocumentacaoSuporte(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentacaoSuporteUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentacaoSuporteUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoNfProdutoOriginal(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.nfProdutoOriginal.push(files[i])
    }
  }


  removeFileNfProdutoOriginal(file: File) {
    const index: number = this.nfProdutoOriginal.indexOf(file);
    this.nfProdutoOriginal.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.nfProdutoOriginal.length == 0) {
      this.formulario.controls['nfProdutoOriginal'].markAsTouched()
      this.formulario.get('nfProdutoOriginal')?.setValue('')
    }
  }

  async getPipesUrlNfProdutoOriginal(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.nfProdutoOriginalUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.nfProdutoOriginalUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveListaDeItensDaPO(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.listaDeItensDaPo.push(files[i])
    }
  }

  removeListaDeItensDaPO(file: File) {
    const index: number = this.listaDeItensDaPo.indexOf(file);
    this.listaDeItensDaPo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.listaDeItensDaPo.length == 0) {
      this.formulario.controls['lista_de_itens_da_po'].markAsTouched()
      this.formulario.get('lista_de_itens_da_po')?.setValue('')
    }
  }

  async getPipesUrlListaItensDaPo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.listaDeItensDaPoUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.listaDeItensDaPoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  inserirNoArray(event: any) {
    // console.log(this.formulario.getRawValue().informacoes_complementares_da_po)
    let valor: any = event.target.value
    // console.log(valor)
    if (this.formulario.getRawValue().informacoes_complementares_da_po) {
      let valorTratado = '"' + valor + '"'
      this.informacoesComplementaresPo.push(valorTratado)
      // console.log(this.informacoesComplementaresPo)
    }
    if (!this.formulario.getRawValue().informacoes_complementares_da_po) {
      const index: number = this.informacoesComplementaresPo.indexOf(valor);
      this.informacoesComplementaresPo.splice(index, 1)
      // console.log(this.informacoesComplementaresPo)
    }
  }

  notaComRateiosValue(event: any) {
    var valor = event.target.value;

    if (valor == 'Sim') {
      this.notaComRateioDeValoresString = 'Sim';
      this.notaComRateios = true;
    }

    if (valor == 'Não') {
      this.notaComRateioDeValoresString = 'Não';
      this.notaComRateios = false;
      this.arquivosDeRateio = [];
      this.formulario.get('arquivo_de_rateio')?.setValue('');
    }
  }

  riscoSacadoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.riscoSacadoString = 'Sim'
      // console.log(this.riscoSacadoString)
    } else {
      this.riscoSacadoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }
  }

  dacteCorretoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.dacteCorretoString = 'Sim'
      // console.log(this.dacteCorretoString)
    } else {
      this.dacteCorretoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }

  }


  onDocumentoChange(event: any) {
    var valor = event.target.value
    // console.log(valor)
    if (valor == "CPF") {
      // console.log("cpf checked")
      this.formulario.get('cpf_do_fornecedor')?.setValidators([Validators.required, Validadores.cpf])
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cnpj_fornecedor')?.clearValidators()
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();
      this.formulario.get('cnpj_fornecedor')?.setValue('')

    }

    if (valor == "CNPJ") {
      // console.log("cnpj checked")
      this.formulario.get('cnpj_fornecedor')?.setValidators([Validators.required, Validadores.cnpj])
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cpf_do_fornecedor')?.clearValidators()
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
      this.formulario.get('cpf_do_fornecedor')?.setValue('')

    }
  }

  onEstadoOrigemChange(event: any) {
    this.formulario.get('estado_de_origem')?.setValue(event.target.value);
  }
  onEstadoDestinoChange(event: any) {
    this.formulario.get('estado_de_destino')?.setValue(event.target.value);
  }

  onConfirmaEntregaChange(event: any) {
    if (event.target.value == "Não se aplica") {
      this.formulario.get('motivo_negacao')?.setValidators([Validators.required, Validators.minLength(5)])
      this.formulario.get('motivo_negacao')?.updateValueAndValidity()
    } else {
      this.formulario.get('motivo_negacao')?.clearValidators()
      this.formulario.get('motivo_negacao')?.updateValueAndValidity()
      this.formulario.get('motivo_negacao')?.setValue("")
    }
  }

  onGrcChange(event: any) {
    if (event.target.value == "Sim") {
      this.formulario.get('estado_de_origem')?.setValidators([Validators.required])
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.setValidators([Validators.required])
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    } else {
      this.formulario.get('estado_de_origem')?.clearValidators();
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.clearValidators();
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    }
  }

  // onContemRegistroPrefeituraChange(event: any) {
  //   if (event.target.value == "Sim") {
  //     this.formulario.controls['anexar_nota_emitida_na_prefeitura'].markAsTouched()
  //     this.formulario.get('anexar_nota_emitida_na_prefeitura')?.setValue('')
  //     this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
  //   } else {
  //     this.list_anexar_nota_emitida_na_prefeitura = [];
  //     this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
  //     this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
  //   }
  // }

  onEmpresaChange(event: any) {

    // console.log("Entrei em empresa change")
    // console.log(this.trocouEmpresa)
    if (this.trocouEmpresa == false) {
      // console.log("entrei na hora inicial")
      this.horaTrocaDaEmpresa = Date.now()
      this.horaTrocaDaEmpresaFormatada = new Date()
      this.horaTrocaDaEmpresaFormatada = this.horaTrocaDaEmpresaFormatada.getHours() + ':' + this.horaTrocaDaEmpresaFormatada.getMinutes() + ':' + this.horaTrocaDaEmpresaFormatada.getSeconds()
      // console.log("hora inicial", this.horaTrocaDaEmpresaFormatada)
      this.trocouEmpresa = true
    }

    let empresa = event.target.value
    // console.log(empresa)
    this.formulario.get('grc')?.setValue('');
    this.formulario.get('grc')?.clearValidators();
    this.formulario.get('grc')?.updateValueAndValidity();
    this.formulario.get('estado_de_origem')?.clearValidators();
    this.formulario.get('estado_de_origem')?.updateValueAndValidity();
    this.formulario.get('estado_de_destino')?.clearValidators();
    this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    this.formulario.get('conta_contabil')?.clearValidators();
    this.formulario.get('conta_contabil')?.updateValueAndValidity();


    if (empresa === 'Agrichem') {
      this.formulario.get('departamento')?.setValidators([Validators.required])
      this.formulario.get('departamento')?.updateValueAndValidity();
      // this.formulario.get('grc')?.setValidators([Validators.required])
      // this.formulario.get('grc')?.updateValueAndValidity();
    }

    if (empresa === '' || empresa === 'TecAgro (FAG)' || empresa === 'TecAgro (SGL)' || empresa === 'TecAgro (FCR)' || empresa === 'Nutrien') {
      this.formulario.get('departamento')?.clearValidators()
      this.formulario.get('departamento')?.updateValueAndValidity();
    }


    if (empresa == 'Agrichem' && this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito') {
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if (empresa == 'Nutrien' && this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito') {
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if (this.formulario.getRawValue().tipo_de_nota !== 'Jurídico'
      && this.formulario.getRawValue().tipo_de_nota !== 'Aluguel'
      && this.formulario.getRawValue().tipo_de_nota !== 'Guias/Imposto/Taxas'
      && (this.formulario.getRawValue().nome_empresa_nutrien !== 'Agrichem' && this.formulario.getRawValue().tipo_de_nota !== 'Nota de Crédito')
      && (this.formulario.getRawValue().nome_empresa_nutrien !== 'Nutrien' && this.formulario.getRawValue().tipo_de_nota !== 'Nota de Crédito')) {
      // console.log("entrei aqui REEMBOLSO")
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }
  }

  onCnpjEmpresaNutrienChange(event: any) {
    let cnpjSelecionado = event.target.value;
    let empresaSelecionada = this.listaEmpresasCnpjs.filter(nome => nome.cnpj === cnpjSelecionado);
    this.formulario.get('nome_empresa_nutrien')?.setValue(empresaSelecionada[0].nome);
  }

  onPagamentoUrgenteChange(event: any) {
    let pagamentoUrgente = event.target.value
    // console.log("Pagamento Urgente : ", pagamentoUrgente)

    if (pagamentoUrgente === 'Sim') {
      this.formulario.get('motivo_da_excecao')?.setValidators([Validators.required])
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }

    if (pagamentoUrgente === 'Não') {
      this.formulario.get('motivo_da_excecao')?.clearValidators()
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }
  }

  onTipoDeNotaChange(event: any) {

    this.formulario.get('documento_do_fornecedor')?.setValidators(Validators.required);
    this.formulario.get('documento_do_fornecedor')?.updateValueAndValidity();

    this.formulario.get('anexo_nota_ou_boleto')?.setValidators(Validators.required);
    this.formulario.get('anexo_nota_ou_boleto')?.updateValueAndValidity();

    // console.log(event)
    let tipo_de_nota = event.target.value
    // console.log(tipo_de_nota)

    // if(tipo_de_nota === 'Reembolso' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias'){



    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço' ||
      tipo_de_nota == 'Pagamento de Autônomo'
    ) {

      // console.log("oi")
      this.formulario.get('conta_contabil')?.clearValidators();
      this.formulario.get('conta_contabil')?.updateValueAndValidity();
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators();
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity();
    }

    if (tipo_de_nota === 'Jurídico' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias/Imposto/Taxas'
      || (this.formulario.getRawValue().nome_empresa_nutrien == 'Agrichem' && this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito')
      || (this.formulario.getRawValue().nome_empresa_nutrien == 'Nutrien' && this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito')) {
      console.log("entrei em juridico")
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }

    // if(tipo_de_nota === 'Frete' || tipo_de_nota === 'CTE') {
    if (tipo_de_nota === 'Frete/CTE') {

      // console.log("entrei aqui FRETE")
      this.formulario.get('qual_a_quantidade_de_cte')?.setValidators([Validators.required])
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if (tipo_de_nota == 'Serviço') {
      this.nfProdutoOriginal = [];
      this.formulario.get('nf_do_produto_original')?.setValue('');
    }

    if (tipo_de_nota == 'Aluguel') {
      Swal.fire({
        // title: 'Você será redirecionado!',
        text: 'Para contas que não estiverem em nome da Nutrien, favor, anexar o contrato de referência no campo anexo documentação suporte.',
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600',
      }).then(() => {
        this.formulario.get('anexo_documentacao_suporte')?.setValidators([Validators.required])
        this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();
      })
      // this.formulario.get('parcela_do_aluguel')?.setValidators([Validators.required])
      // this.formulario.get('parcela_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('m_s_do_aluguel')?.setValidators([Validators.required])
      this.formulario.get('m_s_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('ano_do_aluguel')?.setValidators([Validators.required])
      this.formulario.get('ano_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('nro_nota')?.clearValidators();
      this.formulario.get('nro_nota')?.updateValueAndValidity();
    }

    if (tipo_de_nota != 'Aluguel') {
      this.formulario.get('anexo_documentacao_suporte')?.clearValidators();
      this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();

      this.formulario.get('nro_nota')?.setValidators([Validators.required])
      this.formulario.get('nro_nota')?.updateValueAndValidity();

      // this.formulario.get('parcela_do_aluguel')?.clearValidators();
      // this.formulario.get('parcela_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('m_s_do_aluguel')?.clearValidators();
      this.formulario.get('m_s_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('ano_do_aluguel')?.clearValidators();
      this.formulario.get('ano_do_aluguel')?.updateValueAndValidity();

    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Aluguel' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota === 'Frete/CTE' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço'
    ) {
      // console.log("entrei em numero do pedido obrigatorio")
      this.formulario.get('numero_po')?.setValidators([Validators.required])
      this.formulario.get('numero_po')?.updateValueAndValidity();
    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Devolução de Cliente' ||
      tipo_de_nota == 'Guias/Imposto/Taxas' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota === 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO') {

      // console.log("entrei em numero do pedido nao obrigatorio")
      this.formulario.get('numero_po')?.setValue("");
      this.formulario.get('numero_po')?.clearValidators()
      this.formulario.get('numero_po')?.updateValueAndValidity();
    }

    if (tipo_de_nota != 'Pagamento de Autônomo') {
      this.formulario.get('documento_do_fornecedor')?.setValue("");

      this.list_anexar_nota_emitida_na_prefeitura = [];
      this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
      this.list_anexo_para_pagamento_de_aut_nomo = [];
      this.formulario.get("anexar_nota_emitida_na_prefeitura")?.setValue("");
      this.formulario.get("anexar_registro_do_pagamento_de_aut_nomo")?.setValue("");
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.clearValidators();
      this.formulario.get("anexo_para_pagamento_de_aut_nomo")?.setValue("");
      this.formulario.get('nota_com_rasteio_de_valores')?.setValue('');
    }
    if (tipo_de_nota == 'Pagamento de Autônomo') {
      this.formulario.get('documento_do_fornecedor')?.setValue("CPF");
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.setValidators(Validators.required);
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.updateValueAndValidity();

      this.formulario.get('cpf_do_fornecedor')?.setValidators(Validators.required);
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('documento_do_fornecedor')?.clearValidators();
      this.formulario.get('documento_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('anexo_nota_ou_boleto')?.clearValidators();
      this.formulario.get('anexo_nota_ou_boleto')?.updateValueAndValidity();
      this.arquivosDeRateio = [];
      this.formulario.get('arquivo_de_rateio')?.setValue('');
    }


    if (tipo_de_nota !== 'Jurídico' && tipo_de_nota !== 'Aluguel' && tipo_de_nota !== 'Guias/Imposto/Taxas'
      && (this.formulario.getRawValue().nome_empresa_nutrien !== 'Agrichem' && this.formulario.getRawValue().tipo_de_nota !== 'Nota de Crédito')
      && (this.formulario.getRawValue().nome_empresa_nutrien !== 'Nutrien' && this.formulario.getRawValue().tipo_de_nota !== 'Nota de Crédito')) {
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }
  }

  onDataDeVencimentoChange(event: any) {
    var data = event.target.value



    Swal.fire({
      // title: 'Atenção',
      width: 600,
      html: '<h6 class="fs-20 color-light-green fw-900">Pagamentos Gerais  (Fornecedores)</h6><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Criação de solicitação de pagamento:</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Mínimo de 10 dias de antecedência às datas de pagamento.</p>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>É requerido o envio imediato dos documentos após seu recebimento.</p><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Condição de Pagamento</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Estabelecido no pedido de compra a contar da data de emissão do documento.</p><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Efetivação do Pagamento</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Atendidas as condições acima e o processo de abertura no NBS/pedido não apresente inconsistências, ocorrerá na próxima data de pagamento (15 ou 25) (feriado ou fim de semana no próximo dia útil).</p>',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
      padding: '10px 30px 30px',
      iconColor: '#88D600'
    })
  }

  getDataAtual() {
    let newDate = new Date(),
      month = '' + (newDate.getMonth() + 1),
      day = '' + newDate.getDate(),
      year = newDate.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.dataAtual = [year, month, day].join('-');
  }

  pasteValorDecimal(event: any) {
    event.preventDefault();
    let clipboardData = event.clipboardData;
    this.valorTotalNfAlterado = clipboardData.getData('text').replace(/[^0-9.,]/g, '').replace(/,/g, '.');;
    this.valorTotalNfAlterado = (Number.isInteger(parseFloat(this.valorTotalNfAlterado))) ? parseFloat(this.valorTotalNfAlterado) + ".00" : this.valorTotalNfAlterado;
    this.formataValorDecimal(this.valorTotalNfAlterado)
  }

  focusValorDecimal(event: any) {
    event.target.select()
  }

  formataValorDecimal(input: any) {
    if (input == '0,0' || input == '0.0') {
      input = "";
    } else if (input == "0," || input == "0.") {
      input = "";
    }
    input = input.replace(/[^0-9]/g, ''); // remove all the characters except the numeric values
    if (input.replace(/[^0-9]/g, '').length == 1) input = "0.0" + input;
    else if (input.replace(/[^0-9]/g, '').length == 2) input = "0." + input;
    else input = input.substring(0, input.length - 2) + '.' + input.substring(input.length - 2, input.length);
    input = new Number(input); // set the precision
    input = input.toFixed(2);    // only works with the "."
    input = input.replace(/\./g, ','); // change the splitter to ","
    let x = input.split(','); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(valor)) {
      valor = valor.replace(rgx, '$1' + '.' + '$2');
    }
    valor = valor == "NaN" ? "" : valor;
    this.valorTotalNfAlterado = valor + decimal;
    this.formulario.get('valor_total_da_nf')?.setValue(this.valorTotalNfAlterado);
  }

  getUserEmail() {
    let email = this.userInfo.getUserEmail();
    if (email && email.indexOf('@accenture.com') !== -1) {
      let name = email.substring(0, email.lastIndexOf("@"));
      this.formulario.controls['email']?.setValue(name);
    }
    if (email && email.indexOf('@nutrien.com') !== -1) {
      let name = email.substring(0, email.lastIndexOf("@"));
      this.formulario.controls['email']?.setValue(name);
    }
  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().email
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if (!regex.test(input))
      input = (input.substring(0, (input.length - 1)))
    this.formulario.controls['email']?.setValue(input);
  }

  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()



    this.arquivosDeRateio = [];
    this.notas = [];
    this.boletos = []
    this.xml = []
    this.documentacaoSuporte = [];
    this.listaDeItensDaPo = [];
    this.nfProdutoOriginal = []

    this.arquivosDeRateioUrl = [];
    this.notasUrl = []
    this.boletosUrl = []
    this.xmlUrl = []
    this.documentacaoSuporteUrl = [];
    this.listaDeItensDaPoUrl = [];
    this.nfProdutoOriginalUrl = []

    this.list_anexar_nota_emitida_na_prefeitura = [];
    this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
    this.list_anexo_para_pagamento_de_aut_nomo = [];
    this.registros = []


    this.list_url_anexar_nota_emitida_na_prefeitura = []
    this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
    this.list_anexo_para_pagamento_de_aut_nomo = []
    this.registros = []

    this.notaComRateios = false;

    this.formulario.get('cnpj_fornecedor')?.clearValidators()
    this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();
    this.formulario.get('cpf_do_fornecedor')?.clearValidators()
    this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
    this.formulario.get('departamento')?.clearValidators()
    this.formulario.get('departamento')?.updateValueAndValidity();
    this.formulario.get('nro_nota')?.clearValidators()
    this.formulario.get('nro_nota')?.updateValueAndValidity();

    this.formulario.get("anexar_nota_emitida_na_prefeitura")?.setValue("");
    this.formulario.get("anexar_registro_do_pagamento_de_aut_nomo")?.setValue("");
    this.formulario.get("anexo_para_pagamento_de_aut_nomo")?.setValue("");

    this.notaComRateioDeValoresString = ''
    this.riscoSacadoString = ''

    this.emailApi = !!this.userInfo.getUserEmail() ? this.userInfo.getUserEmail() : this.formulario.get('email')?.value;
    this.userNameApi = !!this.userInfo.getName() ? this.userInfo.getName() : this.formulario.get('nome')?.value;

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)

    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }
    this.getUserEmail();
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/I0t4K1gr', '_blank')
  }

  getTotalTempoAberturaDoCard(tempoInicio: any, tempoFim: any) {
    const diff = Math.max(tempoInicio, tempoFim) - Math.min(tempoInicio, tempoFim)
    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN

    const hrs = Math.floor(diff / HRS)
    const min = Math.floor((diff % HRS) / MIN).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const sec = Math.floor((diff % MIN) / SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const ms = Math.floor(diff % SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 4, useGrouping: false })

    // console.log(`${hrs}:${min}:${sec}`)

    this.tempoDePreenchimentoDoFormulario = `${hrs}:${min}:${sec}`
  }

  // saveRegistro(event: any): void {
  //   var files = event.target.files;

  //   for (var i = 0; i < files.length; i++) {
  //     this.registros.push(files[i])
  //     // this.list_anexar_registro_do_pagamento_de_aut_nomo.push(files[i])
  //   }
  // }

  // removeFileRegistro(file: File) {
  //   const index: number = this.list_anexar_registro_do_pagamento_de_aut_nomo.indexOf(file);
  //   this.list_anexar_registro_do_pagamento_de_aut_nomo.splice(index, 1)
  //   Swal.fire({
  //     position: 'top-end',
  //     icon: 'success',
  //     title: 'Arquivo Removido com Sucesso.',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length == 0) {
  //     this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
  //     this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
  //   }
  // }

  async getPipesUrlRegistro(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.registrosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.registrosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  // **** Most ****

  changeTipoDeNota(event: any) {
    let valor = event.target.value
    console.log(valor)

    switch (valor) {

      case "Aluguel":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Aluguel');
        this.changeCategoria('PO')
        this.changeSubcategoria('Aluguel')
        this.ajustaMoeda("Aluguel")
        this.camposOcr('Aluguel')
        break

      case "Ativo fixo (AFE)":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');

        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material (DANFE)")
        this.camposOcr("Material (DANFE)")
        break

      case "Benefícios":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Benefícios")
        this.camposOcr("Benefícios")
        break

      // Inserido como Água categoria e subcategoria qq um dos consumos seguindo orientação do Silvio

      case "Contas de consumo (Luz, água, telefone, internet, gás)":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Água');
        this.changeCategoria('PO')
        this.changeSubcategoria('Água')
        this.ajustaMoeda('Água')
        this.camposOcr("Contas de consumo (Luz, água, telefone, internet, gás)")
        break

      case "Correio":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Correios');
        this.changeCategoria('PO')
        this.changeSubcategoria('Correios')
        this.ajustaMoeda("Correio")
        this.camposOcr("Correio")
        break

      case "Escrituração - Não gera pagamento":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Escrituração - Não gera pagamento")
        this.camposOcr("Escrituração - Não gera pagamento")
        break

      case "Importação":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Importação")
        this.camposOcr("Importação")
        break

      case "Material (DANFE)":
        console.log("Entrei em material Danfe")
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');

        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material (DANFE)")
        this.camposOcr("Material (DANFE)")
        break

      case "Material Compras e aquisição":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição')
        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material Compras e aquisição")
        this.camposOcr("Material Compras e aquisição")
        break

      case "Sem valor fiscal":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Sem valor fiscal")
        this.camposOcr("Sem valor fiscal")
        break

      case "Serviço":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Serviço")
        this.camposOcr("Serviço")
        // this.fornecedorSelecionado = ''
        break

      case "Frete/CTE":
        this.formulario.controls['tipo_de_nota'].setValue('Frete/CTE');
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');
        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material (DANFE)")
        this.camposOcr("Material (DANFE)")
        break


      case "Cartório":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Cartório');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Cartório")
        this.camposOcr("Cartório")
        break

      case "Devolução de Cliente":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Devolução a clientes');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Devolução de Cliente")
        this.camposOcr("Devolução de Cliente")
        break

      case "Guias/Imposto/Taxas":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Taxas, Guias e Imposto');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Guias/Imposto/Taxas")
        this.camposOcr("Guias/Imposto/Taxas")
        break

      case "Incentivo - Doação":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Doações');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Incentivo - Doação")
        this.camposOcr("Incentivo - Doação")
        break

      case "Jurídico":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Jurídico');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Jurídico")
        this.camposOcr("Jurídico")
        break

      case "Nota de Crédito":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Nota de Crédito');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Nota de Crédito")
        this.camposOcr("Nota de Crédito")
        break

      case "Notas de Débito":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Notas de Débito');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Notas de Débito")
        this.camposOcr("Notas de Débito")
        break

      case "Remessa":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Remessa');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Remessa")
        this.camposOcr("Remessa")
        break

      case "Industrialização":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Doações');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Industrialização")
        this.camposOcr("Industrialização")
        break

      case "Outras NFs sem PO":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Doações');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Outras NFs sem PO")
        this.camposOcr("Outras NFs sem PO")
        break

      default:
        this.formulario.controls['categoria']?.setValue('');
    }
  }

  saveNotaFiscalOCR(event: any): void {

    var files = event.target.files;
    var tamanhoMaximoDoArquivo = 5000000

    // Para ioga Descomentar linhar 3192 - 3196; Comentar 3199 -3219; descomentar 3220 ate 3240

    if(this.formulario.getRawValue().tipo_de_nota == 'Correio' ||
      this.formulario.getRawValue().tipo_de_nota == 'Devolução de Cliente' ||
      this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito' ||
      this.formulario.getRawValue().tipo_de_nota == 'Outras NFs sem PO'){
        return
      }

    //  if (this.formulario.getRawValue().tipo_de_nota == 'Aluguel' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Jurídico' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Correio' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Cartório' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Devolução de Cliente' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Frete/CTE' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Incentivo - Doação' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Guias/Imposto/Taxas' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Nota de Crédito' ||
    //    this.formulario.getRawValue().tipo_de_nota == 'Sem valor fiscal'||
    //    this.formulario.getRawValue().tipo_de_nota == 'Outras NFs sem PO'

    //  ) {
    //    this.mostrarMensagemConferirCamposOcr = false
    //    this.mostrarMensagemConferirCamposSemOcr = true
    //    return
    //  }
    //  else{
    //    this.leituraMost(event)
    //  }

      if ((files[0].size > tamanhoMaximoDoArquivo) ||
      (this.formulario.getRawValue().tipo_de_nota == 'Ativo fixo (AFE)' ||
      this.formulario.getRawValue().tipo_de_nota == 'Benefícios' ||
      this.formulario.getRawValue().tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      this.formulario.getRawValue().tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      this.formulario.getRawValue().tipo_de_nota == 'Frete/CTE'||
      this.formulario.getRawValue().tipo_de_nota == 'Importação' ||
      this.formulario.getRawValue().tipo_de_nota == 'Industrialização' ||
      this.formulario.getRawValue().tipo_de_nota == 'Material (DANFE)' ||
      this.formulario.getRawValue().tipo_de_nota == 'Serviço'
      )){
        var tipo_de_nota = this.formulario.get('tipo_de_nota')?.value
        this.leituraMost(event, tipo_de_nota)
      }


    if ((this.formulario.getRawValue().tipo_de_nota == 'Aluguel' ||
      this.formulario.getRawValue().tipo_de_nota == 'Jurídico' ||
      this.formulario.getRawValue().tipo_de_nota == 'Cartório' ||
      this.formulario.getRawValue().tipo_de_nota == 'Incentivo - Doação' ||
      this.formulario.getRawValue().tipo_de_nota == 'Guias/Imposto/Taxas' ||
      this.formulario.getRawValue().tipo_de_nota == 'Sem valor fiscal') && (files[0].size < tamanhoMaximoDoArquivo))
    {
      var tipo_de_nota = this.formulario.get('tipo_de_nota')?.value

      this.leituraIoga(event, tipo_de_nota)
    }



    // ***MOST
  }

  leituraIoga(event: any, tipo_de_nota:any){
    {
      this.mostrarMensagemConferirCamposOcr = false
      this.mostrarMensagemConferirCamposSemOcr = true

      console.log("entrei em ioga")
      // return

      this.horaInicioChamadaMost = this.getHoraInicioMost()
      console.log('inicioioga', this.horaInicioChamadaMost)
      this.clearFieldsOCR();

      var files = event.target.files;
      console.log('arquivosNf: ', this.notas)
      console.log('arquivosNfUrl: ', this.notasUrl)

      for (var i = 0; i < files.length; i++) {
        for (var i = 0; i < files.length; i++) {
          var fileContent = files[i]
          let fileName = files[i].name
          this.fileName2 = files[i].name;

          console.log('filename', this.fileName2)

          var pontoPosicao = this.fileName2.lastIndexOf('.')
          if(pontoPosicao === -1){
            console.error('O nome do arquivo não contem uma extensao')
          }

          var extensao = this.fileName2.substring(pontoPosicao + 1)
          console.log('extensao', extensao)

          const reader = new FileReader();
          reader.readAsDataURL(fileContent);
          reader.onload = () => {
            fileContent = reader.result?.toString()
            var fileContentStart = fileContent.indexOf(",")
            fileContent = fileContent.substring(fileContentStart + 1)

            // console.log(fileContent)

            this.ocr = true
            console.log(this.ocr)
            this.spinner.show()

            var tipoNota = this.formulario.getRawValue().tipo_de_nota
            console.log('tipoNota',tipoNota)
            this.pipefy.Ioga(fileContent, tipoNota, extensao).pipe(
              timeout(180000)
            )
            .subscribe((res: any) => {
              console.log("RESPOSTA Ioga ==>", res)
              // console.log("Tamanho", res.body.result.length)
              this.spinner.hide()



              if(res.body.status != "erro"){
              this.mostrarMensagemConferirCamposOcr = true
              this.mostrarMensagemConferirCamposSemOcr = false

              var cpf_cnpj_emitente = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "doc_forn")
              cpf_cnpj_emitente = cpf_cnpj_emitente[0].prompt_result=="Não disponível"?"":cpf_cnpj_emitente[0].prompt_result

              var nome_emitente = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "nome_forn")
              nome_emitente = nome_emitente[0].prompt_result=="Não disponível"?"":nome_emitente[0].prompt_result


              var cpf_cnpj = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "doc_cli")
              cpf_cnpj = cpf_cnpj[0].prompt_result=="Não disponível"?"":cpf_cnpj[0].prompt_result


              var nome_tomador = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "nome_cli") // nome da empresa nutrien
              nome_tomador = nome_tomador[0].prompt_result=="Não disponível"?"":nome_tomador[0].prompt_result


              var dataEmissao = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "emissao")
              dataEmissao = dataEmissao[0].prompt_result=="Não disponível"?"": this.formataData(dataEmissao[0].prompt_result)


              var dataVencimento = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "vencimento")
              dataVencimento = dataVencimento[0].prompt_result=="Não disponível"?"": this.formataData(dataVencimento[0].prompt_result)


              var valorTotal = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "valor_nf")
              valorTotal = valorTotal[0].prompt_result=="Não disponível"?"":valorTotal[0].prompt_result


              var numNotaFiscal = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "num_nf")
              numNotaFiscal = numNotaFiscal[0].prompt_result=="Não disponível"?"":numNotaFiscal[0].prompt_result

              var nomeTomador = res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "nome_cli")
              nomeTomador = nomeTomador[0].prompt_result=="Não disponível"?"":nomeTomador[0].prompt_result

              var numeroSerie =  res.body.data.filter((i: { prompt_name: string; })=>i.prompt_name === "num_serie")
              numeroSerie = numeroSerie[0].prompt_result=="Não disponível"?"":numeroSerie[0].prompt_result


              cpf_cnpj_emitente !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente, tipo_de_nota) : null;
              cpf_cnpj !== '' ? this.searchTomadorOCR(cpf_cnpj, tipo_de_nota) : null;


              this.curadoriaOcr(dataEmissao, valorTotal, numNotaFiscal, cpf_cnpj_emitente,
              cpf_cnpj, nome_emitente, false)

              console.log('valores ' +
                "data_de_envio_de_nf: " + dataEmissao + " , " +
                "data_de_vencimento_da_nota: " + dataVencimento + " , " +
                "nro_nota: " + numNotaFiscal + " , " +
                "cnpj_fornecedor: " + cpf_cnpj_emitente + " , " +
                "nome_do_fornecedor: " + nome_emitente + " , " +
                "cnpj_da_empresa_nutrien: " + cpf_cnpj + " , " +
                "nome_empresa_nutrien_ocr: " + nomeTomador + " , " +
                "nr_de_serie: " + numeroSerie + " , "
              )

              this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao);
              this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento);
              this.formataValorDecimal(valorTotal);
              this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
              this.formulario.controls['nro_nota']?.setValue(numNotaFiscal);
              this.formulario.controls['cnpj_fornecedor']?.setValue(cpf_cnpj_emitente);
              this.formulario.controls['nome_do_fornecedor']?.setValue(((nome_emitente).split("\n")[0]).trim());
              this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(cpf_cnpj);

              this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(nomeTomador);
              this.formulario.controls['nr_de_serie']?.setValue(numeroSerie);

              
              

              this.spinner.hide()

            } else {

              console.log("IOGA: ENTREI EM ERRO")
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
              this.curadoriaOcr('', '', '', '', '', '', false)
            }
          }, (error: any) => {
            this.spinner.hide();
            console.log(error.status);
            console.log(error);
            if (error.name === 'TimeoutError') {
              console.log("A requisição excedeu o tempo limite.");
              this.preenchimento_manual_do_formul_rio = 'Sim'
              Swal.fire({
                title: 'Ops!',
                text: 'A requisição excedeu o tempo limite.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              });
              this.curadoriaOcr('', '', '', '', '', '', true)
            } else {

              // Tratar outros erros aqui
              Swal.fire({
                title: 'Ops!',
                text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              });

              this.curadoriaOcr('', '', '', '', '', '', false)
            }
            })
          }
        }
      }
    }
  }

  leituraMost(event:any, tipo_de_nota:any){

    this.horaInicioChamadaMost = this.getHoraInicioMost()
    this.clearFieldsOCR();
    var files = event.target.files;
    // console.log('arquivosNf: ', this.notas)
    // console.log('arquivosNfUrl: ', this.notasUrl)

    for (var i = 0; i < files.length; i++) {
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        this.fileName2 = files[i].name;
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")
          fileContent = fileContent.substring(fileContentStart + 1)
          // console.log(fileContent)
          // console.log(fileName)
          this.ocr = true
          // console.log(this.ocr)
          this.spinner.show()
          // this.pipefy.getUserIp().subscribe((res) => {
          //   console.log("entrei aqui")
          //   console.log("IP =>>", res)
          // })
          this.pipefy.testeMost(fileContent).pipe(
            timeout(60000) // aguarda por 1 minuto a resposta da most
          )
            .subscribe((res: any) => {
              console.log("RESPOSTA MOST ==>", res)
              // console.log("Tamanho", res.body.result.length)
              if (res.body.result.length > 0) {
                this.mostrarMensagemConferirCamposOcr = true
                this.mostrarMensagemConferirCamposSemOcr = false
                console.log("mostrar", this.mostrarMensagemConferirCamposOcr)
              }

              this.horaFimChamadaMost = this.getHoraFimMost()
              this.getTotalTempoMost(this.fimMostMiligsegundos, this.inicioMostMilisegundos)


              if (res.body.result.length > 0) {

                this.json_most = res.body.result[0]
                delete this.json_most.tables

                // let produtos = res.body.result[0].tables.filter((t:{name:string})=>(t.name == 'tabela_produto_servico'))
                // console.log('produtos >', produtos[0].values)

                let tipo = this.formulario.getRawValue().tipo_de_nota
                let chave = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'chave_acesso' && t.score >= 0.8)
                this.chaveAcesso = chave[0] && chave[0].value ? chave[0].value : '';

                if (tipo == 'Material Compras e aquisição' || tipo == 'Material (DANFE)') {
                  let tag = res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("bra-danfe"))
                  console.log(tag)
                  if (tag == -1) {
                    Swal.fire({
                      title: 'Atenção!',
                      text: 'A nota fiscal inserida não é do tipo informado.',
                      icon: 'info',
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#88D600',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    })
                  }
                } else if (tipo == 'Serviço') {
                  let tag = res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("carta-correcao-eletronica") || element.includes("bra-nfe"))
                  console.log(tag)
                  if (tag == -1) {
                    Swal.fire({
                      title: 'Atenção!',
                      text: 'A nota fiscal inserida não é do tipo informado.',
                      icon: 'info',
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#88D600',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    })
                  }
                }

                switch (tipo) {

                  // testar outros tipos de nova e inserir no filtro

                  case "Contas de consumo (Luz, água, telefone, internet, gás)":
                    var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cnpj' && t.score >= 0.8) || t.name == 'cpf_cnpj_emitente' || (t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' || t.name == 'cpf_cnpj_beneficiario' && t.score >= 0.8))
                    var nome_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'identificacao_emitente' && t.score >= 0.8) || (t.name == 'nome_prestador' && t.score >= 0.8))
                    var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'cpf_cnpj' && t.score >= 0.8)
                    var nome_tomador = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'nome_remetente' && t.score >= 0.8) || (t.name == 'razao_social' && t.score >= 0.8) || (t.name == 'nome' && t.score >= 0.8))
                    var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                    var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                    var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                    var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' || t.name == 'numero_fatura' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))
                    var cfop = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cfop' && t.score >= 0.8 || t.name == 'numero_cfop' && t.score >= 0.8))
                    var natureza_da_operacao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'natureza_operacao' && t.score >= 0.8)
                    var numero_de_serie = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'danfe_serie' && t.score >= 0.8) || (t.name == 'serie' && t.score >= 0.8))


                    cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                    nome_emitente = this.verificaCampoOcr(nome_emitente)
                    cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                    nome_tomador = this.verificaCampoOcr(nome_tomador)
                    dataEmissao = this.verificaCampoOcr(dataEmissao)
                    dataVencimento = this.verificaCampoOcr(dataVencimento)
                    valorTotal = this.verificaCampoOcr(valorTotal)
                    numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)
                    cfop = this.verificaCampoOcr(cfop)
                    natureza_da_operacao = this.verificaCampoOcr(natureza_da_operacao)
                    numero_de_serie = this.verificaCampoOcr(numero_de_serie)

                    this.curadoriaOcr(dataEmissao[0].value, valorTotal[0].value, numNotaFiscal[0].value, cpf_cnpj_emitente[0].value,
                      cpf_cnpj[0].value, nome_emitente[0].value, false)

                    this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                    this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
                    this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);
                    this.formulario.controls['cnpj_fornecedor']?.setValue(cpf_cnpj_emitente[0].value);
                    this.formulario.controls['nome_do_fornecedor']?.setValue(((nome_emitente[0].value).split("\n")[0]).trim());
                    this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(cpf_cnpj[0].value);
                    this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(nome_tomador[0].value);
                    this.formulario.controls['natureza_da_opera_o']?.setValue(natureza_da_operacao[0].value);
                    this.formulario.controls['cfop']?.setValue(cfop[0].value);
                    this.formulario.controls['nr_de_serie']?.setValue(numero_de_serie[0].value);

                    // this.cfop = cfop[0].value
                    // this.natureza_da_operacao = natureza_da_operacao[0].value
                    // console.log('cfop', this.cfop)
                    // console.log('natureza', this.natureza_da_operacao)

                    cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value, tipo_de_nota) : this.spinner.hide();
                    cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value, tipo_de_nota) : this.spinner.hide();
                    break;

                  case "Material Compras e aquisição": //validar se existe
                  case "Material (DANFE)":
                  case "Serviço":
                  case "Sem valor fiscal":
                  case "Benefícios":
                  case "Benefícios":
                  case "Escrituração - Não gera pagamento":
                  case "Importação":
                  case "Frete/CTE":
                  case "Ativo fixo (AFE)":
                  case "Cartório": //novos
                  case "Correio":
                  case "Devolução de Cliente":
                  case "Guias/Imposto/Taxas":
                  case "Incentivo - Doação":
                  case "Industrialização":
                  case "Jurídico":
                  case "Nota de Crédito":
                  case "Outras NFs sem PO":

                    // serviço = sem valor fiscal = Importação = Escrituração = Benefícios
                    var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj_emitente' || t.name == 'cnpj' || t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' || t.name == 'cpf_cnpj_beneficiario') && t.score >= 0.8)
                    var nome_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'identificacao_emitente' && t.score >= 0.8) || (t.name == 'nome_prestador' && t.score >= 0.8))
                    var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj') && t.score >= 0.8)
                    var nome_tomador = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'nome_remetente' && t.score >= 0.8) || (t.name == 'razao_social' && t.score >= 0.8) || (t.name == 'nome' && t.score >= 0.8))
                    var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                    var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                    var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                    var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'numero_documento' || t.name == 'numero_fatura' || t.name == 'numero_nota_fiscal' && t.score >= 0.8)
                    var cfop = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cfop' && t.score >= 0.8 || t.name == 'numero_cfop' && t.score >= 0.8))
                    var natureza_da_operacao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'natureza_operacao' && t.score >= 0.8)
                    var numero_de_serie = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'danfe_serie' && t.score >= 0.8) || (t.name == 'serie' && t.score >= 0.8))
                    var codigo_do_servico = res.body.result[0].fields.filter((t: {name:string; score:number}) =>(t.name == 'codigo_servico' && t.score >=0.8))
  
                    cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                    nome_emitente = this.verificaCampoOcr(nome_emitente)
                    cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                    nome_tomador = this.verificaCampoOcr(nome_tomador)
                    dataEmissao = this.verificaCampoOcr(dataEmissao)
                    dataVencimento = this.verificaCampoOcr(dataVencimento)
                    valorTotal = this.verificaCampoOcr(valorTotal)
                    numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)
                    cfop = this.verificaCampoOcr(cfop)
                    natureza_da_operacao = this.verificaCampoOcr(natureza_da_operacao)
                    numero_de_serie = this.verificaCampoOcr(numero_de_serie)
                    codigo_do_servico = this.verificaCampoOcr(codigo_do_servico)






                    this.curadoriaOcr(dataEmissao[0].value, valorTotal[0].value, numNotaFiscal[0].value, cpf_cnpj_emitente[0].value,
                      cpf_cnpj[0].value, nome_emitente[0].value, false)
                    cfop = this.verificaCampoOcr(cfop)
                    natureza_da_operacao = this.verificaCampoOcr(natureza_da_operacao)
                    numero_de_serie = this.verificaCampoOcr(numero_de_serie)


                    this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                    this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
                    this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);
                    this.formulario.controls['cnpj_fornecedor']?.setValue(cpf_cnpj_emitente[0].value);
                    this.formulario.controls['nome_do_fornecedor']?.setValue(((nome_emitente[0].value).split("\n")[0]).trim());
                    this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(cpf_cnpj[0].value);
                    this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(nome_tomador[0].value);
                    this.formulario.controls['natureza_da_opera_o']?.setValue(natureza_da_operacao[0].value);
                    this.formulario.controls['cfop']?.setValue(cfop[0].value);
                    this.formulario.controls['nr_de_serie']?.setValue(numero_de_serie[0].value);
                    // this.codigo_do_servico =  codigo_do_servico[0].value.replaceAll("\n"," ")

                 





                    cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value, tipo_de_nota) : this.spinner.hide();
                    cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value, tipo_de_nota) : this.spinner.hide();
                    break;

                  case "Aluguel":
                    var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj_emitente' || t.name == 'cnpj' && t.score >= 0.8) || (t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' && t.score >= 0.8) || (t.name == 'cpf_cnpj_beneficiario' && t.score >= 0.8))
                    var nome_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'identificacao_emitente' && t.score >= 0.8) || (t.name == 'nome_prestador' && t.score >= 0.8))
                    var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'cpf_cnpj' && t.score >= 0.8)
                    var nome_tomador = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'nome_remetente' && t.score >= 0.8) || (t.name == 'razao_social' && t.score >= 0.8) || (t.name == 'nome' && t.score >= 0.8))
                    var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                    var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                    var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                    var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' || t.name == 'numero_fatura' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))
                    var cfop = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cfop' && t.score >= 0.8 || t.name == 'numero_cfop' && t.score >= 0.8))
                    var natureza_da_operacao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'natureza_operacao' && t.score >= 0.8)
                    var numero_de_serie = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'danfe_serie' && t.score >= 0.8) || (t.name == 'serie' && t.score >= 0.8))


                    cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                    nome_emitente = this.verificaCampoOcr(nome_emitente)
                    cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                    nome_tomador = this.verificaCampoOcr(nome_tomador)
                    dataEmissao = this.verificaCampoOcr(dataEmissao)
                    dataVencimento = this.verificaCampoOcr(dataVencimento)
                    valorTotal = this.verificaCampoOcr(valorTotal)
                    numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)
                    cfop = this.verificaCampoOcr(cfop)
                    natureza_da_operacao = this.verificaCampoOcr(natureza_da_operacao)
                    numero_de_serie = this.verificaCampoOcr(numero_de_serie)


                    this.curadoriaOcr(dataEmissao[0].value, valorTotal[0].value, numNotaFiscal[0].value, cpf_cnpj_emitente[0].value,
                      cpf_cnpj[0].value, nome_emitente[0].value, false)

                    this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                    this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
                    this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);
                    this.formulario.controls['cnpj_fornecedor']?.setValue(cpf_cnpj_emitente[0].value);
                    this.formulario.controls['nome_do_fornecedor']?.setValue(((nome_emitente[0].value).split("\n")[0]).trim());
                    this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(cpf_cnpj[0].value);
                    this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(nome_tomador[0].value);
                    this.formulario.controls['natureza_da_opera_o']?.setValue(natureza_da_operacao[0].value);
                    this.formulario.controls['cfop']?.setValue(cfop[0].value);
                    this.formulario.controls['nr_de_serie']?.setValue(numero_de_serie[0].value);


                    cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value, tipo_de_nota) : this.spinner.hide();
                    cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value, tipo_de_nota) : this.spinner.hide();

                    break;

                  // case "Importação":

                  // var dataVencimento = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'data_vencimento')
                  // var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'numero_documento' || t.name == 'numero_fatura')
                  // var valorTotal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'valor_total_nota')


                  // dataVencimento =this.verificaCampoOcr(dataVencimento)
                  // valorTotal = this.verificaCampoOcr(valorTotal)
                  // numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                  // console.log(dataVencimento)
                  // console.log(valorTotal)

                  // this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                  // this.formulario.controls['valor_total_da_nf']?.setValue(this.formataValorDecimal(valorTotal[0].value))
                  // this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);


                  // this.spinner.hide()
                  // break;


                  default:
                    this.spinner.hide()
                    break;
                }
              } else {
                this.spinner.hide()
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                this.curadoriaOcr('', '', '', '', '', '', false)
              }
            }, (error: any) => {
              this.spinner.hide();
              console.log(error.status);
              console.log(error);
              if (error.name === 'TimeoutError') {
                console.log("A requisição excedeu o tempo limite.");
                this.preenchimento_manual_do_formul_rio = 'Sim'
                Swal.fire({
                  title: 'Ops!',
                  text: 'A requisição excedeu o tempo limite.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });
                this.curadoriaOcr('', '', '', '', '', '', true)
              } else {
                // Tratar outros erros aqui
                Swal.fire({
                  title: 'Ops!',
                  text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });

                this.curadoriaOcr('', '', '', '', '', '', false)
              }


            })
        }
      }
    }
    this.spinner.hide()
    // console.log('fim save2')

  }
  curadoriaOcr(data_envio_nf: any,
    valor_total_da_nf: any,
    numero_da_nota: any,
    cnpj_fornecedor: any,
    cnpj_empresa_nutrien: any,
    nome_fornecedor: any,
    timeoutError: boolean) {
    console.log('entrei aquiiii')
    this.ocr_leitura = ''
    this.ocr_quantidade_de_campos_ocerizados = 0
    this.ocr_data_envio_nf = ''
    this.ocr_valor_total_da_nf = ''
    this.ocr_numero_da_nota = ''
    this.ocr_cnpj_fornecedor = ''
    this.ocr_cnpj_empresa_nutrien = ''
    this.ocr_nome_fornecedor = ''



    console.log('dataenvio', timeoutError)
    let camposOcerizados: any = []
    let quantidadeDeCampos = 6 //quantidade de campos a serem ocerizados
    data_envio_nf !== '' ? camposOcerizados.push(data_envio_nf) : null
    valor_total_da_nf !== '' ? camposOcerizados.push(valor_total_da_nf) : null
    numero_da_nota !== '' ? camposOcerizados.push(numero_da_nota) : null
    cnpj_fornecedor !== '' ? camposOcerizados.push(cnpj_fornecedor) : null
    cnpj_empresa_nutrien !== '' ? camposOcerizados.push(cnpj_empresa_nutrien) : null
    nome_fornecedor !== '' ? camposOcerizados.push(nome_fornecedor) : null

    console.log("Campos Ocerizados", camposOcerizados)
    // fazer if para preencher valores das variaveis
    if (timeoutError) {
      this.ocr_leitura = "Timeout(60 segundos)"
      this.ocr_quantidade_de_campos_ocerizados = 0
      data_envio_nf == '' ? this.ocr_data_envio_nf = 'Não' : this.ocr_data_envio_nf = 'Sim'
      valor_total_da_nf == '' ? this.ocr_valor_total_da_nf = 'Não' : this.ocr_valor_total_da_nf = 'Sim'
      numero_da_nota == '' ? this.ocr_numero_da_nota = 'Não' : this.ocr_numero_da_nota = 'Sim'
      cnpj_fornecedor == '' ? this.ocr_cnpj_fornecedor = 'Não' : this.ocr_cnpj_fornecedor = 'Sim'
      cnpj_empresa_nutrien == '' ? this.ocr_cnpj_empresa_nutrien = 'Não' : this.ocr_cnpj_empresa_nutrien = 'Sim'
      nome_fornecedor == '' ? this.ocr_nome_fornecedor = 'Não' : this.ocr_nome_fornecedor = 'Sim'
    }
    if (camposOcerizados.length == 0 && timeoutError == false) {
      this.ocr_leitura = "Leitura com Erro"
      this.ocr_quantidade_de_campos_ocerizados = camposOcerizados.length
      data_envio_nf == '' ? this.ocr_data_envio_nf = 'Não' : this.ocr_data_envio_nf = 'Sim'
      valor_total_da_nf == '' ? this.ocr_valor_total_da_nf = 'Não' : this.ocr_valor_total_da_nf = 'Sim'
      numero_da_nota == '' ? this.ocr_numero_da_nota = 'Não' : this.ocr_numero_da_nota = 'Sim'
      cnpj_fornecedor == '' ? this.ocr_cnpj_fornecedor = 'Não' : this.ocr_cnpj_fornecedor = 'Sim'
      cnpj_empresa_nutrien == '' ? this.ocr_cnpj_empresa_nutrien = 'Não' : this.ocr_cnpj_empresa_nutrien = 'Sim'
      nome_fornecedor == '' ? this.ocr_nome_fornecedor = 'Não' : this.ocr_nome_fornecedor = 'Sim'
    }
    if (camposOcerizados.length > 0 && camposOcerizados.length < quantidadeDeCampos) {
      this.ocr_leitura = "Leitura Parcial"
      this.ocr_quantidade_de_campos_ocerizados = camposOcerizados.length
      data_envio_nf == '' || data_envio_nf == null ? this.ocr_data_envio_nf = 'Não' : this.ocr_data_envio_nf = 'Sim'
      valor_total_da_nf == '' || valor_total_da_nf == null ? this.ocr_valor_total_da_nf = 'Não' : this.ocr_valor_total_da_nf = 'Sim'
      numero_da_nota == '' || numero_da_nota == null ? this.ocr_numero_da_nota = 'Não' : this.ocr_numero_da_nota = 'Sim'
      cnpj_fornecedor == '' || cnpj_fornecedor == null ? this.ocr_cnpj_fornecedor = 'Não' : this.ocr_cnpj_fornecedor = 'Sim'
      cnpj_empresa_nutrien == '' || cnpj_empresa_nutrien == null ? this.ocr_cnpj_empresa_nutrien = 'Não' : this.ocr_cnpj_empresa_nutrien = 'Sim'
      nome_fornecedor == '' || nome_fornecedor == null ? this.ocr_nome_fornecedor = 'Não' : this.ocr_nome_fornecedor = 'Sim'

    }
    if (camposOcerizados.length == quantidadeDeCampos) {
      this.ocr_leitura = "Leitura Total"
      this.ocr_quantidade_de_campos_ocerizados = camposOcerizados.length
      data_envio_nf == '' ? this.ocr_data_envio_nf = 'Não' : this.ocr_data_envio_nf = 'Sim'
      valor_total_da_nf == '' ? this.ocr_valor_total_da_nf = 'Não' : this.ocr_valor_total_da_nf = 'Sim'
      numero_da_nota == '' ? this.ocr_numero_da_nota = 'Não' : this.ocr_numero_da_nota = 'Sim'
      cnpj_fornecedor == '' ? this.ocr_cnpj_fornecedor = 'Não' : this.ocr_cnpj_fornecedor = 'Sim'
      cnpj_empresa_nutrien == '' ? this.ocr_cnpj_empresa_nutrien = 'Não' : this.ocr_cnpj_empresa_nutrien = 'Sim'
      nome_fornecedor == '' ? this.ocr_nome_fornecedor = 'Não' : this.ocr_nome_fornecedor = 'Sim'
    }

    console.log(this.ocr_leitura)
    console.log(camposOcerizados)
    console.log(this.ocr_data_envio_nf,
      this.ocr_valor_total_da_nf,
      this.ocr_numero_da_nota,
      this.ocr_cnpj_fornecedor,
      this.ocr_cnpj_empresa_nutrien,
      this.ocr_nome_fornecedor,
      this.ocr_quantidade_de_campos_ocerizados)




  }

  verificaCampoOcr(campoOcr: any) {
    var retorno = campoOcr
    // console.log(retorno)
    if (retorno.length == 0) {
      // console.log("entrei aqui")
      retorno = [
        {
          name: '',
          value: ''
        }
      ]
      return retorno
    }
    return retorno
  }

  searchFornecedorOCR(valor: any, tipo_de_nota:any) {
    console.log("entrei em search", tipo_de_nota)
    this.listaFornecedores = !this.listaFornecedores.length ? this.listaFornecedoresOriginal : this.listaFornecedores;
    var filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    console.log('filtro:', filtro)
    console.log('filtro tamanho:', filtro.length)
    filtro.length > 11 ?
      this.formulario.controls['documento_do_fornecedor']?.setValue("CNPJ") :
      this.formulario.controls['documento_do_fornecedor']?.setValue("CPF");
    this.spinner.hide()

    // Descomentar caso seja necessário entrar o Masterdata.

    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaFornecedores = [];

      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length > 0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaFornecedores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
            if(tipo_de_nota !=='Guias/Imposto/Taxas'){
              console.log("fui no masterdata")
            // this.formulario.controls['cnpj_fornecedor']?.setValue(this.listaFornecedores[0].cnpj);
            this.formulario.controls['nome_do_fornecedor']?.setValue(this.listaFornecedores[0].nome);
            }



          }
        } else {
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        }
        // this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
          console.log(error.message)
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        })
    } else {
      this.listaFornecedores = this.listaFornecedoresOriginal;
    }
  }

  searchTomadorOCR(valor: any,tipo_de_nota:any) {
    console.log("entrei em search t", tipo_de_nota)

    this.spinner.hide()
    // remover o return caso chame precise do masterdata
    // return
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.ocr = false
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length > 0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaTomadores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
          }
          if(tipo_de_nota !=='Guias/Imposto/Taxas'){
            console.log("fui no masterdata")
            // this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(this.listaTomadores[0].cnpj);
          this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(this.listaTomadores[0].nome);
          }
          
        } else {
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        }
        this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
          console.log(error.message)
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        })

    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  getHoraInicioMost() {
    console.log("entreiii aquiiii")
    var horaSolicitacao = new Date
    this.inicioMostMilisegundos = Date.now()
    console.log('hora Inicio:' + horaSolicitacao)
    console.log('hora inicio milisegundos: ', this.inicioMostMilisegundos)
    return horaSolicitacao.getHours() + ':' + horaSolicitacao.getMinutes() + ':' + horaSolicitacao.getSeconds()
  }

  getHoraFimMost() {
    var horaSolicitacao = new Date()
    this.fimMostMiligsegundos = Date.now()
    console.log('hora Fim:' + horaSolicitacao)
    return horaSolicitacao.getHours() + ':' + horaSolicitacao.getMinutes() + ':' + horaSolicitacao.getSeconds()
  }

  getTotalTempoMost(tempoInicio: any, tempoFim: any) {
    const diff = Math.max(tempoInicio, tempoFim) - Math.min(tempoInicio, tempoFim)
    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN

    const hrs = Math.floor(diff / HRS)
    const min = Math.floor((diff % HRS) / MIN).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const sec = Math.floor((diff % MIN) / SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const ms = Math.floor(diff % SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 4, useGrouping: false })

    console.log(`${hrs}:${min}:${sec}`)

    this.diferencaMost = `${hrs}:${min}:${sec}`
    console.log('tempoOcr', this.diferencaMost)
  }

  clearFieldsOCR() {
    console.log('entrei clear fields')
    console.log('1 this.arquivosNf:', this.notas)
    console.log('1 this.arquivosNfUrl:', this.notasUrl)
    console.log('1 this.arquivosNfBoleto:', this.boletos)
    console.log('1 this.arquivosNfBoletoUrl:', this.boletosUrl)

    this.notas = [];
    this.notasUrl = [];
    this.boletos = [];
    this.boletosUrl = [];
    this.formulario.controls['cnpj_fornecedor']?.setValue("");
    this.formulario.controls['cpf_do_fornecedor']?.setValue("")
    this.formulario.controls['nome_do_fornecedor']?.setValue("");
    this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue("");
    this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue("");
    this.formulario.controls['documento_do_fornecedor']?.setValue("");
    this.listaFornecedores = [];
    this.listaTomadores = [];
    this.valorTotalNfAlterado = '';
    this.formulario.controls['valor_total_da_nf']?.setValue('');
    this.formulario.controls['data_de_envio_de_nf']?.setValue('');
    this.formulario.controls['data_de_vencimento_da_nota']?.setValue('');
    this.formulario.controls['nro_nota']?.setValue('');

    console.log('2 this.arquivosNf:', this.notas)
    console.log('2 this.arquivosNfUrl:', this.notasUrl)
    console.log('2 this.arquivosNfBoleto:', this.boletos)
    console.log('2 this.arquivosNfBoletoUrl:', this.boletosUrl)
  }


  changeCategoria(event: any) {
    console.log("Entrei change categoria")
    this.zerarTodosAnexos();
    switch (event) {
      case 'PO':
        this.categSelecionadaPO();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
      case 'NPO':
        this.categSelecionadaNPO();
        // this.changeTipoOperacao('');
        // this.changeTipoLancamento('');
        break
      default:
        this.categSelecionadaVazio();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
    }
  }

  changeSubcategoria(event: any) {
    //TO DO
    console.log("subcategoria: ", event)
    this.zerarTodosCamposSubcategoria();
    this.zerarTodosAnexos();
    switch (event) {
      case 'Materiais':

        console.log("entrei em materiais")

        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();

        if (this.formulario.getRawValue().tipo_de_nota == "Material (DANFE)") { // TO DO
          this.formulario.controls['tipo_operacao']?.setValue("Compras/Aquisição")
        }

        if (this.formulario.getRawValue().tipo_de_nota == "Material Compras e aquisição") {
          this.formulario.controls['tipo_operacao']?.setValue("Compras/Aquisição")
        }

        break;
      case 'Serviços':
      case 'Escrituração - Não gera pagamento':
        console.log("entrei em serviço")

        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();
        break

      case 'Frete':
        break

      case 'Benefícios':
        console.log("entrei em beneficios")
        this.formulario.controls['tipo_de_nota']?.setValue('Benefícios');
        break;
      case 'Boleto':
      case 'Nota de Débito':
      case 'Telefonia':
      case 'Água':
      case 'Energia':
      case 'Fatura':
      case 'Aluguel':
      case 'Correios':
        console.log("entrei em outros")
        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();
        break
      default:
        break;
    }
  }

  changeTipoOperacao(event: any) {
    //TO DO
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Matéria Prima':
        this.formulario.controls['migo']?.setValidators([Validators.required]);
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['migo']?.setValue('');
        this.formulario.controls['migo']?.clearValidators();
        this.formulario.controls['migo']?.markAsUntouched();
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
    }
  }

  changeTipoLancamento(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      // case 'Depósito Judicial':
      //   this.formulario.controls['n_mero_do_processo']?.setValidators([Validators.required]);
      //   this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
      //   break;
      default:
        // this.formulario.controls['n_mero_do_processo']?.setValue('');
        // this.formulario.controls['n_mero_do_processo']?.clearValidators();
        // this.formulario.controls['n_mero_do_processo']?.markAsUntouched();
        // this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();

        break;
    }
  }

  categSelecionadaVazio() {
    //TO DO

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosAnexos();

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValue('');
    // this.formulario.controls['favorecido']?.clearValidators();
    // this.formulario.controls['favorecido']?.markAsUntouched();
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValue('');
    // this.formulario.controls['valor_do_fi']?.clearValidators();
    // this.formulario.controls['valor_do_fi']?.markAsUntouched();
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  categSelecionadaNPO() {
    //TO DO
    this.formulario.controls['tipo_de_lan_amento']?.setValidators([Validators.required]);
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    // this.formulario.controls['anexo_npo_modelo_excel']?.setValidators([Validators.required]);
    // this.formulario.controls['anexo_npo_modelo_excel']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValidators([Validators.required]);
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValidators([Validators.required]);
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosCamposQtdePedidos();
  }

  categSelecionadaPO() {
    //TO DO
    this.formulario.controls['subcategoria_po']?.setValidators([Validators.required]);
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  ajustaMoeda(tipoDeNota: string) {

    if (tipoDeNota == "Importação") {
      this.formulario.controls['moeda']?.setValue('USD');
    }
    else {
      this.formulario.controls['moeda']?.setValue('BRL');
    }
  }

  // IPE
  zerarTodosCamposQtdePedidos() {
    // this.formulario.controls['qtde_pedidos'].setValue('');
    // this.formulario.controls['qtde_pedidos'].clearValidators();
    // this.formulario.controls['qtde_pedidos'].setErrors(null);
    // this.formulario.controls['qtde_pedidos'].markAsUntouched();
    // this.formulario.controls['qtde_pedidos'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_1'].setValue('');
    // this.formulario.controls['numero_linha_pedido_1'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_1'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_1'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_2'].setValue('');
    // this.formulario.controls['numero_linha_pedido_2'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_2'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_2'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_3'].setValue('');
    // this.formulario.controls['numero_linha_pedido_3'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_3'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_3'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_4'].setValue('');
    // this.formulario.controls['numero_linha_pedido_4'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_4'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_4'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_5'].setValue('');
    // this.formulario.controls['numero_linha_pedido_5'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_5'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_5'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_6'].setValue('');
    // this.formulario.controls['numero_linha_pedido_6'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_6'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_6'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_7'].setValue('');
    // this.formulario.controls['numero_linha_pedido_7'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_7'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_7'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_8'].setValue('');
    // this.formulario.controls['numero_linha_pedido_8'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_8'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_8'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_9'].setValue('');
    // this.formulario.controls['numero_linha_pedido_9'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_9'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_9'].updateValueAndValidity();

    // this.formulario.controls['numero_linha_pedido_10'].setValue('');
    // this.formulario.controls['numero_linha_pedido_10'].clearValidators();
    // this.formulario.controls['numero_linha_pedido_10'].setErrors(null);
    // this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
    // this.formulario.controls['numero_linha_pedido_10'].updateValueAndValidity();

    // this.formulario.controls['lista_pedidos'].setValue('');
    // this.formulario.controls['lista_pedidos'].clearValidators();
    // this.formulario.controls['lista_pedidos'].setErrors(null);
    // this.formulario.controls['lista_pedidos'].markAsUntouched();
    // this.formulario.controls['lista_pedidos'].updateValueAndValidity();
  }

  zerarTodosAnexos() {
    this.formulario.controls['arquivo_de_rateio'].clearValidators();
    this.formulario.controls['arquivo_de_rateio']?.setValue('');
    this.formulario.controls['arquivo_de_rateio'].setErrors(null);
    this.formulario.controls['arquivo_de_rateio'].markAsUntouched();
    this.formulario.controls['arquivo_de_rateio'].updateValueAndValidity;

    this.formulario.controls['anexo_nota_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_ou_boleto']?.setValue('');
    this.formulario.controls['anexo_nota_ou_boleto'].setErrors(null);
    this.formulario.controls['anexo_nota_ou_boleto'].markAsUntouched();
    this.formulario.controls['anexo_nota_ou_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_boleto'].clearValidators();
    this.formulario.controls['anexo_boleto']?.setValue('');
    this.formulario.controls['anexo_boleto'].setErrors(null);
    this.formulario.controls['anexo_boleto'].markAsUntouched();
    this.formulario.controls['anexo_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_xml'].clearValidators();
    this.formulario.controls['anexo_xml']?.setValue('');
    this.formulario.controls['anexo_xml'].setErrors(null);
    this.formulario.controls['anexo_xml'].markAsUntouched();
    this.formulario.controls['anexo_xml'].updateValueAndValidity;

    this.formulario.controls['anexo_documentacao_suporte'].clearValidators();
    this.formulario.controls['anexo_documentacao_suporte']?.setValue('');
    this.formulario.controls['anexo_documentacao_suporte'].setErrors(null);
    this.formulario.controls['anexo_documentacao_suporte'].markAsUntouched();
    this.formulario.controls['anexo_documentacao_suporte'].updateValueAndValidity;

    this.formulario.controls['nf_do_produto_original'].clearValidators();
    this.formulario.controls['nf_do_produto_original']?.setValue('');
    this.formulario.controls['nf_do_produto_original'].setErrors(null);
    this.formulario.controls['nf_do_produto_original'].markAsUntouched();
    this.formulario.controls['nf_do_produto_original'].updateValueAndValidity;

    this.formulario.controls['lista_de_itens_da_po'].clearValidators();
    this.formulario.controls['lista_de_itens_da_po']?.setValue('');
    this.formulario.controls['lista_de_itens_da_po'].setErrors(null);
    this.formulario.controls['lista_de_itens_da_po'].markAsUntouched();
    this.formulario.controls['lista_de_itens_da_po'].updateValueAndValidity;


    this.arquivosDeRateio = [];
    this.arquivosDeRateioUrl = [];

    this.notas = [];
    this.notasUrl = [];

    this.boletos = [];
    this.boletosUrl = [];

    this.xml = [];
    this.xmlUrl = [];

    this.documentacaoSuporte = [];
    this.documentacaoSuporteUrl = [];

    this.nfProdutoOriginal = [];
    this.nfProdutoOriginalUrl = [];

    this.listaDeItensDaPo = [];
    this.listaDeItensDaPoUrl = [];
  }

  zerarTodosCamposSubcategoria() {

    //TO DO

    this.zerarTodosCamposQtdePedidos();

    // this.formulario.controls['tipo_operacao'].setValue('');
    // this.formulario.controls['tipo_operacao'].clearValidators();
    // this.formulario.controls['tipo_operacao'].setErrors(null);
    // this.formulario.controls['tipo_operacao'].markAsUntouched();
    // this.formulario.controls['tipo_operacao'].updateValueAndValidity();

    // this.formulario.controls['marque_todas_as_op_es_que_se_aplicam'].setValue('');

    // this.formulario.controls['boleto_para_pagamento'].setValue('');
    // this.formulario.controls['boleto_para_pagamento'].clearValidators();
    // this.formulario.controls['boleto_para_pagamento'].setErrors(null);
    // this.formulario.controls['boleto_para_pagamento'].markAsUntouched();
    // this.formulario.controls['boleto_para_pagamento'].updateValueAndValidity();

    // this.formulario.controls['folha_servicos'].setValue('');
    // this.formulario.controls['folha_servicos'].clearValidators();
    // this.formulario.controls['folha_servicos'].setErrors(null);
    // this.formulario.controls['folha_servicos'].markAsUntouched();
    // this.formulario.controls['folha_servicos'].updateValueAndValidity();
  }

  camposOcr(tipoDeNota: string) {

    //TO DO

    if (tipoDeNota == "Material Compras e aquisição"
      || tipoDeNota == "Serviço"
      || tipoDeNota == "Benefícios" //TO DO
      || tipoDeNota == "Escrituração - Não gera pagamento"
      || tipoDeNota == "Importação"
      || tipoDeNota == "Sem valor fiscal"
    ) {
      this.formulario.controls['data_de_emiss_o']?.clearValidators();
      this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

      this.formulario.controls['data_de_vencimento']?.clearValidators();
      this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

      this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
      this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

      this.formulario.controls['valor_da_fatura']?.clearValidators();
      this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

    } else {

      if (this.formulario.getRawValue().categoria == "PO") {
        console.log("entrei na validação PO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

      }

      if (this.formulario.getRawValue().categoria == "NPO") {
        console.log("entrei na validação NPO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        // this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        // this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();
      }
    }
  }

  testeData(event: any) {
    console.log("data emissao =>", event.target.value)
  }

  formataData(data:string){
    var partesData = data.split('/')
    var dia = partesData[0]
    var mes = partesData[1]
    var ano = partesData[2]

    var dataFormatada = ano + '-' + mes + '-' + dia

    return dataFormatada
  }





}
