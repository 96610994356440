<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Contabilidade">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Documentos - Suporte e Relatórios"></app-formularios-titulo>
    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="email_do_solicitante" class="col-sm-4 col-form-label">*E-mail do solicitante: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_solicitante" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_solicitante')?.errors?.required && formulario.get('email_do_solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_solicitante')?.errors?.email && formulario.get('email_do_solicitante')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Nome da Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="nome_da_empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>


    </div>

    <div class="form-group row">
      <label for="codigo_sap" class="col-sm-4 col-form-label">Cód Sap </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="codigo_sap">
      </div>
    </div>

    <div class="form-group row">
      <label for="torre" class="col-sm-4 col-form-label">*Torre</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="torre">
          <option *ngFor="let torre of torres" [value]="torre.tipo">{{torre.tipo}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('torre')?.errors?.required && formulario.get('torre')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="periodo_da_documentacao" class="col-sm-4 col-form-label">*Período da Documentação </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="periodo_da_documentacao">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('periodo_da_documentacao')?.errors?.required && formulario.get('periodo_da_documentacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="qual_documentacao_solicitada" class="col-sm-4 col-form-label">*Qual documentação solicitada? </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea"
          formControlName="qual_documentacao_solicitada"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('qual_documentacao_solicitada')?.errors?.required && formulario.get('qual_documentacao_solicitada')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="anexar_solicitacao_de_auditoria" class="col-sm-4 col-form-label">Anexar Solicitação de Auditoria
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexar_solicitacao_de_auditoria" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
