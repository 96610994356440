import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { empresas } from './empresas';
import { torres } from './torres';
import { pipe_fields_id } from './pipe_fields_id';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css']
})
export class AuditoriaComponent implements OnInit {

  formulario!: UntypedFormGroup;
  empresas = empresas;
  torres = torres;
  myFiles: any[] = [];
  email: string = ''
  solicitacao_de_auditorias: any[] = []
  faltamCamposObrigatorios: boolean = true
  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: UntypedFormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      email_do_solicitante: ['', [Validators.required, Validators.email]],
      nome_da_empresa: ['', [Validators.required]],
      codigo_sap: ['', []],
      torre: ['', [Validators.required]],
      periodo_da_documentacao: ['', [Validators.required]],
      qual_documentacao_solicitada: ['', [Validators.required]],
      anexar_solicitacao_de_auditoria: ['', []],
      observacao: ['', []]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email_do_solicitante')?.setValue(this.emailApi)

    // if(this.formulario.getRawValue().email_do_solicitante !=''){
    //   this.formulario.controls['email_do_solicitante'].disable()
    // }
    // if(this.formulario.getRawValue().email_do_solicitante == undefined){
    //   this.formulario.controls['email_do_solicitante'].enable()
    // }

  }

  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.myFiles);
      if (this.myFiles.length > 0) {
        while (this.myFiles.length != this.solicitacao_de_auditorias.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.myFiles.length > 0) {
        var solicitacao_de_auditorias2 = ''
        solicitacao_de_auditorias2 = '['
        for (var i = 0; i < this.solicitacao_de_auditorias.length; i++) {
          solicitacao_de_auditorias2 = solicitacao_de_auditorias2 + '"' + this.solicitacao_de_auditorias[i] + '",'
        }
        solicitacao_de_auditorias2 = solicitacao_de_auditorias2.slice(0, -1)
        solicitacao_de_auditorias2 = solicitacao_de_auditorias2 + "]"

      } else {
        solicitacao_de_auditorias2 = ''
      }

      let email_do_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_solicitante)
      let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
      let codigo_sap = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_sap)
      let torre = this.trataInput.removeCaracters(this.formulario.getRawValue().torre)
      let periodo_da_documentacao = this.trataInput.removeCaracters(this.formulario.getRawValue().periodo_da_documentacao)
      let qual_documentacao_solicitada = this.trataInput.removeCaracters(this.formulario.getRawValue().qual_documentacao_solicitada)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)


      let pipe_fields = pipe_fields_id
      let fields_values = [email_do_solicitante, nome_da_empresa, codigo_sap, torre, periodo_da_documentacao, qual_documentacao_solicitada, solicitacao_de_auditorias2, observacao]
      let pipe_id = "302443596"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if(res.code == 504){
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/lmJAM3rE" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.solicitacao_de_auditorias = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Auditoria - Documentos Suporte", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi,this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/lmJAM3rE" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.solicitacao_de_auditorias = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.myFiles.push(files[i])
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.solicitacao_de_auditorias.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/lmJAM3rE" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.solicitacao_de_auditorias = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.myFiles.indexOf(file);
    this.myFiles.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.myFiles.length == 0){
      this.formulario.controls['anexar_solicitacao_de_auditoria'].markAsTouched()
      this.formulario.get('anexar_solicitacao_de_auditoria')?.setValue('')
    }
  }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.myFiles = []
    this.solicitacao_de_auditorias = []
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/lmJAM3rE', '_blank')
  }
}
