<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="IPE"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">
    <div class="form-group row">
      <label for="caracteristicas" class="col-sm-4 col-form-label">Marque todas as opções que se aplicam</label>
      <div class="col-sm-8">
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas" value='Todas as opções'
            (change)="changeCheckTodas($event.currentTarget.checked)" [checked]="princTodasOpcoesChecked">
          Todas as opções
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input #checkUrgente type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="urgenteChecked" value='Urgente' (change)="inserirNoArray($event.target.value)"
            (change)="changeCheckUrgente($event.currentTarget.checked)">
          Urgente
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="antecipacaoChecked" value='Antecipação' (change)="inserirNoArray($event.target.value)"
            (change)="changeCheckAntec($event.currentTarget.checked)">
          Antecipação
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="jurosmultasChecked" value='Juros e Multas' (change)="inserirNoArray($event.target.value)"
            (change)="changeCheckJuros($event.currentTarget.checked)">
          Juros e Multas
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="custoPlanejamentoChecked" value='Custo Planejado'
            (change)="inserirNoArray($event.target.value)"
            (change)="changecustoPlanejamento($event.currentTarget.checked)">
          Custo Planejado
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="debitoPosteriorChecked" value='Débito Posterior' (change)="inserirNoArray($event.target.value)"
            (change)="changeDebitoPosterior($event.currentTarget.checked)">
          Débito Posterior
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
            [checked]="importacaoChecked" value='Importação' (change)="inserirNoArray($event.target.value)"
            (change)="changeImportacao($event.currentTarget.checked)">
          Importação
        </label>

        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="bloqueioChecked"
            value='Bloqueio de Pagamento' (change)="inserirNoArray($event.target.value)"
            (change)="changeBloqueio($event.currentTarget.checked)">
          Bloqueio de Pagamento
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="riscoChecked"
            value='Risco Sacado' (change)="inserirNoArray($event.target.value)"
            (change)="changeRisco($event.currentTarget.checked)">
          Risco Sacado
        </label>
        <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input #checkContrato type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="todasOpcoesChecked"
          value = 'Incluir número de Contrato' (change)="changeCheckContrato($event.currentTarget.checked)">
          Incluir número de Contrato
        </label>
        <label class="col-sm-12 col-form-label" for="caracteristicas">
          <input #checkAprovador type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="todasOpcoesChecked"
          value = 'Aprovador não é meu lider' (change)="changeCheckAprovador($event.currentTarget.checked)">
          Aprovador não é meu lider
        </label> -->
      </div>
    </div>

    <div class="form-group row" *ngIf="showMotivoUrgencia">
      <label for="motivo_de_urg_ncia" class="col-sm-4 col-form-label">*Motivo de Urgência</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="motivo_de_urg_ncia" (change)="changeMotivoUrgencia($event)">
          <option *ngFor="let item of motivos_de_urgencia" [value]="item.nome">{{item.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_de_urg_ncia')?.errors?.required && formulario.get('motivo_de_urg_ncia')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="showNumContrato">
      <label for="n_mero_do_contrato" class="col-sm-4 col-form-label">*Número do Contrato</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="n_mero_do_contrato">
          <option *ngFor="let item of listaNumContratos" [value]="item.item">{{item.item}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_do_contrato')?.errors?.required && formulario.get('n_mero_do_contrato')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="formulario.getRawValue().motivo_de_urg_ncia == 'Outros'">
      <label for="descricao_outros" class="col-sm-4 col-form-label">*Descrição Outros</label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descricao_outros"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao_outros')?.errors?.required && formulario.get('descricao_outros')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="fornecedor" class="col-sm-4 col-form-label">*Fornecedor</label>
      <div class="col-sm-8">
        <div class="custom-select fakeSelect"
          [ngClass]="{'ng-invalid': !fornecedorSelecionado, 'ng-valid': fornecedorSelecionado}"
          (click)="cliqueSelectFornecedor()">{{fornecedorSelecionado ? fornecedorSelecionado.nome : ""}}
        </div>
        <div *ngIf="exibirListaFornecedores" class="quadroSelectApi">
          <div class="searchSelectApi">
            <i class="fa fa-search" aria-hidden="true"></i>
            <i class="fa fa-remove" id="iconClearFornecedor" (click)="clearFornecedor()" aria-hidden="true"></i>
            <input type="text" class="inputSearch" formControlName="inputSearchFornecedor"
              placeholder="Ex: 11.111.111-1111/11 ou 11111111111111" (keyup)="searchFornecedor()">
          </div>
          <div *ngIf="!listaFornecedores.length"
            class="sem-result-select-api list-group-item list-group-item-action flex-column align-items-start">
            Nenhum resultado encontrado para este filtro
          </div>
          <div class="list-group selectApi" *ngIf="listaFornecedores.length">
            <div *ngFor="let item of listaFornecedores" (click)="selecionaFornecedor(item)"
              class="list-group-item list-group-item-action flex-column align-items-start">
              <p class="titleList">{{item.nome}}</p>
              <p class="subtitleList" *ngIf="item.cnpj">CNPJ</p>
              <p class="valueList" *ngIf="item.cnpj">{{item.cnpj}}</p>
              <p class="subtitleList" *ngIf="item.codErp">CÓDIGO ERP</p>
              <p class="valueList" *ngIf="item.codErp">{{item.codErp}}</p>
            </div>
          </div>
        </div>
        <app-mensagem class="col-form-label mensagem-erro" *ngIf="exibeErroFornecedor"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.fornecedorSelecionado && this.fornecedorSelecionado.nome == 'NÃO CADASTRADO/ENCONTRADO'">
      <label for="cpf_cnpj_fornecedor" class="col-sm-4 col-form-label">*CPF CNPJ Fornecedor</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cpf_cnpj_fornecedor" mask="000.000.000-00||00.000.000/0000-00"  >
      </div>
    </div>

    <div class="form-group row">
      <label for="tomador" class="col-sm-4 col-form-label">*Empresa Nutrien</label>
      <div class="col-sm-8">
        <div class="custom-select fakeSelect"
          [ngClass]="{'ng-invalid': !tomadorSelecionado, 'ng-valid': tomadorSelecionado}"
          (click)="cliqueSelectTomador()">{{tomadorSelecionado ? tomadorSelecionado.nome : ""}}
        </div>
        <div *ngIf="exibirListaTomadores" class="quadroSelectApi">
          <div class="searchSelectApi">
            <i class="fa fa-search" aria-hidden="true"></i>
            <i class="fa fa-remove" id="iconClearTomador" (click)="clearTomador()" aria-hidden="true"></i>
            <input type="text" class="inputSearch" formControlName="inputSearchTomador"
              placeholder="Ex: 11.111.111-1111/11 ou 11111111111111" (keyup)="searchTomador()">
          </div>
          <div *ngIf="!listaTomadores.length"
            class="sem-result-select-api list-group-item list-group-item-action flex-column align-items-start">
            Nenhum resultado encontrado para este filtro
          </div>
          <div class="list-group selectApi" *ngIf="listaTomadores.length">
            <div *ngFor="let item of listaTomadores" (click)="selecionaTomador(item)"
              class="list-group-item list-group-item-action flex-column align-items-start">
              <p class="titleList">{{item.nome}}</p>
              <p class="subtitleList" *ngIf="item.cnpj">CNPJ</p>
              <p class="valueList" *ngIf="item.cnpj">{{item.cnpj}}</p>
              <p class="subtitleList" *ngIf="item.codErp">CÓDIGO ERP</p>
              <p class="valueList" *ngIf="item.codErp">{{item.codErp}}</p>
            </div>
          </div>
        </div>
        <app-mensagem class="col-form-label mensagem-erro" *ngIf="exibeErroTomador"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.tomadorSelecionado && this.tomadorSelecionado.nome == 'NÃO CADASTRADO/ENCONTRADO'">
      <label for="cnpj_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ Empresa Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cnpj_empresa_nutrien" mask="00.000.000/0000-00"  >
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail_do_requisitante" class="col-sm-4 col-form-label">*E-mail do Requisitante</label>
      <div class="col-sm-8 position-relative">
        <div class="dominioEmail">
          <input type="email" class="form-control" formControlName="e_mail_do_requisitante"
            (keyup)="validaCampoEmail()">
          <span>&#64;nutrien.com</span>
        </div>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_do_requisitante')?.errors?.required && formulario.get('e_mail_do_requisitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_do_requisitante')?.errors?.email && formulario.get('e_mail_do_requisitante')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem> -->
      </div>
    </div>


    <!-- <div class="form-group row">
      <label for="e_mail_do_fornecedor" class="col-sm-4 col-form-label">E-mail do Fornecedor</label>
      <div class="col-sm-8 position-relative">
        <div class="dominioEmail">
          <input type="email" class="form-control" formControlName="e_mail_do_fornecedor">
        </div>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_do_fornecedor')?.errors?.email && formulario.get('e_mail_do_fornecedor')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem> -->
          <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_do_fornecedor')?.errors?.required && formulario.get('e_mail_do_fornecedor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem> -->
      <!-- </div>
    </div> -->

    <!-- <div class="form-group row" *ngIf="showAprovador">
      <label for="aprovador" class="col-sm-4 col-form-label">*Aprovador</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="aprovador">
          <option *ngFor="let item of listaAprovadores" [value]="item.item">{{item.item}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('aprovador')?.errors?.required && formulario.get('aprovador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="categoria" class="col-sm-4 col-form-label">*Categoria</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="categoria" (change)="changeCategoria($event)">
          <option *ngFor="let categoria of categorias" [value]="categoria.nome">{{categoria.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('categoria')?.errors?.required && formulario.get('categoria')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().categoria == 'PO'">
      <label for="subcategoria_po" class="col-sm-4 col-form-label">*Subcategoria</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="subcategoria_po" (change)="changeSubcategoria($event)">
          <option *ngFor="let sub of subcategorias" [value]="sub.nome">{{sub.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('subcategoria_po')?.errors?.required && formulario.get('subcategoria_po')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Materiais'">
      <div class="form-group row">
        <label for="tipo_de_opera_o" class="col-sm-4 col-form-label">*Tipo de Operação</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="tipo_de_opera_o" (change)="changeTipoOperacao($event)">
            <option *ngFor="let tipo of tipo_de_operacao" [value]="tipo.nome">{{tipo.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_opera_o')?.errors?.required && formulario.get('tipo_de_opera_o')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo (XML)</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept="text/xml"
            formControlName="anexo_xml" id="formFileMultiple" (change)="saveArquivoXml($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosXml.length!=0">
        <div class="card-header">Anexo - Arquivo XML</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosXml">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoXml(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="anexo_nota_fiscal_documento_boleto_se_houver" class="col-sm-4 col-form-label">*Anexo Nota
          Fiscal/Documento e Boleto se houver</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_nota_fiscal_documento_boleto_se_houver" id="formFileMultiple"
            (change)="saveArquivoNfBoleto($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNfBoleto.length!=0">
        <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNfBoleto">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNfBoleto(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="marque_todas_as_op_es_que_se_aplicam" class="col-sm-4 col-form-label">Marque todas as opções que se
          aplicam</label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="marque_todas_as_op_es_que_se_aplicam">
            <input type="checkbox" class="form-check-input" formControlName="marque_todas_as_op_es_que_se_aplicam"
              value='Serviço ou Material é um Ativo'>
            Serviço ou Material é um Ativo
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().tipo_de_opera_o == 'Matéria Prima'">
      <div class="form-group row">
        <label for="migo" class="col-sm-4 col-form-label">*MIGO</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="migo">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('migo')?.errors?.required && formulario.get('migo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços'">
      <div class="form-group row">
        <label for="anexo_nota_fiscal_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota Fiscal/Documento e Boleto
          se houver</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_nota_fiscal_ou_boleto" id="formFileMultiple" (change)="saveArquivoNf($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNf.length!=0">
        <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNf">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNf(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="marque_todas_as_op_es_que_se_aplicam" class="col-sm-4 col-form-label">Marque todas as opções que se
          aplicam</label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="marque_todas_as_op_es_que_se_aplicam">
            <input type="checkbox" class="form-check-input" formControlName="marque_todas_as_op_es_que_se_aplicam"
              value='Serviço ou Material é um Ativo'>
            Serviço ou Material é um Ativo
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Boleto ' || 
                formulario.getRawValue().subcategoria_po == 'Nota de Débito ' || 
                formulario.getRawValue().subcategoria_po == 'Telefonia ' || 
                formulario.getRawValue().subcategoria_po == 'Água ' || 
                formulario.getRawValue().subcategoria_po == 'Energia ' || 
                formulario.getRawValue().subcategoria_po == 'Fatura ' || 
                formulario.getRawValue().subcategoria_po == 'Frete ' || 
                formulario.getRawValue().subcategoria_po == 'Aluguel ' || 
                formulario.getRawValue().subcategoria_po == 'Correios' || 
                formulario.getRawValue().subcategoria_po == 'Outros '">
      <div class="form-group row">
        <label for="anexo_nota_fiscal_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota Fiscal/Documento e Boleto
          se houver</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_nota_fiscal_ou_boleto" id="formFileMultiple" (change)="saveArquivoNf($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNf.length!=0">
        <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNf">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNf(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().categoria == 'NPO'">
      <div class="form-group row">
        <label for="tipo_de_lan_amento" class="col-sm-4 col-form-label">*Tipo de Lançamento</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="tipo_de_lan_amento" (change)="changeTipoLancamento($event)">
            <option *ngFor="let tipo of tipo_de_lancamento" [value]="tipo.nome">{{tipo.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_lan_amento')?.errors?.required && formulario.get('tipo_de_lan_amento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().tipo_de_lan_amento == 'Depósito Judicial'">
        <div class="form-group row">
          <label for="n_mero_do_processo" class="col-sm-4 col-form-label">*Número do Processo</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="n_mero_do_processo">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('n_mero_do_processo')?.errors?.required && formulario.get('n_mero_do_processo')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Template Excel</label>
        <div class="col-sm-8">
          <a class="mt-2 d-block"
            href="https://app-storage-service.pipefy.com/v1/signed/uploads/4218e504-eee7-4467-9b85-58e402f1eb04/IPECoreFile-NPOCODINGTEMPLATEv2.xlsx?signature=rPAD3sJwE%2FwRtgkd0e4Gsk1qfVfT%2BGTxOMbx8eETtQc%3D"
            target="_blank" rel="noopener noreferrer">
            Rateio, Centro de Custos e Contas Contábeis
          </a>
        </div>
      </div>

      <div class="form-group row">
        <label for="anexo_npo_modelo_excel" class="col-sm-4 col-form-label">*Anexo (NPO) - Modelo Excel</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".xlsx"
            formControlName="anexo_npo_modelo_excel" id="formFileMultiple" (change)="saveArquivoNpo($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNpo.length!=0">
        <div class="card-header">Anexo - Arquivo NPO</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNpo">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNpo(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_de_emiss_o" class="col-sm-4 col-form-label">*Data de Emissão</label>
      <div class="col-sm-8">
        <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_emiss_o">
        <app-mensagem class="col-form-label mensagem-erro" *ngIf="formulario.getRawValue().data_de_emiss_o > dataAtual"
          mensagem="Não é permitido data futura"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_emiss_o')?.errors?.required && formulario.get('data_de_emiss_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_de_vencimento" class="col-sm-4 col-form-label">*Data de Vencimento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_vencimento')?.errors?.required && formulario.get('data_de_vencimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="moeda" class="col-sm-4 col-form-label">*Moeda</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="moeda">
          <option *ngFor="let moeda of moedas" [value]="moeda.nome">{{moeda.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('moeda')?.errors?.required && formulario.get('moeda')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_da_nota_fiscal" class="col-sm-4 col-form-label">*Número da Nota Fiscal</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_da_nota_fiscal">
        <small>Informar apenas números e traços/barra. Ex: 1111 ou 11/11-1</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_da_nota_fiscal')?.errors?.required && formulario.get('numero_da_nota_fiscal')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="valor_da_fatura" class="col-sm-4 col-form-label">*Valor da Fatura</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_da_fatura"
          (keyup)="formataValorDecimal($event.target.value)" (blur)="exibeConfirmaValor()" [value]="valorFaturaAlterado">
        <small>Informar apenas números. Ex: 1111</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_da_fatura')?.errors?.required && formulario.get('valor_da_fatura')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços' || formulario.getRawValue().categoria == 'NPO'">
      <div class="form-group row">
        <label for="boleto_para_pagamento" class="col-sm-4 col-form-label">*Boleto para Pagamento</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='boleto_para_pagamento' value="Sim" class='radio-btn'>&nbsp;Sim
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='boleto_para_pagamento' value='Não' class='radio-btn'>&nbsp;Não
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('boleto_para_pagamento')?.errors?.required && formulario.get('boleto_para_pagamento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="forma_de_pagamento" class="col-sm-4 col-form-label">Forma de Pagamento</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="forma_de_pagamento">
            <option *ngFor="let forma of formas_pagamento" [value]="forma.nome">{{forma.nome}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços'">
      <div class="form-group row">
        <label for="folha_servicos" class="col-sm-4 col-form-label">Folha de Serviços</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="folha_servicos">
          <!-- <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('folha_servicos')?.errors?.required && formulario.get('folha_servicos')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem> -->
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().categoria == 'NPO'">
      <div class="form-group row">
        <label for="favorecido" class="col-sm-4 col-form-label">*Favorecido</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="favorecido">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('favorecido')?.errors?.required && formulario.get('favorecido')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_do_fi" class="col-sm-4 col-form-label">*Valor do FI</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_do_fi">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_do_fi')?.errors?.required && formulario.get('valor_do_fi')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="setor" class="col-sm-4 col-form-label">Setor</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="setor">
            <option *ngFor="let tipo of setores" [value]="tipo.nome">{{tipo.nome}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="descri_o_breve" class="col-sm-4 col-form-label">Descrição Breve</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="descri_o_breve">
        <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descri_o_breve')?.errors?.required && formulario.get('descri_o_breve')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem> -->
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().categoria == 'PO'">
      <!--*ngIf="formulario.getRawValue().subcategoria_po == 'Serviços' || formulario.getRawValue().subcategoria_po == 'Materiais'"-->
      <div class="form-group row">
        <label for="quantidade_pedidos" class="col-sm-4 col-form-label">*Quantidade de Pedidos</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="quantidade_pedidos" (change)="onTipoChange()">
            <option *ngFor="let qtde of quantidade_pedidos" [value]="qtde.nome">{{qtde.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_pedidos')?.errors?.required && formulario.get('quantidade_pedidos')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 1">
        <div class="form-group row">
          <label for="numero_pedido_1" class="col-sm-4 col-form-label">*Número do Pedido 1</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_1">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_1')?.errors?.required && formulario.get('numero_pedido_1')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_1" class="col-sm-4 col-form-label">*Número da Linha do Pedido 1</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_1">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_1')?.errors?.required && formulario.get('numero_linha_pedido_1')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 2">
        <div class="form-group row">
          <label for="numero_pedido_2" class="col-sm-4 col-form-label">*Número do Pedido 2</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_2">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_2')?.errors?.required && formulario.get('numero_pedido_2')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_2" class="col-sm-4 col-form-label">*Número da Linha do Pedido 2</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_2">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_2')?.errors?.required && formulario.get('numero_linha_pedido_2')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 3">
        <div class="form-group row">
          <label for="numero_pedido_3" class="col-sm-4 col-form-label">*Número do Pedido 3</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_3">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_3')?.errors?.required && formulario.get('numero_pedido_3')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_3" class="col-sm-4 col-form-label">*Número da Linha do Pedido 3</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_3">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_3')?.errors?.required && formulario.get('numero_linha_pedido_3')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 4">
        <div class="form-group row">
          <label for="numero_pedido_4" class="col-sm-4 col-form-label">*Número do Pedido 4</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_4">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_4')?.errors?.required && formulario.get('numero_pedido_4')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_4" class="col-sm-4 col-form-label">*Número da Linha do Pedido 4</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_4">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_4')?.errors?.required && formulario.get('numero_linha_pedido_4')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 5">
        <div class="form-group row">
          <label for="numero_pedido_5" class="col-sm-4 col-form-label">*Número do Pedido 5</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_5">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_5')?.errors?.required && formulario.get('numero_pedido_5')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_5" class="col-sm-4 col-form-label">*Número da Linha do Pedido 5</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_5">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_5')?.errors?.required && formulario.get('numero_linha_pedido_5')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 6">
        <div class="form-group row">
          <label for="numero_pedido_6" class="col-sm-4 col-form-label">*Número do Pedido 6</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_6">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_6')?.errors?.required && formulario.get('numero_pedido_6')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_6" class="col-sm-4 col-form-label">*Número da Linha do Pedido 6</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_6">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_6')?.errors?.required && formulario.get('numero_linha_pedido_6')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 7">
        <div class="form-group row">
          <label for="numero_pedido_7" class="col-sm-4 col-form-label">*Número do Pedido 7</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_7">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_7')?.errors?.required && formulario.get('numero_pedido_7')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_7" class="col-sm-4 col-form-label">*Número da Linha do Pedido 7</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_7">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_7')?.errors?.required && formulario.get('numero_linha_pedido_7')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 8">
        <div class="form-group row">
          <label for="numero_pedido_8" class="col-sm-4 col-form-label">*Número do Pedido 8</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_8">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_8')?.errors?.required && formulario.get('numero_pedido_8')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_8" class="col-sm-4 col-form-label">*Número da Linha do Pedido 8</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_8">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_8')?.errors?.required && formulario.get('numero_linha_pedido_8')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 9">
        <div class="form-group row">
          <label for="numero_pedido_9" class="col-sm-4 col-form-label">*Número do Pedido 9</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_9">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_9')?.errors?.required && formulario.get('numero_pedido_9')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_9" class="col-sm-4 col-form-label">*Número da Linha do Pedido 9</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_9">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_9')?.errors?.required && formulario.get('numero_linha_pedido_9')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos >= 10">
        <div class="form-group row">
          <label for="numero_pedido_10" class="col-sm-4 col-form-label">*Número do Pedido 10</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_pedido_10">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_pedido_10')?.errors?.required && formulario.get('numero_pedido_10')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="numero_linha_pedido_10" class="col-sm-4 col-form-label">*Número da Linha do Pedido 10</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_linha_pedido_10">
            <small>e.g. xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_linha_pedido_10')?.errors?.required && formulario.get('numero_linha_pedido_10')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-pedido" *ngIf="formulario.getRawValue().quantidade_pedidos == 'Mais que 10'">
        <div class="form-group row">
          <label for="lista_pedidos" class="col-sm-4 col-form-label">*Lista de Pedidos</label>
          <div class="col-sm-8">
            <textarea class="form-control" aria-label="With textarea" formControlName="lista_pedidos"></textarea>
            <small>e.g. <br />
              xxxx : xxx,xxx-xxx<br />
              xxxx : xxx,xxx-xxx</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('lista_pedidos')?.errors?.required && formulario.get('lista_pedidos')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao"
        [disabled]="formulario.invalid || formulario.getRawValue().data_de_emiss_o > dataAtual">Enviar</button>
    </div>

  </form>
</div>


<div class="footer">
  <app-footer link="payment"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>