import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators'
import { UserInfosService } from './../autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';

export interface ReturnSecretsResponse {
  data: {
    authorization: ReturnSecrets;
  }
}
export interface ReturnSecrets {
  apikeys: {
    digibee: string,
    pipefy: string,
    zenviaChat: string,
    iogaKey: string,
    iogaProject: string
  },
  tokens: {
    mostqi: string,
    pipefy: string
  }
}
@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {
   public secrets: ReturnSecrets | undefined = !environment.localhost? undefined as any:
   {apikeys: {digibee: environment.digibee_key, pipefy: environment.pipefy_key, zenviaChat: environment.zenviaChat, iogaKey: environment.iogaKey, iogaProject: environment.iogaProject},
   tokens: {mostqi: environment.most_qi_ocr_authorization, pipefy: environment.pipefy_queries_autorization}}

  constructor(private http: HttpClient, private userInfo: UserInfosService, private msalService: MsalService) { }

  token: string | null = ''


  async getTokenFromGgp() {
    const token = ((await this.getSecrets()).apikeys.pipefy);

    var userEmail = this.userInfo.getUserEmail()

    // console.log("UserEmail Token Service:", userEmail)
    //Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'env': 'hml'
    })
    //caso vá para produção, comentar  env da headers.

    // dev
    var bodyJwt = {
      "data": {
        "jwt-username": environment.gerar_jwt_user,
        "jwt-password": environment.gear_jwt_pass,
        "jwt-email": userEmail
      }
    }

    // url dev
    const resposta: any = await this.http.post<any>(environment.gerar_jwt + "?key=" + token, bodyJwt, { headers: header, observe: 'response' }).

      pipe(
        tap(res => {
          console.log()
          const token = res.body.data.Authorization
          // console.log('authorization',token)
          this.setToken(token)
          // console.log('TOKEN=>',this.getToken())
        })
      ).toPromise()
    return resposta
  }

  async setToken(token: string | null) {
    this.token = token
  }

  getToken() {
    return "Bearer " + this.token
  }

  async getSecrets(): Promise<ReturnSecrets> {
    return new Promise<ReturnSecrets>( async (resolve, reject) => {
      if (!!this.secrets) {
        resolve(this.secrets);
      } else {
        let header: HttpHeaders;
        header = new HttpHeaders({
          'Content-Type': 'application/json'
        });

        const body = {
          "data": {
            "jwt-username": environment.get_secrets_user,
            "jwt-password": environment.get_secrets_pass,
            "jwt-email": environment.get_secrets_email
          }
        };

        // console.log("body =>", body)

        try {
          const response = await this.http.post<ReturnSecretsResponse>(environment.get_secrets, body, { headers: header }).toPromise();
          this.secrets = response?.data.authorization;
          // console.log(this.secrets)
          if (response) {
            resolve(response?.data.authorization);
          }
        }
        catch(e) {
          reject(e);
        }
      }
    });
  }

  getAccounts(): void {
      if (!!environment.localhost) {
        return;
      } else {
        const checkValue = () => {
          if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
            this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
            this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
            // this.userInfo.getUserGroups2();
          } else {
            setTimeout(checkValue, 50); // Espera 100ms
          }
        };
        checkValue();
      }
  }
}

//////////////////teste
