<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Fornecedores">
</app-nbs-banner>

<h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>

<div class="container">

  <div class="itens">
    <div class="row menu-itens">
      <div class="">
        <app-box-link-pipe descricao="MUB" url="../../../assets/img/fa-menus/fiscal/impostos_apurados.png" titulo="MUB"
          subtitulo="" tipo_de_link="externo" link="https://nutrien-ag.mub.one/app/portals/solicitante">
        </app-box-link-pipe>
      </div>
    </div>
  </div>

  <div class="itens">
    <div class="row menu-itens">
      <div class="">
        <app-pdfpopup descricao="FAQ" complemento="" url="../../../assets/img/fa-menus/fiscal/guias_fiscais.png"
          titulo="FAQ" subtitulo="" tipo_de_link="externo" link="https://app.pipefy.com/public/form/Py7-DUoD">
        </app-pdfpopup>
      </div>
    </div>
  </div>

  <div class="itens">
    <div class="row menu-itens">
      <div class="">
        <app-box-link-pipe descricao="Materiais" complemento="" url="../../../assets/img/fa-menus/fiscal/cancelamento.png"
          titulo="Suporte para Cadastro de Fornecedores" subtitulo="" tipo_de_link="externo" link="https://app.pipefy.com/public/form/Py7-DUoD">
        </app-box-link-pipe>
      </div>
    </div>
  </div>


</div>


<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
