import { Component, OnInit } from '@angular/core';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { pipe_fields_ids } from './pipe_fields.id';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { interval } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-cancelamento-de-solicitacao',
  templateUrl: './cancelamento-de-solicitacao.component.html',
  styleUrls: ['./cancelamento-de-solicitacao.component.css']
})
export class CancelamentoDeSolicitacaoComponent implements OnInit {

  formulario!: UntypedFormGroup

  error_counter: number = 0;

  emailApi: string = ''
  userNameApi: string = ''


  constructor(private formBuilder: UntypedFormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {



    this.formulario = this.formBuilder.group ({
      nome_do_solicitante :['',[Validators.required]],
      email:['',[Validators.required, Validators.email]],
      numero_do_card_a_ser_cancelado:['',[Validators.required]],
      justificativa: ['',[Validators.required]]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email')?.setValue(this.emailApi)
    // this.formulario.get('nome_do_solicitante')?.setValue(this.userNameApi)


    // if (this.formulario.getRawValue().email != '') {
    //   this.formulario.controls['email'].disable()
    // }
    // if (this.formulario.getRawValue().email == undefined) {
    //   this.formulario.controls['email'].enable()
    // }

    // if (this.formulario.getRawValue().nome_do_solicitante != '') {
    //   this.formulario.controls['nome_do_solicitante'].disable()
    // }
    // if (this.formulario.getRawValue().nome_do_solicitante == undefined) {
    //   this.formulario.controls['nome_do_solicitante'].enable()
    // }
  }



  enviarFormulario (){
    this.spinner.show()
    let nome_do_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_solicitante)
    let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
    let numero_do_card_a_ser_cancelado = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_card_a_ser_cancelado)
    let justificativa = this.trataInput.removeCaracters(this.formulario.getRawValue().justificativa)

    let pipe_fields = pipe_fields_ids
    let fields_values = [nome_do_solicitante, email, numero_do_card_a_ser_cancelado, justificativa]
    let pipe_id = '302487222'

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/WNenDdOI" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Tech', "Cancelamento de Solicitação", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WNenDdOI" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
      }
    })
  }


  resetform() {
    for (let field in this.formulario.controls) {
        this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/WNenDdOI', '_blank')
  }

}
