<!-- <app-header></app-header>
<div class="container">
  <div class="logo"><img class="logo_nbs" src="../../../assets/img/logo_nbs.png"></div>
  <div class="nbs_services">
    <div class="nbs_services-item">
      <app-nbs-banner url="../../../assets/img/home/background.png" descricao="Acessar Serviços Financeiros" titulo="Serviços Financeiros"
        routerLink="/menu"></app-nbs-banner>
    </div>
    <div class="nbs_services-item">
      <app-nbs-banner url="../../../assets/img/background_compras.png" descricao="Acessar Recursos Humanos"
      titulo="Recursos Humanos" routerLink="/recursos-humanos/home"></app-nbs-banner>
    </div>

  </div>
</div> -->

<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner> -->


<app-header></app-header>
<div class="container">
  <div class="logo"><img class="logo_nbs" src="../../../assets/img/logo_nbs.png"></div>
  <div class="nbs_services">
    <div class="nbs_services-item">
      <app-nbs-banner url="../../../assets/img/home/background.png" descricao="Acessar Serviços Financeiros" titulo="Serviços Financeiros"
        routerLink="/menu"></app-nbs-banner>
    </div>
    <div class="nbs_services-item">
      <div class="nbs_banner">
        <div class="box-title">
          <h2 class="nbs_banner-title">Recursos Humanos</h2>
          <p class="nbs_banner-subtitle">Atenção! A partir de agora os serviços de RH devem ser acessados através da plataforma myHR. <a href="https://hcm17.sapsf.com/login?&company=nutrien">Clique aqui para acessar o myHR.</a></p>
        </div>
          <img class="nbs_banner-img" src="../../../assets/img/background_compras.png" >
      </div>
      


    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>



