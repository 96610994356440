<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Serviços para Cartão de Crédito Corporativo"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="prepEnviarFormulario()">



    <div class="form-group row">
      <label for="solicitacao" class="col-sm-4 col-form-label">*Solicitação</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="solicitacao" (change)="onSolicitacaoChange($event)" (change)="mostraValor($event)">
          <option *ngFor="let solicitacao of solicitacoes" [value]="solicitacao.solicitacao">{{solicitacao.solicitacao}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('solicitacao')?.errors?.required && formulario.get('solicitacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().solicitacao == 'Desbloqueio por regularização'">
      <div class="mensagem-topo">
        <h6 class="text-center mb-4">Importante</h6>
        <p>Está opção está disponível apenas para desbloqueio de cartão por acerto na prestação de contas, após conferência e estando tudo correto, seu cartão será desbloqueado.</p>
      </div>
    </div>  


    <div *ngIf="formulario.getRawValue().solicitacao=='Novo cartão corporativo / 2ª Via'">
      <div class="mensagem-topo">
        <h6 class="text-center mb-4">Importante</h6>
        <p>1 - A emissão do cartão de crédito corporativo da Nutrien está sujeita à aprovação do seu gestor.</p>
        <p>2 - Após a aprovação, o cartão físico será enviado diretamente pelo Banco, Correios, em até 15 dias corridos para o endereço que for cadastrado nesse formulário.</p>
        <p>3 - A senha de cartão será enviada por SMS também em até 15 dias corridos para o telefone que for cadastrado nesse formulário (muita atenção para preencher corretamente o telefone com o DDD).</p>
        <p>4 - A senha não será enviada pelos Correios. Caso tenha recebido o cartão fisico, mas a senha não tiver sido enviada por SMS dentro dos 15 dias da solicitação do cartão, entre em contato com os telefones que constam atrás de seu cartão fisico para soliciar o reenvio do SMS.</p>
      </div>
      <div class="form-group row">

        <label for="voce_esta_solicitando" class="col-sm-4 col-form-label">Você está solicitando:</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='voce_esta_solicitando' value="1ª via de cartão" (change)="mostraValor($event)"
            (change)="onVoceEstaSolicitandoChange($event)"
              class='radio-btn'>&nbsp;1ª via de cartão
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='voce_esta_solicitando' value='2ª ou mais vias de cartão'(change)="mostraValor($event)"
            (change)="onVoceEstaSolicitandoChange($event)"
              class='radio-btn'>&nbsp;2ª via de cartão
          </label>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().voce_esta_solicitando=='2ª ou mais vias de cartão'">
        <div class="form-group row">
          <label for="motivo_da_solicitacao" class="col-sm-4 col-form-label">*Motivo da solicitação</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="motivo_da_solicitacao" (change)="mostraValor($event)">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('motivo_da_solicitacao')?.errors?.required && formulario.get('motivo_da_solicitacao')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

      </div>

      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa" (change)="mostraValor($event)">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="rg" class="col-sm-4 col-form-label">*RG</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="rg" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('rg')?.errors?.required && formulario.get('rg')?.touched" mensagem="Campo Obrigatório">
        </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_gestor" class="col-sm-4 col-form-label">*E-mail do Gestor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_gestor"
              (keyup)="validateEmail($event.target.value, 'gestor')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'gestor')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_gestor')?.errors?.required && formulario.get('e_mail_gestor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="centro_de_custo" class="col-sm-4 col-form-label">*Centro de custo</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_custo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('centro_de_custo')?.errors?.required && formulario.get('centro_de_custo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_de_nascimento" class="col-sm-4 col-form-label">*Data de Nascimento</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_nascimento" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_nascimento')?.errors?.required && formulario.get('data_de_nascimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo_da_mae" class="col-sm-4 col-form-label">*Nome completo da mãe</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo_da_mae" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo_da_mae')?.errors?.required && formulario.get('nome_completo_da_mae')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo_do_pai" class="col-sm-4 col-form-label">Nome completo do pai</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo_do_pai" (change)="mostraValor($event)">
        </div>
      </div>

      <div class="form-group row">
        <label for="sexo" class="col-sm-4 col-form-label">*Gênero</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='sexo' value="Masculino" (change)="mostraValor($event)"
              class='radio-btn'>&nbsp;Masculino
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='sexo' value='Feminino' (change)="mostraValor($event)"
              class='radio-btn'>&nbsp;Feminino
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('sexo')?.errors?.required && formulario.get('sexo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <!-- <div class="form-group row">
        <label for="ddi_do_celular" class="col-sm-4 col-form-label">*DDI do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddi_do_celular" (change)="mostraValor($event)" mask="(000)"  >
          <small>055 para Brasil</small>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddi_do_celular')?.errors?.required && formulario.get('ddi_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ddd" class="col-sm-4 col-form-label">*DDD do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddd" (change)="mostraValor($event)" mask="(00)"  >
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddd')?.errors?.required && formulario.get('ddd')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <!-- <div class="form-group row">
        <label for="primeiro_nome_do_portador" class="col-sm-4 col-form-label">*Primeiro nome do portador</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="primeiro_nome_do_portador" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('primeiro_nome_do_portador')?.errors?.required && formulario.get('primeiro_nome_do_portador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ultimo_nome_do_portador" class="col-sm-4 col-form-label">*Último nome do portador</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ultimo_nome_do_portador" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ultimo_nome_do_portador')?.errors?.required && formulario.get('ultimo_nome_do_portador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="cargo_2" class="col-sm-4 col-form-label">*Cargo</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="cargo_2" (change)="mostraValor($event)">
            <option *ngFor="let cargo of cargos" [value]="cargo.cargo">{{cargo.cargo}}
            </option>
          </select>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Consultor/Analista'">O Limite do cartão será: R$ 4.000,00
            para consultor/analista</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Coordenador/Supervisor'">O Limite do cartão será: R$
            10.000,00 para coordenador/supervisor</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Gerente'">O Limite do cartão será: R$ 20.000,00 para
            gerente</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Diretor'">O Limite do cartão será: R$ 70.000,00 para
            diretor</small>
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cargo_2')?.errors?.required && formulario.get('cargo_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="endereco_completo" class="col-sm-4 col-form-label">*Endereço</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="endereco_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('endereco_completo')?.errors?.required && formulario.get('endereco_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="numero" class="col-sm-4 col-form-label">*Número</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero')?.errors?.required && formulario.get('numero')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="complemento" class="col-sm-4 col-form-label">Complemento</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="complemento" (change)="mostraValor($event)">
        </div>
      </div>

      <div class="form-group row">
        <label for="bairro" class="col-sm-4 col-form-label">*Bairro</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="bairro" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('bairro')?.errors?.required && formulario.get('bairro')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cidade" class="col-sm-4 col-form-label">*Cidade</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cidade" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade')?.errors?.required && formulario.get('cidade')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="uf" class="col-sm-4 col-form-label">*UF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="uf" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('uf')?.errors?.required && formulario.get('uf')?.touched" mensagem="Campo Obrigatório">
        </app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cep" class="col-sm-4 col-form-label">*CEP</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cep" mask="00000-000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cep')?.errors?.required && formulario.get('cep')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="pais" class="col-sm-4 col-form-label">*País</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="pais" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('pais')?.errors?.required && formulario.get('pais')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>

    </div>






    <div *ngIf="formulario.getRawValue().solicitacao=='Alteração e/ou atualização de dados cadastrais'">

      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quatro_primeiros_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro primeiros números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_primeiros_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_primeiros_numeros_do_cartao')?.errors?.required && formulario.get('quatro_primeiros_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_ultimos_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro últimos números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_ultimos_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_ultimos_numeros_do_cartao')?.errors?.required && formulario.get('quatro_ultimos_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>


      <div class="form-group row">
        <label for="quais_dados_deseja_atualizar" class="col-sm-4 col-form-label">*Quais dados deseja atualizar </label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="quais_dados_deseja_atualizar"><input type="checkbox"
              class="form-check-input" formControlName="quais_dados_deseja_atualizar"
              (change)="centroDeCusto=!centroDeCusto" (change)="atualizaCentroDeCustoValue($event)" value ='Centro de custo'>&nbsp;&nbsp; Centro de Custo</label>
          <label class="col-sm-12 col-form-label" for="quais_dados_deseja_atualizar"><input type="checkbox"
              class="form-check-input" formControlName="quais_dados_deseja_atualizar"
              (change)="nome=!nome" (change)="atualizaNomeValue($event)" value = 'Nome'>&nbsp;&nbsp;Nome</label>
          <label class="col-sm-12 col-form-label" for="quais_dados_deseja_atualizar"><input type="checkbox"
              class="form-check-input" formControlName="quais_dados_deseja_atualizar"
              (change)='telefone=!telefone' (change)="atualizaTelefoneValue($event)" value = 'Telefone'>&nbsp;&nbsp;Telefone</label>
          <label class="col-sm-12 col-form-label" for="quais_dados_deseja_atualizar"><input type="checkbox"
              class="form-check-input" formControlName="quais_dados_deseja_atualizar"
              (change)="endereco=!endereco" (change)="atualizaEnderecoValue($event)" value = 'Endereço'>&nbsp;&nbsp;Endereço</label>
              <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quais_dados_deseja_atualizar')?.errors?.required && formulario.get('quais_dados_deseja_atualizar')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div *ngIf="centroDeCusto">
        <div class="form-group row">
          <label for="centro_de_custo" class="col-sm-4 col-form-label">*Centro de custo</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="centro_de_custo">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('centro_de_custo')?.errors?.required && formulario.get('centro_de_custo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>

        </div>
      </div>

      <div *ngIf="nome">
        <div class="form-group row">
          <label for="nome_a_ser_alterado" class="col-sm-4 col-form-label">*Nome a ser alterado</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nome_a_ser_alterado">
          </div>
        </div>
      </div>

      <div *ngIf="telefone">
        <div class="form-group row">
          <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"  >
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>


      <div *ngIf="endereco">
        <div class="form-group row">
          <label for="endereco_completo" class="col-sm-4 col-form-label">*Endereço</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="endereco_completo">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('endereco_completo')?.errors?.required && formulario.get('endereco_completo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="numero" class="col-sm-4 col-form-label">*Número</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('numero')?.errors?.required && formulario.get('numero')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="complemento" class="col-sm-4 col-form-label">Complemento</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="complemento">
          </div>
        </div>

        <div class="form-group row">
          <label for="bairro" class="col-sm-4 col-form-label">*Bairro</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="bairro">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('bairro')?.errors?.required && formulario.get('bairro')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="cidade" class="col-sm-4 col-form-label">*Cidade</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="cidade">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cidade')?.errors?.required && formulario.get('cidade')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="uf" class="col-sm-4 col-form-label">*UF</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="uf">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('uf')?.errors?.required && formulario.get('uf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="cep" class="col-sm-4 col-form-label">*CEP</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="cep" mask="00000-000"  >
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cep')?.errors?.required && formulario.get('cep')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="pais" class="col-sm-4 col-form-label">*pais</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="pais">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('pais')?.errors?.required && formulario.get('pais')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>


      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().solicitacao=='Alteração de limite'">

      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_gestor" class="col-sm-4 col-form-label">*E-mail do Gestor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_gestor"
              (keyup)="validateEmail($event.target.value, 'gestor')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'gestor')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_gestor')?.errors?.required && formulario.get('e_mail_gestor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="ddi_do_celular" class="col-sm-4 col-form-label">*DDI do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddi_do_celular" (change)="mostraValor($event)" mask="(000)"  >
          <small>055 para Brasil</small>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddi_do_celular')?.errors?.required && formulario.get('ddi_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ddd" class="col-sm-4 col-form-label">*DDD do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddd" (change)="mostraValor($event)" mask="(00)"  >
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddd')?.errors?.required && formulario.get('ddd')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_primeiros_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro primeiros números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_primeiros_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_primeiros_numeros_do_cartao')?.errors?.required && formulario.get('quatro_primeiros_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_ultimos_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro últimos números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_ultimos_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_ultimos_numeros_do_cartao')?.errors?.required && formulario.get('quatro_ultimos_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="novo_valor_do_limite" class="col-sm-4 col-form-label">Novo valor do limite
          cartão (R$)</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="novo_valor_do_limite" 
          (paste)="pasteValorDecimal($event)"
          (keyup)="formataValorDecimal($event.target.value)"
          (click)="focusValorDecimal($event)"
          (contextmenu)="focusValorDecimal($event)"
          [value]="novoValorDoLimite">
          <small>Informar apenas números. Ex: 1.111.111,00</small>
        </div>
      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        </div>
      </div>


    </div>

    <div *ngIf="formulario.getRawValue().solicitacao=='Liberação gastos em viagens Internacionais'">
      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_gestor" class="col-sm-4 col-form-label">*E-mail do Gestor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_gestor"
              (keyup)="validateEmail($event.target.value, 'gestor')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'gestor')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_gestor')?.errors?.required && formulario.get('e_mail_gestor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="ddi_do_celular" class="col-sm-4 col-form-label">*DDI do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddi_do_celular" (change)="mostraValor($event)" mask="(000)"  >
          <small>055 para Brasil</small>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddi_do_celular')?.errors?.required && formulario.get('ddi_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ddd" class="col-sm-4 col-form-label">*DDD do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddd" (change)="mostraValor($event)" mask="(00)"  >
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddd')?.errors?.required && formulario.get('ddd')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_primeiros_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro primeiros números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_primeiros_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_primeiros_numeros_do_cartao')?.errors?.required && formulario.get('quatro_primeiros_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_ultimos_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro últimos números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_ultimos_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_ultimos_numeros_do_cartao')?.errors?.required && formulario.get('quatro_ultimos_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="pais_de_destino" class="col-sm-4 col-form-label">*País de destino
          </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="pais_de_destino">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('pais_de_destino')?.errors?.required && formulario.get('pais_de_destino')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="data_de_inicio_da_viagem" class="col-sm-4 col-form-label">*Data de início da viagem</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_inicio_da_viagem">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_inicio_da_viagem')?.errors?.required && formulario.get('data_de_inicio_da_viagem')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="data_de_retorno_da_viagem" class="col-sm-4 col-form-label">*Data de retorno da viagem</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_retorno_da_viagem">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_retorno_da_viagem')?.errors?.required && formulario.get('data_de_retorno_da_viagem')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().solicitacao=='Solicitação de Senha do Cartão'">

      <!-- <div class="form-group row">

        <label for="voce_esta_solicitando" class="col-sm-4 col-form-label">Você está solicitando:</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='voce_esta_solicitando' value="1ª via de cartão" (change)="mostraValor($event)"
            (change)="onVoceEstaSolicitandoChange($event)"
              class='radio-btn'>&nbsp;1ª via de cartão
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='voce_esta_solicitando' value='2ª ou mais vias de cartão'(change)="mostraValor($event)"
            (change)="onVoceEstaSolicitandoChange($event)"
              class='radio-btn'>&nbsp;2ª via de cartão
          </label>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="rg" class="col-sm-4 col-form-label">*RG</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="rg" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('rg')?.errors?.required && formulario.get('rg')?.touched" mensagem="Campo Obrigatório">
        </app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_gestor" class="col-sm-4 col-form-label">*E-mail do Gestor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_gestor"
              (keyup)="validateEmail($event.target.value, 'gestor')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'gestor')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_gestor')?.errors?.required && formulario.get('e_mail_gestor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="centro_de_custo" class="col-sm-4 col-form-label">*Centro de custo</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_custo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('centro_de_custo')?.errors?.required && formulario.get('centro_de_custo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="data_de_nascimento" class="col-sm-4 col-form-label">*Data de Nascimento</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_nascimento" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_nascimento')?.errors?.required && formulario.get('data_de_nascimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo_da_mae" class="col-sm-4 col-form-label">*Nome completo da mãe</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo_da_mae" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo_da_mae')?.errors?.required && formulario.get('nome_completo_da_mae')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="nome_completo_do_pai" class="col-sm-4 col-form-label">Nome completo do pai</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo_do_pai" (change)="mostraValor($event)">
        </div>
      </div>

      <div class="form-group row">
        <label for="sexo" class="col-sm-4 col-form-label">*Gênero</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='sexo' value="Masculino" (change)="mostraValor($event)"
              class='radio-btn'>&nbsp;Masculino
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='sexo' value='Feminino' (change)="mostraValor($event)"
              class='radio-btn'>&nbsp;Feminino
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('sexo')?.errors?.required && formulario.get('sexo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <!-- <div class="form-group row">
        <label for="ddi_do_celular" class="col-sm-4 col-form-label">*DDI do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddi_do_celular" (change)="mostraValor($event)" mask="(000)"  >
          <small>055 para Brasil</small>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddi_do_celular')?.errors?.required && formulario.get('ddi_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ddd" class="col-sm-4 col-form-label">*DDD do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddd" (change)="mostraValor($event)" mask="(00)"  >
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddd')?.errors?.required && formulario.get('ddd')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <!-- <div class="form-group row">
        <label for="primeiro_nome_do_portador" class="col-sm-4 col-form-label">*Primeiro nome do portador</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="primeiro_nome_do_portador" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('primeiro_nome_do_portador')?.errors?.required && formulario.get('primeiro_nome_do_portador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ultimo_nome_do_portador" class="col-sm-4 col-form-label">*Último nome do portador</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ultimo_nome_do_portador" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ultimo_nome_do_portador')?.errors?.required && formulario.get('ultimo_nome_do_portador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="cargo_2" class="col-sm-4 col-form-label">*Cargo</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="cargo_2" (change)="mostraValor($event)">
            <option *ngFor="let cargo of cargos" [value]="cargo.cargo">{{cargo.cargo}}
            </option>
          </select>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Consultor/Analista'">O Limite do cartão será: R$ 4.000,00
            para consultor/analista</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Coordenador/Supervisor'">O Limite do cartão será: R$
            10.000,00 para coordenador/supervisor</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Gerente'">O Limite do cartão será: R$ 20.000,00 para
            gerente</small>
          <small *ngIf="formulario.getRawValue().cargo_2 =='Diretor'">O Limite do cartão será: R$ 70.000,00 para
            diretor</small>
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cargo_2')?.errors?.required && formulario.get('cargo_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="endereco_completo" class="col-sm-4 col-form-label">*Endereço</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="endereco_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('endereco_completo')?.errors?.required && formulario.get('endereco_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="numero" class="col-sm-4 col-form-label">*Número</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero')?.errors?.required && formulario.get('numero')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="complemento" class="col-sm-4 col-form-label">Complemento</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="complemento" (change)="mostraValor($event)">
        </div>
      </div>

      <div class="form-group row">
        <label for="bairro" class="col-sm-4 col-form-label">*Bairro</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="bairro" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('bairro')?.errors?.required && formulario.get('bairro')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cidade" class="col-sm-4 col-form-label">*Cidade</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cidade" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade')?.errors?.required && formulario.get('cidade')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="uf" class="col-sm-4 col-form-label">*UF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="uf" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('uf')?.errors?.required && formulario.get('uf')?.touched" mensagem="Campo Obrigatório">
        </app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="cep" class="col-sm-4 col-form-label">*CEP</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cep" mask="00000-000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cep')?.errors?.required && formulario.get('cep')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="pais" class="col-sm-4 col-form-label">*País</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="pais" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('pais')?.errors?.required && formulario.get('pais')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>


    </div>

    <div *ngIf="formulario.getRawValue().solicitacao=='Desbloqueio por regularização'">
      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask="000.000.000-99" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quatro_primeiros_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro primeiros números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_primeiros_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_primeiros_numeros_do_cartao')?.errors?.required && formulario.get('quatro_primeiros_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="quatro_ultimos_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro últimos números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_ultimos_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_ultimos_numeros_do_cartao')?.errors?.required && formulario.get('quatro_ultimos_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>



    </div>

    <!-- <div *ngIf="formulario.getRawValue().solicitacao=='Acompanhamento do Cartão'">
      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8">
          <input type="email" class="form-control" formControlName="e_mail" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.email && formulario.get('e_mail')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>

    </div> -->

    <!-- <div *ngIf="formulario.getRawValue().solicitacao=='Divergência de Dados com Cartão'">
      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8">
          <input type="email" class="form-control" formControlName="e_mail" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.email && formulario.get('e_mail')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
        </div>
      </div>
<div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>



    </div> -->

    <div *ngIf="formulario.getRawValue().solicitacao=='Cancelamento'">


      <div class="form-group row">

        <label for="voce_esta_solicitando" class="col-sm-4 col-form-label">*Motivo?</label>
        <div class="col-sm-8 opcoes">
          <label for="tipo" class=' radio-inline opcao-cancelamento'>
            <input type="radio" formControlName='qual_o_cancelamento_ser_necess_rio' value="Troca de Centro de Custo" (change)="onMotivoDoCancelamentoChange($event)"
              class='radio-btn'>&nbsp;Troca de Centro de Custo
          </label>
          <label for="tipo" class='radio-inline opcao-cancelamento'>
            <input type="radio" formControlName='qual_o_cancelamento_ser_necess_rio' value='Troca de CNPJ'(change)="onMotivoDoCancelamentoChange($event)"
              class='radio-btn'>&nbsp;Troca de CNPJ
          </label>
          <label for="tipo" class=' radio-inline opcao-cancelamento'>
            <input type="radio" formControlName='qual_o_cancelamento_ser_necess_rio' value="Dados do Cartão Incorreto" (change)="onMotivoDoCancelamentoChange($event)"
              class='radio-btn'>&nbsp;Dados do Cartão Incorreto
          </label>
          <label for="tipo" class='radio-inline opcao-cancelamento'>
            <input type="radio" formControlName='qual_o_cancelamento_ser_necess_rio' value='Duplicidade de Cartão'(change)="onMotivoDoCancelamentoChange($event)"
              class='radio-btn'>&nbsp;Duplicidade de Cartão
          </label>
          <label for="tipo" class='radio-inline opcao-cancelamento'>
            <input type="radio" formControlName='qual_o_cancelamento_ser_necess_rio' value='Desligamento'(change)="onMotivoDoCancelamentoChange($event)"
              class='radio-btn'>&nbsp;Desligamento
          </label>



          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('qual_o_cancelamento_ser_necess_rio')?.errors?.required && formulario.get('qual_o_cancelamento_ser_necess_rio')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="form-group row">
        <label for="matricula" class="col-sm-4 col-form-label">*Matrícula</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="matricula" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="nome_completo" class="col-sm-4 col-form-label">*Nome Completo do funcionário Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_completo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_completo')?.errors?.required && formulario.get('nome_completo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="centro_de_custo" class="col-sm-4 col-form-label">*Centro de custo</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_custo" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('centro_de_custo')?.errors?.required && formulario.get('centro_de_custo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail"
              (keyup)="validateEmail($event.target.value, 'solicitante')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'solicitante')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_gestor" class="col-sm-4 col-form-label">*E-mail do Gestor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_gestor"
              (keyup)="validateEmail($event.target.value, 'gestor')"
              (change)="mostraValor($event)"              
              (click)="focusEmail($event)"
              (contextmenu)="focusEmail($event)"
              (paste)="pasteEmail($event, 'gestor')"
            >
            <span>&#64;nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_gestor')?.errors?.required && formulario.get('e_mail_gestor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="gestor_demandante" class="col-sm-4 col-form-label">*Gestor Demandante</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="gestor_demandante" (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('gestor_demandante')?.errors?.required && formulario.get('gestor_demandante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="ddi_do_celular" class="col-sm-4 col-form-label">*DDI do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddi_do_celular" (change)="mostraValor($event)" mask="(000)"  >
          <small>055 para Brasil</small>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddi_do_celular')?.errors?.required && formulario.get('ddi_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <!-- <div class="form-group row">
        <label for="ddd" class="col-sm-4 col-form-label">*DDD do celular</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ddd" (change)="mostraValor($event)" mask="(00)"  >
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ddd')?.errors?.required && formulario.get('ddd')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->

      <div class="form-group row">
        <label for="numero_do_celular" class="col-sm-4 col-form-label">*Telefone de contato</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_do_celular" mask="(00)0000-0000||(00)00000-0000"   (change)="mostraValor($event)">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_celular')?.errors?.required && formulario.get('numero_do_celular')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

     
      <div class="form-group row" *ngIf="this.formulario.getRawValue().qual_o_cancelamento_ser_necess_rio!='Desligamento'" >
        <label for="quatro_primeiros_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro primeiros números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_primeiros_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_primeiros_numeros_do_cartao')?.errors?.required && formulario.get('quatro_primeiros_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row" *ngIf="this.formulario.getRawValue().qual_o_cancelamento_ser_necess_rio!='Desligamento'">
        <label for="quatro_ultimos_numeros_do_cartao" class="col-sm-4 col-form-label">*Quatro últimos números do
          cartão</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quatro_ultimos_numeros_do_cartao" maxlength="4">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quatro_ultimos_numeros_do_cartao')?.errors?.required && formulario.get('quatro_ultimos_numeros_do_cartao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao" (change)="mostraValor($event)"></textarea>
        </div>
      </div>


    </div>

    <!-- <div *ngIf="formulario.getRawValue().solicitacao=='Suporte Geral'">


    </div> -->






    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
        <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
            (click)="abrirPipePublico()">Envie por aqui.</span></p>
      </div> -->
</div>
<!--
<p>Required solicitacao: {{formulario.get('solicitacao')?.hasError('required')}}</p>
<p>Required voce_esta_solicitando: {{formulario.get('voce_esta_solicitando')?.hasError('required')}}</p>
<p>Required motivo_da_solicitacao: {{formulario.get('motivo_da_solicitacao')?.hasError('required')}}</p>
<p>Required numero_de_protocolo_de_cancelamento: {{formulario.get('numero_de_protocolo_de_cancelamento')?.hasError('required')}}</p>
<p>Required empresa: {{formulario.get('empresa')?.hasError('required')}}</p>
<p>Required cpf: {{formulario.get('cpf')?.hasError('required')}}</p>
<p>Required rg: {{formulario.get('rg')?.hasError('required')}}</p>
<p>Required matricula: {{formulario.get('matricula')?.hasError('required')}}</p>
<p>Required nome_completo: {{formulario.get('nome_completo')?.hasError('required')}}</p>
<p>Required e_mail: {{formulario.get('e_mail')?.hasError('required')}}</p>
<p>Required quatro_primeiros_numeros_do_cartao: {{formulario.get('quatro_primeiros_numeros_do_cartao')?.hasError('required')}}</p>
<p>Required quatro_ultimos_numeros_do_cartao: {{formulario.get('quatro_ultimos_numeros_do_cartao')?.hasError('required')}}</p>
<p>Required quais_dados_deseja_atualizar: {{formulario.get('quais_dados_deseja_atualizar')?.hasError('required')}}</p>
<p>Required centro_de_custo: {{formulario.get('centro_de_custo')?.hasError('required')}}</p>
<p>Required nome_a_ser_alterado: {{formulario.get('nome_a_ser_alterado')?.hasError('required')}}</p>
<p>Required data_de_nascimento: {{formulario.get('data_de_nascimento')?.hasError('required')}}</p>
<p>Required nome_completo_da_mae: {{formulario.get('nome_completo_da_mae')?.hasError('required')}}</p>
<p>Required nome_completo_do_pai: {{formulario.get('nome_completo_do_pai')?.hasError('required')}}</p>
<p>Required sexo: {{formulario.get('sexo')?.hasError('required')}}</p>
<p>Required ddi_do_celular: {{formulario.get('ddi_do_celular')?.hasError('required')}}</p>
<p>Required ddd: {{formulario.get('ddd')?.hasError('required')}}</p>
<p>Required numero_do_celular: {{formulario.get('numero_do_celular')?.hasError('required')}}</p>
<p>Required primeiro_nome_do_portador: {{formulario.get('primeiro_nome_do_portador')?.hasError('required')}}</p>
<p>Required ultimo_nome_do_portador: {{formulario.get('ultimo_nome_do_portador')?.hasError('required')}}</p>
<p>Required cargo_2: {{formulario.get('cargo_2')?.hasError('required')}}</p>
<p>Required endereco_completo: {{formulario.get('endereco_completo')?.hasError('required')}}</p>
<p>Required numero: {{formulario.get('numero')?.hasError('required')}}</p>
<p>Required complemento: {{formulario.get('complemento')?.hasError('required')}}</p>
<p>Required bairro: {{formulario.get('bairro')?.hasError('required')}}</p>
<p>Required cidade: {{formulario.get('cidade')?.hasError('required')}}</p>
<p>Required uf: {{formulario.get('uf')?.hasError('required')}}</p>
<p>Required cep: {{formulario.get('cep')?.hasError('required')}}</p>
<p>Required pais: {{formulario.get('pais')?.hasError('required')}}</p>
<p>Required limite_do_cartao: {{formulario.get('limite_do_cartao')?.hasError('required')}}</p>
<p>Required novo_valor_do_limite: {{formulario.get('novo_valor_do_limite')?.hasError('required')}}</p>
<p>Required aprovacao_do_gestor: {{formulario.get('aprovacao_do_gestor')?.hasError('required')}}</p>
<p>Required pais_de_destino: {{formulario.get('pais_de_destino')?.hasError('required')}}</p>
<p>Required data_de_inicio_da_viagem: {{formulario.get('data_de_inicio_da_viagem')?.hasError('required')}}</p>
<p>Required data_de_retorno_da_viagem: {{formulario.get('data_de_retorno_da_viagem')?.hasError('required')}}</p>
<p>Required primeiros_4_digitos_do_cartao: {{formulario.get('primeiros_4_digitos_do_cartao')?.hasError('required')}}</p>
<p>Required ultimos_4_digitos_do_cartao: {{formulario.get('ultimos_4_digitos_do_cartao')?.hasError('required')}}</p>
<p>Required data_de_desligamento_do_funcionario: {{formulario.get('data_de_desligamento_do_funcionario')?.hasError('required')}}</p>
<p>Required observacao: {{formulario.get('observacao')?.hasError('required')}}</p> -->


<div class="footer">
  <app-footer link="payment"></app-footer>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>

