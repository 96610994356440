import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() link:string = ""
  @Input() exibeVoltar:boolean = true

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navegar(){
    this.router.navigate([this.link])
  }

  faq(){
    this.router.navigate(['/forms/ajuda'])
    // window.open('https://teams.microsoft.com/l/channel/19%3aCLyOXydI8jXEU778giMgw_CdE7NNTjG39KT2CE6Gaw01%40thread.tacv2/General?groupId=33affcdb-311a-4c29-bda9-f80dfcf5e27d&tenantId=5e0b361b-59ed-466e-8759-030448046197',"_blank")
  }

  goHome(){
    this.router.navigate([''])
  }

  termo() {
    Swal.fire({
      title: 'Termo de Uso',
      html: '<div class="text-justify p-20">' +
      //'<h4 class="text-center">Versão ' + environment.versao + ' - ' + environment.versaoData + '</h4>' +
      //   '<div>' +
      //   '<span class="color-light-green">Atualizações automáticas a partir da versão 13.0.0</span><br>' +
      //   '<h6>Não se preocupe! Todas as atualizações deverão ocorrer em segundo plano de forma automática.</h6>' +
      //   '<span class="color-light-green">Está com o portal desatualizado? Siga o passo a passo:</span></div>' +
      //'<img class="nbs_banner-img" src="../../../assets/img/melhoria.png" style= "width: 100%" >' +
       //'<h4 class="text-center">Outras atualizações</h4>' +
        //'Fiscal</br></br>'+
        '<p> Ao acessar e usar este site, você aceita os seguintes termos e condições, sem limitação ou qualificação.</p>' +
				'<p>Salvo disposição em contrário, o conteúdo deste site, incluindo, mas não se limitando a, o texto e as imagens aqui contidos e sua disposição são propriedade da Accenture. Todas as marcas comerciais usadas ou mencionadas neste site são propriedade de seus respectivos proprietários.</p>' +
				'<p>Nada contido neste site deve ser interpretado como conferindo por implicação, preclusão ou de outra forma, qualquer licença ou direito a qualquer direito autoral, ' +
        ' patente, marca comercial ou outro interesse de propriedade da Accenture ou de terceiros. Este site e o conteúdo fornecido neste site, incluindo, mas não se limitando a, imagens gráficas, áudio, vídeo, código html, botões e texto, não podem ser copiados, reproduzidos, republicados, carregados, postados, transmitidos ou distribuídos em de qualquer forma, sem o consentimento prévio por escrito da Accenture, exceto que você pode baixar, exibir e imprimir uma cópia dos materiais em qualquer computador único exclusivamente para seu uso pessoal e não comercial, desde que você não modifique o material em qualquer forma e você mantém intactos todos os direitos autorais, marcas registradas e outros avisos de propriedade </p>' +
				'<p>As informações fornecidas neste site são gratuitas e apenas para fins informativos e não criam uma relação de negócios ou serviços profissionais entre você e a Accenture.</p>' +
				'<p>Os links neste site podem levar a serviços ou sites não operados pela Accenture. Nenhum julgamento ou garantia é feito com relação a esses outros serviços ou sites, e a Accenture não se responsabiliza por esses outros sites ou serviços. Um link para outro site ou serviço não é um endosso desse site ou serviço. Qualquer uso que você fizer das informações fornecidas neste site, ou de qualquer site ou serviço vinculado a este site, é por sua própria conta e risco.</p>' +
				'<p>Este site e seu conteúdo são fornecidos "no estado em que se encontram" e a Accenture não faz nenhuma representação ou garantia de qualquer tipo com relação a este site ou ' +
        'qualquer site ou serviço acessível por meio deste site. A Accenture renuncia expressamente a todas as garantias expressas e implícitas, incluindo, mas não se limitando a, garantias implícitas de comercialização, adequação a um propósito específico, título e não violação. Em nenhum caso, a Accenture ou seus prestadores de serviços terceirizados serão responsáveis por qualquer parte por quaisquer danos diretos, indiretos, incidentais, especiais, exemplares, consequenciais ou outros (incluindo, mas não se limitando a, lucros cessantes, interrupção de negócios, perda de programas ou dados) sem levar em conta a forma de ação e se em contrato, ato ilícito, negligência, responsabilidade objetiva ou de outra forma, decorrentes de ou em conexão com este site, qualquer conteúdo ou acessado por meio deste site ou qualquer serviço do site vinculado para, ou qualquer cópia, exibição ou uso dos mesmos.</p>' +
				'<p>Você é responsável por cumprir as leis da jurisdição da qual está acessando este site e concorda que não acessará ou usará as informações contidas neste site em violação a tais leis.</p>' +
				'<p>A menos que expressamente declarado de outra forma neste documento, qualquer informação enviada por você através deste site será considerada não confidencial e não proprietária.</p>' +
				'<p>Você declara que tem o direito legal de enviar tais informações e concorda que não enviará nenhuma informação a menos que tenha o direito legal de fazê-lo. Devido à natureza aberta da Internet, recomendamos que você não envie informações que considere confidenciais.</p>' +
        // '<p>Pelo presente, concedemos a você uma licença limitada e revogável para baixar e imprimir cópias de qualquer parte do Conteúdo deste Site ao qual você tenha obtido acesso apropriado, mas apenas para seu uso comercial interno ou para seu próprio uso pessoal e não comercial, e somente se você não remover, modificar ou ocultar qualquer direito autoral, marca registrada, ou outros avisos de propriedade do Conteúdo baixado. A licença acima está sujeita a estes Termos de Uso e não inclui o direito de usar qualquer mineração de dados, robôs ou métodos semelhantes de coleta ou extração de dados. Esta licença é revogável a qualquer momento sem aviso prévio e com ou sem causa. Você não pode e nem pode permitir que outros copiem, distribuam, executem ou exibam publicamente, preparem trabalhos derivados com base em, difundam, explorem ou usem qualquer parte do Conteúdo deste Site, exceto conforme expressamente previsto nestes Termos de Uso sem nossa permissão prévia por escrito. Nada nestes Termos de Uso deve ser interpretado como transferência de qualquer direito, título ou interesse neste Site ou seu Conteúdo para você ou qualquer outra pessoa, exceto a licença limitada para usar este Site e seu Conteúdo, nos termos expressamente estabelecidos aqui.</p>' +
				// '<h3>Conteúdo de terceiros</h3>' +
				// '<p>Algumas das informações, artigos e outros materiais disponíveis através deste Site (incluindo, sem limitação, informações como notícias, informações meteorológicas, referências de pragas e informações de tratamento, formulários governamentais, rótulo e informações da Ficha de Dados de Segurança do Material (“FDS” ou antiga "FISPQ") fornecidos à Nutrien por terceiros, são fornecidos apenas em seu interesse e conveniência. A Nutrien não endossa materiais ou os fornecedores que os fornecem para nós, nem a Nutrien garante ou declara que esses materiais são atuais, precisos, completos ou confiáveis.</p>' +
				// '<h3>Serviços de Terceiros</h3>' +
				// '<p>Certos recursos e funcionalidades oferecidos em nosso Site, incluindo, sem limitação, o Serviço, são fornecidos por terceiros não relacionados à Nutrien (coletivamente, "Serviços de Terceiros"). A Nutrien não é responsável de forma alguma por tais Serviços de Terceiros. O acesso e o uso de Serviços de Terceiros podem exigir a sua concordância com os Termos Adicionais.</p>' +
				// '<h3>Marcas comerciais</h3> ' +
				// '<p>As marcas comerciais e marcas de serviço usadas ou exibidas neste Site ("Marcas comerciais") são marcas registradas e não registradas da Nutrien, nossas entidades relacionadas ou terceiros. Você não pode usar quaisquer Marcas Comerciais exibidas neste Site, sem a permissão prévia e expressa por escrito da Nutrien ou do proprietário da marca registrada.</p>' +
				// '<h3>Links para sites de terceiros</h3>' +
				// '<p>Este Site contém links para outros sites de propriedade e operados por terceiros não relacionados à Nutrien. Não somos responsáveis por examinar ou avaliar, e não garantimos os produtos ou ofertas de qualquer um desses terceiros, ou a precisão do conteúdo de seus sites. Nós não assumimos qualquer responsabilidade ou obrigação pelas ações, produtos e conteúdo de tais sites. Antes de usar qualquer site de terceiros, você deve revisar os termos de uso e políticas aplicáveis a esses sites. A inclusão de um link neste Site não implica nosso endosso de tal site de terceiros. Se você decidir acessar qualquer um desses sites de terceiros vinculados, você o faz por sua conta e risco.</p>' +
				// '<h3>Links para sites relacionados à Nutrien </h3>' +
				// '<p>Este Site pode conter links para outros sites de propriedade da Nutrien, nossa empresa matriz ou outras entidades relacionadas à Nutrien. Sites diferentes são usados para diferentes finalidades e, portanto, podem ter termos de uso e políticas de privacidade diferentes. Estes Termos de Uso (e a Declaração de Privacidade &amp; Cookies online mencionada acima) aplicam-se apenas a Nutrien. Antes de usar qualquer outro site, você deve revisar os termos de uso e as políticas aplicáveis para esse site.</p>' +
				// '<h3>Isenção de Garantias</h3>' +
				// '<p>O USO DESTE SITE E DE TODO O CONTEÚDO, PRODUTOS E SERVIÇOS DISPONIBILIZADOS ATRAVÉS DESTE SITE, É POR SUA CONTA E RISCO. ESTE SITE, E TODO O CONTEÚDO, PRODUTOS E SERVIÇOS DISPONIBILIZADOS POR MEIO DESTE SITE, É FORNECIDO "NO ESTADO EM QUE SE ENCONTRA" E "CONFORME DISPONÍVEL", SEM REPRESENTAÇÕES OU GARANTIAS DE QUALQUER TIPO. NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, A NUTRIEN, SUA CONTROLADORA, AFILIADAS, SUBSIDIÁRIAS E SEUS RESPECTIVOS DIRETORES, EXECUTIVOS, FUNCIONÁRIOS, AGENTES, LICENCIADORES E PRESTADORES DE SERVIÇOS (COLETIVAMENTE, AS "PARTES DA NUTRIEN"), RENUNCIAM EXPRESSAMENTE A TODAS AS REPRESENTAÇÕES E GARANTIAS DE QUALQUER TIPO, SEJAM EXPRESSAS, IMPLÍCITAS OU ESTATUTÁRIAS, COM RELAÇÃO A ESTE SITE; OS CONTEÚDOS, PRODUTOS E SERVIÇOS DISPONIBILIZADOS ATRAVÉS DESTE SITE; SEU USO DESTE SITE; OU QUAISQUER SITES AOS QUAIS ESTE SITE ESTEJA VINCULADO. SEM LIMITAR A GENERALIDADE DO ACIMA EXPOSTO, AS PARTES DA NUTRIEN RENUNCIAM A TODAS AS DECLARAÇÕES E GARANTIAS (A) DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA, TÍTULO E NÃO VIOLAÇÃO; B) DECORRENTES DO CURSO DE NEGOCIAÇÃO OU DE EXECUÇÃO.</p>' +
        //  'Pagamentos</br></br>' +
        //  '<div> <b class="font-weight-bold">ALTERAÇÃO: </b>' +
        //  '<span class="color-light-green">Inclusão da opção “Agrichem” no campo Empresa.</span><br>' +
        //  '<b class="font-weight-bold">MENU: </b>' +
        //  '<span>Serviços Financeiros / Finanças / Cartão de Crédito Corporativo - Serviços</span><br><br>' +
        //  '<div> <b class="font-weight-bold">ALTERAÇÃO: </b>' +
        //  '<span class="color-light-green">Para o Tipo de Nota de Crédito e Empresa Nutrien ou Agrichem, exibe o campo Conta Contábil.</span><br>' +
        //  '<b class="font-weight-bold">MENU: </b>' +
        //  '<span>Serviços Financeiros > Finanças > Lançamento/Pagamento - Notas/Impostos e outras cobranças</span><br><br>' +
         //'</br>'+


        '</div>',
      iconColor: '#88D600',
      confirmButtonColor: '#88D600',
      customClass: {
        container: "comunicadoMelhoria",
      }

    })

  }

}
