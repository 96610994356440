<div class="card">
    <div class="card-body">
        <img src="./../../assets/img/logo.png" alt="logo-nutrien" class='logo img-fluid'>
        <form class="d-flex flex-column align-items-center form mt-4">
            <div class="form-group pt-4 col-lg-08">
                <p class="h4" [style]='{"color":"white"}'>Seja Bem vindo!</p>
                <!-- <input class="form-control col-lg-12 col-sm-12" placeholder="Usuário" formControlName="usuario" type="text"> -->
                <!-- <small class="texto-ajuda">8 primeiros dígitos do CNPJ</small> -->
                <!-- <app-mensagem *ngIf="loginForm.get('usuario')?.errors?.email && loginForm.get('usuario')?.touched" mensagem="Favor inserir e-mail no formato válido"></app-mensagem> -->
            </div>
            <div class="form-group pt-4 col-lg-08">
                <!-- <input class="form-control col-lg-12 col-sm-12" placeholder="Senha" formControlName="senha" [type]="hide ? 'password' : 'text'" formControlName="senha">> -->

                <!-- <small class="texto-ajuda">8 primeiros dígitos do CNPJ</small> -->

                <!-- <app-mensagem *ngIf="loginForm.get('usuario')?.errors?.email && loginForm.get('usuario')?.touched" mensagem="Favor inserir e-mail no formato válido"></app-mensagem> -->

            </div>

            <!--  <div class="form-group pt-4 mb-4">
                <div class="input-group">
                    <input class="form-control senha-input" placeholder="Senha" [type]="hide ? 'password' : 'text'" formControlName="senha">
                     <input class="form-control senha-input" placeholder="Senha" [type]="hide ? 'password' : 'text'" formControlName="senha"> -->

            <!-- <div class="input-group-append"> -->
            <!-- <span (click)="hide = !hide"><i class= 'input-group-text material-icons' [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility_off' : 'visibility'}}</i></span> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <app-re-captcha class="re-captcha" *ngIf="!loginForm.invalid"></app-re-captcha> -->
            <button (click)="login()" type="submit" class="btn btn-primary btn-block botao-nutrien ">Entrar</button>


        </form>

        <!-- <button class="d-none" mat-raised-button>teste de </button> -->
        <!-- <ngx-spinner type="ball-clip-rotate-multiple"></ngx-spinner> -->
        <!-- <p class="mt-4"><a class="forget-pass" [routerLink]="['forget-pass']">Esqueceu sua senha?</a></p> -->
        <small>Versao 30/06 16.25</small>

    </div>

</div>
