<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_lojas.png" descricao="teste" titulo="Lojas"
  subtitulo="Centros de Experiência"></app-nbs-banner>



<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Faturamento - exceto fertilizante"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="sistema_do_pedido" class="col-sm-4 col-form-label">*Sistema do Pedido</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_do_pedido' value="Protheus / TOTVs Agrosema" (change)="onSistemaDoPedidoChange($event)"
            class='form-check-input '>&nbsp;Protheus / TOTVs Agrosema
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_do_pedido' value='SAP' (change)="onSistemaDoPedidoChange($event)" class='form-check-input '>&nbsp;SAP
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_do_pedido' value='Siagri'(change)="onSistemaDoPedidoChange($event)" class='form-check-input '>&nbsp;Siagri
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('sistema_do_pedido')?.errors?.required && formulario.get('sistema_do_pedido')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().sistema_do_pedido!=''">
      <label for="nome_do_cliente" class="col-sm-4 col-form-label">*Nome do Cliente: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_cliente">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_cliente')?.errors?.required && formulario.get('nome_do_cliente')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().sistema_do_pedido=='Siagri' || this.formulario.getRawValue().sistema_do_pedido=='Protheus / TOTVs Agrosema'">
      <label for="inscri_o_estatudual_ou_nome_propriedade" class="col-sm-4 col-form-label">*Inscrição Estadual ou Nome
        Propriedade: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="inscri_o_estatudual_ou_nome_propriedade">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.errors?.required && formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().sistema_do_pedido!=''">
      <label for="ordem_de_venda" class="col-sm-4 col-form-label">*Ordem de venda/contrato parceria</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="ordem_de_venda">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ordem_de_venda')?.errors?.required && formulario.get('ordem_de_venda')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().sistema_do_pedido=='SAP'">
      <label for="centro" class="col-sm-4 col-form-label">*Centro</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='centro' value="7010 - COL" class='form-check-input '>&nbsp;7010 - COL
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='centro' value='7083 - Angatuba' class='form-check-input '>&nbsp;7083 -
          Angatuba
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='centro' value='7089 - Itapetininga' class='form-check-input '>&nbsp;7089
          - Itapetininga
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('centro')?.errors?.required && formulario.get('centro')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().sistema_do_pedido=='Siagri' || this.formulario.getRawValue().sistema_do_pedido=='Protheus / TOTVs Agrosema'">
      <label for="cidade_estado_pedido_origem" class="col-sm-4 col-form-label">*Cidade/Estado pedido origem</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cidade_estado_pedido_origem">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade_estado_pedido_origem')?.errors?.required && formulario.get('cidade_estado_pedido_origem')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().sistema_do_pedido=='Siagri' || this.formulario.getRawValue().sistema_do_pedido=='Protheus / TOTVs Agrosema'">
      <label for="cidade_estado_faturamento" class="col-sm-4 col-form-label">*Cidade/Estado faturamento</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cidade_estado_faturamento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade_estado_faturamento')?.errors?.required && formulario.get('cidade_estado_faturamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().sistema_do_pedido=='Siagri' || this.formulario.getRawValue().sistema_do_pedido=='Protheus / TOTVs Agrosema'">
      <label for="cultura" class="col-sm-4 col-form-label">*Cultura</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cultura">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cultura')?.errors?.required && formulario.get('cultura')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().sistema_do_pedido=='Siagri' || this.formulario.getRawValue().sistema_do_pedido=='Protheus / TOTVs Agrosema'">
      <label for="solicita_o_retorno_webagil_realizada" class="col-sm-4 col-form-label">*Solicitação WebAGil
        realizada?</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='solicita_o_retorno_webagil_realizada' value="Sim" (change)="onSolicitacaoWebAgilRealizadaChange($event)"
            class='form-check-input '>&nbsp;Sim
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='solicita_o_retorno_webagil_realizada' value='Não' (change)="onSolicitacaoWebAgilRealizadaChange($event)"
            class='form-check-input '>&nbsp;Não
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='solicita_o_retorno_webagil_realizada' value='Não se aplica'(change)="onSolicitacaoWebAgilRealizadaChange($event)"
            class='form-check-input '>&nbsp;Não se aplica
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('solicita_o_retorno_webagil_realizada')?.errors?.required && formulario.get('solicita_o_retorno_webagil_realizada')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().solicita_o_retorno_webagil_realizada=='Sim'">
      <label for="n_mero_da_solicita_o" class="col-sm-4 col-form-label">*Número da Solicitação</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="n_mero_da_solicita_o">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_solicita_o')?.errors?.required && formulario.get('n_mero_da_solicita_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="tipo_de_faturamento" class="col-sm-4 col-form-label">*Tipo de Faturamento</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='tipo_de_faturamento' value="Parcial" (change)="onTipodeFaturamentoChange($event)"
            class='form-check-input '>&nbsp;Parcial
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='tipo_de_faturamento' value='Total' (change)="onTipodeFaturamentoChange($event)" class='form-check-input '>&nbsp;Total
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_faturamento')?.errors?.required && formulario.get('tipo_de_faturamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_faturamento=='Parcial'">
      <label for="quantidade_de_produtos_a_serem_faturados_no_pedido" class="col-sm-4 col-form-label">*Quantidade de
        produtos a serem faturados no pedido</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="quantidade_de_produtos_a_serem_faturados_no_pedido" (change)="onQuantidadeDeProdutosChange($event)">

          <option *ngFor="let quantidade of quantidadeDeProdutos" [value]="quantidade.quantidade">
            {{quantidade.quantidade}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.errors?.required && formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- Quantidade de Produtos = 1 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=1">

      <div class="form-group row">
        <label for="c_digo_produto_1" class="col-sm-4 col-form-label">*Código Produto 1</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_1')?.errors?.required && formulario.get('c_digo_produto_1')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_1" class="col-sm-4 col-form-label">*Descrição Produto 1</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_1')?.errors?.required && formulario.get('descri_o_produto_1')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_1" class="col-sm-4 col-form-label">*Quantidade Produto 1</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_1')?.errors?.required && formulario.get('quantidade_1')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_1" class="col-sm-4 col-form-label">*Lote 1</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_1')?.errors?.required && formulario.get('lote_1')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">
    </div>

    <!-- Quantidade de Produtos = 2 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=2">

      <div class="form-group row">
        <label for="c_digo_produto_2" class="col-sm-4 col-form-label">*Código Produto 2</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_2')?.errors?.required && formulario.get('c_digo_produto_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_2" class="col-sm-4 col-form-label">*Descrição Produto 2</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_2')?.errors?.required && formulario.get('descri_o_produto_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_2" class="col-sm-4 col-form-label">*Quantidade Produto 2</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_2')?.errors?.required && formulario.get('quantidade_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_2" class="col-sm-4 col-form-label">*Lote 2</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_2')?.errors?.required && formulario.get('lote_2')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <hr class="line">

    </div>

    <!-- Quantidade de Produtos = 3 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=3">

      <div class="form-group row">
        <label for="c_digo_produto_3" class="col-sm-4 col-form-label">*Código Produto 3</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_3')?.errors?.required && formulario.get('c_digo_produto_3')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_3" class="col-sm-4 col-form-label">*Descrição Produto 3</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_3')?.errors?.required && formulario.get('descri_o_produto_3')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_3" class="col-sm-4 col-form-label">*Quantidade Produto 3</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_3')?.errors?.required && formulario.get('quantidade_3')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_3" class="col-sm-4 col-form-label">*Lote 3</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_3')?.errors?.required && formulario.get('lote_3')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <hr class="line">

    </div>

    <!-- Quantidade de Produtos = 4 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=4">

      <div class="form-group row">
        <label for="c_digo_produto_4" class="col-sm-4 col-form-label">*Código Produto 4</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_4')?.errors?.required && formulario.get('c_digo_produto_4')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_4" class="col-sm-4 col-form-label">*Descrição Produto 4</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_4')?.errors?.required && formulario.get('descri_o_produto_4')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_4" class="col-sm-4 col-form-label">*Quantidade Produto 4</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_4')?.errors?.required && formulario.get('quantidade_4')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_4" class="col-sm-4 col-form-label">*Lote 4</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_4')?.errors?.required && formulario.get('lote_4')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 5 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=5">

      <div class="form-group row">
        <label for="c_digo_produto_5" class="col-sm-4 col-form-label">*Código Produto 5</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_5')?.errors?.required && formulario.get('c_digo_produto_5')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_5" class="col-sm-4 col-form-label">*Descrição Produto 5</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_5')?.errors?.required && formulario.get('descri_o_produto_5')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_5" class="col-sm-4 col-form-label">*Quantidade Produto 5</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_5')?.errors?.required && formulario.get('quantidade_5')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_5" class="col-sm-4 col-form-label">*Lote 5</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_5')?.errors?.required && formulario.get('lote_5')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 6 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=6">

      <div class="form-group row">
        <label for="c_digo_produto_6" class="col-sm-4 col-form-label">*Código Produto 6</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_6')?.errors?.required && formulario.get('c_digo_produto_6')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_6" class="col-sm-4 col-form-label">*Descrição Produto 6</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_6')?.errors?.required && formulario.get('descri_o_produto_6')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_6" class="col-sm-4 col-form-label">*Quantidade Produto 6</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_6')?.errors?.required && formulario.get('quantidade_6')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_6" class="col-sm-4 col-form-label">*Lote 6</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_6')?.errors?.required && formulario.get('lote_6')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 7 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=7">

      <div class="form-group row">
        <label for="c_digo_produto_7" class="col-sm-4 col-form-label">*Código Produto 7</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_7')?.errors?.required && formulario.get('c_digo_produto_7')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_7" class="col-sm-4 col-form-label">*Descrição Produto 7</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_7')?.errors?.required && formulario.get('descri_o_produto_7')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_7" class="col-sm-4 col-form-label">*Quantidade Produto 7</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_7')?.errors?.required && formulario.get('quantidade_7')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_7" class="col-sm-4 col-form-label">*Lote 7</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_7')?.errors?.required && formulario.get('lote_7')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 8 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=8">

      <div class="form-group row">
        <label for="c_digo_produto_8" class="col-sm-4 col-form-label">*Código Produto 8</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_8')?.errors?.required && formulario.get('c_digo_produto_8')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_8" class="col-sm-4 col-form-label">*Descrição Produto 8</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_8')?.errors?.required && formulario.get('descri_o_produto_8')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_8" class="col-sm-4 col-form-label">*Quantidade Produto 8</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_8')?.errors?.required && formulario.get('quantidade_8')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_8" class="col-sm-4 col-form-label">*Lote 8</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_8')?.errors?.required && formulario.get('lote_8')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 9 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=9">

      <div class="form-group row">
        <label for="c_digo_produto_9" class="col-sm-4 col-form-label">*Código Produto 9</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_9')?.errors?.required && formulario.get('c_digo_produto_9')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_9" class="col-sm-4 col-form-label">*Descrição Produto 9</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_9')?.errors?.required && formulario.get('descri_o_produto_9')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_9" class="col-sm-4 col-form-label">*Quantidade Produto 9</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_9')?.errors?.required && formulario.get('quantidade_9')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_9" class="col-sm-4 col-form-label">*Lote 9</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_9')?.errors?.required && formulario.get('lote_9')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 10 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=10">

      <div class="form-group row">
        <label for="c_digo_produto_10" class="col-sm-4 col-form-label">*Código Produto 10</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_10')?.errors?.required && formulario.get('c_digo_produto_10')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_10" class="col-sm-4 col-form-label">*Descrição Produto 10</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_10')?.errors?.required && formulario.get('descri_o_produto_10')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_10" class="col-sm-4 col-form-label">*Quantidade Produto 10</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_10')?.errors?.required && formulario.get('quantidade_10')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_10" class="col-sm-4 col-form-label">*Lote 10</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_10')?.errors?.required && formulario.get('lote_10')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 11 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=11">

      <div class="form-group row">
        <label for="c_digo_produto_11" class="col-sm-4 col-form-label">*Código Produto 11</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_11')?.errors?.required && formulario.get('c_digo_produto_11')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_11" class="col-sm-4 col-form-label">*Descrição Produto 11</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_11')?.errors?.required && formulario.get('descri_o_produto_11')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_11" class="col-sm-4 col-form-label">*Quantidade Produto 11</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_11')?.errors?.required && formulario.get('quantidade_11')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_11" class="col-sm-4 col-form-label">*Lote 11</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_11')?.errors?.required && formulario.get('lote_11')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 12 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=12">

      <div class="form-group row">
        <label for="c_digo_produto_12" class="col-sm-4 col-form-label">*Código Produto 12</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_12')?.errors?.required && formulario.get('c_digo_produto_12')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_12" class="col-sm-4 col-form-label">*Descrição Produto 12</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_12')?.errors?.required && formulario.get('descri_o_produto_12')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_12" class="col-sm-4 col-form-label">*Quantidade Produto 12</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_12')?.errors?.required && formulario.get('quantidade_12')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_12" class="col-sm-4 col-form-label">*Lote 12</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_12')?.errors?.required && formulario.get('lote_12')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 13 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=13">

      <div class="form-group row">
        <label for="c_digo_produto_13" class="col-sm-4 col-form-label">*Código Produto 13</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_13')?.errors?.required && formulario.get('c_digo_produto_13')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_13" class="col-sm-4 col-form-label">*Descrição Produto 13</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_13')?.errors?.required && formulario.get('descri_o_produto_13')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_13" class="col-sm-4 col-form-label">*Quantidade Produto 13</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_13')?.errors?.required && formulario.get('quantidade_13')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_13" class="col-sm-4 col-form-label">*Lote 13</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_13')?.errors?.required && formulario.get('lote_13')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 14 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=14">

      <div class="form-group row">
        <label for="c_digo_produto_14" class="col-sm-4 col-form-label">*Código Produto 14</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_14')?.errors?.required && formulario.get('c_digo_produto_14')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_14" class="col-sm-4 col-form-label">*Descrição Produto 14</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_14')?.errors?.required && formulario.get('descri_o_produto_14')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_14" class="col-sm-4 col-form-label">*Quantidade Produto 14</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_14')?.errors?.required && formulario.get('quantidade_14')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_14" class="col-sm-4 col-form-label">*Lote 14</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_14')?.errors?.required && formulario.get('lote_14')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 15 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=15">

      <div class="form-group row">
        <label for="c_digo_produto_15" class="col-sm-4 col-form-label">*Código Produto 15</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_15')?.errors?.required && formulario.get('c_digo_produto_15')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_15" class="col-sm-4 col-form-label">*Descrição Produto 15</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_15')?.errors?.required && formulario.get('descri_o_produto_15')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_15" class="col-sm-4 col-form-label">*Quantidade Produto 15</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_15')?.errors?.required && formulario.get('quantidade_15')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_15" class="col-sm-4 col-form-label">*Lote 15</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_15')?.errors?.required && formulario.get('lote_15')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 16 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=16">

      <div class="form-group row">
        <label for="c_digo_produto_16" class="col-sm-4 col-form-label">*Código Produto 16</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_16')?.errors?.required && formulario.get('c_digo_produto_16')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_16" class="col-sm-4 col-form-label">*Descrição Produto 16</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_16')?.errors?.required && formulario.get('descri_o_produto_16')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_16" class="col-sm-4 col-form-label">*Quantidade Produto 16</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_16')?.errors?.required && formulario.get('quantidade_16')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_16" class="col-sm-4 col-form-label">*Lote 16</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_16')?.errors?.required && formulario.get('lote_16')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 17 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=17">

      <div class="form-group row">
        <label for="c_digo_produto_17" class="col-sm-4 col-form-label">*Código Produto 17</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_17')?.errors?.required && formulario.get('c_digo_produto_17')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_17" class="col-sm-4 col-form-label">*Descrição Produto 17</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_17')?.errors?.required && formulario.get('descri_o_produto_17')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_17" class="col-sm-4 col-form-label">*Quantidade Produto 17</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_17')?.errors?.required && formulario.get('quantidade_17')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_17" class="col-sm-4 col-form-label">*Lote 17</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_17')?.errors?.required && formulario.get('lote_17')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 18 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=18">

      <div class="form-group row">
        <label for="c_digo_produto_18" class="col-sm-4 col-form-label">*Código Produto 18</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_18')?.errors?.required && formulario.get('c_digo_produto_18')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_18" class="col-sm-4 col-form-label">*Descrição Produto 18</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_18')?.errors?.required && formulario.get('descri_o_produto_18')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_18" class="col-sm-4 col-form-label">*Quantidade Produto 18</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_18')?.errors?.required && formulario.get('quantidade_18')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_18" class="col-sm-4 col-form-label">*Lote 18</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_18')?.errors?.required && formulario.get('lote_18')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 19 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=19">

      <div class="form-group row">
        <label for="c_digo_produto_19" class="col-sm-4 col-form-label">*Código Produto 19</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_19')?.errors?.required && formulario.get('c_digo_produto_19')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_19" class="col-sm-4 col-form-label">*Descrição Produto 19</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_19')?.errors?.required && formulario.get('descri_o_produto_19')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_19" class="col-sm-4 col-form-label">*Quantidade Produto 19</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_19')?.errors?.required && formulario.get('quantidade_19')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_19" class="col-sm-4 col-form-label">*Lote 19</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_19')?.errors?.required && formulario.get('lote_19')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>


    <!-- Quantidade de Produtos = 20 -->

    <div
      *ngIf=" this.formulario.getRawValue().tipo_de_faturamento=='Parcial' && formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido>=20">

      <div class="form-group row">
        <label for="c_digo_produto_20" class="col-sm-4 col-form-label">*Código Produto 20</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="c_digo_produto_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('c_digo_produto_20')?.errors?.required && formulario.get('c_digo_produto_20')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descri_o_produto_20" class="col-sm-4 col-form-label">*Descrição Produto 20</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_produto_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_produto_20')?.errors?.required && formulario.get('descri_o_produto_20')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="quantidade_20" class="col-sm-4 col-form-label">*Quantidade Produto 20</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_20')?.errors?.required && formulario.get('quantidade_20')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="lote_20" class="col-sm-4 col-form-label">*Lote 20</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="lote_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('lote_20')?.errors?.required && formulario.get('lote_20')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <hr class="line">

    </div>



    <div class="form-group row">
      <label for="lat_long_entrada" class="col-sm-4 col-form-label">Lat Long Entrega</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="lat_long_entrada">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('lat_long_entrada')?.errors?.required && formulario.get('lat_long_entrada')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_solicitada_para_faturamento" class="col-sm-4 col-form-label">*Data solicitada para
        faturamento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_solicitada_para_faturamento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_solicitada_para_faturamento')?.errors?.required && formulario.get('data_solicitada_para_faturamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="faturamento_cont_m_defensivo_qu_mico" class="col-sm-4 col-form-label">*Faturamento necessita de
        emissão de Receituário Agronômico?</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='faturamento_cont_m_defensivo_qu_mico' value="Sim" (change)="onFaturamentoNecessitaDeEmissaoChange($event)"
            class='form-check-input '>&nbsp;Sim
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='faturamento_cont_m_defensivo_qu_mico' value='Não' (change)="onFaturamentoNecessitaDeEmissaoChange($event)"
            class='form-check-input '>&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('faturamento_cont_m_defensivo_qu_mico')?.errors?.required && formulario.get('faturamento_cont_m_defensivo_qu_mico')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().faturamento_cont_m_defensivo_qu_mico=='Sim'">
      <label for="nome_e_e_mail_respons_vel_t_cnico" class="col-sm-4 col-form-label">Nome e e-mail responsável
        técnico</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_e_e_mail_respons_vel_t_cnico">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.errors?.required && formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="emitir_boleto_ao_cliente_no_momento_do_faturamento" class="col-sm-4 col-form-label">*Emitir boleto ao
        cliente no momento do faturamento?</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='emitir_boleto_ao_cliente_no_momento_do_faturamento' value="Sim" (change)="onEmitirBoletoChange($event)"
            class='form-check-input '>&nbsp;Sim
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='emitir_boleto_ao_cliente_no_momento_do_faturamento' value='Não' (change)="onEmitirBoletoChange($event)"
            class='form-check-input '>&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('emitir_boleto_ao_cliente_no_momento_do_faturamento')?.errors?.required && formulario.get('emitir_boleto_ao_cliente_no_momento_do_faturamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().emitir_boleto_ao_cliente_no_momento_do_faturamento=='Sim'">
      <label for="data_de_vencimento" class="col-sm-4 col-form-label">*Data de vencimento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_vencimento')?.errors?.required && formulario.get('data_de_vencimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="incluir_documentos_pasta_de_roteiriza_o" class="col-sm-4 col-form-label">*Incluir documentos pasta de
        roteirização?</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='incluir_documentos_pasta_de_roteiriza_o' value="Sim" (change)="onIncluirDocumentosChange($event)"
            class='form-check-input '>&nbsp;Sim
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='incluir_documentos_pasta_de_roteiriza_o' value='Não' (change)="onIncluirDocumentosChange($event)"
            class='form-check-input '>&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('incluir_documentos_pasta_de_roteiriza_o')?.errors?.required && formulario.get('incluir_documentos_pasta_de_roteiriza_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().incluir_documentos_pasta_de_roteiriza_o=='Não'">
      <label for="e_mail_para_envio_de_documenta_o" class="col-sm-4 col-form-label">*E-mail para envio de
        documentação</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="e_mail_para_envio_de_documenta_o">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_para_envio_de_documenta_o')?.errors?.required && formulario.get('e_mail_para_envio_de_documenta_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="ctv" class="col-sm-4 col-form-label">*CTV</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="ctv">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ctv')?.errors?.required && formulario.get('ctv')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="estado_loja_xp" class="col-sm-4 col-form-label">*Estado loja/XP</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="estado_loja_xp">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('estado_loja_xp')?.errors?.required && formulario.get('estado_loja_xp')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="cidade_loja_xp" class="col-sm-4 col-form-label">*Cidade loja/XP</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cidade_loja_xp">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade_loja_xp')?.errors?.required && formulario.get('cidade_loja_xp')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="solicitante" class="col-sm-4 col-form-label">*Solicitante</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="solicitante">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('solicitante')?.errors?.required && formulario.get('solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail_solicitante" class="col-sm-4 col-form-label">*E-mail solicitante</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="e_mail_solicitante">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_solicitante')?.errors?.required && formulario.get('e_mail_solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail_secund_rio" class="col-sm-4 col-form-label">E-mail secundário</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="e_mail_secund_rio">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_secund_rio')?.errors?.required && formulario.get('e_mail_secund_rio')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="observa_es" class="col-sm-4 col-form-label">Observações </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observa_es"></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label for="demanda_urgente" class="col-sm-4 col-form-label">*Demanda urgente?</label>
      <div class="col-sm-8">
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='demanda_urgente' value="Sim" class='form-check-input '>&nbsp;Sim
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='demanda_urgente' value='Não' class='form-check-input '>&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('demanda_urgente')?.errors?.required && formulario.get('demanda_urgente')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().demanda_urgente=='Sim'">
      <label for="descreva_a_urg_ncia" class="col-sm-4 col-form-label">Descreva a urgência</label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descreva_a_urg_ncia"></textarea>
      </div>
    </div>






    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>