<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Criação Conta Contábil">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Criação Conta Contábil"
    titulo="Criação Conta Contábil"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <app-form-mat [fields]="fields" urlPipefy="https://app.pipefy.com/public/form/CX2N0cA2" (valueChangeForm)="valueChangeForm($event)" pipeId="303098502"></app-form-mat>
  
  <div class="mensagem-alert" *ngIf="values?.nome_da_empresa?.value === 'Nutrien' " >
    <p class="text-center">Prezado solicitante, para a Nutrien (BU 7806), a solicitação de criação de contas contábeis e/ou centros
      de custos devem ser abertos no portal do service desk global:
      <a href="https://serviceportal.nutrien.com" target="_blank"> Finance Data Management - Service Portal (service-now.com)</a></p>
  </div>

<div class="footer">
  <app-footer link="/accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>