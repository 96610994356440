<app-header></app-header>

<app-nbs-banner url="../../../assets/img/background.png" descricao="teste" titulo="Cadastro"></app-nbs-banner>


<div class="container">
  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>



  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-menu descricao="Clientes" titulo="Clientes"
          url="../../../assets/img/fa-menus/fiscal/impostos_apurados.png"
          target="true" linkTarget="https://hcm17.sapsf.com/login?&company=nutrien" >
        </app-box-link-menu>
      </div>
      </div>
      </div>

  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-menu descricao="Fornecedores"
          url="../../../assets/img/fa-menus/fiscal/suporte_fiscal.png"
          titulo="Fornecedores" routerLink="/fornecedores">
        </app-box-link-menu>
      </div>

      </div>
      </div>



          <div class="itens">
            <div class="row menu-itens">
              <div class="col-lg-12">
                <app-box-link-menu descricao="Materiais"
                  url="../../../assets/img/fa-menus/fiscal/emissao_de_nf_de_importacao.png"
                  titulo="Materiais" target="true" linkTarget="https://hcm17.sapsf.com/login?&company=nutrien" >
                </app-box-link-menu>
              </div>
            </div>
          </div>

  </div>

  <div class="footer">
    <app-footer link="menu"></app-footer>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
  </ngx-spinner>
