import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from '../../../../services/trata-inputs.service';
import { contabilidade } from './fluxo_de_contabilidade';
import { fiscal } from './fluxo_fiscal';
import { lojas } from './fluxo_lojas';
import { pagamento } from './fluxo_pagamento';
import { comprasMateriaisIndiretos } from './fluxo_compras_materiais_indiretos'
import { areas } from './areas';
import { pipe_fields_id } from './pipe_fields_ids';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.css']
})
export class AjudaComponent implements OnInit {

  formulario!: UntypedFormGroup

  areas = areas
  fluxoContabilidade = contabilidade
  fluxoFiscal = fiscal
  fluxoLojas = lojas
  fluxoPagamento = pagamento
  fluxoCompras = comprasMateriaisIndiretos

  error_counter: number = 0;

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      seu_nome: ['', [Validators.required]],
      tel: ['', []],
      email: ['', [Validators.required, Validators.email]],
      melhor_forma_de_contato: ['', [Validators.required]],
      area: ['', [Validators.required]],
      fluxo_contabilidade: ['', []],
      fluxo_fiscal: ['', []],
      fluxo_lojas: ['', []],
      fluxo_pagamentos: ['', []],
      fluxo_compras: ['', []],
      duvida: ['', [Validators.required]
      ]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email')?.setValue(this.emailApi)
    // this.formulario.get('seu_nome')?.setValue(this.userNameApi)


    // if (this.formulario.getRawValue().email != '') {
    //   this.formulario.controls['email'].disable()
    // }
    // if (this.formulario.getRawValue().email == undefined) {
    //   this.formulario.controls['email'].enable()
    // }

    // if (this.formulario.getRawValue().seu_nome != '') {
    //   this.formulario.controls['seu_nome'].disable()
    // }
    // if (this.formulario.getRawValue().seu_nome == undefined) {
    //   this.formulario.controls['seu_nome'].enable()
    // }
  }

  enviarFormulario() {
    this.spinner.show()

    let seu_nome = this.trataInput.removeCaracters(this.formulario.getRawValue().seu_nome)
    let tel = this.trataInput.removeCaracters(this.formulario.getRawValue().tel)
    let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
    let melhor_forma_de_contato = this.trataInput.removeCaracters(this.formulario.getRawValue().melhor_forma_de_contato)
    let area = this.trataInput.removeCaracters(this.formulario.getRawValue().area)
    let fluxo_contabilidade = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo_contabilidade)
    let fluxo_fiscal = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo_fiscal)
    let fluxo_lojas = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo_lojas)
    let fluxo_pagamentos = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo_pagamentos)
    let fluxo_compras = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo_compras)
    let duvida = this.trataInput.removeCaracters(this.formulario.getRawValue().duvida)

    let pipe_fields = pipe_fields_id
    let fields_values = [seu_nome, tel, email, melhor_forma_de_contato, area, fluxo_contabilidade, fluxo_fiscal, fluxo_lojas, fluxo_pagamentos, fluxo_compras, duvida]
    let pipe_id = '302487537'

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/RZG0i3pz" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Tech', "FAQ - Dúvidas", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/RZG0i3pz" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
      }
    })
  }


  onAreaChange(event: any) {
    let area = event.target.value

    switch (area) {
      case 'Contabilidade':
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');

        break;

      case 'Fiscal':
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');
        break;

      case 'Lojas Centros de Experiência':
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');
        break;

      case 'Pagamentos':
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');
        break;

      case 'Compras de Serviços e Materiais Indiretos':
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        break;

      case 'Outros':
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');
        break;

      default:
        this.formulario.controls['fluxo_contabilidade'].setValue('');
        this.formulario.controls['fluxo_fiscal'].setValue('');
        this.formulario.controls['fluxo_lojas'].setValue('');
        this.formulario.controls['fluxo_pagamentos'].setValue('');
        this.formulario.controls['fluxo_compras'].setValue('');
    }
  }



  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/RZG0i3pz', '_blank')
  }
}



