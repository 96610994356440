<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Consulta de Chamados">
</app-nbs-banner>


<div class="container">


  <!-- <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Consulta de Chamados"></app-formularios-titulo> -->

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha algum dos campos abaixo para para realizar a sua consulta.</h6>
      <!-- <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p> -->
    </div>

  <form [formGroup]="formulario" (submit)="buscarRegistros()">

    <div class="form-row" >
      <div class="form-group col-sm-12 col-md-3 col-lg-2">
        <label class ='col-form-label' for="numero_do_chamado">*Número do Chamado:</label>
        <input type="text" class="form-control" formControlName="numero_do_chamado">
      </div>

      <div class="form-group col-sm-12 col-md-3 col-lg-2">
        <label class ='col-form-label' for="data_inicio">Data Início</label>
        <input type="date" class="form-control" formControlName="data_inicio">
      </div>

      <div class="form-group col-sm-12 col-md-3 col-lg-2">
        <label class ='col-form-label' for="data_fim">Data fim</label>
        <input type="date" class="form-control" formControlName="data_fim">
      </div>

      <div class="form-group col-sm-12 col-md-3 col-lg-2">
        <label class ='col-form-label' for="fluxo">Solicitação</label>
        <select class=" custom-select" formControlName="fluxo">
          <option *ngFor="let fluxo of fluxos" [value]="fluxo.nome"><b>{{fluxo.area}} - </b> {{fluxo.nome}}</option>
        </select>
      </div>

      <div class="form-group col-sm-12 col-md-3 col-lg-2">
        <label class ='col-form-label' for="status">Status</label>
        <select class=" custom-select" formControlName="status">
          <option *ngFor="let status of status" [value]="status.nome">{{status.nome}}</option>
        </select>
      </div>


      <div class="form-group col-sm-12 col-md-3 col-lg-3"
      *ngIf="userEmail == 'diego.campos@nutrien.com'">
        <label class ='col-form-label' for="e_mail_solicitante">Email do Solicitante</label>
        <input type="email" class="form-control" formControlName="e_mail_solicitante">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('e_mail_solicitante')?.errors?.email && formulario.get('e_mail_solicitante')?.touched"
        mensagem="Formato de e-mail inválido"></app-mensagem>

      </div>


    </div>

    <!-- <div class="form-group row">
      <label for="numero_do_chamado" class="col-sm-4 col-form-label">*Número do Chamado: </label>
      <div class="col-sm-3">

      </div>

      <label for="numero_do_chamado" class="col-sm-4 col-form-label">*Número do Chamado: </label>
      <div class="col-sm-3">
        <input type="number" class="form-control" formControlName="numero_do_chamado">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_chamado')?.errors?.required && formulario.get('numero_do_chamado')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->





    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Buscar...</button>
    </div>

    <!-- <button type="button" (click)="exportExcel()">Extrari Relatório em Excel</button> -->
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->



  </form>

  <div>
    <h5 class="text-center" *ngIf="quantidade_de_chamados == 1"> Foi encontrado {{quantidade_de_chamados}} registro para a sua busca.</h5>
    <h5 class="text-center" *ngIf="quantidade_de_chamados > 1"> Foram encontrados {{quantidade_de_chamados}} registros para a sua busca.</h5>
    <h5 class="text-center" *ngIf="quantidade_de_chamados == 0"> Não foram encontrados registros para a sua busca.</h5>

  <div class="card text-center" *ngIf="quantidade_de_chamados>0">
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Número do Chamado</th>
            <th scope="col">Nota Fiscal</th>
            <th scope="col">Número do Pedido</th>
            <th scope="col">Fluxo</th>
            <th scope="col">Data de Abertura</th>
            <th scope="col">Data de Vencimento</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody *ngFor="let chamado of chamados | paginate: {itemsPerPage:10, currentPage:p};">
          <tr>
            <td scope="row">
              <a *ngIf="chamado.status == 'Pendência com Solicitante' && chamado.link_de_pend_ncia" [href]="chamado.link_de_pend_ncia" target="_blank" class="link-chamado-pipefy">
                <i class="fa fa-external-link mr-1"></i>
                {{chamado.numero_chamado}}
              </a>
              <span *ngIf="chamado.status !== 'Pendência com Solicitante' || !chamado.link_de_pend_ncia">
                {{chamado.numero_chamado}}
              </span>
            </td>
            <td>{{chamado.nota_fiscal}}</td>
            <td>{{chamado.nr_pedido}}</td>
            <td>{{chamado.solicitacao}}</td>
            <td>{{chamado.data_abertura}}</td>
            <td>{{chamado.data_de_vencimento}}</td>
            <td>{{chamado.status}}</td>
          </tr>
        </tbody>

      </table>
      <pagination-controls (pageChange)="p = $event"
      previousLabel="Voltar"
      nextLabel="Próximo"></pagination-controls>

      <!-- <h5 class="card-title">Chamado {{respostaApi[0].numero_do_chamado}}</h5>
      <hr class="line">
      <p class="card-text">Status Atual: {{respostaApi[0].status}}</p>
      <p class="card-text">Data de Abertura: {{respostaApi[0].data_de_criacao}}</p>
      <p class="card-text">Última Atualização: {{respostaApi[0].ultima_atualizacao}}</p> -->

      <button class="btn botao" (click)="novoChamado()">Realizar Nova Consulta</button>
    </div>
  </div>
</div>

</div>


<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
