import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalGuard  } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MsalGuard2 extends MsalGuard  {

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    if (!!environment.localhost) {
      return new Observable(observer => observer.next(true));
    }
    return super.canActivate(next, state);
  }
}