import { Component, OnInit } from '@angular/core';
import { Empresas } from './empresas';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from './../../../../services/trata-inputs.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { pipe_fields_id } from './pipe_fields_id';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from './../../../../autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-pagamento-iof-rh',
  templateUrl: './pagamento-iof-rh.component.html',
  styleUrls: ['./pagamento-iof-rh.component.css']
})
export class PagamentoIofRhComponent implements OnInit {

  formulario!: UntypedFormGroup
  empresas = Empresas

  error_counter: number = 0;
  erro: boolean = false
  emailApi : string = ''


  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService, private pipefy: PipefyService, private spinner: NgxSpinnerService, private msalService: MsalService,
    private userInfo: UserInfosService
    ) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome_responsavel_nutrien:['',[Validators.required]],
      email_responsavel_nutrien:['',[Validators.required, Validators.email]],
      empresa:['',[Validators.required]],
      data_do_inicio_do_decendio:['',[Validators.required]],
      data_final_do_decendio:['',[]],
      descricao_da_solicitacao:['',[Validators.required]],
      observacao:['',[]]

    })

    this.emailApi = this.userInfo.getUserEmail()

  }

  enviarFormulario(){

    this.spinner.show()

    let nome_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_responsavel_nutrien)
    let email_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().email_responsavel_nutrien)
    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    let data_do_inicio_do_decendio = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_do_inicio_do_decendio))
    let data_final_do_decendio = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_final_do_decendio))
    let descricao_da_solicitacao = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_da_solicitacao)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = pipe_fields_id
    // console.log(pipe_fields)
    let fields_values = [nome_responsavel_nutrien, email_responsavel_nutrien, empresa, data_do_inicio_do_decendio,data_final_do_decendio,descricao_da_solicitacao, observacao]
    let pipe_id = '302487550'

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetForm()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/drd9par-" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Fiscal', "Fiscal - criação de card -- Pagamento IOF - RH", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Solicitação de Pagamento IOF - Contrato de Mútuos - RH', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any)=>{
      this.spinner.hide()
      // console.log("erro:",error)
      // console.log("error.status:",error.status)
      // console.log(typeof(error.status))
      if(error.status == 401){
        // console.log("entrei em error.status")
        Swal.fire({
          title: 'Ops, sua sessão expirou!',
          text: 'Por favor, saia e logue novamente.',
          icon: 'error',
          confirmButtonText: 'Sair',
          confirmButtonColor: '#ff6961',
          allowOutsideClick:false,
          allowEscapeKey:false
        }).then(()=>{
          this.msalService.logoutRedirect()
        })
      }else{
        // console.log("entrei em outro erro")
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
          allowOutsideClick:false,
          allowEscapeKey:false
        })
      }
    })
    }

    resetForm(){
      for (let field in this.formulario.controls) {
        this.formulario.controls[field].setValue('');
      }
      this.formulario.markAsUntouched()
    }

    abrirPipePublico() {
      window.open('https://app.pipefy.com/public/form/drd9par-', '_blank')
    }



  }



