<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Recursos Humanos" subtitulo="Autoatendimento">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Reembolso - Benefícios"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nomeAuto" class="col-sm-4 col-form-label">*Nome: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nomeAuto" readonly="readonly" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nomeAuto')?.errors?.required && formulario.get('nomeAuto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
        <label for="cpf" class="col-sm-4 col-form-label">*CPF: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf" mask='000.000.000-99'>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="emailAuto" class="col-sm-4 col-form-label">*E-mail: </label>
        <div class="col-sm-8">
          <input type="email" class="form-control" formControlName="emailAuto" readonly="readonly" >
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('emailAuto')?.errors?.required && formulario.get('emailAuto')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('emailAuto')?.errors?.email && formulario.get('emailAuto')?.touched"
            mensagem="Formato de e-mail inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
  
      </div>

      <!-- <div class="form-group row">
        <label for="valor_do_ve_culo" class="col-sm-4 col-form-label">*Valor do Veículo: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_do_ve_culo" mask="separator.2" thousandSeparator=".">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_do_ve_culo')?.errors?.required && formulario.get('valor_do_ve_culo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="tipo" class="col-sm-4 col-form-label">*Tipo de Reembolso </label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="tipo"><input type="checkbox"
              class="form-check-input" formControlName="tipo"
              (change)="documentacao=!documentacao"  (change)="inserirNoArray($event)" (change)="documentacaoIsChecked($event)" value = 'Documentação  (Despachante/Licenciamento/Seguro Obrigatório) (R$)'>&nbsp;&nbsp;Documentação  (Despachante/Licenciamento/Seguro Obrigatório) (R$)</label>
          <label class="col-sm-12 col-form-label" for="tipo"><input type="checkbox"
              class="form-check-input" formControlName="tipo"
              (change)='ipva=!ipva'  (change)="inserirNoArray($event)" (change)="ipvaIsChecked($event)" value = 'IPVA (R$)'>&nbsp;&nbsp;IPVA (R$)</label>
          <label class="col-sm-12 col-form-label" for="tipo"><input type="checkbox"
              class="form-check-input" formControlName="tipo"
              (change)="seguroCarro=!seguroCarro"  (change)="inserirNoArray($event)" (change)="seguroIsChecked($event)" value = 'Seguro Carro (R$)'>&nbsp;&nbsp;Seguro Carro (R$)</label>
              <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="(!documentacao && !ipva && !seguroCarro) && formulario.get('tipo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="detalhes-reembolso" *ngIf="mostrarDocumentacao">
        <div class="form-group row">
          <label for="valor_integral_documenta_o" class="col-sm-4 col-form-label">*Valor Integral da Documentação: </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="valor_integral_documenta_o" mask="separator.2" thousandSeparator="." (blur)="calculoDocumentacao()">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('valor_integral_documenta_o')?.errors?.required && formulario.get('valor_integral_documenta_o')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Reembolso de Documentação
          </label>
          <div class="col-sm-8">
            <div>
              <label class="mb-1">Porcentagem de Reembolso:</label>
              <span class="valorCalculado">{{porcentagemReembolsoDocumentacao}}%</span>
            </div>
            <div class="mt-3">
              <label class="mb-1">Valor a reembolsar:</label>
              <span class="valorCalculado">{{valorCalculadoDocumentacao | currency}}</span>
            </div>
            <small class="texto-ajuda">*O valor será calculado após inclusão do valor</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="anexo_documenta_o" class="col-sm-4 col-form-label">*Comprovante de Pagamento Documentação
          </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_documenta_o" id="formFileMultiple" (change)="saveDocumentacao($event)"
              multiple="multiple">
              <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('anexo_documenta_o')?.errors?.required && formulario.get('anexo_documenta_o')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentacaoAnexo.length!=0">
          <div class="card-header">Anexos</div>
          <ul class="list-group list-group-flush" *ngFor="let file of documentacaoAnexo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeDocumentacaoFile(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
        <hr class="line">
      </div>


      <div class="detalhes-reembolso" *ngIf="mostrarIpva">
        <div class="form-group row">
          <label for="ipva" class="col-sm-4 col-form-label">*IPVA: </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="ipva" mask="separator.2" thousandSeparator="." (blur)="calculoIpva()">
            <small class="texto-ajuda">*Incluir o valor integral do IPVA</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('ipva')?.errors?.required && formulario.get('ipva')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Reembolso de Ipva
          </label>
          <div class="col-sm-8">
            <div>
              <label class="mb-1">Porcentagem de Reembolso:</label>
              <span class="valorCalculado">{{porcentagemReembolsoIpva}}%</span>
            </div>
            <div class="mt-3">
              <label class="mb-1">Valor a reembolsar:</label>
              <span class="valorCalculado">{{valorCalculadoIpva | currency}}</span>
            </div>
            <small class="texto-ajuda">*O valor será calculado após inclusão do valor</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="anexo_ipva" class="col-sm-4 col-form-label">*Comprovante de Pagamento IPVA
          </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_ipva" id="formFileMultiple" (change)="saveIpva($event)"
              multiple="multiple">
              <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('anexo_ipva')?.errors?.required && formulario.get('anexo_ipva')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="ipvaAnexo.length!=0">
          <div class="card-header">Anexos</div>
          <ul class="list-group list-group-flush" *ngFor="let file of ipvaAnexo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeIpvaFile(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
        <hr class="line">
      </div>

      <div class="detalhes-reembolso" *ngIf="mostrarSeguro">
        <div class="form-group row">
          <label for="seguro_carro" class="col-sm-4 col-form-label">*Seguro Carro: </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="seguro_carro" mask="separator.2" thousandSeparator="." (blur)="calculoSeguro()">
            <small class="texto-ajuda">*Incluir o valor integral do Seguro</small>
  
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('seguro_carro')?.errors?.required && formulario.get('seguro_carro')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Reembolso de Seguro
          </label>
          <div class="col-sm-8">
            <div>
              <label class="mb-1">Porcentagem de Reembolso:</label>
              <span class="valorCalculado">{{porcentagemReembolsoSeguro}}%</span>
            </div>
            <div class="mt-3">
              <label class="mb-1">Valor a reembolsar:</label>
              <span class="valorCalculado">{{valorCalculadoSeguro | currency}}</span>
            </div>
            <small class="texto-ajuda">*O valor será calculado após inclusão do valor</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="anexo_seguro" class="col-sm-4 col-form-label">*Comprovante de Pagamento Seguro
          </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_seguro" id="formFileMultiple" (change)="saveSeguro($event)"
              multiple="multiple">
              <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('anexo_seguro')?.errors?.required && formulario.get('anexo_seguro')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="seguroAnexo.length!=0">
          <div class="card-header">Anexos</div>
          <ul class="list-group list-group-flush" *ngFor="let file of seguroAnexo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeSeguroFile(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
        <hr class="line">
      </div>

      <div class="form-group row detalhes-reembolso" *ngIf="totalValorReembolsoCalculado">
        <label class="col-sm-4 col-form-label">Valor Total a ser reembolsado
        </label>
        <div class="col-sm-8">
          <label class="col-form-label">{{totalValorReembolsar | currency}}</label>
        </div>
      </div>


      <!-- COMPROVANTES ANTIGO -->
      <!-- <div class="form-group row" >
        <label for="comprovantes" class="col-sm-4 col-form-label">*Comprovantes de Pagamento
        </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="comprovantes" id="formFileMultiple" (change)="saveComprovantes($event)"
            multiple="multiple">
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('comprovantes')?.errors?.required && formulario.get('comprovantes')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
  
      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="comprovantesAnexo.length!=0">
        <div class="card-header">Anexos</div>
        <ul class="list-group list-group-flush" *ngFor="let file of comprovantesAnexo">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeComprovantesFile(this.file)"></i>
            </span>
          </li>
        </ul>
      </div> -->

      <div class="form-group-row box-observacao">
        <small class="text-center observacao-titulo">Observação</small>
        <br>
        <br>
        <small class="text-center observacao-texto">O cálculo de IPVA e Seguro do Carro será feito de acordo com a elegibilidade e percentual utilizado do valor disponível para a compra do veículo.</small>
      </div>


    

    

    <!-- <div class="form-group row">
      <label for="torre" class="col-sm-4 col-form-label">Torre</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="torre">
          <option *ngFor="let torre of torres" [value]="torre.tipo">{{torre.tipo}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="periodo_da_documentacao" class="col-sm-4 col-form-label">*Período da Documentação </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="periodo_da_documentacao">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('periodo_da_documentacao')?.errors?.required && formulario.get('periodo_da_documentacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="qual_documentacao_solicitada" class="col-sm-4 col-form-label">*Qual documentação solicitada? </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea"
          formControlName="qual_documentacao_solicitada"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('qual_documentacao_solicitada')?.errors?.required && formulario.get('qual_documentacao_solicitada')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="anexar_solicitacao_de_auditoria" class="col-sm-4 col-form-label">Anexar Solicitação de Auditoria
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexar_solicitacao_de_auditoria" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div> -->

    <div class="form-group pt-1 col-5 box-botao">
      <button type="submit" class="btn botao" [disabled]="usuarioSemPermissao">Enviar</button>
      <!-- [disabled]="formulario.invalid || usuarioSemPermissao || (!comprovantes && !documentacao && !ipva && !seguroCarro)"-->
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
