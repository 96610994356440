import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { pipe_fields_id_abertura_de_tickets } from '../pipe_fields_abertura_de_ticket';

@Component({
  selector: 'app-duvidas-sobre-ponto',
  templateUrl: './duvidas-sobre-ponto.component.html',
  styleUrls: ['./duvidas-sobre-ponto.component.css']
})
export class DuvidasSobrePontoComponent implements OnInit {

  formulario!: UntypedFormGroup;
  documentacao: any[] = [];
  documentacaoUrl: any[] = []

  error_counter: number = 0;
  erro: boolean = false

  constructor(private formBuilder: UntypedFormBuilder, private trataInput: TrataInputsService, private pipefy: PipefyService, private spinner: NgxSpinnerService, private msalService: MsalService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      descricao: ['', [Validators.required]],
      documentacao: ['', []],
      nome_completo: ['', []],
      cpf: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    })
  }

  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.documentacao);
      if (this.documentacao.length > 0) {
        while (this.documentacao.length != this.documentacaoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.documentacao.length > 0) {
        var documentacaoUrl2 = ''
        documentacaoUrl2 = '['
        for (var i = 0; i < this.documentacaoUrl.length; i++) {
          documentacaoUrl2 = documentacaoUrl2 + '"' + this.documentacaoUrl[i] + '",'
        }
        documentacaoUrl2 = documentacaoUrl2.slice(0, -1)
        documentacaoUrl2 = documentacaoUrl2 + "]"

      } else {
        documentacaoUrl2 = ''
      }

      let descricao = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao)
      let nome_completo = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_completo)
      let cpf = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf)
      let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)



      let pipe_fields = pipe_fields_id_abertura_de_tickets
      let fields_values = ["Dúvidas sobre ponto",descricao,documentacaoUrl2, nome_completo, cpf, empresa, email]
      let pipe_id = "302517888"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentacaoUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'HR', 'RH', "Autoatendimento - criação de card -- Dúvidas sobre ponto", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas']

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentacaoUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.documentacao.push(files[i])
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.documentacaoUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentacaoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.documentacao.indexOf(file);
    this.documentacao.splice(index, 1)
    alert("Arquivo Removido")
  }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.documentacao = []
    this.documentacaoUrl = []
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/WVHXhf0h', '_blank')
  }
}
