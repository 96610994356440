<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Recursos Humanos" subtitulo="Autoatendimento">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Ponto Eletrônico"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome_do_funcionario" class="col-sm-4 col-form-label">*Nome do Funcionário </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_funcionario">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('nome_do_funcionario')?.errors?.required && formulario.get('nome_do_funcionario')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*E-mail</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="matricula" class="col-sm-4 col-form-label">*Matrícula </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="matricula">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('matricula')?.errors?.required && formulario.get('matricula')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="empresa">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="justificativa_do_ajuste" class="col-sm-4 col-form-label">*Justificativa do Ajuste </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="justificativa_do_ajuste">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('justificativa_do_ajuste')?.errors?.required && formulario.get('justificativa_do_ajuste')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="descricao" class="col-sm-4 col-form-label">*Descrição </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descricao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao')?.errors?.required && formulario.get('descricao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="anexo" class="col-sm-4 col-form-label">*Anexos
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexo')?.errors?.required && formulario.get('anexo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexo.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of anexo">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="/recursos-humanos/home"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
