<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_lojas.png" descricao="teste" titulo="Lojas"
  subtitulo="Centros de Experiência"></app-nbs-banner>



<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Venda e Faturamento Pedido"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <!-- <div class="form-group row">
      <label for="tipo_de_venda" class="col-sm-4 col-form-label">*Tipo de venda</label>
      <div class="col-sm-8">
        <label for="tipo_de_venda" class=' radio-inline'>
          <input type="radio" formControlName='tipo_de_venda' value="Redistribuição"
            class='radio-btn'>&nbsp;Redistribuição
          Física
        </label>
        <label for="tipo_de_venda" class='radio-inline'>
          <input type="radio" formControlName='tipo_de_venda' value='Revenda' class='radio-btn'>&nbsp;Revenda
          Jurídica
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('tipo_de_venda')?.errors?.required && formulario.get('tipo_de_venda')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa" (change)="onTipoChange()"> <!--(change)="verificaExibicaoCondParcelamento()"-->
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="categoria" class="col-sm-4 col-form-label">*Categoria </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="categoria" (change)="onTipoChange()"> <!--(change)="verificaExibicaoCondParcelamento()"-->
          <option *ngFor="let categoria of categorias" [value]="categoria.nome">{{categoria.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('categoria')?.errors?.required && formulario.get('categoria')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="tipo_de_cliente" class="col-sm-4 col-form-label">*Tipo de cliente</label>
      <div class="col-sm-8">
        <label for="tipo_de_cliente" class=' radio-inline'>
          <input type="radio" formControlName='tipo_de_cliente' value="Pessoa Física" class='radio-btn'
            (change)="onDocumentoChange($event)">&nbsp;Pessoa
          Física
        </label>
        <label for="tipo_de_cliente" class='radio-inline'>
          <input type="radio" formControlName='tipo_de_cliente' value='Pessoa Jurídica' class='radio-btn'
            (change)="onDocumentoChange($event)">&nbsp;Pessoa
          Jurídica
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('tipo_de_cliente')?.errors?.required && formulario.get('tipo_de_cliente')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_cliente=='Pessoa Física'">
      <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cpf" mask='000.000.000-99'>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched" mensagem="Campo Obrigatório">
      </app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_cliente=='Pessoa Física'">
      <label for="nome_cliente" class="col-sm-4 col-form-label">*Nome Cliente</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_cliente">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('nome_cliente')?.errors?.required && formulario.get('nome_cliente')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_cliente=='Pessoa Jurídica'">
      <label for="cnpj" class="col-sm-4 col-form-label">*CNPJ</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cnpj" mask="00.000.000/0000-00">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('cnpj')?.errors?.required && formulario.get('cnpj')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_cliente=='Pessoa Jurídica'">
      <label for="razao_social" class="col-sm-4 col-form-label">*Razão Social</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="razao_social">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('razao_social')?.errors?.required && formulario.get('razao_social')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="inscricao_estadual" class="col-sm-4 col-form-label">*Inscrição Estadual </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="inscricao_estadual">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('inscricao_estadual')?.errors?.required && formulario.get('inscricao_estadual')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_da_propriedade" class="col-sm-4 col-form-label">Nome da Propriedade</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_da_propriedade">
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="cliente_novo" class="col-sm-4 col-form-label">Cliente Novo?</label>
      <div class="col-sm-8">
        <label for="cliente_novo" class=' radio-inline'>
          <input type="radio" formControlName='cliente_novo' value="Sim" class='radio-btn'>&nbsp;Sim
        </label>
        <label for="cliente_novo" class='radio-inline'>
          <input type="radio" formControlName='cliente_novo' value='Não' class='radio-btn'>&nbsp;Não
        </label>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="cultura" class="col-sm-4 col-form-label">*Cultura</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cultura">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('cultura')?.errors?.required && formulario.get('cultura')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().categoria === 'Defensivos'">
      <label for="frete" class="col-sm-4 col-form-label">Necessário Receituário Agronônico?</label>
      <div class="col-sm-8">
        <label for="frete" class=' radio-inline'>
          <input type="radio" formControlName='necessario_receituario_agronomico' value="Sim" class='radio-btn'
            >&nbsp;Sim
        </label>
        <label for="freteo" class='radio-inline'>
          <input type="radio" formControlName='necessario_receituario_agronomico' value='Não' class='radio-btn'
            >&nbsp;Não
        </label>
      </div>
    </div>
    <div *ngIf="formulario.getRawValue().categoria == 'Defensivos' && formulario.getRawValue().necessario_receituario_agronomico=== 'Sim'">
      <div class="form-group row">
        <label for="i_praga" class="col-sm-4 col-form-label">i. Praga
        </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="i_praga">
        </div>
      </div>

      <div class="form-group row">
        <label for="ii_metodo_de_aplicacao" class="col-sm-4 col-form-label">ii. Método de Aplicação
        </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="ii_metodo_de_aplicacao">
        </div>
      </div>


      <div class="form-group row">
        <label for="iii_periodo_de_aplicacao" class="col-sm-4 col-form-label">iii. Período de Aplicação
        </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="iii_periodo_de_aplicacao">
        </div>
      </div>

    </div>



    <div class="form-group row">
      <label for="tipo_de_pedido" class="col-sm-4 col-form-label">Tipo de Pedido </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tipo_de_pedido">
          <option *ngFor="let tipo of tipos_de_pedido" [value]="tipo.tipo">{{tipo.tipo}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="campanha" class="col-sm-4 col-form-label">Campanha </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="campanha">
          <option *ngFor="let campanha of campanhas" [value]="campanha.campanha">{{campanha.campanha}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_de_produtos_no_pedido" class="col-sm-4 col-form-label">*Número de produtos no pedido</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="numero_de_produtos_no_pedido"
          (change)="ajustaTipodoNumeroDeProdutosDoPedido($event)" (change)="onTipoChange()">
          <option *ngFor="let produto of numero_de_produtos" [value]="produto.quantidade">{{produto.quantidade}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('numero_de_produtos_no_pedido')?.errors?.required && formulario.get('numero_de_produtos_no_pedido')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- Detalhes 1 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=1">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_1" class="col-sm-4 col-form-label">*1 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_1"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_1, 'descricao_produto_1')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_1, 'descricao_produto_1')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_1, 'descricao_produto_1')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_1, 'descricao_produto_1')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_1')?.errors?.required && formulario.get('codigo_do_produto_1')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_1" class="col-sm-4 col-form-label">*1 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_1" value=descricaoArray [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_1')?.errors?.required && formulario.get('codigo_do_produto_1')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>

        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_1" class="col-sm-4 col-form-label">1 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_1">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_1" class="col-sm-4 col-form-label">*1 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_1">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_1')?.errors?.required && formulario.get('descricao_produto_1')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>

        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_1" class="col-sm-4 col-form-label">*1 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_1">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('familia_produto_1')?.errors?.required && formulario.get('familia_produto_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_1" class="col-sm-4 col-form-label">*1 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_1')?.errors?.required && formulario.get('quantidade_produto_1')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_1" class="col-sm-4 col-form-label">*1 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_1" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_1')?.errors?.required && formulario.get('valor_unitario_produto_1')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_1" class="col-sm-4 col-form-label">*1 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_1')?.errors?.required && formulario.get('embalagem_produto_1')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_1" class="col-sm-4 col-form-label">*1 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_1">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_1')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_1')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_1" class="col-sm-4 col-form-label">*1 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_1">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_1')?.errors?.required && formulario.get('planta_de_expedicao_1')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_1" class="col-sm-4 col-form-label">*1 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_1">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_1')?.errors?.required && formulario.get('centro_de_expedicao_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 2 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=2">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_2" class="col-sm-4 col-form-label">*2 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_2"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_2, 'descricao_produto_2')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_2, 'descricao_produto_2')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_2, 'descricao_produto_2')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_2, 'descricao_produto_2')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_2')?.errors?.required && formulario.get('codigo_do_produto_2')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_2" class="col-sm-4 col-form-label">*2 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_2" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_2')?.errors?.required && formulario.get('codigo_do_produto_2')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_2" class="col-sm-4 col-form-label">2 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_2">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_2" class="col-sm-4 col-form-label">*2 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_2">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_2')?.errors?.required && formulario.get('descricao_produto_2')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_2" class="col-sm-4 col-form-label">*2 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_2">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_2')?.errors?.required && formulario.get('familia_produto_2')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_2" class="col-sm-4 col-form-label">*2 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_2')?.errors?.required && formulario.get('quantidade_produto_2')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_2" class="col-sm-4 col-form-label">*2 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_2" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_2')?.errors?.required && formulario.get('valor_unitario_produto_2')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_2" class="col-sm-4 col-form-label">*2 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_2')?.errors?.required && formulario.get('embalagem_produto_2')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_2" class="col-sm-4 col-form-label">*2 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_2">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_2')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_2')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_2" class="col-sm-4 col-form-label">*2 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_2">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_2')?.errors?.required && formulario.get('planta_de_expedicao_2')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_2" class="col-sm-4 col-form-label">*2 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_2">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_2')?.errors?.required && formulario.get('centro_de_expedicao_2')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 3 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=3">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_3" class="col-sm-4 col-form-label">*3 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_3"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_3, 'descricao_produto_3')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_3, 'descricao_produto_3')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_3, 'descricao_produto_3')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_3, 'descricao_produto_3')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_3')?.errors?.required && formulario.get('codigo_do_produto_3')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_3" class="col-sm-4 col-form-label">*3 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_3" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_3')?.errors?.required && formulario.get('codigo_do_produto_3')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_3" class="col-sm-4 col-form-label">3 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_3">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_3" class="col-sm-4 col-form-label">*3 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_3">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_3')?.errors?.required && formulario.get('descricao_produto_3')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_3" class="col-sm-4 col-form-label">*3 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_3">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_3')?.errors?.required && formulario.get('familia_produto_3')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_3" class="col-sm-4 col-form-label">*3 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_3')?.errors?.required && formulario.get('quantidade_produto_3')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_3" class="col-sm-4 col-form-label">*3 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_3" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_3')?.errors?.required && formulario.get('valor_unitario_produto_3')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_3" class="col-sm-4 col-form-label">*3 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_3')?.errors?.required && formulario.get('embalagem_produto_3')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_3" class="col-sm-4 col-form-label">*3- Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_3">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_3')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_3')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_3" class="col-sm-4 col-form-label">*3 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_3">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_3')?.errors?.required && formulario.get('planta_de_expedicao_3')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_3" class="col-sm-4 col-form-label">*3 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_3">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_3')?.errors?.required && formulario.get('centro_de_expedicao_3')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 4 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=4">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_4" class="col-sm-4 col-form-label">*4 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_4"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_4, 'descricao_produto_4')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_4, 'descricao_produto_4')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_4, 'descricao_produto_4')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_4, 'descricao_produto_4')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_4')?.errors?.required && formulario.get('codigo_do_produto_4')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_4" class="col-sm-4 col-form-label">*4 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_4" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_4')?.errors?.required && formulario.get('codigo_do_produto_4')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_4" class="col-sm-4 col-form-label">4 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_4">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_4" class="col-sm-4 col-form-label">*4 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_4">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_4')?.errors?.required && formulario.get('descricao_produto_4')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>


      <!-- <div class="form-group row">
        <label for="familia_produto_4" class="col-sm-4 col-form-label">*4 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_4">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_4')?.errors?.required && formulario.get('familia_produto_4')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_4" class="col-sm-4 col-form-label">*4 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_4')?.errors?.required && formulario.get('quantidade_produto_4')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_4" class="col-sm-4 col-form-label">*4 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_4" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_4')?.errors?.required && formulario.get('valor_unitario_produto_4')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_4" class="col-sm-4 col-form-label">*4 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_4')?.errors?.required && formulario.get('embalagem_produto_4')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_4" class="col-sm-4 col-form-label">*4 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_4">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_4')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_4')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_4" class="col-sm-4 col-form-label">*4 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_4">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_4')?.errors?.required && formulario.get('planta_de_expedicao_4')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_4" class="col-sm-4 col-form-label">*4 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_4">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_4')?.errors?.required && formulario.get('centro_de_expedicao_4')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 5 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=5">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_5" class="col-sm-4 col-form-label">*5 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_5"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_5, 'descricao_produto_5')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_5, 'descricao_produto_5')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_5, 'descricao_produto_5')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_5, 'descricao_produto_5')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_5')?.errors?.required && formulario.get('codigo_do_produto_5')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_5" class="col-sm-4 col-form-label">*5- Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_5" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_5')?.errors?.required && formulario.get('codigo_do_produto_5')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_5" class="col-sm-4 col-form-label">5 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_5">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_5" class="col-sm-4 col-form-label">*5- Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_5">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_5')?.errors?.required && formulario.get('descricao_produto_5')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_5" class="col-sm-4 col-form-label">*5 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_5">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_5')?.errors?.required && formulario.get('familia_produto_5')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_5" class="col-sm-4 col-form-label">*5 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_5')?.errors?.required && formulario.get('quantidade_produto_5')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_5" class="col-sm-4 col-form-label">*5 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_5" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_5')?.errors?.required && formulario.get('valor_unitario_produto_5')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_5" class="col-sm-4 col-form-label">*5 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_5')?.errors?.required && formulario.get('embalagem_produto_5')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_5" class="col-sm-4 col-form-label">*5 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_5">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_5')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_5')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_5" class="col-sm-4 col-form-label">*5 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_5">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_5')?.errors?.required && formulario.get('planta_de_expedicao_5')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_5" class="col-sm-4 col-form-label">*5 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_5">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_5')?.errors?.required && formulario.get('centro_de_expedicao_5')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 6 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=6">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_6" class="col-sm-4 col-form-label">*6 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_6"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_6, 'descricao_produto_6')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_6, 'descricao_produto_6')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_6, 'descricao_produto_6')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_6, 'descricao_produto_6')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_6')?.errors?.required && formulario.get('codigo_do_produto_6')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_6" class="col-sm-4 col-form-label">*6 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_6" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_6')?.errors?.required && formulario.get('codigo_do_produto_6')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_6" class="col-sm-4 col-form-label">6 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_6">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_6" class="col-sm-4 col-form-label">*6 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_6">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_6')?.errors?.required && formulario.get('descricao_produto_6')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_6" class="col-sm-4 col-form-label">*6 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_6">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_6')?.errors?.required && formulario.get('familia_produto_6')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_6" class="col-sm-4 col-form-label">*6 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_6')?.errors?.required && formulario.get('quantidade_produto_6')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_6" class="col-sm-4 col-form-label">*6 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_6" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_6')?.errors?.required && formulario.get('valor_unitario_produto_6')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_6" class="col-sm-4 col-form-label">*6 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_6')?.errors?.required && formulario.get('embalagem_produto_6')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_6" class="col-sm-4 col-form-label">*6 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_6">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_6')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_6')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_6" class="col-sm-4 col-form-label">*6 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_6">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_6')?.errors?.required && formulario.get('planta_de_expedicao_6')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_6" class="col-sm-4 col-form-label">*6 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_6">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_6')?.errors?.required && formulario.get('centro_de_expedicao_6')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 7 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=7">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_7" class="col-sm-4 col-form-label">*7 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_7"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_7, 'descricao_produto_7')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_7, 'descricao_produto_7')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_7, 'descricao_produto_7')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_7, 'descricao_produto_7')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_7')?.errors?.required && formulario.get('codigo_do_produto_7')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_7" class="col-sm-4 col-form-label">*7 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_7" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_7')?.errors?.required && formulario.get('codigo_do_produto_7')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_7" class="col-sm-4 col-form-label">7 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_7">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_7" class="col-sm-4 col-form-label">*7 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_7">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_7')?.errors?.required && formulario.get('descricao_produto_7')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_7" class="col-sm-4 col-form-label">*7 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_7">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_7')?.errors?.required && formulario.get('familia_produto_7')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_7" class="col-sm-4 col-form-label">*7 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_7')?.errors?.required && formulario.get('quantidade_produto_7')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_7" class="col-sm-4 col-form-label">*7 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_7" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_7')?.errors?.required && formulario.get('valor_unitario_produto_7')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_7" class="col-sm-4 col-form-label">*7 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_7')?.errors?.required && formulario.get('embalagem_produto_7')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_7" class="col-sm-4 col-form-label">*7 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_7">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_7')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_7')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_7" class="col-sm-4 col-form-label">*7 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_7">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_7')?.errors?.required && formulario.get('planta_de_expedicao_7')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_7" class="col-sm-4 col-form-label">*7 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_7">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_7')?.errors?.required && formulario.get('centro_de_expedicao_7')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 8 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=8">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_8" class="col-sm-4 col-form-label">*8 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_8"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_8, 'descricao_produto_8')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_8, 'descricao_produto_8')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_8, 'descricao_produto_8')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_8, 'descricao_produto_8')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_8')?.errors?.required && formulario.get('codigo_do_produto_8')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_8" class="col-sm-4 col-form-label">*8 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_8" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_8')?.errors?.required && formulario.get('codigo_do_produto_8')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_8" class="col-sm-4 col-form-label">8 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_8">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_8" class="col-sm-4 col-form-label">*8 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_8">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_8')?.errors?.required && formulario.get('descricao_produto_8')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_8" class="col-sm-4 col-form-label">*8 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_8">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_8')?.errors?.required && formulario.get('familia_produto_8')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_8" class="col-sm-4 col-form-label">*8 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_8')?.errors?.required && formulario.get('quantidade_produto_8')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_8" class="col-sm-4 col-form-label">*8 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_8" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_8')?.errors?.required && formulario.get('valor_unitario_produto_8')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_8" class="col-sm-4 col-form-label">*8 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_8')?.errors?.required && formulario.get('embalagem_produto_8')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_8" class="col-sm-4 col-form-label">*8 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_8">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_8')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_8')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_8" class="col-sm-4 col-form-label">*8 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_8">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_8')?.errors?.required && formulario.get('planta_de_expedicao_8')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_8" class="col-sm-4 col-form-label">*8 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_8">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_8')?.errors?.required && formulario.get('centro_de_expedicao_8')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 9 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=9">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_9" class="col-sm-4 col-form-label">*9 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_9"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_9, 'descricao_produto_9')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_9, 'descricao_produto_9')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_9, 'descricao_produto_9')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_9, 'descricao_produto_9')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_9')?.errors?.required && formulario.get('codigo_do_produto_9')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_9" class="col-sm-4 col-form-label">*9 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_9" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_9')?.errors?.required && formulario.get('codigo_do_produto_9')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_9" class="col-sm-4 col-form-label">9 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_9">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_9" class="col-sm-4 col-form-label">*9 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_9">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_9')?.errors?.required && formulario.get('descricao_produto_9')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_9" class="col-sm-4 col-form-label">*9 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_9">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_9')?.errors?.required && formulario.get('familia_produto_9')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_9" class="col-sm-4 col-form-label">*9 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_9')?.errors?.required && formulario.get('quantidade_produto_9')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_9" class="col-sm-4 col-form-label">*9 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_9" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_9')?.errors?.required && formulario.get('valor_unitario_produto_9')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_9" class="col-sm-4 col-form-label">*9 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_9')?.errors?.required && formulario.get('embalagem_produto_9')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_9" class="col-sm-4 col-form-label">*9 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_9">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_9')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_9')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_9" class="col-sm-4 col-form-label">*9 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_9">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_9')?.errors?.required && formulario.get('planta_de_expedicao_9')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_9" class="col-sm-4 col-form-label">*9 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_9">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_9')?.errors?.required && formulario.get('centro_de_expedicao_9')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 10 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=10">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_10" class="col-sm-4 col-form-label">*10 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_10"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_10, 'descricao_produto_10')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_10, 'descricao_produto_10')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_10, 'descricao_produto_10')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_10, 'descricao_produto_10')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_10')?.errors?.required && formulario.get('codigo_do_produto_10')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_10" class="col-sm-4 col-form-label">*10 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_10" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_10')?.errors?.required && formulario.get('codigo_do_produto_10')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_10" class="col-sm-4 col-form-label">10 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_10">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_10" class="col-sm-4 col-form-label">*10 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_10">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_10')?.errors?.required && formulario.get('descricao_produto_10')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_10" class="col-sm-4 col-form-label">*10 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_10">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_10')?.errors?.required && formulario.get('familia_produto_10')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_10" class="col-sm-4 col-form-label">*10 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_10')?.errors?.required && formulario.get('quantidade_produto_10')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_10" class="col-sm-4 col-form-label">*10 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_10" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_10')?.errors?.required && formulario.get('valor_unitario_produto_10')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_10" class="col-sm-4 col-form-label">*10 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_10')?.errors?.required && formulario.get('embalagem_produto_10')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_10" class="col-sm-4 col-form-label">*10 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_10">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_10')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_10')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_10" class="col-sm-4 col-form-label">*10 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_10">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_10')?.errors?.required && formulario.get('planta_de_expedicao_10')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_10" class="col-sm-4 col-form-label">*10 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_10">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_10')?.errors?.required && formulario.get('centro_de_expedicao_10')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 11 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=11">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_11" class="col-sm-4 col-form-label">*11 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_11"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_11, 'descricao_produto_11')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_11, 'descricao_produto_11')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_11, 'descricao_produto_11')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_11, 'descricao_produto_11')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_11')?.errors?.required && formulario.get('codigo_do_produto_11')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_11" class="col-sm-4 col-form-label">*11 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_11" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_11')?.errors?.required && formulario.get('codigo_do_produto_11')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_11" class="col-sm-4 col-form-label">11 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_11">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_11" class="col-sm-4 col-form-label">*11 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_11">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_11')?.errors?.required && formulario.get('descricao_produto_11')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_11" class="col-sm-4 col-form-label">*11 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_11">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_11')?.errors?.required && formulario.get('familia_produto_11')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_11" class="col-sm-4 col-form-label">*11 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_11')?.errors?.required && formulario.get('quantidade_produto_11')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_11" class="col-sm-4 col-form-label">*11 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_11" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_11')?.errors?.required && formulario.get('valor_unitario_produto_11')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_11" class="col-sm-4 col-form-label">*11 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_11')?.errors?.required && formulario.get('embalagem_produto_11')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_11" class="col-sm-4 col-form-label">*11 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_11">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_11')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_11')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_11" class="col-sm-4 col-form-label">*11 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_11">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_11')?.errors?.required && formulario.get('planta_de_expedicao_11')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_11" class="col-sm-4 col-form-label">*11 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_11">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_11')?.errors?.required && formulario.get('centro_de_expedicao_11')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 12 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=12">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_12" class="col-sm-4 col-form-label">*12 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_12"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_12, 'descricao_produto_12')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_12, 'descricao_produto_12')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_12, 'descricao_produto_12')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_12, 'descricao_produto_12')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_12')?.errors?.required && formulario.get('codigo_do_produto_12')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_12" class="col-sm-4 col-form-label">*12 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_12" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_12')?.errors?.required && formulario.get('codigo_do_produto_12')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_12" class="col-sm-4 col-form-label">12 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_12">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_12" class="col-sm-4 col-form-label">*12 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_12">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_12')?.errors?.required && formulario.get('descricao_produto_12')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_12" class="col-sm-4 col-form-label">*12 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_12">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_12')?.errors?.required && formulario.get('familia_produto_12')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_12" class="col-sm-4 col-form-label">*12 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_12')?.errors?.required && formulario.get('quantidade_produto_12')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_12" class="col-sm-4 col-form-label">*12 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_12" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_12')?.errors?.required && formulario.get('valor_unitario_produto_12')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_12" class="col-sm-4 col-form-label">*12 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_12')?.errors?.required && formulario.get('embalagem_produto_12')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_12" class="col-sm-4 col-form-label">*12 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_12">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_12')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_12')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_12" class="col-sm-4 col-form-label">*12 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_12">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_12')?.errors?.required && formulario.get('planta_de_expedicao_12')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_12" class="col-sm-4 col-form-label">*12 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_12">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_12')?.errors?.required && formulario.get('centro_de_expedicao_12')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 13 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=13">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_13" class="col-sm-4 col-form-label">*13 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_13"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_13, 'descricao_produto_13')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_13, 'descricao_produto_13')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_13, 'descricao_produto_13')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_13, 'descricao_produto_13')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_13')?.errors?.required && formulario.get('codigo_do_produto_13')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_13" class="col-sm-4 col-form-label">*13 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_13" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_13')?.errors?.required && formulario.get('codigo_do_produto_13')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_13" class="col-sm-4 col-form-label">13 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_13">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_13" class="col-sm-4 col-form-label">*13 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_13">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_13')?.errors?.required && formulario.get('descricao_produto_13')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_13" class="col-sm-4 col-form-label">*13 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_13">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_13')?.errors?.required && formulario.get('familia_produto_13')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_13" class="col-sm-4 col-form-label">*13 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_13')?.errors?.required && formulario.get('quantidade_produto_13')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_13" class="col-sm-4 col-form-label">*13 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_13" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_13')?.errors?.required && formulario.get('valor_unitario_produto_13')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_13" class="col-sm-4 col-form-label">*13 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_13')?.errors?.required && formulario.get('embalagem_produto_13')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_13" class="col-sm-4 col-form-label">*13 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_13">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_13')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_13')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_13" class="col-sm-4 col-form-label">*13 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_13">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_13')?.errors?.required && formulario.get('planta_de_expedicao_13')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>
      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_13" class="col-sm-4 col-form-label">*13 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_13">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_13')?.errors?.required && formulario.get('centro_de_expedicao_13')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 14 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=14">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_14" class="col-sm-4 col-form-label">*14 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_14"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_14, 'descricao_produto_14')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_14, 'descricao_produto_14')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_14, 'descricao_produto_14')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_14, 'descricao_produto_14')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_14')?.errors?.required && formulario.get('codigo_do_produto_14')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_14" class="col-sm-4 col-form-label">*14 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_14" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_14')?.errors?.required && formulario.get('codigo_do_produto_14')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_14" class="col-sm-4 col-form-label">14 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_14">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_14" class="col-sm-4 col-form-label">*14 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_14">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_14')?.errors?.required && formulario.get('descricao_produto_14')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_14" class="col-sm-4 col-form-label">*14 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_14">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_14')?.errors?.required && formulario.get('familia_produto_14')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_14" class="col-sm-4 col-form-label">*14 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_14')?.errors?.required && formulario.get('quantidade_produto_14')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_14" class="col-sm-4 col-form-label">*14 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_14" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_14')?.errors?.required && formulario.get('valor_unitario_produto_14')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_14" class="col-sm-4 col-form-label">*14 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_14')?.errors?.required && formulario.get('embalagem_produto_14')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_14" class="col-sm-4 col-form-label">*14 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_14">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_14')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_14')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_14" class="col-sm-4 col-form-label">*14 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_14">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_14')?.errors?.required && formulario.get('planta_de_expedicao_14')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_14" class="col-sm-4 col-form-label">*14 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_14">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_14')?.errors?.required && formulario.get('centro_de_expedicao_14')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 15 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=15">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_15" class="col-sm-4 col-form-label">*15 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_15"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_15, 'descricao_produto_15')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_15, 'descricao_produto_15')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_15, 'descricao_produto_15')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_15, 'descricao_produto_15')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_15')?.errors?.required && formulario.get('codigo_do_produto_15')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_15" class="col-sm-4 col-form-label">*15 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_15" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_15')?.errors?.required && formulario.get('codigo_do_produto_15')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_15" class="col-sm-4 col-form-label">15 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_15">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_15" class="col-sm-4 col-form-label">*15 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_15">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_15')?.errors?.required && formulario.get('descricao_produto_15')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_15" class="col-sm-4 col-form-label">*15 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_15">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_15')?.errors?.required && formulario.get('familia_produto_15')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_15" class="col-sm-4 col-form-label">*15 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_15')?.errors?.required && formulario.get('quantidade_produto_15')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_15" class="col-sm-4 col-form-label">*15 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_15" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_15')?.errors?.required && formulario.get('valor_unitario_produto_15')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_15" class="col-sm-4 col-form-label">*15 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_15')?.errors?.required && formulario.get('embalagem_produto_15')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_15" class="col-sm-4 col-form-label">*15 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_15">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_15')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_15')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_15" class="col-sm-4 col-form-label">*15 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_15">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_15')?.errors?.required && formulario.get('planta_de_expedicao_15')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_15" class="col-sm-4 col-form-label">*15 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_15">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_15')?.errors?.required && formulario.get('centro_de_expedicao_15')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 16 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=16">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_16" class="col-sm-4 col-form-label">*16 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_16"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_16, 'descricao_produto_16')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_16, 'descricao_produto_16')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_16, 'descricao_produto_16')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_16, 'descricao_produto_16')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_16')?.errors?.required && formulario.get('codigo_do_produto_16')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_16" class="col-sm-4 col-form-label">*16 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_16" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_16')?.errors?.required && formulario.get('codigo_do_produto_16')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_16" class="col-sm-4 col-form-label">16 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_16">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_16" class="col-sm-4 col-form-label">*16 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_16">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_16')?.errors?.required && formulario.get('descricao_produto_16')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_16" class="col-sm-4 col-form-label">*16 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_16">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_16')?.errors?.required && formulario.get('familia_produto_16')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_16" class="col-sm-4 col-form-label">*16 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_16')?.errors?.required && formulario.get('quantidade_produto_16')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_16" class="col-sm-4 col-form-label">*16 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_16" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_16')?.errors?.required && formulario.get('valor_unitario_produto_16')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_16" class="col-sm-4 col-form-label">*16 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_16')?.errors?.required && formulario.get('embalagem_produto_16')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_16" class="col-sm-4 col-form-label">*16 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_16">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_16')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_16')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_16" class="col-sm-4 col-form-label">*16 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_16">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_16')?.errors?.required && formulario.get('planta_de_expedicao_16')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_16" class="col-sm-4 col-form-label">*16 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_16">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_16')?.errors?.required && formulario.get('centro_de_expedicao_16')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 17 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=17">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_17" class="col-sm-4 col-form-label">*17 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_17"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_17, 'descricao_produto_17')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_17, 'descricao_produto_17')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_17, 'descricao_produto_17')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_17, 'descricao_produto_17')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_17')?.errors?.required && formulario.get('codigo_do_produto_17')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_17" class="col-sm-4 col-form-label">*17 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_17" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_17')?.errors?.required && formulario.get('codigo_do_produto_17')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_17" class="col-sm-4 col-form-label">17 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_17">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_17" class="col-sm-4 col-form-label">*17 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_17">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_17')?.errors?.required && formulario.get('descricao_produto_17')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_17" class="col-sm-4 col-form-label">*17 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_17">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_17')?.errors?.required && formulario.get('familia_produto_17')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_17" class="col-sm-4 col-form-label">*17 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_17')?.errors?.required && formulario.get('quantidade_produto_17')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_17" class="col-sm-4 col-form-label">*17 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_17" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_17')?.errors?.required && formulario.get('valor_unitario_produto_17')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_17" class="col-sm-4 col-form-label">*17 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_17')?.errors?.required && formulario.get('embalagem_produto_17')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_17" class="col-sm-4 col-form-label">*17 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_17">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_17')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_17')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_17" class="col-sm-4 col-form-label">*17 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_17">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_17')?.errors?.required && formulario.get('planta_de_expedicao_17')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_17" class="col-sm-4 col-form-label">*17 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_17">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_17')?.errors?.required && formulario.get('centro_de_expedicao_17')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 18 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=18">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_18" class="col-sm-4 col-form-label">*18 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_18"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_18, 'descricao_produto_18')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_18, 'descricao_produto_18')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_18, 'descricao_produto_18')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_18, 'descricao_produto_18')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_18')?.errors?.required && formulario.get('codigo_do_produto_18')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_18" class="col-sm-4 col-form-label">*18 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_18" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_18')?.errors?.required && formulario.get('codigo_do_produto_18')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_18" class="col-sm-4 col-form-label">18 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_18">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_18" class="col-sm-4 col-form-label">*18 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_18">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_18')?.errors?.required && formulario.get('descricao_produto_18')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_18" class="col-sm-4 col-form-label">*18 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_18">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_18')?.errors?.required && formulario.get('familia_produto_18')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_18" class="col-sm-4 col-form-label">*18 - Quantidade</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="quantidade_produto_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_18')?.errors?.required && formulario.get('quantidade_produto_18')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_18" class="col-sm-4 col-form-label">*18 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_18" mask="separator.2" thousandSeparator="."   >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_18')?.errors?.required && formulario.get('valor_unitario_produto_18')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_18" class="col-sm-4 col-form-label">*18 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_18')?.errors?.required && formulario.get('embalagem_produto_18')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_18" class="col-sm-4 col-form-label">*18 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_18">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_18')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_18')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_18" class="col-sm-4 col-form-label">*18 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_18">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_18')?.errors?.required && formulario.get('planta_de_expedicao_18')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_18" class="col-sm-4 col-form-label">*18 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_18">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_18')?.errors?.required && formulario.get('centro_de_expedicao_18')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 19 -->
    <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=19">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_19" class="col-sm-4 col-form-label">*19 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_19"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_19, 'descricao_produto_19')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_19, 'descricao_produto_19')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_19, 'descricao_produto_19')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_19, 'descricao_produto_19')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_19')?.errors?.required && formulario.get('codigo_do_produto_19')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_19" class="col-sm-4 col-form-label">*19 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_19" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_19')?.errors?.required && formulario.get('codigo_do_produto_19')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_19" class="col-sm-4 col-form-label">19 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_19">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_19" class="col-sm-4 col-form-label">*19 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_19">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_19')?.errors?.required && formulario.get('descricao_produto_19')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_19" class="col-sm-4 col-form-label">*19 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_19">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_19')?.errors?.required && formulario.get('familia_produto_19')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_19" class="col-sm-4 col-form-label">*19 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_19')?.errors?.required && formulario.get('quantidade_produto_19')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_19" class="col-sm-4 col-form-label">*19 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_19" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_19')?.errors?.required && formulario.get('valor_unitario_produto_19')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_19" class="col-sm-4 col-form-label">*19 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_19')?.errors?.required && formulario.get('embalagem_produto_19')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_19" class="col-sm-4 col-form-label">*19 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_19">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_19')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_19')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_19" class="col-sm-4 col-form-label">*19 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_19">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_19')?.errors?.required && formulario.get('planta_de_expedicao_19')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_19" class="col-sm-4 col-form-label">*19 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_19">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_19')?.errors?.required && formulario.get('centro_de_expedicao_19')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>

    <!-- Detalhes 20 -->

     <div class="detalhes-pedido" *ngIf="quantidade_de_produtos_int>=20">
      <div *ngIf="exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_20" class="col-sm-4 col-form-label">*20 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_20"
              (blur)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_20, 'descricao_produto_20')"
              (keyup.enter)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_20, 'descricao_produto_20')"
              (keydown.tab)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_20, 'descricao_produto_20')">
            <div class="btnGetDescProd" (click)="getDescricaoProduto(formulario.getRawValue().codigo_do_produto_20, 'descricao_produto_20')">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="erroCodigoInvalido"
              mensagem="Código do Produto Inválido">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_20')?.errors?.required && formulario.get('codigo_do_produto_20')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_20" class="col-sm-4 col-form-label">*20 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_20" [attr.disabled]="true" placeholder="Pesquise o Código do produto acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_20')?.errors?.required && formulario.get('descricao_produto_20')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('codigo_do_produto_20')?.errors?.required && formulario.get('codigo_do_produto_20')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="!exibeListaProdutos">
        <div class="form-group row">
          <label for="codigo_do_produto_20" class="col-sm-4 col-form-label">20 - Código Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control position-relative" formControlName="codigo_do_produto_20">
          </div>
        </div>

        <div class="form-group row">
          <label for="descricao_produto_20" class="col-sm-4 col-form-label">*20 - Descrição Produto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="descricao_produto_20">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('descricao_produto_20')?.errors?.required && formulario.get('descricao_produto_20')?.touched"
              mensagem="Campo Obrigatório">
            </app-mensagem>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="familia_produto_20" class="col-sm-4 col-form-label">*20 - Famíllia Produto </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="familia_produto_20">
            <option *ngFor="let produto of familia_do_produto" [value]="produto.familia">{{produto.familia}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('familia_produto_20')?.errors?.required && formulario.get('familia_produto_20')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="quantidade_produto_20" class="col-sm-4 col-form-label">*20 - Quantidade</label>
        <div class="col-sm-8">
          <input type="number" class="form-control" formControlName="quantidade_produto_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_produto_20')?.errors?.required && formulario.get('quantidade_produto_20')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_unitario_produto_20" class="col-sm-4 col-form-label">*20 - Valor Unitário</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_unitario_produto_20" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_unitario_produto_20')?.errors?.required && formulario.get('valor_unitario_produto_20')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="embalagem_produto_20" class="col-sm-4 col-form-label">*20 - Embalagem</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="embalagem_produto_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('embalagem_produto_20')?.errors?.required && formulario.get('embalagem_produto_20')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_da_entrega_solicitada_produto_20" class="col-sm-4 col-form-label">*20 - Data da entrega
          solicitada</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_da_entrega_solicitada_produto_20">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_da_entrega_solicitada_produto_20')?.errors?.required && formulario.get('data_da_entrega_solicitada_produto_20')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <div class="form-group row"
          *ngIf="(formulario.getRawValue().empresa === 'Nutrien' && formulario.getRawValue().categoria !== '') ||
                 (formulario.getRawValue().empresa !== 'Nutrien' && formulario.getRawValue().empresa !== '' && formulario.getRawValue().categoria === 'Fertilizantes')">
        <label for="planta_de_expedicao_20" class="col-sm-4 col-form-label">*20 - Planta de Expedição</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="planta_de_expedicao_20">
            <option *ngFor="let planta of planta_de_expedicao" [value]="planta.nome">{{planta.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('planta_de_expedicao_20')?.errors?.required && formulario.get('planta_de_expedicao_20')?.touched"
            mensagem="Campo Obrigatório">
          </app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="centro_de_expedicao_20" class="col-sm-4 col-form-label">*20 - Centro de Expedição</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="centro_de_expedicao_20">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('centro_de_expedicao_20')?.errors?.required && formulario.get('centro_de_expedicao_20')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <hr class="line">
    </div>



    <div class="form-group row">
      <label for="frete" class="col-sm-4 col-form-label">Frete</label>
      <div class="col-sm-8">
        <label for="frete" class=' radio-inline'>
          <input type="radio" formControlName='frete' value="CIF" class='radio-btn'
            (change)="onFreteChange($event)">&nbsp;CIF
        </label>
        <label for="freteo" class='radio-inline'>
          <input type="radio" formControlName='frete' value='FOB' class='radio-btn'
            (change)="onFreteChange($event)">&nbsp;FOB
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().frete =='CIF'">
      <label for="cif_valor_do_frete" class="col-sm-4 col-form-label">*(CIF) Valor do Frete</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cif_valor_do_frete" mask="separator.2" thousandSeparator="."  >
        <small>Informar apenas números. Ex: 1.111.111</small>

        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('valor_total_do_pedido')?.errors?.required && formulario.get('valor_total_do_pedido')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="pedido_contem_defensivo_quimico" class="col-sm-4 col-form-label">Pedido contém Defensivo
        Químico</label>
      <div class="col-sm-8">
        <label for="pedido_contem_defensivo_quimico" class=' radio-inline'>
          <input type="radio" formControlName='pedido_contem_defensivo_quimico' value="Sim" class='radio-btn'>&nbsp;Sim
        </label>
        <label for="pedido_contem_defensivo_quimicoo" class='radio-inline'>
          <input type="radio" formControlName='pedido_contem_defensivo_quimico' value='Não' class='radio-btn'>&nbsp;Não
        </label>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="valor_total_do_pedido" class="col-sm-4 col-form-label">*Valor Total do Pedido</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_total_do_pedido" mask="separator.2" thousandSeparator="."  >
        <small>Informar apenas números. Ex: 1.111.111</small>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('valor_total_do_pedido')?.errors?.required && formulario.get('valor_total_do_pedido')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="forma_de_pagamento" class="col-sm-4 col-form-label">Forma de pagamento</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="forma_de_pagamento" (change)="verificaExibicaoCondParcelamento()">
          <option *ngFor="let forma_de_pagamento of formas_de_pagamento" [value]="forma_de_pagamento.forma">
            {{forma_de_pagamento.forma}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="exibeCondParcelamento">
      <label for="condicao_de_parcelamento" class="col-sm-4 col-form-label">*Condições de Parcelamento</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="condicao_de_parcelamento">
          <option *ngFor="let condicao of condicoes_de_parcelamento" [value]="condicao.nome">
            {{condicao.nome}}</option>
        </select>
        <app-mensagem class="col-form-label  mensagem-erro"
        *ngIf="formulario.get('condicao_de_parcelamento')?.errors?.required && formulario.get('condicao_de_parcelamento')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="!exibeCondParcelamento">
      <label for="vencimento_condicao_de_pagamento" class="col-sm-4 col-form-label">*Vencimento / Condição de pagamento</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="vencimento_condicao_de_pagamento">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('vencimento_condicao_de_pagamento')?.errors?.required && formulario.get('vencimento_condicao_de_pagamento')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="consultor_tecnico_de_vendas" class="col-sm-4 col-form-label">*Consultor Técnico de Vendas</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="consultor_tecnico_de_vendas">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('consultor_tecnico_de_vendas')?.errors?.required && formulario.get('consultor_tecnico_de_vendas')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="email_do_solicitante" class="col-sm-4 col-form-label">*E-mail do solicitante </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_solicitante">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_solicitante')?.errors?.required && formulario.get('email_do_solicitante')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_solicitante')?.errors?.email && formulario.get('email_do_solicitante')?.touched"
        mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail_secund_rio" class="col-sm-4 col-form-label">E-mail Secundário </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="e_mail_secund_rio">
      </div>
    </div>

    <div class="form-group row">
      <label for="data_do_pedido" class="col-sm-4 col-form-label">*Data do pedido</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_do_pedido">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('data_do_pedido')?.errors?.required && formulario.get('data_do_pedido')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="demanda_urgente" class="col-sm-4 col-form-label">*Demanda urgente?</label>
      <div class="col-sm-8">
        <label for="demanda_urgente" class=' radio-inline'>
          <input type="radio" formControlName='demanda_urgente' value="Sim" class='radio-btn'>&nbsp;Sim
          <!--(change)="onDemandaUrgenteChange($event)"-->
        </label>
        <label for="demanda_urgente" class='radio-inline'>
          <input type="radio" formControlName='demanda_urgente' value='Não' class='radio-btn'>&nbsp;Não
          <!-- (change)="onDemandaUrgenteChange($event)" -->
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('demanda_urgente')?.errors?.required && formulario.get('demanda_urgente')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="realizar_faturamento_imediato_nbs" class="col-sm-4 col-form-label">*Realizar faturamento imediato pelo NBS?</label>
      <div class="col-sm-8">
        <label for="realizar_faturamento_imediato_nbs" class=' radio-inline'>
          <input type="radio" formControlName='realizar_faturamento_imediato_nbs' value="Sim" class='radio-btn'>&nbsp;Sim
        </label>
        <label for="realizar_faturamento_imediato_nbs" class='radio-inline'>
          <input type="radio" formControlName='realizar_faturamento_imediato_nbs' value='Não' class='radio-btn'>&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('realizar_faturamento_imediato_nbs')?.errors?.required && formulario.get('realizar_faturamento_imediato_nbs')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="formulario.getRawValue().demanda_urgente=='Sim'">
      <label for="descreva_a_urgencia" class="col-sm-4 col-form-label">*Descreva urgência </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descreva_a_urgencia"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('descreva_a_urgencia')?.errors?.required && formulario.get('descreva_a_urgencia')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->


    <div class="form-group row">
      <label for="municipio_loja_nutrien" class="col-sm-4 col-form-label">*Município Loja Nutrien </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="municipio_loja_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('municipio_loja_nutrien')?.errors?.required && formulario.get('municipio_loja_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="estado_loja_nutrien" class="col-sm-4 col-form-label">Estado Loja Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="estado_loja_nutrien">
          <option *ngFor="let estado of estados" [value]="estado.nome">{{estado.nome}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label for="anexo" class="col-sm-4 col-form-label">Anexo
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexos.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of anexos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>



    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
        <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
            (click)="abrirPipePublico()">Envie por aqui.</span></p>
      </div> -->
</div>


<div class="footer">
  <app-footer link="customer-service"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p [style]="{'font-size': '20px','color': 'white'}">{{msgSpinner}}</p>
</ngx-spinner>
