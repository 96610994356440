<app-header></app-header>

<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="Banner" titulo="Recursos Humanos"
  subtitulo="O que faremos hoje?">
</app-nbs-banner>

<div class="container">


  <div class="wrapper">

    <!-- ABERTURA DE TICKET -->

    <div>
      <div class="box-title" (click)="showOptions('Abertura de Ticket')">
        <h3>Atendimento de Dúvidas</h3>
      </div>
      <div class='option-list' *ngIf="showTicketOptions" [@inOutAnimation]>

        <!-- ngIf="verificaPerfilTeste('funcionario')&&!verificaPerfilTeste('3d6dba46-648d-4b3d-87f7-ea0622a54d7f')
        &&!verificaPerfilTeste('4d351926-1c04-489a-b1e7-de3de3704726')&&!verificaPerfilTeste('93a92c9c-3001-4798-bb50-68a4947c9973')" -->

        <!-- <div class="box-option" routerLink="/rh/forms/medicina-e-seguranca-do-trabalho">
          <span>Afastamentos Médicos >15 (SHE)</span>
        </div> -->

        <div class="box-option" routerLink="/rh/forms/delegar-gestao" *ngIf="verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe')|| verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080')">
          <span>Delegar Gestão</span>
        </div>

        <!-- *ngIf="verificaPerfil('funcionario')&&!verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe')
                                                                                          &&!verificaPerfil('3d6dba46-648d-4b3d-87f7-ea0622a54d7f')" -->
        <!-- <div class="box-option" routerLink="/rh/forms/duvidas-sobre-beneficios">
          <span>Dúvidas sobre Benefícios</span>
        </div> -->

        <!-- <div class="box-option" routerLink="/rh/forms/duvidas-sobre-pagamentos">
          <span>Dúvidas sobre o Pagamento</span>
        </div> -->
        <!-- <div class="box-option" routerLink="/rh/forms/duvidas-sobre-o-ponto">
          <span>Dúvidas sobre o Ponto</span>
        </div> -->
        <div class="box-option" routerLink="/rh/forms/duvidas-sobre-informe-de-rendimentos">
          <span>Dúvidas sobre Informe de Rendimentos</span>
        </div>
        <div class="box-option" routerLink="/rh/forms/atendimento-imposto-de-renda">
          <span>Duvidas sobre Imposto de Renda-MP 1.171/2023</span>
        </div>
        <div class="box-option" routerLink="/rh/forms/atendimento-de-duvidas">
          <span>Atendimento de Dúvidas</span>
        </div>
      </div>
    </div>

    <!-- AUTOATENDIMENTO -->
    <div>
      <div class="box-title" (click)="showOptions('Autoatendimento')">
        <h3>Solicitações</h3>
      </div>
      <div class='option-list' *ngIf="showAutoAtendimentoOptions" [@inOutAnimation]>

        <!-- <div class="box-option" (click)="showAlteracaoBancarias($event)">
          <span>Alteração de Dados Bancários</span>
        </div>
        <div class="box-option2" *ngIf="!hideAlteracaoBancarias">
          <p routerLink="/rh/forms/alteracao-dados-bancarios">Alteração de Dados Bancários Salário</p>
          <p (click)="goToMub()">Alteração de Dados Bancários Reembolso</p>
        </div> -->

        <!-- <div class="box-option" (click)="goToLg()">
          <span>Alterações de Dados Pessoais</span>
        </div> -->

        <!-- <div class="box-option" (click)="showDesligamento($event)">
          <span>Rescisão</span>
        </div>
        <div class="box-option2" *ngIf="!hideDesligamento">
          <p (click)="goToLg()">Solicitar Rescisão</p>
          <p routerLink="/rh/forms/cancelamento-de-desligamento">Cancelar Rescisão</p>
        </div> -->

        <div class="box-option" (click)="goToLg()">
          <span>Inclusão e Alteração de Dependentes</span>
        </div>

        <!-- <div class="box-option" (click)="goToLg()">
          <span>Meus Benefícios</span>
        </div> -->

        <div class="box-option" (click)="showRecibos($event)">
          <span>Meus Recibos</span>
        </div>
        <div class="box-option2" *ngIf="!hideRecibos">
          <p (click)="goToLg()">Demonstrativo de Pagamento</p>
          <p (click)="buscarInformes()" data-toggle="modal" data-target="#modalInformeRendimentos">
            Informe de Rendimentos - 2022/2023
          </p>
          <!-- <p (click)="goToLg()">Informe de Rendimentos</p> -->
        </div>

        <div [ngClass]="{'showModal': showInforme}"></div>
        <div class="modal fade" data-backdrop="false" [hidden]="!showInforme" id="modalInformeRendimentos" tabindex="-1" role="dialog" aria-labelledby="modalInformeRendimentosTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header d-block">
                <h5 class="modal-title text-center">Informe de Rendimentos - 2022/2023</h5>
              </div>
              <div class="modal-body">
                <p class="text-center">Clique no botão ao lado do Informe de Rendimentos que deseja efetuar download</p>
                <div>
                  <table class="table table-responsive-sm">
                    <thead>
                      <tr>
                        <th scope="col">Empresa</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Documento</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let informe of urlsInforme">
                      <tr>
                        <td scope="row">
                          <span>
                            {{informe.nome_empresarial | titlecase}}
                          </span>
                        </td>
                        <td scope="row">
                          <span>
                            {{informe.cnpj}}
                          </span>
                        </td>
                        <td scope="row">
                          <button (click)="downloadInforme(informe)" class="btn buttonDownload">
                            Baixar
                            <i class="fa fa-download ml-2"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn" data-dismiss="modal" (click)="showInforme = !showInforme">Ok</button>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="box-option" (click)="goToLg()" *ngIf="verificaPerfil('3d6dba46-648d-4b3d-87f7-ea0622a54d7f')">
          <span>Movimentação</span>
        </div> -->

        <div class="box-option" (click)="hidePonto=!hidePonto" >
          <span>Ponto Eletrônico</span>
        </div>
        <div class="box-option2" *ngIf="!hidePonto">
          <p routerLink="/rh/forms/ponto-eletronico" *ngIf="verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe') || verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080')">Ajuste Retroativo de Ocorrências</p>
          <p (click)="goToLg()" *ngIf="!verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe') || !verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080')">Marcar Ponto</p>
          <p (click)="goToLg()" *ngIf="verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe') || verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080')">Tratativa de Marcações</p>
        </div>

        <div class="box-option" (click)="goToLg()">
          <span>Programar Férias</span>
        </div>

        <div class="box-option" 
        *ngIf="verificaPerfil('fde507b8-d3a5-4b19-a0d7-db582d8d918b')" routerLink="/rh/forms/ferias">
          <span>Programar Férias &lt; 40 Dias</span>
        </div>

        <div class="box-option" routerLink="/payment/forms/reembolso-beneficios" *ngIf="verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe') || verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080')">
          <span>Reembolso de Benefícios</span>
        </div>

        <!-- Comentar Admissao, Rescisao, Movimentaç~cao e Beneficios para enviar para prod -->

        <div class="box-option" routerLink="/rh/forms/admissao"  *ngIf="verificaPerfil('6cea77e1-8647-4e81-aa2e-2762012b7e72')">
          <span>Admissão</span>
        </div>
        <!-- <div class="box-option" routerLink="/rh/forms/rescisao">
          <span>Rescisão</span>
        </div> -->
        
        <!-- <div class="box-option" (click)="showMovimentacao($event)" *ngIf="verificaPerfil('3d6dba46-648d-4b3d-87f7-ea0622a54d7f')">
          <span>Movimentação</span>
        </div>
        <div class="box-option2" *ngIf="!hideMovimentacao">
          <p routerLink="/rh/forms/movimentacao-uo-posicao-cargo" >Criação de UO, Cargo e Posição</p>
          <p routerLink="/rh/forms/movimentacao">Movimentação (NBS)</p>
          <p (click)="goToLg()">Movimentação (LG)</p>
        </div> -->

        <div class="box-option" routerLink="/rh/forms/beneficios">
          <span>Benefícios </span>
          <br>
          <span>(VT e Previdência Privada)</span> <!--Seguro de Vida-->
        </div>

        <!-- <div class="box-option" routerLink="/payment/forms/reembolso-beneficios">
          <span>Reembolso de Benefícios TESTE</span>
        </div> -->

      </div>
    </div>

    <!-- CONECTA NUTRIEN -->
    <div>
      <div class="box-title" (click)="goToConectaNutrien()">
        <h3>Conecta Nutrien</h3>
      </div>
    </div>

    <!-- TALENT ACQUISITION -->

    <div *ngIf="verificaPerfil('49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe') || verificaPerfil('d6bb0353-f6e0-46c0-add2-74ec4e1b5080') || verificaPerfil('93a92c9c-3001-4798-bb50-68a4947c9973') ">
      <div class="box-title" (click)="goToNutrienGupy()">
        <h3>Talent Acquisition</h3>
      </div>
    </div>

    <!-- CARGOS E SALÁRIOS -->
    <div *ngIf="verificaPerfil('3d6dba46-648d-4b3d-87f7-ea0622a54d7f')|| verificaPerfil('4d351926-1c04-489a-b1e7-de3de3704726')">
      <div class="box-title" (click)="showOptions('Cargos e Salários')">
        <h3>Cargos e Salários</h3>
      </div>
      <div class='option-list' *ngIf="showCargosSalariosOptions" [@inOutAnimation]>
        <div class="box-option" (click)="goToLgCargosSalarios()">
          <span>Acesso ao sistema</span>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>