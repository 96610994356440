import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';
import { MsalBroadcastService,MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private msalService:MsalService,  private usuarioService: UsuarioService, private router:Router, private msalBroadcastService: MsalBroadcastService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean>{

    if(this.msalService.instance.getActiveAccount()== null){
      console.log("entrei aqui")
      this.router.navigate(['login'])
      return false
    }
    this.router.navigate([''])
    return true
  }
//     return this.msalBroadcastService.inProgress$
//     .pipe(
//       filter((status: InteractionStatus) => status === InteractionStatus.None),
//       switchMap(() => {
//         console.log("Entrei aqui 1")
//         if (this.msalService.instance.getAllAccounts().length > 0) {
//           console.log("Redirecionando para home")

//           console.log(this.msalService.instance.getAllAccounts())
//           this.router.navigate([''])
//           return of(true);
//         }

//         console.log("Redirecionando para login")
//         this.router.navigate(['/login']);
//         console.log("entrei aqui: ", this.msalService.instance.getAllAccounts())
//         return of(false);
//       })
//     );

// }
}
