<div class="content">
  
  <form [formGroup]="form" (submit)="_submit()">
    <ng-container  *ngFor="let field of _fields; index as i">
    <!-- Mensagem na tela para o usuário -->
    <div class="mensagem-topo" *ngIf=" (!!!field._disabled) && !!!field.hide && field.type == 'mensagem1' "
      [style]='{"margin-bottom":"0 !important"}'>
      <h6 *ngIf="!!field.typeMensagem1.titulo" class="text-center">{{field.typeMensagem1.titulo}}</h6>
      <p *ngIf="!!field.typeMensagem1.subTitulo" class="text-center">{{field.typeMensagem1.subTitulo}}</p>
    </div>

    <div class="mensagem-topo" *ngIf=" (!!!field._disabled) && !!!field.hide && field.type == 'mensagemHTML' "
      [style]='{"margin-bottom":"0 !important"}'>
      <p *ngIf="!!field.typeMensagemHTML" class="text-center">{{field.typeMensagemHTML}}</p>
    </div>

    <div class="form-group row"
      *ngIf="(!!!field._disabled) && !!!field.hide && field.type != 'submit' && field.type != 'buttonSubmit' && field.type != 'mensagem1' && field.type != 'mensagem1' && field.type != 'updateField' ">
      <label *ngIf="(!!field?.label || field) " for="{{field.name}}"
        class="col-sm-4 col-form-label">{{checkValidatorRequired(field)?
        "*": ""}}{{field?.label}}</label>

      <div class="col-sm-8">
        <!-- Input de text padrão -->
        <input *ngIf=" field.type == 'text' || field.type == 'nome-solicitante'" (focus)="focusInput(field.name, $event.target.value)"
          (blur)="blurInput(field.name, $event.target.value)" type="text" class="form-control"
          [value]="field.value" [formControlName]="field.name" [id]="field.name"
          [placeholder]="!!field.placeholder? field.placeholder : ''" [attr.disabled]="!!field?.disabled? true : null"
          autofocus>

        <!-- Input de number padrão -->
        <input *ngIf=" field.type == 'number' " (focus)="focusInput(field.name, $event.target.value)"
          (blur)="blurInput(field.name, $event.target.value)" [type]=field.type class="form-control"
          [value]="field.value" [formControlName]="field.name" [id]="field.name"
          [placeholder]="!!field.placeholder? field.placeholder : ''" [attr.disabled]="!!field?.disabled? true : null"
          autofocus>

        <!-- Input de currency padrão fix-->
        <input *ngIf=" field.type == 'currency' " (focus)="focusInput(field.name, $event.target.value)"
          (blur)="blurInput(field.name, $event.target.value)" type="text" class="form-control"
          [value]="field.value" [formControlName]="field.name" [id]="field.name" (keyup)="formataValorDecimal(field.name, $event.target.value)"
          [placeholder]="!!field.placeholder? field.placeholder : ''" [attr.disabled]="!!field?.disabled? true : null"
          autofocus>

        <!-- Textarea de texto padrão -->
        <textarea *ngIf=" field.type == 'textArea' " (focus)="focusInput(field.name, $event.target.value)"
        (input)="removeCaracteres($event)"
          (blur)="blurInput(field.name, $event.target.value)" class="form-control" [value]="field.value"
          [rows]="field.typeArea?.rows" [cols]="field.typeArea?.rows" [formControlName]="field.name" [id]="field.name"
          [attr.disabled]="!!field?.disabled? true : null" [placeholder]="!!field.placeholder? field.placeholder : ''" 
          autofocus></textarea>

        <!-- Input text de CPF com mascara -->
        <input formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'cpf' " mask="000.000.000-99"
          (focus)="focusInput(field.name, $event.target.value)" (blur)="blurInput(field.name, $event.target.value)"
          [attr.disabled]="!!field?.disabled? true : null" type="text" class="form-control"
          placeholder={{field.placeholder}} autofocus>

        <!-- Input text de CNPJ com mascara -->
        <input formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'cnpj' "
          mask="00.000.000/0000-00" (focus)="focusInput(field.name, $event.target.value)"
          (blur)="blurInput(field.name, $event.target.value)" type="text" class="form-control"
          [attr.disabled]="!!field?.disabled? true : null" placeholder={{field.placeholder}} autofocus>

        <!-- Input text de rg com mascara -->
        <input formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'rg' "
          (focus)="focusInput(field.name, $event.target.value)" (blur)="blurInput(field.name, $event.target.value)"
          [attr.disabled]="!!field?.disabled? true : null" type="text" class="form-control"
          placeholder={{field.placeholder}} autofocus>

        <!-- Input date -->
        <input [type]="field.type" formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'date' "
          [attr.disabled]="!!field?.disabled? true : null" class="form-control"
          (focus)="focusInput(field.name, $event.target.value)" (blur)="blurInput(field.name, $event.target.value)"
          placeholder={{field.placeholder}} autofocus>

        <!-- Input email -->
        <input formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'email' || field.type == 'email-solicitante' "
          (focus)="focusInput(field.name, $event.target.value)" (blur)="blurInput(field.name, $event.target.value)"
          [attr.disabled]="!!field?.disabled? true : null" type="text" class="form-control"
          placeholder={{field.placeholder}} autofocus>

        <!-- Input telefone -->
        <input formControlName="{{field.name}}" id="{{field.name}}" *ngIf=" field.type == 'tel' "
          mask="(00)0000-0000||(00)00000-0000" (focus)="focusInput(field.name, $event.target.value)"
          [attr.disabled]="!!field?.disabled? true : null" (blur)="blurInput(field.name, $event.target.value)"
          type="tel" class="form-control" placeholder={{field.placeholder}} autofocus>

        <!-- Input do tipo Checklist Horizontal -->
        <div *ngIf=" field.type == 'checklistHorizontal' && !!field?.select ">
          <label *ngFor="let option of field.select" for="{{field.name+option.value}}" class='radio-inline'>
            <input type="checkbox" [ngValue]="option" id="{{field.name+option.value}}" name="{{field.name}}"
              [attr.disabled]="!!option.disabled? true : null" (change)="_changeSelectChecklist($event, field._name)"
              class='radio-btn'>&nbsp;{{option.name}}</label>
        </div>

        <!-- Input do tipo Checklist Vertical -->
        <div *ngIf=" field.type == 'checklistVertical' && !!field?.select ">
          <label *ngFor="let option of field.select" for="{{field.name+option.value}}" class='col-form-label'
            [style]='{"width":"100%"}'>
            <input type="checkbox" [ngValue]="option" id="{{field.name+option.value}}" name="{{field.name}}"
              [attr.disabled]="!!option.disabled? true : null" (change)="_changeSelectChecklist($event, field._name)"
              class='radio-btn'>&nbsp;{{option.name}}</label>
        </div>

        <!-- Input do tipo Radio Horizontal -->
        <div *ngIf=" field.type == 'radioHorizontal' && !!field?.select ">
          <label *ngFor="let option of field.select" for="{{field.name+option.value}}" class='radio-inline'>
            <input type="radio" formControlName='{{field.name}}' [value]="option" id="{{field.name+option.value}}"
              name="{{field.name}}" [attr.disabled]="!!option.disabled? true : null"
              (ngModelChange)="_changeSelectOption($event, field._name)" class='radio-btn'>&nbsp;{{option.name}}</label>
        </div>

        <!-- Input do tipo Radio Vertical -->
        <div *ngIf=" field.type == 'radioVertical' && !!field?.select ">
          <label *ngFor="let option of field.select" for="{{field.name+option.value}}" class='col-form-label'
            [style]='{"width":"100%"}'>
            <input type="radio" formControlName='{{field.name}}' [value]="option" id="{{field.name+option.value}}"
              name="{{field.name}}" [attr.disabled]="!!option.disabled? true : null"
              (ngModelChange)="_changeSelectOption($event, field._name)" class='radio-btn'>&nbsp;{{option.name}}</label>
        </div>

        <!-- Select padrão sem pesquisa -->
        <select *ngIf="field.type == 'select' && (!!!field._disabled) && !!!field.hide && !!field.select"
          formControlName="{{field.name}}" class="custom-select"
          (ngModelChange)="_changeSelectOption($event, field._name)" autofocus>
          <ng-container *ngFor="let option of field.select">
            <option *ngIf="!option.hide" [ngValue]="option" [disabled]="!!option.disabled">
              {{ option.name }}
            </option>
          </ng-container>
        </select>

        <!-- Select com pesquisa -->
        <app-select-search *ngIf="field.type == 'selectSearch' && (!!!field._disabled) && !!!field.hide && !!field?.select"
          formControlName="{{field.name}}" [ordenar]="field?.selectOrdenar"
          [placeholder]="!!field?.placeholder? field?.placeholder : '' " autofocus>
        </app-select-search>

        <!-- Select com Connector -->
        <app-select-search *ngIf="field.type == 'connector' && (!!!field._disabled) && !!!field.hide " formControlName="{{field.name}}"
          [ordenar]="field?.selectOrdenar" [placeholder]="!!field?.placeholder? field?.placeholder : '' " autofocus>
        </app-select-search>

        <!-- Seletor de arquivos -->
        <app-select-file *ngIf="field.type == 'attachment' && (!!!field._disabled) && !!!field.hide" formControlName="{{field.name}}"
          autofocus></app-select-file>

          <div class="texto-ajuda" *ngIf="!!field?.descricao2">
            <ng-container *ngFor="let linha of field?.descricao2">
              <a *ngIf="!!linha?.url" [href]="linha.url" target="_blank">{{linha.text}} </a>
              <span *ngIf="!!!linha?.url">{{linha.text}}</span>
            </ng-container>
          </div>
          <div class="texto-ajuda" *ngIf="!!field?.descricao">
            <div *ngFor="let linha of field?.descricao">
              <a *ngIf="linha.type == 'url' " [href]="linha.value" target="_blank">{{linha.value}} </a>
              <span *ngIf="linha.type == 'text' ">{{linha.value}}</span>
            </div>
          </div>

        <!-- Mensagens de erro -->
        <span class="col-form-label mensagem-erro"
          *ngIf="form.get(field.name)?.errors?.required && form.get(field.name)?.touched">Campo Obrigatório</span>
        <span class="col-form-label mensagem-erro"
          *ngIf="form.get(field.name)?.errors?.email && form.get(field.name)?.touched">Formato de e-mail inválido</span>
        <span class="col-form-label mensagem-erro"
          *ngIf="form.get(field.name)?.errors?.cpf && form.get(field.name)?.touched">Número de cpf
          incorreto</span>
        <span class="col-form-label mensagem-erro"
          *ngIf="form.get(field.name)?.errors?.cnpj && form.get(field.name)?.touched">Número de CNPJ
          incorreto</span>
      </div>
    </div>

    <!-- Botão de enviar -->
    <div button class="form-group pt-1 col-lg-3 box-botao" *ngIf="field.type == 'buttonSubmit' && (!!!field._disabled) && !!!field.hide" >
      <button type="submit" class="btn botao"
        [ngStyle]="{'opacity': (!!form.invalid || !!field.disabled)? 0.45 : 1} ">{{!!field?.label? field.label :
        field.name}}</button>
    </div>

    <!-- <input type="submit" value={{field.name}} *ngIf="field.type == 'buttonSubmit' && (!!!field._disabled) && !!!field.hide "
      [disabled]="form.invalid || !!field.disabled" /> -->
    </ng-container>
  </form>
</div>