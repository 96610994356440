import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_ids } from './pipe_fields_ids';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { categorias, moedas, motivos_de_urgencia, subcategorias, tipo_de_operacao, tipo_de_lancamento, formas_pagamento, setores, quantidade_pedidos } from './index';
import { tipos_de_notas_lancamento } from './tipo_de_nota_lancamento';
import { empresas_lancamento } from './empresas_lancamento';
import { quantidade_de_itens_lancamento } from './quantidade_de_itens_lancamento';
import { lista_cnpjs_empresa_nutrien_lancamento } from './lista_cnpjs_empresa_nutrien_lancamento';
import { pipe_fields_ids_lancamento } from './pipe_fields_ids_lancamento';


@Component({
  selector: 'app-ipe-2',
  templateUrl: './ipe.component-2.html',
  styleUrls: ['./ipe.component-2.css']
})
export class IpeComponent_2 implements OnInit {
  formulario!: UntypedFormGroup;

  email: string = ''
  
  listaEmitentes: any[] = [];
  listaEmitentesOriginal: any[] = [];
  listaTomadores: any[] = [];
  listaTomadoresOriginal: any[] = [];
  // listaNumContratos: any[] = [];
  // listaAprovadores: any[] = [];
  
  categorias = categorias;
  moedas = moedas;
  motivos_de_urgencia = motivos_de_urgencia;
  subcategorias = subcategorias;
  tipo_de_operacao = tipo_de_operacao;
  tipo_de_lancamento = tipo_de_lancamento;
  formas_pagamento = formas_pagamento;
  setores = setores;
  quantidade_pedidos = quantidade_pedidos;

  caracteristicasChecked:any[] = []

  arquivosNpo: any[] = [];
  arquivosNpoUrl: any[] = [];

  arquivosXml: any[] = [];
  arquivosXmlUrl: any[] = [];

  arquivosNf: any[] = [];
  arquivosNfUrl: any[] = [];

  arquivosNfBoleto: any[] = [];
  arquivosNfBoletoUrl: any[] = [];


  showMotivoUrgencia: boolean = false;
  showNumContrato: boolean = false;
  showAprovador: boolean = false;
  
  urgenteChecked: boolean = false;
  antecipacaoChecked: boolean = false;
  jurosmultasChecked: boolean = false;
  custoPlanejamentoChecked: boolean = false;
  debitoPosteriorChecked: boolean = false;
  importacaoChecked: boolean = false;
  princTodasOpcoesChecked: boolean = false;

  exibirListaEmitentes: boolean = false;
  emitenteSelecionado: any;
  exibeErroEmitente: boolean = false;

  exibirListaTomadores: boolean = false;
  tomadorSelecionado: any;
  exibeErroTomador: boolean = false;

  
  error_counter: number = 0;
  erro: boolean = false


  // Campos Lançamento de Notas


  formulario_lancamento!: UntypedFormGroup;
  arquivosDeRateio: any[] = [];
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []
  documentacaoSuporte: any[] = [];
  nfProdutoOriginal: any[] = []

  listaDeItensDaPo: any[] = [];
  riscoSacado: boolean = false;
  notaComRateios: boolean = false;
  tiposDeNotas = tipos_de_notas_lancamento;
  empresas = empresas_lancamento
  quantidadeDeItens = quantidade_de_itens_lancamento
  listaEmpresasCnpjs = lista_cnpjs_empresa_nutrien_lancamento

  notaComRateioDeValoresString : string = ''
  riscoSacadoString: string = ''

  arquivosDeRateioUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl:any[]=[]
  documentacaoSuporteUrl: any[] = []
  nfProdutoOriginalUrl: any[] = []
  listaDeItensDaPoUrl: any [] = []

  emailApi: string = ''
  userNameApi: string = ''

  custoComplementar: boolean = false;
  debitoPosterior: boolean = false
  informacoesComplementaresPo:any[] = []

 





  @ViewChild('checkUrgente') checkUrgente: any;
  @ViewChild('checkContrato') checkContrato: any;
  @ViewChild('checkAprovador') checkAprovador: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService)
    { }


  ngOnInit(): void {
   
    this.formulario = this.formBuilder.group({
      caracteristicas: ['', []],
      motivo_de_urg_ncia: ['', []],
      descricao_outros: ['', []],
      // n_mero_do_contrato: ['', [Validators.required]],
      // aprovador: ['', [Validators.required]],
      e_mail_do_requisitante: ['', [Validators.required]], //Validators.email
      categoria: ['', [Validators.required]],
      subcategoria_po: ['', []],
      tipo_de_opera_o: ['', []],
      marque_todas_as_op_es_que_se_aplicam: ['', []],
      tipo_de_lan_amento: ['', []],
      n_mero_do_processo: ['', []],
      boleto_para_pagamento: ['', []],
      forma_de_pagamento: ['', []],
      folha_servicos: ['', []],
      favorecido: ['', []],
      valor_do_fi: ['', []],
      setor: ['', []],
      data_de_emiss_o: ['', [Validators.required]],
      data_de_vencimento: ['', [Validators.required]],
      moeda: ['', [Validators.required]],
      numero_da_nota_fiscal: ['', [Validators.required]],
      valor_da_fatura: ['', [Validators.required]],
      descri_o_breve: ['', []],
      quantidade_pedidos: ['', []],

      numero_pedido_1: ['', []],
      numero_linha_pedido_1: ['', []],
      numero_pedido_2: ['', []],
      numero_linha_pedido_2: ['', []],
      numero_pedido_3: ['', []],
      numero_linha_pedido_3: ['', []],
      numero_pedido_4: ['', []],
      numero_linha_pedido_4: ['', []],
      numero_pedido_5: ['', []],
      numero_linha_pedido_5: ['', []],
      numero_pedido_6: ['', []],
      numero_linha_pedido_6: ['', []],
      numero_pedido_7: ['', []],
      numero_linha_pedido_7: ['', []],
      numero_pedido_8: ['', []],
      numero_linha_pedido_8: ['', []],
      numero_pedido_9: ['', []],
      numero_linha_pedido_9: ['', []],
      numero_pedido_10: ['', []],
      numero_linha_pedido_10: ['', []],
      lista_pedidos: ['', []],

      anexo_xml: ['', []],
      anexo_nota_fiscal_ou_boleto: ['', []],
      anexo_nota_fiscal_documento_boleto_se_houver: ['', []],
      anexo_npo_modelo_excel: ['', []],

      inputSearchEmitente: ['', []],
      inputSearchTomador: ['', []]
    })


    this.formulario_lancamento = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      risco_sacado: ['', []],
      qual_a_quantidade_de_cte:['',[]],
      tipo_de_nota: ['', [Validators.required]],
      conta_contabil:['',[]],
      documento_do_fornecedor: ['', [Validators.required]],
      cpf_do_fornecedor: ['', []],
      cnpj_fornecedor: ['', []],
      nome_do_fornecedor: ['', [Validators.required]],
      cnpj_da_empresa_nutrien: ['', [Validators.required]],
      nome_empresa_nutrien: ['', [Validators.required]],
      nro_nota: ['', [Validators.required]],
      data_de_envio_de_nf: ['', [Validators.required]],
      valor_total_da_nf: ['', [Validators.required]],
      nota_com_rasteio_de_valores: [, []],
      arquivo_de_rateio: ['', []],
      data_de_vencimento_da_nota: ['', [Validators.required]],
      departamento: ['', []],
      numero_de_pedido: ['', []],
      quantidade_de_itens_do_pedido: ['', []],
      numero_do_item_da_po:['',[]],
      lista_de_itens_da_po:['',[]],
      centro_de_custo: ['', []],
      anexo_nota_ou_boleto: ['', [Validators.required]],
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
      anexo_documentacao_suporte: ['', []],
      nota_para_pagamento_fora_da_politica: ['', []],
      nota_para_pagamento_urgente: ['', []],
      motivo_da_excecao: ['',[]],
      observacao: ['', []],
      informacoes_complementares_da_po:['',[]],

      nf_do_produto_original:['',[]],
      tipo_de_custo_planejado:['',[]],
      n_mero_de_cte:['',[]],
      opex_e_capex:['',[]],
      tipo_de_importa_o:['',[]],
      tipo_de_pagamento:['',[]],

      n_mero_afe:['',[]]

    })

    // this.getUserEmail();
    this.getListaEmitente();
    this.getListaTomador();
  }


  enviarFormulario() {

    if(this.formulario.getRawValue().subcategoria_po !== 'Frete '){
      this.spinner.show()

      const criarBody = async () => {
  
        await this.getPipesUrlArquivoNpo(this.arquivosNpo);
        if(this.arquivosNpo.length >0){
          while (this.arquivosNpo.length != this.arquivosNpoUrl.length) {
            console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }
  
        if(this.arquivosNpo.length>0){
          var arquivosNpoUrl2 = ''
          arquivosNpoUrl2 = '['
          for(var i = 0; i < this.arquivosNpoUrl.length ; i++){
            arquivosNpoUrl2 = arquivosNpoUrl2+'"'+this.arquivosNpoUrl[i]+'",'
          }
          arquivosNpoUrl2 = arquivosNpoUrl2.slice(0, -1)
          arquivosNpoUrl2 = arquivosNpoUrl2+"]"
  
        }else{
          arquivosNpoUrl2 =''
        }
  
        await this.getPipesUrlArquivoXml(this.arquivosXml);
        if(this.arquivosXml.length >0){
          while (this.arquivosXml.length != this.arquivosXmlUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.arquivosXml.length>0){
          var arquivosXmlUrl2 = ''
           arquivosXmlUrl2 = '['
          for(var i = 0; i < this.arquivosXmlUrl.length ; i++){
             arquivosXmlUrl2 =  arquivosXmlUrl2+'"'+this.arquivosXmlUrl[i]+'",'
          }
           arquivosXmlUrl2 = arquivosXmlUrl2.slice(0, -1)
           arquivosXmlUrl2 = arquivosXmlUrl2+"]"
  
        }else{
          arquivosXmlUrl2 =''
        }
  
        await this.getPipesUrlArquivoNf(this.arquivosNf);
        if(this.arquivosNf.length >0){
          while (this.arquivosNf.length != this.arquivosNfUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.arquivosNf.length>0){
          var arquivosNfUrl2 = ''
           arquivosNfUrl2 = '['
          for(var i = 0; i < this.arquivosNfUrl.length ; i++){
             arquivosNfUrl2 =  arquivosNfUrl2+'"'+this.arquivosNfUrl[i]+'",'
          }
           arquivosNfUrl2 = arquivosNfUrl2.slice(0, -1)
           arquivosNfUrl2 = arquivosNfUrl2+"]"
  
        }else{
          arquivosNfUrl2 =''
        }
  
        await this.getPipesUrlArquivoNfBoleto(this.arquivosNfBoleto);
        if(this.arquivosNfBoleto.length >0){
          while (this.arquivosNfBoleto.length != this.arquivosNfBoletoUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.arquivosNfBoleto.length>0){
          var arquivosNfBoletoUrl2 = ''
           arquivosNfBoletoUrl2 = '['
          for(var i = 0; i < this.arquivosNfBoletoUrl.length ; i++){
             arquivosNfBoletoUrl2 =  arquivosNfBoletoUrl2+'"'+this.arquivosNfBoletoUrl[i]+'",'
          }
           arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2.slice(0, -1)
           arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2+"]"
  
        }else{
          arquivosNfBoletoUrl2 =''
        }
  
        let caracteristicas = '['+this.caracteristicasChecked+']';
        let motivo_urgencia = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_de_urg_ncia);
        let descricao_outros = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_outros);
        let email_requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_do_requisitante) + '@nutrien.com';
        let categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().categoria);
        let subcategoria_po = this.trataInput.removeCaracters(this.formulario.getRawValue().subcategoria_po);
        let tipo_operacao = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_opera_o);
        let marque_todas_opcoes_aplicam = this.formulario.getRawValue().marque_todas_as_op_es_que_se_aplicam == true ? 'Serviço ou Material é um Ativo' : '';
        let tipo_lancamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_lan_amento);
        let numero_processo = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_do_processo);
        let boleto_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().boleto_para_pagamento);
        let forma_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().forma_de_pagamento);
        let folha_servicos = this.trataInput.removeCaracters(this.formulario.getRawValue().folha_servicos);
        let favorecido = this.trataInput.removeCaracters(this.formulario.getRawValue().favorecido);
        let valor_fi = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_fi);
        let setor = this.trataInput.removeCaracters(this.formulario.getRawValue().setor);
        let data_emissao = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_emiss_o);
        let data_vencimento = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento);
        let moeda = this.trataInput.removeCaracters(this.formulario.getRawValue().moeda);
        let numero_nota_fiscal = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_da_nota_fiscal);
        let valor_fatura = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_da_fatura);
        let descricao_breve = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_breve);
        let quantidade_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_pedidos);
        let numero_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_1);
        let numero_linha_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_1);
        let numero_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_2);
        let numero_linha_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_2);
        let numero_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_3);
        let numero_linha_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_3);
        let numero_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_4);
        let numero_linha_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_4);
        let numero_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_5);
        let numero_linha_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_5);
        let numero_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_6);
        let numero_linha_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_6);
        let numero_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_7);
        let numero_linha_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_7);
        let numero_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_8);
        let numero_linha_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_8);
        let numero_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_9);
        let numero_linha_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_9);
        let numero_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_10);
        let numero_linha_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_10);
        let lista_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().lista_pedidos);
  
        let pipe_fields = pipe_fields_ids
        let fields_values = [
          caracteristicas,
          motivo_urgencia,
          descricao_outros,
          this.emitenteSelecionado.cardId,
          this.tomadorSelecionado.cardId,
          email_requisitante,
          categoria,
          subcategoria_po,
          tipo_operacao,
          tipo_lancamento,
          numero_processo,
          arquivosNfUrl2,
          arquivosXmlUrl2,
          arquivosNfBoletoUrl2,
          arquivosNpoUrl2,
          marque_todas_opcoes_aplicam,
          data_emissao,
          data_vencimento,
          moeda,
          numero_nota_fiscal,
          valor_fatura,
          boleto_pagamento,
          forma_pagamento,
          folha_servicos,
          favorecido,
          valor_fi,
          setor,
          descricao_breve,
          quantidade_pedidos,
          numero_pedido_1,
          numero_linha_pedido_1,
          numero_pedido_2,
          numero_linha_pedido_2,
          numero_pedido_3,
          numero_linha_pedido_3,
          numero_pedido_4,
          numero_linha_pedido_4,
          numero_pedido_5,
          numero_linha_pedido_5,
          numero_pedido_6,
          numero_linha_pedido_6,
          numero_pedido_7,
          numero_linha_pedido_7,
          numero_pedido_8,
          numero_linha_pedido_8,
          numero_pedido_9,
          numero_linha_pedido_9,
          numero_pedido_10,
          numero_linha_pedido_10,
          lista_pedidos
        ]
        let pipe_id = "302691440"
        
  
        this.pipefy.criaBody(pipe_fields,fields_values,pipe_id)
  
        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
          // console.log(res)
          this.spinner.hide()
  
          if (res.data.result_creation_card.id != undefined) {
            let card_id = res.data.result_creation_card.id
            Swal.fire({
              title: 'Chamado aberto com Sucesso!',
              text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
              // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
            })
            this.resetform();
          }
  
          if (res.data.result_creation_card.errors != undefined) {
            var erro = res.data.result_creation_card.errors[0].message
            console.log(erro);
            Swal.fire({
              title: 'Desculpe!',
              html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>',
              text: erro,
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
  
            this.arquivosXmlUrl = [];
            this.arquivosNfUrl = [];
            this.arquivosNfBoletoUrl = [];
            this.arquivosNpoUrl = [];
  
  
  
            let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
              'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.email, this.userInfo.getVersion()]
  
            var pipe_fields_incidentes_values2 = []
            for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
              pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
            }
            // console.log(pipe_fields_incidentes_values2)
  
            let pipe_incidentes_id = '302487533'
  
            this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
              // console.log(res)
            })
          }
        },(error: any) => {
          this.spinner.hide()
          // console.log(error.status)
          console.log(error)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosXmlUrl = [];
            this.arquivosNfUrl = [];
            this.arquivosNfBoletoUrl = [];
            this.arquivosNpoUrl = [];
          }
        })
  
      }
      criarBody()
    }


    if(this.formulario.getRawValue().subcategoria_po == 'Frete '){
      this.spinner.show()

      const criarBody = async () => {
  
        await this.getPipesUrlArquivoRateio(this.arquivosDeRateio);
        if(this.arquivosDeRateio.length >0){
          while (this.arquivosDeRateio.length != this.arquivosDeRateioUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }
  
        if(this.arquivosDeRateio.length>0){
          var arquivosDeRateioUrl2 = ''
          arquivosDeRateioUrl2 = '['
          for(var i = 0; i < this.arquivosDeRateioUrl.length ; i++){
            arquivosDeRateioUrl2 = arquivosDeRateioUrl2+'"'+this.arquivosDeRateioUrl[i]+'",'
          }
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2.slice(0, -1)
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2+"]"
  
        }else{
          arquivosDeRateioUrl2 =''
        }
  
        await this.getPipesUrlNota(this.notas);
        // console.log(this.notas)
        // console.log(this.notas.length)
        // console.log(this.notasUrl)
  
        if(this.notas.length >0){
          while (this.notas.length != this.notasUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
  
        if(this.notas.length>0){
          var notasUrl2 = ''
           notasUrl2 = '['
          for(var i = 0; i < this.notasUrl.length ; i++){
             notasUrl2 =  notasUrl2+'"'+this.notasUrl[i]+'",'
          }
           notasUrl2 = notasUrl2.slice(0, -1)
           notasUrl2 = notasUrl2+"]"
  
        }else{
          notasUrl2 =''
        }
  
        await this.getPipesUrlBoleto(this.boletos);
        // console.log(this.boletos)
        // console.log(this.boletos.length)
        // console.log(this.boletosUrl)
  
        if(this.boletos.length >0){
          while (this.boletos.length != this.boletosUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.boletos.length>0){
          var boletosUrl2 = ''
           boletosUrl2 = '['
          for(var i = 0; i < this.boletosUrl.length ; i++){
             boletosUrl2 =  boletosUrl2+'"'+this.boletosUrl[i]+'",'
          }
           boletosUrl2 = boletosUrl2.slice(0, -1)
           boletosUrl2 = boletosUrl2+"]"
  
        }else{
          boletosUrl2 =''
        }
  
        await this.getPipesUrlXML(this.xml);
        // console.log(this.xml)
        // console.log(this.xml.length)
        // console.log(this.xmlUrl)
  
        if(this.xml.length >0){
          while (this.xml.length != this.xmlUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.xml.length>0){
          var xmlUrl2 = ''
           xmlUrl2 = '['
          for(var i = 0; i < this.xmlUrl.length ; i++){
             xmlUrl2 =  xmlUrl2+'"'+this.xmlUrl[i]+'",'
          }
           xmlUrl2 = xmlUrl2.slice(0, -1)
           xmlUrl2 = xmlUrl2+"]"
  
        }else{
          xmlUrl2 =''
        }
  
  
        await this.getPipesUrlDocumentacaoSuporte(this.documentacaoSuporte);
        if(this.documentacaoSuporte.length >0){
          while (this.documentacaoSuporte.length != this.documentacaoSuporteUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.documentacaoSuporte.length>0){
          var documentacaoSuporteUrl2 = ''
           documentacaoSuporteUrl2 = '['
          for(var i = 0; i < this.documentacaoSuporteUrl.length ; i++){
             documentacaoSuporteUrl2 =  documentacaoSuporteUrl2+'"'+this.documentacaoSuporteUrl[i]+'",'
          }
           documentacaoSuporteUrl2 = documentacaoSuporteUrl2.slice(0, -1)
           documentacaoSuporteUrl2 = documentacaoSuporteUrl2+"]"
  
        }else{
          documentacaoSuporteUrl2 =''
        }
  
        await this.getPipesUrlNfProdutoOriginal(this.nfProdutoOriginal);
        if(this.nfProdutoOriginal.length >0){
          while (this.nfProdutoOriginal.length != this.nfProdutoOriginalUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.nfProdutoOriginal.length>0){
          var nfProdutoOriginalUrl2 = ''
           nfProdutoOriginalUrl2 = '['
          for(var i = 0; i < this.nfProdutoOriginalUrl.length ; i++){
             nfProdutoOriginalUrl2 =  nfProdutoOriginalUrl2+'"'+this.nfProdutoOriginalUrl[i]+'",'
          }
           nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2.slice(0, -1)
           nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2+"]"
  
        }else{
          nfProdutoOriginalUrl2 =''
        }
  
        await this.getPipesUrlListaItensDaPo(this.listaDeItensDaPo);
        if(this.listaDeItensDaPo.length >0){
          while (this.listaDeItensDaPo.length != this.listaDeItensDaPoUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
          }
        }
  
        if(this.listaDeItensDaPo.length>0){
          var listaDeItensDaPoUrl2 = ''
           listaDeItensDaPoUrl2 = '['
          for(var i = 0; i < this.listaDeItensDaPoUrl.length ; i++){
             listaDeItensDaPoUrl2 =  listaDeItensDaPoUrl2+'"'+this.listaDeItensDaPoUrl[i]+'",'
          }
           listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2.slice(0, -1)
           listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2+"]"
  
        }else{
          listaDeItensDaPoUrl2 =''
        }
  
  
      let nome = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().email)
      let risco_sacado = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().risco_sacado)
      let tipo_de_nota = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().tipo_de_nota)
      let qual_a_quantidade_de_cte = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().qual_a_quantidade_de_cte)
      let conta_contabil = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().conta_contabil)
      let documento_do_fornecedor = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().documento_do_fornecedor)
      let cpf_do_fornecedor = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().cpf_do_fornecedor)
      let cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().cnpj_fornecedor)
      let nome_do_fornecedor = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nome_do_fornecedor)
      let cnpj_da_empresa_nutrien = this.formulario_lancamento.getRawValue().cnpj_da_empresa_nutrien.replaceAll('.', '').replaceAll("/", "").replaceAll("-", "");
      let nome_empresa_nutrien = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nome_empresa_nutrien)
      let nro_nota = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nro_nota)
      let data_de_envio_de_nf = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().data_de_envio_de_nf)
      let valor_total_da_nf = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().valor_total_da_nf)
      let data_de_vencimento_da_nota = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().data_de_vencimento_da_nota))
      let departamento = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().departamento)
      let numero_de_pedido = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().numero_de_pedido)
      let quantidade_de_itens_do_pedido = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().quantidade_de_itens_do_pedido)
      let numero_do_item_da_po = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().numero_do_item_da_po)
  
      let centro_de_custo = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().centro_de_custo)
      let nota_para_pagamento_fora_da_politica = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nota_para_pagamento_fora_da_politica)
      let nota_para_pagamento_urgente = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().nota_para_pagamento_urgente)
      let motivo_da_excecao = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().motivo_da_excecao)
      let observacao = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().observacao)
      let informacoes_complementares_da_po = '['+this.informacoesComplementaresPo+']'
  
      let tipo_de_custo_planejado = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().tipo_de_custo_planejado)
      let n_mero_de_cte = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().n_mero_de_cte)
      let opex_e_capex = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().opex_e_capex)
      let tipo_de_importa_o = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().tipo_de_importa_o)
      let tipo_de_pagamento = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().tipo_de_pagamento)
  
      let n_mero_afe = this.trataInput.removeCaracters(this.formulario_lancamento.getRawValue().n_mero_afe)
  
  
      // console.log('departamento', departamento)
  
      let pipe_fields = pipe_fields_ids_lancamento
      let fields_values = [nome,email,this.riscoSacadoString,tipo_de_nota,qual_a_quantidade_de_cte,conta_contabil,documento_do_fornecedor,cpf_do_fornecedor,
        cnpj_fornecedor,nome_do_fornecedor,cnpj_da_empresa_nutrien,nome_empresa_nutrien,nro_nota,data_de_envio_de_nf,valor_total_da_nf,
        this.notaComRateioDeValoresString,arquivosDeRateioUrl2,data_de_vencimento_da_nota,departamento,numero_de_pedido,
        quantidade_de_itens_do_pedido,numero_do_item_da_po,listaDeItensDaPoUrl2,centro_de_custo,notasUrl2,documentacaoSuporteUrl2,
        nota_para_pagamento_fora_da_politica,nota_para_pagamento_urgente,motivo_da_excecao,observacao,informacoes_complementares_da_po,boletosUrl2,xmlUrl2,nfProdutoOriginalUrl2,
        tipo_de_custo_planejado,n_mero_de_cte,opex_e_capex,tipo_de_importa_o,tipo_de_pagamento,n_mero_afe]

      let pipe_id = "302487539"

      //  "302983357"
  
      this.pipefy.criaBody(pipe_fields,fields_values,pipe_id)
  
  
      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()
  
        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform_lancamento()
          this.resetform()
        }
  
        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
  
  
  
          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi, this.userInfo.getVersion()]
  
          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)
  
          let pipe_incidentes_id = '302487533'
  
          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      },(error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
        }
      })
  
      }
      criarBody()
    }

   
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  saveArquivoNpo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNpo.push(files[i])
    }
  }

  removeFileArquivoNpo(file: File) {
    const index: number = this.arquivosNpo.indexOf(file);
    this.arquivosNpo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNpo.length == 0){
      this.formulario.controls['anexo_npo_modelo_excel'].markAsTouched()
      this.formulario.get('anexo_npo_modelo_excel')?.setValue('')
    }
  }

  async getPipesUrlArquivoNpo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.arquivosNpoUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNpoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoXml(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosXml.push(files[i])
    }
  }

  removeFileArquivoXml(file: File) {
    const index: number = this.arquivosXml.indexOf(file);
    this.arquivosXml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosXml.length == 0){
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlArquivoXml(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.arquivosXmlUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosXmlUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNf(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNf.push(files[i])
    }
  }

  removeFileArquivoNf(file: File) {
    const index: number = this.arquivosNf.indexOf(file);
    this.arquivosNf.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNf.length == 0){
      this.formulario.controls['anexo_nota_fiscal_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_ou_boleto')?.setValue('')
    }
  }

  async getPipesUrlArquivoNf(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        console.log(fileContent)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.arquivosNfUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNfUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNfBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNfBoleto.push(files[i])
    }
  }

  removeFileArquivoNfBoleto(file: File) {
    const index: number = this.arquivosNfBoleto.indexOf(file);
    this.arquivosNfBoleto.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNfBoleto.length == 0){
      this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_documento_boleto_se_houver')?.setValue('')
    }
  }

  async getPipesUrlArquivoNfBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.arquivosNfBoletoUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNfBoletoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  // getUserEmail() {
  //   this.email = this.userInfo.getUserEmail();
  //   this.formulario.get('e_mail_do_requisitante')?.setValue(this.email);
  // }

  getListaEmitente() {
    this.spinner.show();
    let key = 'Emitente';    
    this.pipefy.buscaTabelasMasterdata(key, '').subscribe((res: any) => {
      
      let retorno = res.data.masterdata;

      if(retorno) {
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaEmitentes.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.listaEmitentesOriginal = this.listaEmitentes;
        this.spinner.hide();
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Emitente/Forncecedor. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.spinner.hide();
      }

    },
    (error: any) => {
      this.spinner.hide();
      console.log(error)
    })
  }

  getListaTomador() {
    let key = 'Tomador';
    this.pipefy.buscaTabelasMasterdata(key, '').subscribe((res: any) => {
      
      let retorno = res.data.masterdata;

      if(retorno) {
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaTomadores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2].replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            cardId: retorno[index].card_id
          })
        }
        this.listaTomadoresOriginal = this.listaTomadores;
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Tomador. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
  },

  (error: any) => {
      console.log(error)
  })
  }

  changeCheckTodas(event: any) {
    this.urgenteChecked = event;
    this.antecipacaoChecked = event;
    this.jurosmultasChecked = event;
    this.custoPlanejamentoChecked = event;
    this.debitoPosteriorChecked = event;
    this.importacaoChecked = event;
    this.princTodasOpcoesChecked = event;
    if (event) {
      this.changeCheckUrgente(true)
      this.changeCheckAntec(true);
      this.changeCheckJuros(true);
      this.changecustoPlanejamento(true);
      this.changeDebitoPosterior(true);
      this.changeImportacao(true);
      this.inserirNoArray('Todos')
      // this.changeCheckContrato(true)
      // this.changeCheckAprovador(true)
    } else {
      this.changeCheckUrgente(false);
      this.changeCheckAntec(false);
      this.changeCheckJuros(false);
      this.changecustoPlanejamento(false);
      this.changeDebitoPosterior(false);
      this.changeImportacao(false);
      this.inserirNoArray('Nenhum')
      // this.changeCheckContrato(false)
      // this.changeCheckAprovador(false)
    }
  }

  changeCheckAntec(event: any){
    this.antecipacaoChecked = event;
  }

  changeCheckJuros(event: any){
    this.jurosmultasChecked = event;
  }

  changecustoPlanejamento(event: any){
    this.custoPlanejamentoChecked = event;
  }

  changeDebitoPosterior(event: any){
    this.debitoPosteriorChecked = event;
  }

  changeImportacao(event: any){
    this.importacaoChecked = event;
  }

  changeCheckUrgente(event: any) {
    this.showMotivoUrgencia = event;
    this.urgenteChecked = event;
    if (this.showMotivoUrgencia) {
      this.formulario.controls['motivo_de_urg_ncia']?.setValue('');
      this.formulario.controls['motivo_de_urg_ncia']?.setValidators([Validators.required]);
      this.formulario.controls['motivo_de_urg_ncia']?.updateValueAndValidity();

    } else {
      this.formulario.controls['motivo_de_urg_ncia'].clearValidators();
      this.formulario.controls['motivo_de_urg_ncia'].updateValueAndValidity;
      this.formulario.controls['motivo_de_urg_ncia'].setValue('');

    }    
  }

  // changeCheckContrato(event: any) {
  //   this.showNumContrato = event;
  //   if (this.showNumContrato) {
  //     this.formulario.get('n_mero_do_contrato')?.reset;
  //     this.formulario.get('n_mero_do_contrato')?.markAsUntouched;
  //   } else {
  //     this.formulario.get('n_mero_do_contrato')?.clearValidators;
  //     this.formulario.get('n_mero_do_contrato')?.setValue('');
  //     this.formulario.get('n_mero_do_contrato')?.markAsUntouched;
  //     this.formulario.get('n_mero_do_contrato')?.updateValueAndValidity();
  //   }
  // }

  // changeCheckAprovador(event: any) {
  //   this.showAprovador = event;
  //   if (this.showAprovador) {
  //     this.formulario.get('aprovador')?.reset;
  //     this.formulario.get('aprovador')?.markAsUntouched;
  //   } else {
  //     this.formulario.get('aprovador')?.clearValidators;
  //     this.formulario.get('aprovador')?.setValue('');
  //     this.formulario.get('aprovador')?.markAsUntouched;
  //     this.formulario.get('aprovador')?.updateValueAndValidity();
  //   }
  // }

  changeMotivoUrgencia(event:any) {
    this.formulario.controls['descricao_outros']?.setValue('');
    if (event.target.value == 'Outros') {
      this.formulario.controls['descricao_outros']?.setValidators([Validators.required]);
      this.formulario.controls['descricao_outros']?.updateValueAndValidity();
    } else {
      this.formulario.controls['descricao_outros'].clearValidators();
      this.formulario.controls['descricao_outros'].updateValueAndValidity;
      this.formulario.controls['descricao_outros'].setErrors(null);
    }
  }

  inserirNoArray(event:any){
    if (event == 'Todos') {
      this.caracteristicasChecked = ['"Urgente"', '"Antecipação"', '"Juros e Multas"', '"Custo de Planejamento"', '"Débito Posterior"', '"Importação"'];

    } else if (event == 'Nenhum') {
      this.caracteristicasChecked = [];

    } else if(this.formulario.getRawValue().caracteristicas){
      this.caracteristicasChecked.push('"'+event+'"')
      this.princTodasOpcoesChecked = this.caracteristicasChecked.length == 5 ? true : false;

    } else if(!this.formulario.getRawValue().caracteristicas){
      let eventEdit = `"${event}"`;
      this.caracteristicasChecked = this.caracteristicasChecked.filter(item => item !== eventEdit)
      this.princTodasOpcoesChecked = false;
    }
  }

  cliqueSelectEmitente() {
    this.listaEmitentes = this.listaEmitentesOriginal;
    this.exibirListaEmitentes = !this.exibirListaEmitentes;
    this.formulario.get('inputSearchEmitente')?.setValue('');
    this.exibeErroEmitente = this.emitenteSelecionado ? false : true;
    this.exibirListaTomadores = false;
  }

  cliqueSelectTomador() {
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = !this.exibirListaTomadores;
    this.formulario.get('inputSearchTomador')?.setValue('');
    this.exibeErroTomador = this.tomadorSelecionado ? false : true;
    this.exibirListaEmitentes = false;
  }

  searchEmitente() {
    this.listaEmitentes = !this.listaEmitentes.length ? this.listaEmitentesOriginal : this.listaEmitentes;
    let filtro = this.formulario.getRawValue().inputSearchEmitente;
    if (filtro.length >= 5) {
      this.spinner.show();
      this.listaEmitentes = [];
      
      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        
        let retorno = res.data.masterdata;
  
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaEmitentes.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error)
      })
    } else {
      this.listaEmitentes = this.listaEmitentesOriginal;
    }
  }

  searchTomador() {
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = this.formulario.getRawValue().inputSearchTomador;
    if (filtro.length >= 5) {
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        
        let retorno = res.data.masterdata;
  
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaTomadores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error)
      })
      
    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  verificaContemNumeros(str: string) {
    return /^\d+$/.test(str);
  }

  selecionaEmitente(item: any) {
    this.emitenteSelecionado = item;
    this.listaEmitentes = this.listaEmitentesOriginal;
    this.exibirListaEmitentes = false;
    this.exibeErroEmitente = false;
  }

  selecionaTomador(item: any) {
    this.tomadorSelecionado = item;
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = false;
    this.exibeErroTomador = false;
  }

  changeCategoria(event:any) {
    this.zerarTodosAnexos();
    switch (event.target.value) {
      case 'PO':
        this.categSelecionadaPO();
        this.changeTipoLancamento('');
        break;
      case 'NPO':
        this.categSelecionadaNPO();
        this.changeTipoLancamento('');
        break
      default:
        this.categSelecionadaVazio();
        this.changeTipoLancamento('');
        break;
    }
  }

  onTipoChange() {
    let quantidadeDeItens = this.formulario.getRawValue().quantidade_pedidos
    this.clearValidatorsDetalhes()
    this.limpaValoresProdutos(quantidadeDeItens);
    
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        break;

      case '2':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        break;

      case '3':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        break;
          
      case '4':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        break;
        
      case '5':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        break;
        
      case '6':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        break;
        
      case '7':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        break;
        
      case '8':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        break;
        
      case '9':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        break;
        
      case '10':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        break;
        
      case 'Mais que 10':
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;
    }
  }

  clearValidatorsDetalhes(){
    this.formulario.controls['numero_pedido_1']?.clearValidators();
    this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_1']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_2']?.clearValidators();
    this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_2']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_3']?.clearValidators();
    this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_3']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_4']?.clearValidators();
    this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_4']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_5']?.clearValidators();
    this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_5']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_6']?.clearValidators();
    this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_6']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_7']?.clearValidators();
    this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_7']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_8']?.clearValidators();
    this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_8']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_9']?.clearValidators();
    this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_9']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_10']?.clearValidators();
    this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_10']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();

    this.formulario.controls['lista_pedidos']?.clearValidators();
    this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
  }

  limpaValoresProdutos(quantidadeDeItens: any) {
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
      
      case '2':
        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '3':
        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '4':
        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '5':
        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '6':
        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '7':
        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '8':
        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '9':
        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '10':
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case 'Mais que 10':
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
        
        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
    }
  }

  categSelecionadaVazio() {
    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.zerarTodosAnexos();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValue('');
    this.formulario.controls['favorecido']?.clearValidators();
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValue('');
    this.formulario.controls['valor_do_fi']?.clearValidators();
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  categSelecionadaNPO() {
    this.formulario.controls['tipo_de_lan_amento']?.setValidators([Validators.required]);
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['anexo_npo_modelo_excel']?.setValidators([Validators.required]);
    this.formulario.controls['anexo_npo_modelo_excel']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValidators([Validators.required]);
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValidators([Validators.required]);
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();
  }

  categSelecionadaPO() {
    this.formulario.controls['subcategoria_po']?.setValidators([Validators.required]);
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValue('');
    this.formulario.controls['favorecido']?.clearValidators();
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValue('');
    this.formulario.controls['valor_do_fi']?.clearValidators();
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  changeSubcategoria(event: any) {
    this.zerarTodosCamposSubcategoria();
    this.zerarTodosAnexos();
    switch (event.target.value) {
      case 'Materiais':
        this.formulario.controls['tipo_de_opera_o']?.setValidators([Validators.required]);
        this.formulario.controls['tipo_de_opera_o']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.updateValueAndValidity();
        break;
      case 'Serviços':
        this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
        this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

        this.formulario.controls['folha_servicos']?.setValidators([Validators.required]);
        this.formulario.controls['folha_servicos']?.updateValueAndValidity();

        this.formulario.controls['quantidade_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['quantidade_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break
      case 'Boleto ':
      case 'Nota de Débito ':
      case 'Telefonia ':
      case 'Água ':
      case 'Energia ':
      case 'Fatura ':
      case 'Frete ':
      case 'Aluguel ':
      case 'Correios':
      case 'Outros ':
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break
      default:
        break;
    }
  }
  
  changeTipoLancamento(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Depósito Judicial':
        this.formulario.controls['n_mero_do_processo']?.setValidators([Validators.required]);
        this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['n_mero_do_processo']?.setValue('');
        this.formulario.controls['n_mero_do_processo']?.clearValidators();
        this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
        break;
    }
  }
  
  zerarTodosAnexos() {
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValue('');

    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValue('');

    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValue('');

    this.formulario.controls['anexo_npo_modelo_excel'].clearValidators();
    this.formulario.controls['anexo_npo_modelo_excel'].updateValueAndValidity;
    this.formulario.controls['anexo_npo_modelo_excel']?.setValue('');
    
    this.arquivosXml = [];
    this.arquivosXmlUrl = [];
    
    this.arquivosNf = [];
    this.arquivosNfUrl = [];
    
    this.arquivosNfBoleto = [];
    this.arquivosNfBoletoUrl = [];
    
    this.arquivosNpo = [];
    this.arquivosNpoUrl = [];
  }

  zerarTodosCamposSubcategoria() {
    this.formulario.controls['tipo_de_opera_o'].setValue('');
    this.formulario.controls['tipo_de_opera_o'].clearValidators();
    this.formulario.controls['tipo_de_opera_o'].updateValueAndValidity();
    this.formulario.controls['tipo_de_opera_o'].setErrors(null);

    this.formulario.controls['marque_todas_as_op_es_que_se_aplicam'].setValue('');

    this.formulario.controls['boleto_para_pagamento'].setValue('');
    this.formulario.controls['boleto_para_pagamento'].clearValidators();
    this.formulario.controls['boleto_para_pagamento'].updateValueAndValidity();
    this.formulario.controls['boleto_para_pagamento'].setErrors(null);

    this.formulario.controls['folha_servicos'].setValue('');
    this.formulario.controls['folha_servicos'].clearValidators();
    this.formulario.controls['folha_servicos'].updateValueAndValidity();
    this.formulario.controls['folha_servicos'].setErrors(null);

    this.formulario.controls['quantidade_pedidos'].setValue('');
    this.formulario.controls['quantidade_pedidos'].clearValidators();
    this.formulario.controls['quantidade_pedidos'].updateValueAndValidity();
    this.formulario.controls['quantidade_pedidos'].setErrors(null);

    this.formulario.controls['numero_pedido_1'].setValue('');
    this.formulario.controls['numero_pedido_1'].clearValidators();
    this.formulario.controls['numero_pedido_1'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_1'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_1'].setValue('');
    this.formulario.controls['numero_linha_pedido_1'].clearValidators();
    this.formulario.controls['numero_linha_pedido_1'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_1'].setErrors(null);

    this.formulario.controls['numero_pedido_2'].setValue('');
    this.formulario.controls['numero_pedido_2'].clearValidators();
    this.formulario.controls['numero_pedido_2'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_2'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_2'].setValue('');
    this.formulario.controls['numero_linha_pedido_2'].clearValidators();
    this.formulario.controls['numero_linha_pedido_2'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_2'].setErrors(null);

    this.formulario.controls['numero_pedido_3'].setValue('');
    this.formulario.controls['numero_pedido_3'].clearValidators();
    this.formulario.controls['numero_pedido_3'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_3'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_3'].setValue('');
    this.formulario.controls['numero_linha_pedido_3'].clearValidators();
    this.formulario.controls['numero_linha_pedido_3'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_3'].setErrors(null);

    this.formulario.controls['numero_pedido_4'].setValue('');
    this.formulario.controls['numero_pedido_4'].clearValidators();
    this.formulario.controls['numero_pedido_4'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_4'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_4'].setValue('');
    this.formulario.controls['numero_linha_pedido_4'].clearValidators();
    this.formulario.controls['numero_linha_pedido_4'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_4'].setErrors(null);

    this.formulario.controls['numero_pedido_5'].setValue('');
    this.formulario.controls['numero_pedido_5'].clearValidators();
    this.formulario.controls['numero_pedido_5'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_5'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_5'].setValue('');
    this.formulario.controls['numero_linha_pedido_5'].clearValidators();
    this.formulario.controls['numero_linha_pedido_5'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_5'].setErrors(null);

    this.formulario.controls['numero_pedido_6'].setValue('');
    this.formulario.controls['numero_pedido_6'].clearValidators();
    this.formulario.controls['numero_pedido_6'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_6'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_6'].setValue('');
    this.formulario.controls['numero_linha_pedido_6'].clearValidators();
    this.formulario.controls['numero_linha_pedido_6'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_6'].setErrors(null);

    this.formulario.controls['numero_pedido_7'].setValue('');
    this.formulario.controls['numero_pedido_7'].clearValidators();
    this.formulario.controls['numero_pedido_7'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_7'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_7'].setValue('');
    this.formulario.controls['numero_linha_pedido_7'].clearValidators();
    this.formulario.controls['numero_linha_pedido_7'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_7'].setErrors(null);

    this.formulario.controls['numero_pedido_8'].setValue('');
    this.formulario.controls['numero_pedido_8'].clearValidators();
    this.formulario.controls['numero_pedido_8'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_8'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_8'].setValue('');
    this.formulario.controls['numero_linha_pedido_8'].clearValidators();
    this.formulario.controls['numero_linha_pedido_8'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_8'].setErrors(null);

    this.formulario.controls['numero_pedido_9'].setValue('');
    this.formulario.controls['numero_pedido_9'].clearValidators();
    this.formulario.controls['numero_pedido_9'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_9'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_9'].setValue('');
    this.formulario.controls['numero_linha_pedido_9'].clearValidators();
    this.formulario.controls['numero_linha_pedido_9'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_9'].setErrors(null);

    this.formulario.controls['numero_pedido_10'].setValue('');
    this.formulario.controls['numero_pedido_10'].clearValidators();
    this.formulario.controls['numero_pedido_10'].updateValueAndValidity();
    this.formulario.controls['numero_pedido_10'].setErrors(null);

    this.formulario.controls['numero_linha_pedido_10'].setValue('');
    this.formulario.controls['numero_linha_pedido_10'].clearValidators();
    this.formulario.controls['numero_linha_pedido_10'].updateValueAndValidity();
    this.formulario.controls['numero_linha_pedido_10'].setErrors(null);

    this.formulario.controls['lista_pedidos'].setValue('');
    this.formulario.controls['lista_pedidos'].clearValidators();
    this.formulario.controls['lista_pedidos'].updateValueAndValidity();
    this.formulario.controls['lista_pedidos'].setErrors(null);

  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().e_mail_do_requisitante
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if(!regex.test(input))
      input = (input.substring(0, (input.length-1)))
    this.formulario.controls['e_mail_do_requisitante']?.setValue(input);
  }

  resetform() {
    console.log("entrei aqui")
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched();

    this.showMotivoUrgencia = false;
    this.showNumContrato = false;
    this.showAprovador = false;
    this.urgenteChecked = false;
    this.antecipacaoChecked = false;
    this.jurosmultasChecked = false;
    this.custoPlanejamentoChecked = false;
    this.debitoPosteriorChecked = false;
    this.importacaoChecked = false;

    this.formulario.controls['motivo_de_urg_ncia'].clearValidators();
    this.formulario.controls['motivo_de_urg_ncia'].updateValueAndValidity;
    this.formulario.controls['motivo_de_urg_ncia'].setErrors(null);

    this.formulario.controls['descricao_outros'].clearValidators();
    this.formulario.controls['descricao_outros'].updateValueAndValidity;
    this.formulario.controls['descricao_outros'].setErrors(null);

    this.formulario.controls['tipo_de_lan_amento'].clearValidators();
    this.formulario.controls['tipo_de_lan_amento'].updateValueAndValidity;
    this.formulario.controls['tipo_de_lan_amento'].setErrors(null);


    this.formulario.controls['favorecido'].clearValidators();
    this.formulario.controls['favorecido'].updateValueAndValidity;
    this.formulario.controls['favorecido'].setErrors(null);


    this.formulario.controls['valor_do_fi'].clearValidators();
    this.formulario.controls['valor_do_fi'].updateValueAndValidity;
    this.formulario.controls['valor_do_fi'].setErrors(null);


    this.formulario.controls['subcategoria_po'].clearValidators();
    this.formulario.controls['subcategoria_po'].updateValueAndValidity;
    this.formulario.controls['subcategoria_po'].setErrors(null);

    
    this.zerarTodosCamposSubcategoria();


    this.formulario.controls['n_mero_do_processo'].clearValidators();
    this.formulario.controls['n_mero_do_processo'].updateValueAndValidity;
    this.formulario.controls['n_mero_do_processo'].setErrors(null);


    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].setErrors(null);


    this.formulario.controls['anexo_npo_modelo_excel'].clearValidators();
    this.formulario.controls['anexo_npo_modelo_excel'].updateValueAndValidity;
    this.formulario.controls['anexo_npo_modelo_excel'].setErrors(null);


    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].setErrors(null);


    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].setErrors(null);


    this.emitenteSelecionado = '';
    this.tomadorSelecionado = '';

    
    this.arquivosXml = [];
    this.arquivosXmlUrl = [];
    
    this.arquivosNf = [];
    this.arquivosNfUrl = [];
    
    this.arquivosNfBoleto = [];
    this.arquivosNfBoletoUrl = [];
    
    this.arquivosNpo = [];
    this.arquivosNpoUrl = [];

    console.log(this.formulario.getRawValue().motivo_de_urg_ncia)


    
    
    // this.getUserEmail();
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/_rj6N2Po', '_blank')
  }






  // Funcões Lançamento de Notas


  saveArquivoRateio(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosDeRateio.push(files[i])
    }
  }

  removeFileArquivoRateio(file: File) {
    const index: number = this.arquivosDeRateio.indexOf(file);
    this.arquivosDeRateio.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosDeRateio.length == 0){
      this.formulario_lancamento.controls['arquivo_de_rateio'].markAsTouched()
      this.formulario_lancamento.get('arquivo_de_rateio')?.setValue('')
    }
  }

  async getPipesUrlArquivoRateio(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.arquivosDeRateioUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosDeRateioUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveAnexoNota(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.notas.push(files[i])
    }
  }

  removeFileNota(file: File) {
    const index: number = this.notas.indexOf(file);
    this.notas.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.notas.length == 0){
      this.formulario_lancamento.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario_lancamento.get('anexo_nota_ou_boleto')?.setValue('')

    }
  }

  async getPipesUrlNota(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.notasUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.notasUrl = []
          }

        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveAnexoBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.boletos.push(files[i])
    }
  }

  removeFileBoleto(file: File) {
    const index: number = this.boletos.indexOf(file);
    this.boletos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.boletos.length == 0){
      this.formulario_lancamento.controls['anexo_boleto'].markAsTouched()
      this.formulario_lancamento.get('anexo_boleto')?.setValue('')
    }
  }

  async getPipesUrlBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.boletosUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.boletosUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveAnexoXML(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.xml.push(files[i])
    }
  }

  removeFileXML(file: File) {
    const index: number = this.xml.indexOf(file);
    this.xml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.xml.length == 0){
      this.formulario_lancamento.controls['anexo_xml'].markAsTouched()
      this.formulario_lancamento.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlXML(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.xmlUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.xmlUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }












  saveAnexoDocumentacaoSuporte(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.documentacaoSuporte.push(files[i])
    }
  }

  removeFilDocumentacaoSuporte(file: File) {
    const index: number = this.documentacaoSuporte.indexOf(file);
    this.documentacaoSuporte.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.documentacaoSuporte.length == 0){
      this.formulario_lancamento.controls['anexo_documentacao_suporte'].markAsTouched()
      this.formulario_lancamento.get('anexo_documentacao_suporte')?.setValue('')
    }
  }

  async getPipesUrlDocumentacaoSuporte(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.documentacaoSuporteUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentacaoSuporteUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }



  saveAnexoNfProdutoOriginal(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.nfProdutoOriginal.push(files[i])
    }
  }


  removeFileNfProdutoOriginal(file: File) {
    const index: number = this.nfProdutoOriginal.indexOf(file);
    this.nfProdutoOriginal.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.nfProdutoOriginal.length == 0){
      this.formulario_lancamento.controls['nfProdutoOriginal'].markAsTouched()
      this.formulario_lancamento.get('nfProdutoOriginal')?.setValue('')
    }
  }

  async getPipesUrlNfProdutoOriginal(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.nfProdutoOriginalUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.nfProdutoOriginalUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveListaDeItensDaPO(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.listaDeItensDaPo.push(files[i])
    }
  }

  removeListaDeItensDaPO(file: File) {
    const index: number = this.listaDeItensDaPo.indexOf(file);
    this.listaDeItensDaPo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.listaDeItensDaPo.length == 0){
      this.formulario_lancamento.controls['lista_de_itens_da_po'].markAsTouched()
      this.formulario_lancamento.get('lista_de_itens_da_po')?.setValue('')
    }
  }

  async getPipesUrlListaItensDaPo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.listaDeItensDaPoUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.listaDeItensDaPoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  inserirNoArray_lancamento(event:any){
    // console.log(this.formulario_lancamento.getRawValue().informacoes_complementares_da_po)
    let valor:any = event.target.value
    // console.log(valor)
    if(this.formulario_lancamento.getRawValue().informacoes_complementares_da_po){
      let valorTratado = '"'+valor+'"'
    this.informacoesComplementaresPo.push(valorTratado)
    // console.log(this.informacoesComplementaresPo)
    }
    if(!this.formulario_lancamento.getRawValue().informacoes_complementares_da_po){
      const index: number = this.informacoesComplementaresPo.indexOf(valor);
      this.informacoesComplementaresPo.splice(index, 1)
      // console.log(this.informacoesComplementaresPo)
    }
  }

  notaComRateiosValue(event:any){
    var valor = event.target
    if(valor.checked){
      this.notaComRateioDeValoresString = 'Sim'
      // console.log(this.notaComRateioDeValoresString)
    }else{
      this.notaComRateioDeValoresString = ''
      // console.log('vazio',this.notaComRateioDeValoresString)
    }
  }

  riscoSacadoValue(event:any){
    var valor = event.target
    if(valor.checked){
      this.riscoSacadoString = 'Sim'
      // console.log(this.riscoSacadoString)
    }else{
      this.riscoSacadoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }
  }


  onDocumentoChange(event: any) {
    var valor = event.target.value
    // console.log(valor)
    if (valor == "CPF") {
      // console.log("cpf checked")
      this.formulario_lancamento.get('cpf_do_fornecedor')?.setValidators([Validators.required])
      this.formulario_lancamento.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario_lancamento.get('cnpj_fornecedor')?.clearValidators()
      this.formulario_lancamento.get('cnpj_fornecedor')?.updateValueAndValidity();

    }

    if(valor == "CNPJ"){
      // console.log("cnpj checked")
      this.formulario_lancamento.get('cnpj_fornecedor')?.setValidators([Validators.required])
      this.formulario_lancamento.get('cnpj_fornecedor')?.updateValueAndValidity();

      this.formulario_lancamento.get('cpf_do_fornecedor')?.clearValidators()
      this.formulario_lancamento.get('cpf_do_fornecedor')?.updateValueAndValidity();
    }
  }


  onEmpresaChange(event: any) {
    let empresa = event.target.value
    // console.log(empresa)

    if (empresa === 'Agrichem') {
      this.formulario_lancamento.get('departamento')?.setValidators([Validators.required])
      this.formulario_lancamento.get('departamento')?.updateValueAndValidity();
    }

    if (empresa === '' || empresa === 'TecAgro (FAG)' || empresa === 'TecAgro (SGL)' || empresa === 'TecAgro (FCR)' || empresa === 'Nutrien') {
      this.formulario_lancamento.get('departamento')?.clearValidators()
      this.formulario_lancamento.get('departamento')?.updateValueAndValidity();
    }
  }

  onCnpjEmpresaNutrienChange(event: any) {
    let cnpjSelecionado = event.target.value;
    let empresaSelecionada = this.listaEmpresasCnpjs.filter(nome => nome.cnpj === cnpjSelecionado);
    this.formulario_lancamento.get('nome_empresa_nutrien')?.setValue(empresaSelecionada[0].nome);
  }

  onPagamentoUrgenteChange(event:any){
    let pagamentoUrgente = event.target.value
    // console.log("Pagamento Urgente : ", pagamentoUrgente)

    if (pagamentoUrgente === 'Sim'){
      this.formulario_lancamento.get('motivo_da_excecao')?.setValidators([Validators.required])
      this.formulario_lancamento.get('motivo_da_excecao')?.updateValueAndValidity();
    }

    if(pagamentoUrgente === 'Não'){
      this.formulario_lancamento.get('motivo_da_excecao')?.clearValidators()
      this.formulario_lancamento.get('motivo_da_excecao')?.updateValueAndValidity();
    }
  }

  onTipoDeNotaChange(event:any){
    // console.log(event)
    let tipo_de_nota = event.target.value
    // console.log(tipo_de_nota)

    // if(tipo_de_nota === 'Reembolso' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias'){
      if(tipo_de_nota === 'Jurídico' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias/Imposto/Taxas'){

      // console.log("entrei aqui REEMBOLSO")
      this.formulario_lancamento.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario_lancamento.get('conta_contabil')?.updateValueAndValidity()
      this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }
    // if(tipo_de_nota === 'Frete' || tipo_de_nota === 'CTE') {
      if(tipo_de_nota === 'Frete/CTE') {

        // console.log("entrei aqui FRETE")
        this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.setValidators([Validators.required])
        this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
        this.formulario_lancamento.get('conta_contabil')?.clearValidators()
      this.formulario_lancamento.get('conta_contabil')?.updateValueAndValidity()
      }

    // if(tipo_de_nota == '' ||
    //    tipo_de_nota == 'Pagamento serviço PO' ||
    //    tipo_de_nota == 'Pagamento material PO com NFE e XML' ||
    //    tipo_de_nota == 'Pagamento material PO com NFE' ||
    //    tipo_de_nota == 'Pagamento material sem PO' ||
    //    tipo_de_nota == 'Boleto' ||
    //    tipo_de_nota == 'Materia Prima' ||
    //    tipo_de_nota == 'Sem valor fiscal' ||
    //    tipo_de_nota == 'Uso e consumo' ||
    //    tipo_de_nota == 'Importação' ||
    //    tipo_de_nota == 'Ativo fixo (AFE)' ||
    //    tipo_de_nota == 'Retorno Armazem' ||
    //    tipo_de_nota == 'Outras NFs sem PO' ||
    //    tipo_de_nota == 'DANFE' ||
    //    tipo_de_nota == 'NFPS' ||
    //    tipo_de_nota == 'DANFE (DIFAL)' ||
    //    tipo_de_nota == 'Fatura' ||
    //    tipo_de_nota == 'Imposto' ||
    //    tipo_de_nota == 'Cartório' ||
    //    tipo_de_nota == 'Conta de consumo' ){

        if(tipo_de_nota == '' ||
        tipo_de_nota == 'Ativo fixo (AFE)' ||
        tipo_de_nota == 'Benefícios' ||
        tipo_de_nota == 'Cartório' ||
        tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
        tipo_de_nota == 'Correio' ||
        tipo_de_nota == 'Escrituração - Não gera pagamento' ||
        tipo_de_nota == 'Importação' ||
        tipo_de_nota == 'Incentivo - Doação' ||
        tipo_de_nota == 'Industrialização' ||
        tipo_de_nota == 'Jurídico' ||
        tipo_de_nota == 'Material (DANFE)' ||
        tipo_de_nota == 'Nota de Crédito' ||
        tipo_de_nota == 'Outras NFs sem PO' ||
        tipo_de_nota == 'Sem valor fiscal' ||
        tipo_de_nota == 'Serviço' 
        ){  
      
      // console.log("oi")
      this.formulario_lancamento.get('conta_contabil')?.clearValidators()
      this.formulario_lancamento.get('conta_contabil')?.updateValueAndValidity()
      this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario_lancamento.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }

    if(tipo_de_nota == 'Aluguel'){
      Swal.fire({
        // title: 'Você será redirecionado!',
        text: 'Para contas que não estiverem em nome da Nutrien, favor, anexar o contrato de referência no campo anexo documentação suporte.',
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600',
      }).then(()=>{
        this.formulario_lancamento.get('anexo_documentacao_suporte')?.setValidators([Validators.required])
        this.formulario_lancamento.get('anexo_documentacao_suporte')?.updateValueAndValidity();
      })
    }

    if(tipo_de_nota != 'Aluguel'){
      this.formulario_lancamento.get('anexo_documentacao_suporte')?.clearValidators();
      this.formulario_lancamento.get('anexo_documentacao_suporte')?.updateValueAndValidity();
    }
  }

  onDataDeVencimentoChange(event: any){
    var data = event.target.value
    Swal.fire({
      // title: 'Atenção',
      width: 600,
      html: '<h6 class="fs-20 color-light-green fw-900">Pagamentos Gerais  (Fornecedores)</h6><br>'+
            '<h6 class="fs-20 color-light-green fw-900">Criação de solicitação de pagamento:</h6>'+
            '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Mínimo de 10 dias de antecedência às datas de pagamento.</p>'+
            '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>É requerido o envio imediato dos documentos após seu recebimento.</p><br>'+
            '<h6 class="fs-20 color-light-green fw-900">Condição de Pagamento</h6>'+
            '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Estabelecido no pedido de compra a contar da data de emissão do documento.</p><br>'+
            '<h6 class="fs-20 color-light-green fw-900">Efetivação do Pagamento</h6>'+
            '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Atendidas as condições acima e o processo de abertura no NBS/pedido não apresente inconsistências, ocorrerá na próxima data de pagamento (15 ou 25) (feriado ou fim de semana no próximo dia útil).</p>',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
      padding:'10px 30px 30px',
      iconColor:'#88D600'
    })
  }


  resetform_lancamento() {
    for (let field in this.formulario_lancamento.controls) {
      this.formulario_lancamento.controls[field].setValue('');
    }
    this.formulario_lancamento.markAsUntouched()

    this.arquivosDeRateio = [];
    this.notas = [];
    this.boletos = []
    this.xml = []
    this.documentacaoSuporte = [];
    this.listaDeItensDaPo = [];

    this.arquivosDeRateioUrl = [];
    this.notasUrl = []
    this.boletosUrl = []
    this.xmlUrl = []
    this.documentacaoSuporteUrl = [];
    this.listaDeItensDaPoUrl = [];

    this.formulario_lancamento.get('cnpj_fornecedor')?.clearValidators()
    this.formulario_lancamento.get('cnpj_fornecedor')?.updateValueAndValidity();
    this.formulario_lancamento.get('cpf_do_fornecedor')?.clearValidators()
    this.formulario_lancamento.get('cpf_do_fornecedor')?.updateValueAndValidity();
    this.formulario_lancamento.get('departamento')?.clearValidators()
    this.formulario_lancamento.get('departamento')?.updateValueAndValidity();

    this.notaComRateioDeValoresString = ''
    this.riscoSacadoString = ''

    this.emailApi = this.userInfo.getUserEmail()
    this.userNameApi = this.userInfo.getName()

    this.formulario_lancamento.get('email')?.setValue(this.emailApi)
    this.formulario_lancamento.get('nome')?.setValue(this.userNameApi)


    if (this.formulario_lancamento.getRawValue().email != '') {
      // this.formulario_lancamento.controls['email'].disable()
    }
    if (this.formulario_lancamento.getRawValue().email == undefined) {
      // this.formulario_lancamento.controls['email'].enable()
      this.formulario_lancamento.get('email')?.setValue('')

    }

    if (this.formulario_lancamento.getRawValue().nome != '') {
      // this.formulario_lancamento.controls['nome'].disable()
    }
    if (this.formulario_lancamento.getRawValue().nome == undefined) {
      // this.formulario_lancamento.controls['nome'].enable()
      this.formulario_lancamento.get('nome')?.setValue('')
    }
  }

  abrirPipePublico_lancamento() {
    window.open('https://app.pipefy.com/public/form/I0t4K1gr', '_blank')
  }


}
