<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Recursos Humanos" subtitulo="Autoatendimento">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Cancelamento de Desligamento"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="cpf" class="col-sm-4 col-form-label">*CPF</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cpf" mask='000.000.000-99'>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf')?.errors?.required && formulario.get('cpf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_do_funcionario" class="col-sm-4 col-form-label">*Nome do funcionário </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_funcionario">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_funcionario')?.errors?.required && formulario.get('nome_do_funcionario')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="justificativa" class="col-sm-4 col-form-label">*Justificativa </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="justificativa"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('justificativa')?.errors?.required && formulario.get('justificativa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="codigo_do_processo" class="col-sm-4 col-form-label">*Código do Processo </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="codigo_do_processo">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_do_processo')?.errors?.required && formulario.get('codigo_do_processo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="data_de_admissao" class="col-sm-4 col-form-label">*Data de Admissão </label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_admissao">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_admissao')?.errors?.required && formulario.get('data_de_admissao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="cnpj_empregador" class="col-sm-4 col-form-label">*CNPJ Empregador </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cnpj_empregador"  mask="00.000.000/0000-00">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_empregador')?.errors?.required && formulario.get('cnpj_empregador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="empresa">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="unidade" class="col-sm-4 col-form-label">*Unidade </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="unidade">
        <small>Com CNPJ caso seja diferente do empregador</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('unidade')?.errors?.required && formulario.get('unidade')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="local_de_trabalho" class="col-sm-4 col-form-label">*Local de Trabalho </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="local_de_trabalho">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('local_de_trabalho')?.errors?.required && formulario.get('local_de_trabalho')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="cargo" class="col-sm-4 col-form-label">*Cargo (Função / Posição) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cargo">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cargo')?.errors?.required && formulario.get('cargo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="setor" class="col-sm-4 col-form-label">*Setor </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="setor">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('setor')?.errors?.required && formulario.get('setor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="centro_de_custo" class="col-sm-4 col-form-label">*Centro de Custo </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="centro_de_custo">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('centro_de_custo')?.errors?.required && formulario.get('centro_de_custo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="cbo" class="col-sm-4 col-form-label">*CBO </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cbo">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cbo')?.errors?.required && formulario.get('cbo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="gestor" class="col-sm-4 col-form-label">*Gestor</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="gestor">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('gestor')?.errors?.required && formulario.get('gestor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="tipo_de_exame" class="col-sm-4 col-form-label">*Tipo de Exame</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="tipo_de_exame">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_exame')?.errors?.required && formulario.get('tipo_de_exame')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="email_do_colaborador" class="col-sm-4 col-form-label">*E-mail do colaborador </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_colaborador">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_colaborador')?.errors?.required && formulario.get('email_do_colaborador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_colaborador')?.errors?.email && formulario.get('email_do_colaborador')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="celular_do_colaborador" class="col-sm-4 col-form-label">*Celular do Colaborador</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="celular_do_colaborador" mask="(00)0000-0000||(00)00000-0000">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('celular_do_colaborador')?.errors?.required && formulario.get('celular_do_colaborador')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_de_demissao" class="col-sm-4 col-form-label">*Data de Demissão</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_demissao">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_demissao')?.errors?.required && formulario.get('data_de_demissao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row">
      <label for="rg" class="col-sm-4 col-form-label">*RG</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="rg">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('rg')?.errors?.required && formulario.get('rg')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_de_nascimento" class="col-sm-4 col-form-label">*Data de Nascimento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_nascimento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_nascimento')?.errors?.required && formulario.get('data_de_nascimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="/recursos-humanos/home"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde...</p>
</ngx-spinner>
