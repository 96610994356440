import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_ids } from './pipe_fields_ids';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { categorias, moedas, motivos_de_urgencia, subcategorias, tipo_de_operacao, tipo_de_lancamento, formas_pagamento, setores, quantidade_pedidos } from './index';


@Component({
  selector: 'app-ipe',
  templateUrl: './ipe.component.html',
  styleUrls: ['./ipe.component.css']
})
export class IpeComponent implements OnInit {
  formulario!: UntypedFormGroup;

  email: string = '';

  dataAtual: any = '';
  
  listaFornecedores: any[] = [];
  listaFornecedoresOriginal: any[] = [];
  listaTomadores: any[] = [];
  listaTomadoresOriginal: any[] = [];
  // listaNumContratos: any[] = [];
  // listaAprovadores: any[] = [];
  
  categorias = categorias;
  moedas = moedas;
  motivos_de_urgencia = motivos_de_urgencia;
  subcategorias = subcategorias;
  tipo_de_operacao = tipo_de_operacao;
  tipo_de_lancamento = tipo_de_lancamento;
  formas_pagamento = formas_pagamento;
  setores = setores;
  quantidade_pedidos = quantidade_pedidos;

  valorFaturaAlterado: any = 0;

  caracteristicasChecked:any[] = []

  arquivosNpo: any[] = [];
  arquivosNpoUrl: any[] = [];

  arquivosXml: any[] = [];
  arquivosXmlUrl: any[] = [];

  arquivosNf: any[] = [];
  arquivosNfUrl: any[] = [];

  arquivosNfBoleto: any[] = [];
  arquivosNfBoletoUrl: any[] = [];


  showMotivoUrgencia: boolean = false;
  showNumContrato: boolean = false;
  showAprovador: boolean = false;
  
  urgenteChecked: boolean = false;
  antecipacaoChecked: boolean = false;
  jurosmultasChecked: boolean = false;
  custoPlanejamentoChecked: boolean = false;
  debitoPosteriorChecked: boolean = false;
  importacaoChecked: boolean = false;
  bloqueioChecked: boolean = false;
  riscoChecked: boolean = false;
  princTodasOpcoesChecked: boolean = false;

  exibirListaFornecedores: boolean = false;
  fornecedorSelecionado: any;
  exibeErroFornecedor: boolean = false;

  exibirListaTomadores: boolean = false;
  tomadorSelecionado: any;
  exibeErroTomador: boolean = false;
  
  error_counter: number = 0;
  erro: boolean = false


  @ViewChild('checkUrgente') checkUrgente: any;
  @ViewChild('checkContrato') checkContrato: any;
  @ViewChild('checkAprovador') checkAprovador: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService)
    { }


  ngOnInit(): void {
   
    this.formulario = this.formBuilder.group({
      caracteristicas: ['', []],
      motivo_de_urg_ncia: ['', []],
      descricao_outros: ['', []],
      cpf_cnpj_fornecedor: ['', []],
      cnpj_empresa_nutrien: ['', []],
      // n_mero_do_contrato: ['', [Validators.required]],
      // aprovador: ['', [Validators.required]],
      e_mail_do_requisitante: ['', [Validators.required]], //Validators.email
      // e_mail_do_fornecedor : ['',[Validators.email]],
      categoria: ['', [Validators.required]],
      subcategoria_po: ['', []],
      tipo_de_opera_o: ['', []],
      migo: ['', []],
      marque_todas_as_op_es_que_se_aplicam: ['', []],
      tipo_de_lan_amento: ['', []],
      n_mero_do_processo: ['', []],
      boleto_para_pagamento: ['', []],
      forma_de_pagamento: ['', []],
      folha_servicos: ['', []],
      favorecido: ['', []],
      valor_do_fi: ['', []],
      setor: ['', []],
      data_de_emiss_o: ['', [Validators.required]],
      data_de_vencimento: ['', [Validators.required]],
      moeda: ['', [Validators.required]],
      numero_da_nota_fiscal: ['', [Validators.required]],
      valor_da_fatura: ['', [Validators.required]],
      descri_o_breve: ['', []],
      quantidade_pedidos: ['', []],

      numero_pedido_1: ['', []],
      numero_linha_pedido_1: ['', []],
      numero_pedido_2: ['', []],
      numero_linha_pedido_2: ['', []],
      numero_pedido_3: ['', []],
      numero_linha_pedido_3: ['', []],
      numero_pedido_4: ['', []],
      numero_linha_pedido_4: ['', []],
      numero_pedido_5: ['', []],
      numero_linha_pedido_5: ['', []],
      numero_pedido_6: ['', []],
      numero_linha_pedido_6: ['', []],
      numero_pedido_7: ['', []],
      numero_linha_pedido_7: ['', []],
      numero_pedido_8: ['', []],
      numero_linha_pedido_8: ['', []],
      numero_pedido_9: ['', []],
      numero_linha_pedido_9: ['', []],
      numero_pedido_10: ['', []],
      numero_linha_pedido_10: ['', []],
      lista_pedidos: ['', []],

      anexo_xml: ['', []],
      anexo_nota_fiscal_ou_boleto: ['', []],
      anexo_nota_fiscal_documento_boleto_se_houver: ['', []],
      anexo_npo_modelo_excel: ['', []],

      inputSearchFornecedor: ['', []],
      inputSearchTomador: ['', []]
    })

    this.getUserEmail();
    this.formataValorDecimal('0');
    this.getListaFornecedor();
    this.getListaTomador();
    this.getDataAtual();
  }


  enviarFormulario() {

    this.spinner.show()
    // console.log('inicio envio')

    const criarBody = async () => {

      await this.getPipesUrlArquivoNpo(this.arquivosNpo);
      // console.log('function getPipesUrlArquivoNpo')
      if(this.arquivosNpo.length >0){
        // console.log('arquivosNpo.length >0')
        while (this.arquivosNpo.length != this.arquivosNpoUrl.length) {
          // console.log("esperando....")
          // console.log('inicio delay getPipesUrlArquivoNpo')
          await this.delay(300);
          // console.log('termino delay getPipesUrlArquivoNpo')
          if(this.erro){
            console.log('this.erro arquivosNpo', this.erro)
            break;
          }
        }
        if(this.erro){
          console.log('this.erro arquivosXml', this.erro)
          return
        }
      }

      if(this.arquivosNpo.length>0){
        var arquivosNpoUrl2 = ''
        arquivosNpoUrl2 = '['
        for(var i = 0; i < this.arquivosNpoUrl.length ; i++){
          arquivosNpoUrl2 = arquivosNpoUrl2+'"'+this.arquivosNpoUrl[i]+'",'
        }
        arquivosNpoUrl2 = arquivosNpoUrl2.slice(0, -1)
        arquivosNpoUrl2 = arquivosNpoUrl2+"]"

      }else{
        arquivosNpoUrl2 =''
      }

      await this.getPipesUrlArquivoXml(this.arquivosXml);
      // console.log('function getPipesUrlArquivoXml')
      if(this.arquivosXml.length >0){
        // console.log('arquivosXml.length >0')
        while (this.arquivosXml.length != this.arquivosXmlUrl.length) {
          // console.log("esperando....")
          // console.log('inicio delay getPipesUrlArquivoXml')
          await this.delay(300);
          // console.log('termino delay getPipesUrlArquivoXml')
          if(this.erro){
            console.log('this.erro arquivosXml', this.erro)
            break;
          }
        }
        if(this.erro){
          console.log('this.erro arquivosXml', this.erro)
          return
        }
      }

      if(this.arquivosXml.length>0){
        var arquivosXmlUrl2 = ''
         arquivosXmlUrl2 = '['
        for(var i = 0; i < this.arquivosXmlUrl.length ; i++){
           arquivosXmlUrl2 =  arquivosXmlUrl2+'"'+this.arquivosXmlUrl[i]+'",'
        }
         arquivosXmlUrl2 = arquivosXmlUrl2.slice(0, -1)
         arquivosXmlUrl2 = arquivosXmlUrl2+"]"

      }else{
        arquivosXmlUrl2 =''
      }

      await this.getPipesUrlArquivoNf(this.arquivosNf);
      // console.log('function getPipesUrlArquivoNf')
      if(this.arquivosNf.length >0){
        // console.log('arquivosNf.length >0')
        while (this.arquivosNf.length != this.arquivosNfUrl.length) {
          // console.log("esperando....")
          // console.log('inicio delay getPipesUrlArquivoNf')
          await this.delay(300);
          // console.log('termino delay getPipesUrlArquivoNf')
          if(this.erro){
            console.log('this.erro getPipesUrlArquivoNf', this.erro)
            break;
          }
        }
        if(this.erro){
          console.log('this.erro getPipesUrlArquivoNf', this.erro)
          return
        }
      }

      if(this.arquivosNf.length>0){
        var arquivosNfUrl2 = ''
         arquivosNfUrl2 = '['
        for(var i = 0; i < this.arquivosNfUrl.length ; i++){
           arquivosNfUrl2 =  arquivosNfUrl2+'"'+this.arquivosNfUrl[i]+'",'
        }
         arquivosNfUrl2 = arquivosNfUrl2.slice(0, -1)
         arquivosNfUrl2 = arquivosNfUrl2+"]"

      }else{
        arquivosNfUrl2 =''
      }

      await this.getPipesUrlArquivoNfBoleto(this.arquivosNfBoleto);
      // console.log('function getPipesUrlArquivoNfBoleto')
      if(this.arquivosNfBoleto.length >0){
        // console.log('arquivosNfBoleto.length >0')
        while (this.arquivosNfBoleto.length != this.arquivosNfBoletoUrl.length) {
          // console.log("esperando....")
          // console.log('inicio delay getPipesUrlArquivoNfBoleto')
          await this.delay(300);
          // console.log('termino delay getPipesUrlArquivoNfBoleto')
          if(this.erro){
            console.log('this.erro getPipesUrlArquivoNfBoleto', this.erro)
            break;
          }
        }
        if(this.erro){
          console.log('this.erro getPipesUrlArquivoNfBoleto', this.erro)
          return
        }
      }

      if(this.arquivosNfBoleto.length>0){
        var arquivosNfBoletoUrl2 = ''
         arquivosNfBoletoUrl2 = '['
        for(var i = 0; i < this.arquivosNfBoletoUrl.length ; i++){
           arquivosNfBoletoUrl2 =  arquivosNfBoletoUrl2+'"'+this.arquivosNfBoletoUrl[i]+'",'
        }
         arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2.slice(0, -1)
         arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2+"]"

      }else{
        arquivosNfBoletoUrl2 =''
      }

      // console.log('iniciar fields')

      let caracteristicas = '['+this.caracteristicasChecked+']';
      let motivo_urgencia = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_de_urg_ncia);
      let descricao_outros = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_outros);
      let cpf_cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf_cnpj_fornecedor);
      let cnpj_empresa_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj_empresa_nutrien);
      let email_requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_do_requisitante) + '@nutrien.com';
      // let e_mail_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_do_fornecedor)
      let categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().categoria);
      let subcategoria_po = this.trataInput.removeCaracters(this.formulario.getRawValue().subcategoria_po);
      let tipo_operacao = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_opera_o);
      let migo = this.trataInput.removeCaracters(this.formulario.getRawValue().migo);
      let marque_todas_opcoes_aplicam = this.formulario.getRawValue().marque_todas_as_op_es_que_se_aplicam == true ? 'Serviço ou Material é um Ativo' : '';
      let tipo_lancamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_lan_amento);
      let numero_processo = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_do_processo);
      let boleto_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().boleto_para_pagamento);
      let forma_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().forma_de_pagamento);
      let folha_servicos = this.trataInput.removeCaracters(this.formulario.getRawValue().folha_servicos);
      let favorecido = this.trataInput.removeCaracters(this.formulario.getRawValue().favorecido);
      let valor_fi = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_fi);
      let setor = this.trataInput.removeCaracters(this.formulario.getRawValue().setor);
      let data_emissao = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_emiss_o);
      let data_vencimento = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento);
      let moeda = this.trataInput.removeCaracters(this.formulario.getRawValue().moeda);
      let numero_nota_fiscal = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_da_nota_fiscal);
      let valor_fatura = this.valorFaturaAlterado;
      let descricao_breve = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_breve);
      let quantidade_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_pedidos);
      let numero_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_1);
      let numero_linha_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_1);
      let numero_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_2);
      let numero_linha_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_2);
      let numero_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_3);
      let numero_linha_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_3);
      let numero_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_4);
      let numero_linha_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_4);
      let numero_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_5);
      let numero_linha_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_5);
      let numero_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_6);
      let numero_linha_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_6);
      let numero_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_7);
      let numero_linha_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_7);
      let numero_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_8);
      let numero_linha_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_8);
      let numero_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_9);
      let numero_linha_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_9);
      let numero_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_10);
      let numero_linha_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_10);
      let lista_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().lista_pedidos);

      let pipe_fields = pipe_fields_ids
      let fields_values = [
        caracteristicas,
        motivo_urgencia,
        descricao_outros,
        this.fornecedorSelecionado.cardId,
        cpf_cnpj_fornecedor,
        this.tomadorSelecionado.cardId,
        cnpj_empresa_nutrien,
        email_requisitante,
        // e_mail_do_fornecedor,
        categoria,
        subcategoria_po,
        tipo_operacao,
        migo,
        tipo_lancamento,
        numero_processo,
        // arquivosNfUrl2,
        // arquivosXmlUrl2,
        // arquivosNfBoletoUrl2,
        // arquivosNpoUrl2,
        marque_todas_opcoes_aplicam,
        data_emissao,
        data_vencimento,
        moeda,
        numero_nota_fiscal,
        valor_fatura,
        boleto_pagamento,
        forma_pagamento,
        folha_servicos,
        favorecido,
        valor_fi,
        setor,
        descricao_breve,
        quantidade_pedidos,
        numero_pedido_1,
        numero_linha_pedido_1,
        numero_pedido_2,
        numero_linha_pedido_2,
        numero_pedido_3,
        numero_linha_pedido_3,
        numero_pedido_4,
        numero_linha_pedido_4,
        numero_pedido_5,
        numero_linha_pedido_5,
        numero_pedido_6,
        numero_linha_pedido_6,
        numero_pedido_7,
        numero_linha_pedido_7,
        numero_pedido_8,
        numero_linha_pedido_8,
        numero_pedido_9,
        numero_linha_pedido_9,
        numero_pedido_10,
        numero_linha_pedido_10,
        lista_pedidos,
        this.arquivosNfUrl,
        this.arquivosXmlUrl,
        this.arquivosNfBoletoUrl,
        this.arquivosNpoUrl
      ]
      let pipe_id = "302691440"
      // console.log('fields_values: ', fields_values)

      this.pipefy.criaBody(pipe_fields,fields_values,pipe_id)
      // console.log('iniciar criação do card')
      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform();
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          console.log(erro);
          Swal.fire({
            title: 'Desculpe!',
            html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })

          this.arquivosXmlUrl = [];
          this.arquivosNfUrl = [];
          this.arquivosNfBoletoUrl = [];
          this.arquivosNpoUrl = [];



          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.email, this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      },(error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        console.log(error)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosXmlUrl = [];
          this.arquivosNfUrl = [];
          this.arquivosNfBoletoUrl = [];
          this.arquivosNpoUrl = [];
        }
      })

    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  saveArquivoNpo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNpo.push(files[i])
    }
  }

  removeFileArquivoNpo(file: File) {
    const index: number = this.arquivosNpo.indexOf(file);
    this.arquivosNpo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNpo.length == 0){
      this.formulario.controls['anexo_npo_modelo_excel'].markAsTouched()
      this.formulario.get('anexo_npo_modelo_excel')?.setValue('')
    }
  }

  async getPipesUrlArquivoNpo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i=0; i<binaryString.length; i++){
        byteArray[i] += binaryString.charCodeAt(i);
       }/*from w  ww .  j a v a 2  s  .c  o  m*/

        this.pipefy.getFileUrl2(byteArray, fileName).subscribe((res) => {
          // let url = res.data.attachments_url_pipefy[0]
          // this.arquivosNpoUrl.push(url)
          let urlResposta = res.body.data.createPresignedUrl.url
        
          var fileUrlTemp = ""; 
          var startIndex = 0; 
          
          if (urlResposta.indexOf("orgs") > 0) { 
            startIndex = urlResposta.indexOf("orgs/"); 
          } else { 
            startIndex = urlResposta.indexOf("uploads/"); 
          } 

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNpoUrl.push(fileUrlTemp)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNpoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoXml(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosXml.push(files[i])
    }
  }

  removeFileArquivoXml(file: File) {
    const index: number = this.arquivosXml.indexOf(file);
    this.arquivosXml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosXml.length == 0){
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlArquivoXml(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i=0; i<binaryString.length; i++){
        byteArray[i] += binaryString.charCodeAt(i);
       }/*from w  ww .  j a v a 2  s  .c  o  m*/

        this.pipefy.getFileUrl2(byteArray, fileName).subscribe((res) => {
          // let url = res.data.attachments_url_pipefy[0]
          // this.arquivosXmlUrl.push(url)
          let urlResposta = res.body.data.createPresignedUrl.url
        
          var fileUrlTemp = ""; 
          var startIndex = 0; 
          
          if (urlResposta.indexOf("orgs") > 0) { 
            startIndex = urlResposta.indexOf("orgs/"); 
          } else { 
            startIndex = urlResposta.indexOf("uploads/"); 
          } 

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosXmlUrl.push(fileUrlTemp)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosXmlUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNf(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNf.push(files[i])
    }
  }

  removeFileArquivoNf(file: File) {
    const index: number = this.arquivosNf.indexOf(file);
    this.arquivosNf.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNf.length == 0){
      this.formulario.controls['anexo_nota_fiscal_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_ou_boleto')?.setValue('')
    }
  }

  async getPipesUrlArquivoNf(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i=0; i<binaryString.length; i++){
        byteArray[i] += binaryString.charCodeAt(i);
       }/*from w  ww .  j a v a 2  s  .c  o  m*/

        this.pipefy.getFileUrl2(byteArray, fileName).subscribe((res) => {
          // let url = res.data.attachments_url_pipefy[0]
          // this.arquivosNfUrl.push(url)
          let urlResposta = res.body.data.createPresignedUrl.url
        
          var fileUrlTemp = ""; 
          var startIndex = 0; 
          
          if (urlResposta.indexOf("orgs") > 0) { 
            startIndex = urlResposta.indexOf("orgs/"); 
          } else { 
            startIndex = urlResposta.indexOf("uploads/"); 
          } 

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNfUrl.push(fileUrlTemp)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNfUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNfBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNfBoleto.push(files[i])
    }
  }

  removeFileArquivoNfBoleto(file: File) {
    const index: number = this.arquivosNfBoleto.indexOf(file);
    this.arquivosNfBoleto.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNfBoleto.length == 0){
      this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_documento_boleto_se_houver')?.setValue('')
    }
  }

  async getPipesUrlArquivoNfBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i=0; i<binaryString.length; i++){
        byteArray[i] += binaryString.charCodeAt(i);
       }/*from w  ww .  j a v a 2  s  .c  o  m*/

        this.pipefy.getFileUrl2(byteArray, fileName).subscribe((res) => {
          // let url = res.data.attachments_url_pipefy[0]
          // this.arquivosNfBoletoUrl.push(url)
          let urlResposta = res.body.data.createPresignedUrl.url
        
          var fileUrlTemp = ""; 
          var startIndex = 0; 
          
          if (urlResposta.indexOf("orgs") > 0) { 
            startIndex = urlResposta.indexOf("orgs/"); 
          } else { 
            startIndex = urlResposta.indexOf("uploads/"); 
          } 

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNfBoletoUrl.push(fileUrlTemp)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNfBoletoUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  getUserEmail() {
    this.email = this.userInfo.getUserEmail();
    if (this.email && this.email.indexOf('@nutrien.com') !== -1) {
      let name = this.email.substring(0, this.email.lastIndexOf("@"));
      this.formulario.controls['e_mail_do_requisitante']?.setValue(name);
    }
  }

  getListaFornecedor() {
    this.spinner.show();
    let key = 'Emitente';    
    this.pipefy.buscaTabelasMasterdata(key, '').subscribe((res: any) => {
      
      let retorno = res.data.masterdata;

      if(retorno) {
        this.listaFornecedores.push({
          nome: 'NÃO CADASTRADO/ENCONTRADO',
          cardId: '666469115'
        })
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaFornecedores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.listaFornecedoresOriginal = this.listaFornecedores;
        this.spinner.hide();
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Forncecedor. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.spinner.hide();
      }

    },
    (error: any) => {
      this.spinner.hide();
      console.log(error)
    })
  }

  getListaTomador() {
    let key = 'Tomador';
    this.pipefy.buscaTabelasMasterdata(key, '').subscribe((res: any) => {
      
      let retorno = res.data.masterdata;

      if(retorno) {
        this.listaTomadores.push({
          nome: 'NÃO CADASTRADO/ENCONTRADO',
          cardId: '666469142'
        })
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaTomadores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2].replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            cardId: retorno[index].card_id
          })
        }
        this.listaTomadoresOriginal = this.listaTomadores;
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Tomador. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
  },

  (error: any) => {
      console.log(error)
  })
  }

  changeCheckTodas(event: any) {
    this.urgenteChecked = event;
    this.antecipacaoChecked = event;
    this.jurosmultasChecked = event;
    this.custoPlanejamentoChecked = event;
    this.debitoPosteriorChecked = event;
    this.importacaoChecked = event;
    this.bloqueioChecked = event;
    this.riscoChecked = event;
    this.princTodasOpcoesChecked = event;
    if (event) {
      this.changeCheckUrgente(true)
      this.changeCheckAntec(true);
      this.changeCheckJuros(true);
      this.changecustoPlanejamento(true);
      this.changeDebitoPosterior(true);
      this.changeImportacao(true);
      this.changeBloqueio(true);
      this.changeRisco(true);
      this.inserirNoArray('Todos')
      // this.changeCheckContrato(true)
      // this.changeCheckAprovador(true)
    } else {
      this.changeCheckUrgente(false);
      this.changeCheckAntec(false);
      this.changeCheckJuros(false);
      this.changecustoPlanejamento(false);
      this.changeDebitoPosterior(false);
      this.changeImportacao(false);
      this.changeBloqueio(false);
      this.changeRisco(false);
      this.inserirNoArray('Nenhum')
      // this.changeCheckContrato(false)
      // this.changeCheckAprovador(false)
    }
  }

  changeCheckAntec(event: any){
    this.antecipacaoChecked = event;
  }

  changeCheckJuros(event: any){
    this.jurosmultasChecked = event;
  }

  changecustoPlanejamento(event: any){
    this.custoPlanejamentoChecked = event;
  }

  changeDebitoPosterior(event: any){
    this.debitoPosteriorChecked = event;
  }

  changeImportacao(event: any){
    this.importacaoChecked = event;
  }

  changeBloqueio(event: any){
    this.bloqueioChecked = event;
  }

  changeRisco(event: any){
    this.riscoChecked = event;
  }

  changeCheckUrgente(event: any) {
    this.showMotivoUrgencia = event;
    this.urgenteChecked = event;
    if (this.showMotivoUrgencia) {
      this.formulario.controls['motivo_de_urg_ncia']?.setValue('');
      this.formulario.controls['motivo_de_urg_ncia']?.setValidators([Validators.required]);
      this.formulario.controls['motivo_de_urg_ncia']?.updateValueAndValidity();

    } else {
      this.formulario.controls['motivo_de_urg_ncia'].clearValidators();
      this.formulario.controls['motivo_de_urg_ncia'].updateValueAndValidity;
      this.formulario.controls['motivo_de_urg_ncia'].setValue('');

    }    
  }

  // changeCheckContrato(event: any) {
  //   this.showNumContrato = event;
  //   if (this.showNumContrato) {
  //     this.formulario.get('n_mero_do_contrato')?.reset;
  //     this.formulario.get('n_mero_do_contrato')?.markAsUntouched;
  //   } else {
  //     this.formulario.get('n_mero_do_contrato')?.clearValidators;
  //     this.formulario.get('n_mero_do_contrato')?.setValue('');
  //     this.formulario.get('n_mero_do_contrato')?.markAsUntouched;
  //     this.formulario.get('n_mero_do_contrato')?.updateValueAndValidity();
  //   }
  // }

  // changeCheckAprovador(event: any) {
  //   this.showAprovador = event;
  //   if (this.showAprovador) {
  //     this.formulario.get('aprovador')?.reset;
  //     this.formulario.get('aprovador')?.markAsUntouched;
  //   } else {
  //     this.formulario.get('aprovador')?.clearValidators;
  //     this.formulario.get('aprovador')?.setValue('');
  //     this.formulario.get('aprovador')?.markAsUntouched;
  //     this.formulario.get('aprovador')?.updateValueAndValidity();
  //   }
  // }

  changeMotivoUrgencia(event:any) {
    this.formulario.controls['descricao_outros']?.setValue('');
    if (event.target.value == 'Outros') {
      this.formulario.controls['descricao_outros']?.setValidators([Validators.required]);
      this.formulario.controls['descricao_outros']?.updateValueAndValidity();
    } else {
      this.formulario.controls['descricao_outros'].clearValidators();
      this.formulario.controls['descricao_outros'].setErrors(null);
      this.formulario.controls['descricao_outros'].markAsUntouched();
      this.formulario.controls['descricao_outros'].updateValueAndValidity;
    }
  }

  inserirNoArray(event:any){

    if (event == 'Todos') {
      this.caracteristicasChecked = ['"Urgente"', '"Antecipação"', '"Juros e Multas"', '"Custo Planejado"', '"Débito Posterior"', '"Importação"', '"Bloqueio de Pagamento"', '"Risco Sacado"'];

    } else if (event == 'Nenhum') {
      this.caracteristicasChecked = [];

    } else if(this.formulario.getRawValue().caracteristicas){
      // this.caracteristicasChecked = [];
      this.caracteristicasChecked.push('"'+event+'"')
      this.princTodasOpcoesChecked = this.caracteristicasChecked.length == 5 ? true : false;

    } else if(!this.formulario.getRawValue().caracteristicas){
      // this.caracteristicasChecked = [];
      let eventEdit = `"${event}"`;
      this.caracteristicasChecked = this.caracteristicasChecked.filter(item => item !== eventEdit)
      this.princTodasOpcoesChecked = false;
    }

    // console.log(this.caracteristicasChecked)
  }

  cliqueSelectFornecedor() {
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.exibirListaFornecedores = !this.exibirListaFornecedores;
    this.formulario.get('inputSearchFornecedor')?.setValue('');
    this.exibeErroFornecedor = this.fornecedorSelecionado ? false : true;
    this.exibirListaTomadores = false;
  }

  cliqueSelectTomador() {
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = !this.exibirListaTomadores;
    this.formulario.get('inputSearchTomador')?.setValue('');
    this.exibeErroTomador = this.tomadorSelecionado ? false : true;
    this.exibirListaFornecedores = false;
  }

  clearFornecedor() {
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.formulario.get('inputSearchFornecedor')?.setValue('');
  }

  clearTomador() {
    this.listaTomadores = this.listaTomadoresOriginal;
    this.formulario.get('inputSearchTomador')?.setValue('');
  }

  searchFornecedor() {
    this.listaFornecedores = !this.listaFornecedores.length ? this.listaFornecedoresOriginal : this.listaFornecedores;
    let filtro = this.formulario.getRawValue().inputSearchFornecedor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    // let filtro = this.formulario.getRawValue().inputSearchFornecedor;
    // if (filtro) {
    //   filtro = filtro.toString()
    // } else {
    //   return
    // }
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaFornecedores = [];
      
      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        
        let retorno = res.data.masterdata;
  
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaFornecedores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
      })
    } else {
      this.listaFornecedores = this.listaFornecedoresOriginal;
    }
  }

  searchTomador() {
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = this.formulario.getRawValue().inputSearchTomador.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    // let filtro = this.formulario.getRawValue().inputSearchTomador;
    // if (filtro) {
    //   filtro = filtro.toString()
    // } else {
    //   return
    // }
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).subscribe((res: any) => {
        
        let retorno = res.data.masterdata;
  
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');
  
          this.listaTomadores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
      })
      
    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  verificaContemNumeros(str: string) {
    return /^\d+$/.test(str);
  }

  selecionaFornecedor(item: any) {
    this.fornecedorSelecionado = item;
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.exibirListaFornecedores = false;
    this.exibeErroFornecedor = false;
  }

  selecionaTomador(item: any) {
    this.tomadorSelecionado = item;
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = false;
    this.exibeErroTomador = false;
  }

  changeCategoria(event:any) {
    this.zerarTodosAnexos();
    switch (event.target.value) {
      case 'PO':
        this.categSelecionadaPO();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
      case 'NPO':
        this.categSelecionadaNPO();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break
      default:
        this.categSelecionadaVazio();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
    }
  }

  onTipoChange() {
    let quantidadeDeItens = this.formulario.getRawValue().quantidade_pedidos
    this.clearValidatorsDetalhes()
    this.limpaValoresProdutos(quantidadeDeItens);
    
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        break;

      case '2':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        break;

      case '3':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        break;
          
      case '4':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        break;
        
      case '5':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        break;
        
      case '6':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        break;
        
      case '7':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        break;
        
      case '8':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        break;
        
      case '9':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        break;
        
      case '10':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        
        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        break;
        
      case 'Mais que 10':
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;
    }
  }

  clearValidatorsDetalhes(){
    this.formulario.controls['numero_pedido_1']?.clearValidators();
    this.formulario.controls['numero_pedido_1']?.markAsUntouched();
    this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_1']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_1']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_2']?.clearValidators();
    this.formulario.controls['numero_pedido_2']?.markAsUntouched();
    this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_2']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_3']?.clearValidators();
    this.formulario.controls['numero_pedido_3']?.markAsUntouched();
    this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_3']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_4']?.clearValidators();
    this.formulario.controls['numero_pedido_4']?.markAsUntouched();
    this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_4']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_5']?.clearValidators();
    this.formulario.controls['numero_pedido_5']?.markAsUntouched();
    this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_5']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_6']?.clearValidators();
    this.formulario.controls['numero_pedido_6']?.markAsUntouched();
    this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_6']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_7']?.clearValidators();
    this.formulario.controls['numero_pedido_7']?.markAsUntouched();
    this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_7']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_8']?.clearValidators();
    this.formulario.controls['numero_pedido_8']?.markAsUntouched();
    this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_8']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_9']?.clearValidators();
    this.formulario.controls['numero_pedido_9']?.markAsUntouched();
    this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_9']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_10']?.clearValidators();
    this.formulario.controls['numero_pedido_10']?.markAsUntouched();
    this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
    
    this.formulario.controls['numero_linha_pedido_10']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_10']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();

    this.formulario.controls['lista_pedidos']?.clearValidators();
    this.formulario.controls['lista_pedidos']?.markAsUntouched();
    this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
  }

  limpaValoresProdutos(quantidadeDeItens: any) {
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
      
      case '2':
        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '3':
        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '4':
        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '5':
        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '6':
        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '7':
        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '8':
        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '9':
        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '10':
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case 'Mais que 10':
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
        
        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
    }
  }

  categSelecionadaVazio() {
    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.zerarTodosAnexos();

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValue('');
    this.formulario.controls['favorecido']?.clearValidators();
    this.formulario.controls['favorecido']?.markAsUntouched();
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValue('');
    this.formulario.controls['valor_do_fi']?.clearValidators();
    this.formulario.controls['valor_do_fi']?.markAsUntouched();
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  categSelecionadaNPO() {
    this.formulario.controls['tipo_de_lan_amento']?.setValidators([Validators.required]);
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['anexo_npo_modelo_excel']?.setValidators([Validators.required]);
    this.formulario.controls['anexo_npo_modelo_excel']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValidators([Validators.required]);
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValidators([Validators.required]);
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.zerarTodosCamposQtdePedidos();
  }

  categSelecionadaPO() {
    this.formulario.controls['subcategoria_po']?.setValidators([Validators.required]);
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['quantidade_pedidos']?.setValidators([Validators.required]);
    this.formulario.controls['quantidade_pedidos']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    this.formulario.controls['favorecido']?.setValue('');
    this.formulario.controls['favorecido']?.clearValidators();
    this.formulario.controls['favorecido']?.markAsUntouched();
    this.formulario.controls['favorecido']?.updateValueAndValidity();

    this.formulario.controls['valor_do_fi']?.setValue('');
    this.formulario.controls['valor_do_fi']?.clearValidators();
    this.formulario.controls['valor_do_fi']?.markAsUntouched();
    this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  changeSubcategoria(event: any) {
    this.zerarTodosCamposSubcategoria();
    this.zerarTodosAnexos();
    switch (event.target.value) {
      case 'Materiais':
        this.formulario.controls['tipo_de_opera_o']?.setValidators([Validators.required]);
        this.formulario.controls['tipo_de_opera_o']?.updateValueAndValidity();

        // this.formulario.controls['quantidade_pedidos']?.setValidators([Validators.required]);
        // this.formulario.controls['quantidade_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.updateValueAndValidity();
        break;
      case 'Serviços':
        this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
        this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

        // this.formulario.controls['folha_servicos']?.setValidators([Validators.required]);
        // this.formulario.controls['folha_servicos']?.updateValueAndValidity();

        // this.formulario.controls['quantidade_pedidos']?.setValidators([Validators.required]);
        // this.formulario.controls['quantidade_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break
      case 'Boleto ':
      case 'Nota de Débito ':
      case 'Telefonia ':
      case 'Água ':
      case 'Energia ':
      case 'Fatura ':
      case 'Frete ':
      case 'Aluguel ':
      case 'Correios':
      case 'Outros ':
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break
      default:
        break;
    }
  }

  changeTipoOperacao(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Matéria Prima':
        this.formulario.controls['migo']?.setValidators([Validators.required]);
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['migo']?.setValue('');
        this.formulario.controls['migo']?.clearValidators();
        this.formulario.controls['migo']?.markAsUntouched();
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
    }
  }
  
  changeTipoLancamento(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Depósito Judicial':
        this.formulario.controls['n_mero_do_processo']?.setValidators([Validators.required]);
        this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['n_mero_do_processo']?.setValue('');
        this.formulario.controls['n_mero_do_processo']?.clearValidators();
        this.formulario.controls['n_mero_do_processo']?.markAsUntouched();
        this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
        break;
    }
  }
  
  zerarTodosAnexos() {
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValue('');
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].setErrors(null);
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].markAsUntouched();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;

    this.formulario.controls['anexo_xml'].clearValidators();
    this.formulario.controls['anexo_xml']?.setValue('');
    this.formulario.controls['anexo_xml'].setErrors(null);
    this.formulario.controls['anexo_xml'].markAsUntouched();
    this.formulario.controls['anexo_xml'].updateValueAndValidity;

    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValue('');
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].setErrors(null);
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].markAsUntouched();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_npo_modelo_excel'].clearValidators();
    this.formulario.controls['anexo_npo_modelo_excel']?.setValue('');
    this.formulario.controls['anexo_npo_modelo_excel'].setErrors(null);
    this.formulario.controls['anexo_npo_modelo_excel'].markAsUntouched();
    this.formulario.controls['anexo_npo_modelo_excel'].updateValueAndValidity;
    
    this.arquivosXml = [];
    this.arquivosXmlUrl = [];
    
    this.arquivosNf = [];
    this.arquivosNfUrl = [];
    
    this.arquivosNfBoleto = [];
    this.arquivosNfBoletoUrl = [];
    
    this.arquivosNpo = [];
    this.arquivosNpoUrl = [];
  }

  zerarTodosCamposSubcategoria() {

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['tipo_de_opera_o'].setValue('');
    this.formulario.controls['tipo_de_opera_o'].clearValidators();
    this.formulario.controls['tipo_de_opera_o'].setErrors(null);
    this.formulario.controls['tipo_de_opera_o'].markAsUntouched();
    this.formulario.controls['tipo_de_opera_o'].updateValueAndValidity();

    this.formulario.controls['marque_todas_as_op_es_que_se_aplicam'].setValue('');

    this.formulario.controls['boleto_para_pagamento'].setValue('');
    this.formulario.controls['boleto_para_pagamento'].clearValidators();
    this.formulario.controls['boleto_para_pagamento'].setErrors(null);
    this.formulario.controls['boleto_para_pagamento'].markAsUntouched();
    this.formulario.controls['boleto_para_pagamento'].updateValueAndValidity();

    this.formulario.controls['folha_servicos'].setValue('');
    this.formulario.controls['folha_servicos'].clearValidators();
    this.formulario.controls['folha_servicos'].setErrors(null);
    this.formulario.controls['folha_servicos'].markAsUntouched();
    this.formulario.controls['folha_servicos'].updateValueAndValidity();
  }

  zerarTodosCamposQtdePedidos() {
    this.formulario.controls['quantidade_pedidos'].setValue('');
    this.formulario.controls['quantidade_pedidos'].clearValidators();
    this.formulario.controls['quantidade_pedidos'].setErrors(null);
    this.formulario.controls['quantidade_pedidos'].markAsUntouched();
    this.formulario.controls['quantidade_pedidos'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_1'].setValue('');
    this.formulario.controls['numero_pedido_1'].clearValidators();
    this.formulario.controls['numero_pedido_1'].setErrors(null);
    this.formulario.controls['numero_pedido_1'].markAsUntouched();    
    this.formulario.controls['numero_pedido_1'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_1'].setValue('');
    this.formulario.controls['numero_linha_pedido_1'].clearValidators();
    this.formulario.controls['numero_linha_pedido_1'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();    
    this.formulario.controls['numero_linha_pedido_1'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_2'].setValue('');
    this.formulario.controls['numero_pedido_2'].clearValidators();
    this.formulario.controls['numero_pedido_2'].setErrors(null);
    this.formulario.controls['numero_pedido_2'].markAsUntouched();
    this.formulario.controls['numero_pedido_2'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2'].setValue('');
    this.formulario.controls['numero_linha_pedido_2'].clearValidators();
    this.formulario.controls['numero_linha_pedido_2'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_3'].setValue('');
    this.formulario.controls['numero_pedido_3'].clearValidators();
    this.formulario.controls['numero_pedido_3'].setErrors(null);
    this.formulario.controls['numero_pedido_3'].markAsUntouched();
    this.formulario.controls['numero_pedido_3'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3'].setValue('');
    this.formulario.controls['numero_linha_pedido_3'].clearValidators();
    this.formulario.controls['numero_linha_pedido_3'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_4'].setValue('');
    this.formulario.controls['numero_pedido_4'].clearValidators();
    this.formulario.controls['numero_pedido_4'].setErrors(null);
    this.formulario.controls['numero_pedido_4'].markAsUntouched();
    this.formulario.controls['numero_pedido_4'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4'].setValue('');
    this.formulario.controls['numero_linha_pedido_4'].clearValidators();
    this.formulario.controls['numero_linha_pedido_4'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_5'].setValue('');
    this.formulario.controls['numero_pedido_5'].clearValidators();
    this.formulario.controls['numero_pedido_5'].setErrors(null);
    this.formulario.controls['numero_pedido_5'].markAsUntouched();
    this.formulario.controls['numero_pedido_5'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5'].setValue('');
    this.formulario.controls['numero_linha_pedido_5'].clearValidators();
    this.formulario.controls['numero_linha_pedido_5'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_6'].setValue('');
    this.formulario.controls['numero_pedido_6'].clearValidators();
    this.formulario.controls['numero_pedido_6'].setErrors(null);
    this.formulario.controls['numero_pedido_6'].markAsUntouched();
    this.formulario.controls['numero_pedido_6'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6'].setValue('');
    this.formulario.controls['numero_linha_pedido_6'].clearValidators();
    this.formulario.controls['numero_linha_pedido_6'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_7'].setValue('');
    this.formulario.controls['numero_pedido_7'].clearValidators();
    this.formulario.controls['numero_pedido_7'].setErrors(null);
    this.formulario.controls['numero_pedido_7'].markAsUntouched();
    this.formulario.controls['numero_pedido_7'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7'].setValue('');
    this.formulario.controls['numero_linha_pedido_7'].clearValidators();
    this.formulario.controls['numero_linha_pedido_7'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_8'].setValue('');
    this.formulario.controls['numero_pedido_8'].clearValidators();
    this.formulario.controls['numero_pedido_8'].setErrors(null);
    this.formulario.controls['numero_pedido_8'].markAsUntouched();
    this.formulario.controls['numero_pedido_8'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8'].setValue('');
    this.formulario.controls['numero_linha_pedido_8'].clearValidators();
    this.formulario.controls['numero_linha_pedido_8'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_9'].setValue('');
    this.formulario.controls['numero_pedido_9'].clearValidators();
    this.formulario.controls['numero_pedido_9'].setErrors(null);
    this.formulario.controls['numero_pedido_9'].markAsUntouched();
    this.formulario.controls['numero_pedido_9'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9'].setValue('');
    this.formulario.controls['numero_linha_pedido_9'].clearValidators();
    this.formulario.controls['numero_linha_pedido_9'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_10'].setValue('');
    this.formulario.controls['numero_pedido_10'].clearValidators();
    this.formulario.controls['numero_pedido_10'].setErrors(null);
    this.formulario.controls['numero_pedido_10'].markAsUntouched();
    this.formulario.controls['numero_pedido_10'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_10'].setValue('');
    this.formulario.controls['numero_linha_pedido_10'].clearValidators();
    this.formulario.controls['numero_linha_pedido_10'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10'].updateValueAndValidity();

    this.formulario.controls['lista_pedidos'].setValue('');
    this.formulario.controls['lista_pedidos'].clearValidators();
    this.formulario.controls['lista_pedidos'].setErrors(null);
    this.formulario.controls['lista_pedidos'].markAsUntouched();
    this.formulario.controls['lista_pedidos'].updateValueAndValidity();
  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().e_mail_do_requisitante
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if(!regex.test(input))
      input = (input.substring(0, (input.length-1)))
    this.formulario.controls['e_mail_do_requisitante']?.setValue(input);
  }

  formataValorDecimal( input: any ) {
    input = input.replace( /[^0-9]/g, '' ); // remove all the characters except the numeric values
    if( input.length == 0 ) input = "0.00"; // set the default value
    else if( input.length == 1 ) input = "0.0" + input;
    else if( input.length == 2 ) input = "0." + input;
    else input = input.substring( 0, input.length - 2 ) + '.' + input.substring( input.length - 2, input.length );
    input = new Number( input ); // set the precision
    input = input.toFixed( 2 );    // only works with the "."
    input = input.replace( /\./g, ',' ); // change the splitter to ","
    let x = input.split( ',' ); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while( rgx.test( valor ) ) {
      valor = valor.replace( rgx, '$1' + '.' + '$2' );
    }
    this.valorFaturaAlterado = valor + decimal
  }

  getDataAtual() {
    let newDate = new Date(),
        month = '' + (newDate.getMonth() + 1),
        day = '' + newDate.getDate(),
        year = newDate.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    this.dataAtual = [year, month, day].join('-');
  }

  exibeConfirmaValor() {
    Swal.fire({
      title: 'Atenção!',
      text: 'Confirmar se o valor corresponde exatamente ao valor do documento em PDF anexo.',
      // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
    })
  }

  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched();

    this.showMotivoUrgencia = false;
    this.showNumContrato = false;
    this.showAprovador = false;
    this.urgenteChecked = false;
    this.antecipacaoChecked = false;
    this.jurosmultasChecked = false;
    this.custoPlanejamentoChecked = false;
    this.debitoPosteriorChecked = false;
    this.importacaoChecked = false;

    this.caracteristicasChecked = [];

    this.zerarTodosCamposSubcategoria();

    this.zerarTodosAnexos();

    this.fornecedorSelecionado = '';
    this.tomadorSelecionado = '';

    this.formulario.controls['motivo_de_urg_ncia'].clearValidators();
    this.formulario.controls['motivo_de_urg_ncia'].setErrors(null);
    this.formulario.controls['motivo_de_urg_ncia'].markAsUntouched();
    this.formulario.controls['motivo_de_urg_ncia'].updateValueAndValidity;

    this.formulario.controls['descricao_outros'].clearValidators();
    this.formulario.controls['descricao_outros'].setErrors(null);
    this.formulario.controls['descricao_outros'].markAsUntouched();
    this.formulario.controls['descricao_outros'].updateValueAndValidity;

    this.formulario.controls['tipo_de_lan_amento'].clearValidators();
    this.formulario.controls['tipo_de_lan_amento'].setErrors(null);
    this.formulario.controls['tipo_de_lan_amento'].markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento'].updateValueAndValidity;


    this.formulario.controls['favorecido'].clearValidators();
    this.formulario.controls['favorecido'].setErrors(null);
    this.formulario.controls['favorecido'].markAsUntouched();
    this.formulario.controls['favorecido'].updateValueAndValidity;


    this.formulario.controls['valor_do_fi'].clearValidators();
    this.formulario.controls['valor_do_fi'].setErrors(null);
    this.formulario.controls['valor_do_fi'].markAsUntouched();
    this.formulario.controls['valor_do_fi'].updateValueAndValidity;


    this.formulario.controls['subcategoria_po'].clearValidators();
    this.formulario.controls['subcategoria_po'].setErrors(null);
    this.formulario.controls['subcategoria_po'].markAsUntouched();
    this.formulario.controls['subcategoria_po'].updateValueAndValidity;


    this.formulario.controls['n_mero_do_processo'].clearValidators();
    this.formulario.controls['n_mero_do_processo'].setErrors(null);
    this.formulario.controls['n_mero_do_processo'].markAsUntouched();
    this.formulario.controls['n_mero_do_processo'].updateValueAndValidity;
    
    this.getUserEmail();
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/_rj6N2Po', '_blank')
  }


}
