import { Injectable } from '@angular/core';
import { UserInfosService } from '../autenticacao/usuario/user-infos.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReturnSecrets, TokenServiceService } from './token-service.service';

export interface VerificarAtualizacao {
  "data": {
    "dados": {
      "e_mail": string,
      "status": "ok" | "não ok" | "não existe o email no pipe!" | "versão do card foi atualizada.",
      "versão": string
    }
  },
}
@Injectable({
  providedIn: 'root'
})
export class ControleDeVersoesService {

  constructor(private userInfo: UserInfosService,
    private httpClient: HttpClient, private token: TokenServiceService) {
  }

  acessaIpe: boolean = false

  getVersao() {
    return environment.versao
  }


  async consultaVersao() {
    const token = (await (this.token.getSecrets())).apikeys.digibee;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    // if(userEmail =='' || userEmail == undefined){
    // }

    let body = { "data": { "processo": "Consultar", "email": userEmail, "versao": environment.versaoPopup } }

    return await this.httpClient.post<any>(environment.controle_de_versao + "?apikey=" + token, body).toPromise()
  }


  async atualizaVersao() {
    const token = (await (this.token.getSecrets())).apikeys.digibee;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    // if(userEmail =='' || userEmail == undefined){
    // }

    let body = { "data": { "processo": "Atualizar", "email": userEmail, "versao": environment.versaoPopup } }

    return await this.httpClient.post<any>(environment.controle_de_versao + "?apikey=" + token, body).toPromise()
  }

  async podeAcessarIpe() {
    const token = (await (this.token.getSecrets())).apikeys.digibee;;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    if (userEmail == '' || userEmail == undefined) {


    }

    let body = { "data": { "email": userEmail } }

    return await this.httpClient.post<any>(environment.acessar_ipe + "?apikey=" + token, body
    ).pipe(
      tap(async res => {
        this.acessaIpe = res.data.dados.status
        console.log(this.acessaIpe)
        JSON.stringify(res);
        return await res;
      }))
  }

  getAcessaIpe() {
    return this.acessaIpe
  }

  async atualizarPortal() {
    if(environment.localhost) {
      return;
    }
    console.log("Procurando atualização.")
    const token = (await (this.token.getSecrets())).apikeys.digibee;
    let body = { "data": { "processo": "Consultar", "email": environment.email_versao, "versao": environment.versao } }

    const res = (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao + "?apikey=" + token, body).toPromise())?.data?.dados;
    if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) > parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
        window.location.reload();
    }
    if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) < parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
      (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao + "?apikey=" + token, 
      { "data": { "processo": "Atualizar", "email": environment.email_versao, "versao": environment.versao} }
      ).toPromise());
    }

    setInterval(async () => {
      const res = (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao + "?apikey=" + token, body).toPromise())?.data.dados;
      if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) > parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
        console.log("Atualizando portal...");
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
    }, environment.verificar_atualizacao_minutos * 60000);
  }

  formatarVersao(valor: string) {
    const partes = valor.split('.');
    partes[partes.length - 1] = '0';
    return partes.join('.');
  }



}

