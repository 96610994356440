import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { pipe_fields_cancelamento_de_desligamento_ids } from './pipe_fields_ids';

@Component({
  selector: 'app-cancelamento-de-desligamento',
  templateUrl: './cancelamento-de-desligamento.component.html',
  styleUrls: ['./cancelamento-de-desligamento.component.css']
})
export class CancelamentoDeDesligamentoComponent implements OnInit {

  formulario!: UntypedFormGroup;

  error_counter: number = 0;
  erro: boolean = false

  constructor(private formBuilder: UntypedFormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      cpf: ['', [Validators.required]],
      nome_do_funcionario: ['', [Validators.required]],
      justificativa: ['', [Validators.required]],
      codigo_do_processo: ['', [Validators.required]],
    })
  }

  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      let cpf = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf)
      let nome_do_funcionario = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_funcionario)
      let justificativa = this.trataInput.removeCaracters(this.formulario.getRawValue().justificativa)
      let codigo_do_processo = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_processo)

      ;(await this.pipefy.cancelarDesligamento(cpf,nome_do_funcionario,justificativa,codigo_do_processo)).subscribe((res:any)=>{
        this.spinner.hide()
        console.log(res)

        if(res.data.message == 'sucesso'){
          Swal.fire({
                  title: 'Solicitação realizada com sucesso!',
                  text: 'A solicitação de cancelamento de desligamento foi realizada com sucesso.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                })
                this.resetform()
        }

        if(res.data.message == 'CPF não encontrado'){
          Swal.fire({
                  title: 'CPF não encontrado',
                  text: 'Desculpe, não foi encontrado nenhuma solicitação de desligamento com o CPF informado.',
                  icon: 'error',
                  confirmButtonColor: '#88D600',
                })
        }
      })

      // this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      //   this.spinner.hide()

      //   if (res.data.result_creation_card.id != undefined) {
      //     let card_id = res.data.result_creation_card.id
      //     Swal.fire({
      //       title: 'Chamado aberto com Sucesso!',
      //       text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
      //       icon: 'success',
      //       confirmButtonText: 'Ok',
      //       confirmButtonColor: '#88D600',
      //     })
      //     this.resetform()
      //   }

      //   if (res.data.result_creation_card.errors != undefined) {
      //     var erro = res.data.result_creation_card.errors[0].message
      //     Swal.fire({
      //       title: 'Desculpe!',
      //       html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
      //       text: erro,
      //       icon: 'error',
      //       confirmButtonColor: '#88D600',
      //     })

      //     let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'HR', 'RH', "Demissão - criação de card -- Cancelamento de Desligamento", erro, 'Portal NBS Error', 'incidente@portal.com',
      //       'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas']

      //     var pipe_fields_incidentes_values2 = []
      //     for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
      //       pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
      //     }

      //     let pipe_incidentes_id = '302487533'

      //     this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
      //     })
      //   }
      // }, (error: any) => {
      //   this.spinner.hide()
      //   this.error_counter = this.error_counter + 1
      //   if (this.error_counter < 2) {
      //     if (error.status == 401) {
      //       Swal.fire({
      //         title: 'Ops, sua sessão expirou!',
      //         text: 'Por favor, saia e logue novamente.',
      //         icon: 'error',
      //         confirmButtonText: 'Sair',
      //         confirmButtonColor: '#ff6961',
      //         allowOutsideClick: false,
      //         allowEscapeKey: false
      //       })
      //         .then(() => {
      //           this.msalService.logoutRedirect()
      //         }
      //         )
      //     } else {
      //       Swal.fire({
      //         title: 'Desculpe!',
      //         text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
      //         icon: 'error',
      //         confirmButtonText: 'Ok',
      //         confirmButtonColor: '#88D600',
      //         allowOutsideClick: false,
      //         allowEscapeKey: false
      //       })
      //     }
      //   }
      //   else {
      //     this.spinner.hide()
      //     Swal.fire({
      //       title: 'Desculpe!',
      //       html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
      //       icon: 'error',
      //       confirmButtonColor: '#88D600',
      //     })
      //   }
      // })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/WVHXhf0h', '_blank')
  }
}
