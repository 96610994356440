import { naturezaDoLancamento } from './tipo_lancamento_contabil';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { empresas } from './empresas';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { pipe_fields_id} from './pipe_fields_id';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { areasResponsavelLancamento } from './areasResponsavelLancamento'


@Component({
  selector: 'app-lancamento-contabil',
  templateUrl: './lancamento-contabil.component.html',
  styleUrls: ['./lancamento-contabil.component.css']
})
export class LancamentoContabilComponent implements OnInit {

  formulario!: UntypedFormGroup;
  empresas = empresas;
  naturezaDoLancamento = naturezaDoLancamento
  areaResponsavelLancamentos = areasResponsavelLancamento;
  documentosSuporte: any[] = [];
  template: any[] = []

  documentosSuporteUrls: any[] = []
  templatesUrl:any[] = []

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: UntypedFormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({

      nome:['',[Validators.required]],
      email:['',[Validators.required, Validators.email]],
      nome_da_empresa:['',[Validators.required]],
      business_area_categoria:['',[]],
      data_do_lancamento:['',[Validators.required]],
      area_responsavel_lancamento: ['', [Validators.required]],
      tipo_de_lan_amento_cont_bil:['',[Validators.required]],
      descricao: ['',[Validators.required]],
      impacto_pel:['', [Validators.required]],
      lancamento_revertido_proximo_mes : ['',[Validators.required]],
      lancamento_recorrente: ['',[Validators.required]],
      reversao: ['',[Validators.required]],
      valor: ['',[Validators.required]],
      anexo_suporte_de_documentos: ['',[Validators.required]],
      anexo_template_de_lancamento : ['',[Validators.required]],
      observacao:['',[]],
      data_da_revers_o:['',[]]
      
      
      // conta_a_debito:['',[Validators.required]],
      // conta_a_credito:['',[Validators.required]],
      // conta_cont_bil:['',[]],
      // centro_de_custo:['',[]],
      // centro_de_lucro:['',[Validators.required]],

    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email')?.setValue(this.emailApi)
    // this.formulario.get('nome')?.setValue(this.userNameApi)


    // if(this.formulario.getRawValue().email !=''){
    //   this.formulario.controls['email'].disable()
    // }
    // if(this.formulario.getRawValue().email == undefined){
    //   this.formulario.controls['email'].enable()
    // }

    // if(this.formulario.getRawValue().nome !=''){
    //   this.formulario.controls['nome'].disable()
    // }
    // if(this.formulario.getRawValue().nome == undefined){
    //   this.formulario.controls['nome'].enable()
    // }

  }


  enviarFormulario(){

    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrlDocumentosSuporte(this.documentosSuporte);
      if(this.documentosSuporte.length >0){
        while (this.documentosSuporte.length != this.documentosSuporteUrls.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.documentosSuporte.length>0){
        var documentosSuporteUrls2 = ''
        documentosSuporteUrls2 = '['
        for(var i = 0; i < this.documentosSuporteUrls.length ; i++){
          documentosSuporteUrls2 = documentosSuporteUrls2+'"'+this.documentosSuporteUrls[i]+'",'
        }
        documentosSuporteUrls2 = documentosSuporteUrls2.slice(0, -1)
        documentosSuporteUrls2 = documentosSuporteUrls2+"]"

      }else{
        documentosSuporteUrls2 =''
      }

      await this.getPipesUrlTemplates(this.template);
      if(this.template.length >0){
        while (this.template.length != this.templatesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.template.length>0){
        var templatesUrl2 = ''
         templatesUrl2 = '['
        for(var i = 0; i < this.templatesUrl.length ; i++){
           templatesUrl2 =  templatesUrl2+'"'+this.templatesUrl[i]+'",'
        }
         templatesUrl2 = templatesUrl2.slice(0, -1)
         templatesUrl2 = templatesUrl2+"]"

      }else{
         templatesUrl2 =''
      }

    let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
    let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
    let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
    let data_do_lancamento = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_do_lancamento))
    let areaResponsavelLancamento = this.trataInput.removeCaracters(this.formulario.getRawValue().area_responsavel_lancamento);
    let tipo_de_lan_amento_cont_bil = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_lan_amento_cont_bil)
    let descricao = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao)
    let impactopel = this.trataInput.removeCaracters(this.formulario.getRawValue().impacto_pel);
    let lancamento_revertido_proximo_mes = this.trataInput.removeCaracters(this.formulario.getRawValue().lancamento_revertido_proximo_mes)
    let lancamento_recorrente = this.trataInput.removeCaracters(this.formulario.getRawValue().lancamento_recorrente)
    let reversao = this.trataInput.removeCaracters(this.formulario.getRawValue().reversao)
    let valor = this.trataInput.removeCaracters(this.formulario.getRawValue().valor)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
    let data_da_revers_o = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_revers_o))


    let business_area_categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().business_area_categoria)
    // let conta_a_debito = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_a_debito)
    // let conta_a_credito = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_a_credito)
    // let conta_cont_bil = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_cont_bil)
    // let centro_de_custo = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_custo)
    // let centro_de_lucro = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_lucro)


    let pipe_fields = pipe_fields_id

    let fields_values = [
      nome,
      email, 
      nome_da_empresa,
      data_do_lancamento, 
      areaResponsavelLancamento,
      tipo_de_lan_amento_cont_bil,
      descricao,
      impactopel,
      lancamento_revertido_proximo_mes,
      lancamento_recorrente,
      reversao,
      valor,
      documentosSuporteUrls2,
      templatesUrl2,
      observacao,
      business_area_categoria,
      data_da_revers_o
      ];

      let pipe_id = "303391666"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/3hqn8u5j" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentosSuporteUrls = []
          this.templatesUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Lançamento Contábil", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi,this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      },(error: any) => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3hqn8u5j" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentosSuporteUrls = []
          this.templatesUrl = []
        }
      })
    }
    criarBody()

  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  saveDocumentosSuporte(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++){
      // console.log(files[i].name)
      this.documentosSuporte.push(files[i])
    }

    // console.log(this.documentosSuporte)
    // console.log(this.documentosSuporte.length)
  }

  removeFileDocumentosSuporte(file: File){
    const index: number = this.documentosSuporte.indexOf(file);
      this.documentosSuporte.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.documentosSuporte.length == 0){
        this.formulario.controls['anexo_suporte_de_documentos'].markAsTouched()
        this.formulario.get('anexo_suporte_de_documentos')?.setValue('')
      }
}

async getPipesUrlDocumentosSuporte(files: any) {
  this.erro = false
  for (var i = 0; i < files.length; i++) {
    var fileContent = files[i]
    let fileName = files[i].name
    const reader = new FileReader();
    reader.readAsDataURL(fileContent);
    reader.onload = async () => {
      fileContent = reader.result?.toString()
      var fileContentStart = fileContent.indexOf(",")

      fileContent = fileContent.substring(fileContentStart + 1)
      fileContent = fileContent.toString()

      var binaryString = window.atob(fileContent);
      var byteArray = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++) {
        byteArray[i] += binaryString.charCodeAt(i);
      }/*from w  ww .  j a v a 2  s  .c  o  m*/

      let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


      var fileUrlTemp = "";
      var startIndex = 0;

      if (urlResposta.indexOf("orgs") > 0) {
        startIndex = urlResposta.indexOf("orgs/");
      } else {
        startIndex = urlResposta.indexOf("uploads/");
      }

      var endIndex = urlResposta.indexOf("?");
      fileUrlTemp = urlResposta.substring(startIndex, endIndex);

      this.documentosSuporteUrls.push(fileUrlTemp)
    },
      (error: any) => {
        this.erro = true
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3hqn8u5j" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentosSuporteUrls = []
        }
      }
  }
  return new Promise<void>((resolve) => { resolve(); });
}

saveTemplate(event: any): void {
  var files = event.target.files;

  for (var i = 0; i < files.length; i++){
    // console.log(files[i].name)
    this.template.push(files[i])
  }

  // console.log(this.template)
  // console.log(this.template.length)
}

removeFileTemplate(file: File){
  const index: number = this.template.indexOf(file);
    this.template.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.template.length == 0){
      this.formulario.controls['anexo_template_de_lancamento'].markAsTouched()
      this.formulario.get('anexo_template_de_lancamento')?.setValue('')
    }
}

async getPipesUrlTemplates(files: any) {
  this.erro = false
  for (var i = 0; i < files.length; i++) {
    var fileContent = files[i]
    let fileName = files[i].name
    const reader = new FileReader();
    reader.readAsDataURL(fileContent);
    reader.onload = async () => {
      fileContent = reader.result?.toString()
      var fileContentStart = fileContent.indexOf(",")

      fileContent = fileContent.substring(fileContentStart + 1)
      fileContent = fileContent.toString()

      var binaryString = window.atob(fileContent);
      var byteArray = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++) {
        byteArray[i] += binaryString.charCodeAt(i);
      }/*from w  ww .  j a v a 2  s  .c  o  m*/

      let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


      var fileUrlTemp = "";
      var startIndex = 0;

      if (urlResposta.indexOf("orgs") > 0) {
        startIndex = urlResposta.indexOf("orgs/");
      } else {
        startIndex = urlResposta.indexOf("uploads/");
      }

      var endIndex = urlResposta.indexOf("?");
      fileUrlTemp = urlResposta.substring(startIndex, endIndex);

      this.templatesUrl.push(fileUrlTemp)
    },
      (error: any) => {
        this.erro = true
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3hqn8u5j" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.templatesUrl = []
        }
      }
  }
  return new Promise<void>((resolve) => { resolve(); });
}

resetform() {
  for (let field in this.formulario.controls) {
    this.formulario.controls[field].setValue('');
  }
  this.formulario.markAsUntouched()
  this.documentosSuporte = []
  this.template = []
  this.documentosSuporteUrls = []
  this.templatesUrl =[]

  // console.log(this.documentosSuporte)
  // console.log(this.template)
}

testeValor(event:any){
  let valor = event.target.value
  // console.log(valor)
}

onLancamentoRevertidoChange(event:any){
  let valor = event.target.value
  console.log('valor', valor)
  console.log('data', this.formulario.getRawValue().data_da_revers_o )

  if(valor == 'Sim'){
    this.formulario.get('data_da_revers_o')?.setValidators([Validators.required])
    this.formulario.get('data_da_revers_o')?.updateValueAndValidity()

  }

  if(valor == 'Não'){
    this.formulario.get("data_da_revers_o")?.setValue("");
    this.formulario.get('data_da_revers_o')?.clearValidators()
    this.formulario.get('data_da_revers_o')?.updateValueAndValidity()
  }


}

abrirPipePublico() {
  window.open('https://app.pipefy.com/public/form/3hqn8u5j', '_blank')
}

onChangeAreaResponsavelLancamento(event: any) {
  this.formulario.get("area_responsavel_lancamento")?.setValue(event.target.value);
}

onChangeTipoDeLancamentoContabil(event:any){
  // let valor = event.target.value
  // console.log(valor)

  // if(valor == 'Reclassificação'){
  //   this.formulario.get('conta_cont_bil')?.setValidators([Validators.required])
  //   this.formulario.get('conta_cont_bil')?.updateValueAndValidity()

  //   this.formulario.get('lancamento_revertido_proximo_mes')?.clearValidators()
  //   this.formulario.get('lancamento_revertido_proximo_mes')?.updateValueAndValidity()
  //   this.formulario.get('lancamento_revertido_proximo_mes')?.setValue('')

  //   this.formulario.get('lancamento_recorrente')?.clearValidators()
  //   this.formulario.get('lancamento_recorrente')?.updateValueAndValidity()
  //   this.formulario.get('lancamento_recorrente')?.setValue('')
    
  // }

  // if(valor !='Reclassificação'){
  //   this.formulario.get('conta_cont_bil')?.clearValidators()
  //   this.formulario.get('conta_cont_bil')?.updateValueAndValidity()

  //   this.formulario.get('lancamento_revertido_proximo_mes')?.setValidators([Validators.required])
  //   this.formulario.get('lancamento_revertido_proximo_mes')?.updateValueAndValidity()

  //   this.formulario.get('lancamento_recorrente')?.setValidators([Validators.required])
  //   this.formulario.get('lancamento_recorrente')?.updateValueAndValidity()
  // }
}
}


      
