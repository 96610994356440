<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste"
  titulo="Contabilidade">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Contábil - Lançamentos Manuais"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

    <form [formGroup]="formulario" (submit)="enviarFormulario()">

      <div class="form-group row">
        <label for="nome" class="col-sm-4 col-form-label">*Nome do Solicitante: </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="nome">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">*E-mail do Solicitante: </label>
        <div class="col-sm-8">
          <input type="email"  class="form-control" formControlName="email">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"  mensagem ="Formato de e-mail inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Empresa Aplicável: </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="nome_da_empresa">
            <option value="">Selecione...</option>
            <!-- <option value="7805 - Nutrien Participações">7805 - Nutrien Participações</option> -->
            <option value="7806 - Nutrien Soluções Agrícolas (Utilertil)">7806 - Nutrien Soluções Agrícolas (Utilertil)</option>
            <option value="7813 - Agrichem">7813 - Agrichem</option>
            <option value="7820 - APMS">7820 - APMS</option>
            <option value="7821 - Sementes GOIAS (SGL)">7821 - Sementes GOIAS (SGL)</option>
            <option value="7822 - Fronteira Comercio (FCR)">7822 - Fronteira Comercio (FCR)</option>
            <option value="7830 - Terra Nova">7830 - Terra Nova</option>
            <option value="7832 - Confiança">7832 - Confiança</option>
            <option value="7840 - Bio Rural">7840 - Bio Rural</option>
            <!-- <option value="7850 - Marca Agro">7850 - Marca Agro</option> -->
            <option value="7860 - Casa do Adubo">7860 - Casa do Adubo</option>
            <option value="7861 - Casal">7861 - Casal</option>
            <option value="7870 - Safra Rica">7870 - Safra Rica</option>

          </select>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="business_area_categoria" class="col-sm-4 col-form-label">Área Solicitante </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="business_area_categoria">
          <!-- <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('business_area_categoria')?.errors?.required && formulario.get('business_area_categoria')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem> -->
        </div>
      </div>

      <div class="form-group row">
        <label for="data_do_lancamento" class="col-sm-4 col-form-label">*Mês e Ano de Competência</label>
        <div class="col-sm-8">
          <input type="date"  class="form-control" formControlName="data_do_lancamento">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('data_do_lancamento')?.errors?.required && formulario.get('data_do_lancamento')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>



      <div class="form-group row">
        <label for="area_responsavel_lancamento" class="col-sm-4 col-form-label">*Área responsável pelo Lançamento </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="area_responsavel_lancamento" (change)="onChangeAreaResponsavelLancamento($event)">
            <option *ngFor="let area of areaResponsavelLancamentos" [value]="area" >{{area}}</option>
          </select>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('area_responsavel_lancamento')?.errors?.required && formulario.get('area_responsavel_lancamento')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="tipo_de_lan_amento_cont_bil" class="col-sm-4 col-form-label">*Natureza do Lançamento </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="tipo_de_lan_amento_cont_bil" (change)="onChangeTipoDeLancamentoContabil($event)">
            <option *ngFor="let naturezaDoLancamento of naturezaDoLancamento" [value]="naturezaDoLancamento.tipo" >{{naturezaDoLancamento.tipo}}</option>
          </select>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('tipo_de_lan_amento_cont_bil')?.errors?.required && formulario.get('tipo_de_lan_amento_cont_bil')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="descricao" class="col-sm-4 col-form-label">*Descrição do Lançamento</label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName = "descricao"></textarea>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('descricao')?.errors?.required && formulario.get('descricao')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="impacto_pel" class="col-sm-4 col-form-label">*Impacto no P&L?</label>
        <div class="col-sm-8">
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='impacto_pel' value="Sim" class='radio-btn'>&nbsp;Sim
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='impacto_pel' value='Não' class='radio-btn'>&nbsp;Não
          </label>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('impacto_pel')?.errors?.required && formulario.get('impacto_pel')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="conta_a_debito" class="col-sm-4 col-form-label">*Conta a débito </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="conta_a_debito">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('conta_a_debito')?.errors?.required && formulario.get('conta_a_debito')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <!-- <div class="form-group row">
        <label for="conta_a_credito" class="col-sm-4 col-form-label">*Conta a crédito </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="conta_a_credito">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('conta_a_credito')?.errors?.required && formulario.get('conta_a_credito')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <!-- <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_lan_amento_cont_bil=='Reclassificação'">
        <label for="conta_cont_bil" class="col-sm-4 col-form-label">*Conta Contábil </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="conta_cont_bil">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('conta_cont_bil')?.errors?.required && formulario.get('conta_cont_bil')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->


      <!-- <div class="form-group row">
        <label for="centro_de_custo" class="col-sm-4 col-form-label">Centro de custo </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="centro_de_custo">
        </div>
      </div> -->

      <!-- <div class="form-group row">
        <label for="centro_de_lucro" class="col-sm-4 col-form-label">*Centro de lucro </label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="centro_de_lucro">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('centro_de_lucro')?.errors?.required && formulario.get('centro_de_lucro')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
      <div class="form-group row">
        <label for="lancamento_revertido_proximo_mes" class="col-sm-4 col-form-label">*O lançamento deverá ser revertido no próximo mês?</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='lancamento_revertido_proximo_mes' value="Sim" class='radio-btn' (change)="onLancamentoRevertidoChange($event)">&nbsp;Sim
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='lancamento_revertido_proximo_mes' value='Não' class='radio-btn' (change)="onLancamentoRevertidoChange($event)">&nbsp;Não
          </label>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('lancamento_revertido_proximo_mes')?.errors?.required && formulario.get('lancamento_revertido_proximo_mes')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

       

      <div *ngIf="formulario.getRawValue().lancamento_revertido_proximo_mes=='Sim'">
   
        <div class="form-group row">
          <label for="data_da_revers_o" class="col-sm-4 col-form-label">*Data da reversão</label>
          <div class="col-sm-8">
            <input type="date"  class="form-control" formControlName="data_da_revers_o">
            <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('data_da_revers_o')?.errors?.required && formulario.get('data_da_revers_o')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>
      
      
      <div class="form-group row">
        <label for="lancamento_recorrente" class="col-sm-4 col-form-label">*O lançamento é recorrente?</label>
        <div class="col-sm-8">
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='lancamento_recorrente' value="Sim" class='radio-btn' (change)="testeValor($event)">&nbsp;Sim
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='lancamento_recorrente' value='Não' class='radio-btn' (change)="testeValor($event)">&nbsp;Não
          </label>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('lancamento_recorrente')?.errors?.required && formulario.get('lancamento_recorrente')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="reversao" class="col-sm-4 col-form-label">*Reversão</label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="reversao">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('reversao')?.errors?.required && formulario.get('reversao')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor" class="col-sm-4 col-form-label">*Valor</label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="valor" mask="separator.2" thousandSeparator="."  >
          <small>Informar apenas números. Ex: 1.111.111</small>
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('valor')?.errors?.required && formulario.get('valor')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>



      <div class="form-group row">
        <label for="anexo_suporte_de_documentos" class="col-sm-4 col-form-label">*Anexo - Suporte do Lançamento </label>
        <div class="col-sm-8">
          <input type = "file" class="form-control form-anexo" aria-label="With textarea" formControlName = "anexo_suporte_de_documentos" id="formFileMultiple"  (change)="saveDocumentosSuporte($event)" multiple="multiple">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('anexo_suporte_de_documentos')?.errors?.required && formulario.get('anexo_suporte_de_documentos')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentosSuporte.length!=0">
        <div class="card-header">Anexo - Suporte do lançamento</div>
        <ul class="list-group list-group-flush" *ngFor="let file of documentosSuporte">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileDocumentosSuporte(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="anexo_template_de_lancamento" class="col-sm-4 col-form-label">*Anexar o template de lançamento </label>
        <div class="col-sm-8">
          <input type = "file" class="form-control form-anexo" aria-label="With textarea" formControlName = "anexo_template_de_lancamento" id="formFileMultiple"  (change)="saveTemplate($event)" multiple="multiple">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('anexo_template_de_lancamento')?.errors?.required && formulario.get('anexo_template_de_lancamento')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="template.length!=0">
        <div class="card-header">Anexo - Template de Lançamento</div>
        <ul class="list-group list-group-flush" *ngFor="let file of template">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileTemplate(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>



      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName = "observacao"></textarea>
        </div>
      </div>

      <div class="form-group pt-1 col-lg-3 box-botao">
        <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
      </div>
      <!-- <div class="box-dificuldade">
        <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
            (click)="abrirPipePublico()">Envie por aqui.</span></p>
      </div> -->

    </form>
  </div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
