import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide:boolean = true

  button: any

  loginForm!: UntypedFormGroup;
  login_invalido: boolean = false


  private usuarioEmailValidator = [
    Validators.maxLength(100),
    Validators.minLength(10),
  ]



  constructor(private msalService:MsalService, private router:Router,
    private formBuilder:UntypedFormBuilder,
    ) { }

  ngOnInit(): void {

    this.msalService.instance.handleRedirectPromise().then(
      res => {
        if (res !== null && res.account != null) {
          this.msalService.instance.setActiveAccount(res.account)
        }
      }
    )

    // this.loginForm = this.formBuilder.group({
    //  controls:{
    //    buton: [this.login(),
    //     Validators.required]
    //  }
    // })
  }

  login(): void {
    console.log("clicado")
   this.msalService.loginRedirect()
    // this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
    //   this.msalService.instance.setActiveAccount(response.account)
    // })
  }

  logout():void {
    this.msalService.logout()
 }


 isLoggedIn():boolean {
  return this.msalService.instance.getActiveAccount() !=null
}

}

