import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { tiposDeNotas } from './tipo-de-nota';
import { empresas } from './empresas';
import { quantidadeItens } from './quantidadeItens';
import { pipe_fields_ids } from './pipe_fields_ids';
import { HttpErrorResponse } from '@angular/common/http';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { status } from './../../../consulta-de-chamados/status';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { lista_cnpjs_empresa_nutrien } from './lista_cnpjs_empresa_nutrien';
import { Validadores } from 'src/app/componentes/formMat/Validatores';
import { environment } from 'src/environments/environment';
import { estados } from "./estados";
import { parcelaAluguel } from './parcelaAluguel';
import { mesAluguel } from './mesAluguel';
import { anoAluguel } from './anoAluguel';

@Component({
  selector: 'app-solicitacao-de-lancamentos-de-notas',
  templateUrl: './solicitacao-de-lancamentos-de-notas.component.html',
  styleUrls: ['./solicitacao-de-lancamentos-de-notas.component.css']
})
export class SolicitacaoDeLancamentosDeNotasComponent implements OnInit {



  formulario!: UntypedFormGroup;
  arquivosDeRateio: any[] = [];
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []
  documentacaoSuporte: any[] = [];
  nfProdutoOriginal: any[] = []

  listaDeItensDaPo: any[] = [];
  riscoSacado: boolean = false;
  dacteCorreto: boolean = false;
  notaComRateios: boolean = false;
  tiposDeNotas = tiposDeNotas;
  empresas = empresas;
  quantidadeDeItens = quantidadeItens

  notaComRateioDeValoresString: string = ''
  riscoSacadoString: string = ''
  dacteCorretoString: string = ''

  arquivosDeRateioUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl: any[] = []
  documentacaoSuporteUrl: any[] = []
  nfProdutoOriginalUrl: any[] = []
  listaDeItensDaPoUrl: any[] = []

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  custoComplementar: boolean = false;
  debitoPosterior: boolean = false
  informacoesComplementaresPo: any[] = []

  listaEmpresasCnpjs = lista_cnpjs_empresa_nutrien;

  dataAtual: any = '';
  valorTotalNfAlterado: any = 0;

  estados = estados;
  parcelaAluguel = parcelaAluguel;
  mesAluguel = mesAluguel;
  anoAluguel = anoAluguel;

  horaInicioChamadaMost: string = ''
  horaFimChamadaMost: string = ''

  horaTrocaDaEmpresa: any
  horaCriacaoDoCard: any
  tempoDePreenchimentoDoFormulario: any

  trocouEmpresa: boolean = false

  horaTrocaDaEmpresaFormatada: any
  horaCriacaoDoCardFormatada: any

  list_anexo_para_pagamento_de_aut_nomo: any[] = [];
  list_anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  list_anexar_nota_emitida_na_prefeitura: any = [];

  anexo_para_pagamento_de_aut_nomo: any[] = [];
  anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  anexar_nota_emitida_na_prefeitura: any = [];

  list_url_anexo_para_pagamento_de_aut_nomo: any[] = [];
  list_url_anexar_registro_do_pagamento_de_aut_nomo: any[] = [];
  list_url_anexar_nota_emitida_na_prefeitura: any = [];


  registros: any[]= []
  registrosUrl:any[]=[]

  contPopUp: number = 0;


  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }


  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required]],
      // risco_sacado: ['', []],
      qual_a_quantidade_de_cte: ['', []],
      tipo_de_nota: ['', [Validators.required]],
      conta_contabil: ['', []],
      documento_do_fornecedor: ['', []],
      cpf_do_fornecedor: ['', []],
      cnpj_fornecedor: ['', [Validadores.cnpj]],
      nome_do_fornecedor: ['', [Validators.required]],
      cnpj_da_empresa_nutrien: ['', [Validators.required, Validadores.cnpj]],
      nome_empresa_nutrien: ['', [Validators.required]],
      nro_nota: ['', []],
      // parcela_do_aluguel: ['', []],
      m_s_do_aluguel: ['', []],
      ano_do_aluguel: ['', []],
      data_de_envio_de_nf: ['', [Validators.required]],
      valor_total_da_nf: ['', [Validators.required]],
      nota_com_rasteio_de_valores: [, []],
      arquivo_de_rateio: ['', []],
      data_de_vencimento_da_nota: ['', [Validators.required]],
      departamento: ['', []],
      numero_po: ['', []],
      quantidade_de_itens_do_pedido: ['', []],
      numero_do_item_da_po: ['', []],
      lista_de_itens_da_po: ['', []],
      centro_de_custo: ['', []],
      anexo_nota_ou_boleto: ['', []],
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
      anexo_documentacao_suporte: ['', []],
      nota_para_pagamento_fora_da_politica: ['', []],
      nota_para_pagamento_urgente: ['', []],
      motivo_da_excecao: ['', []],
      observacao: ['', []],
      informacoes_complementares_da_po: ['', []],

      nf_do_produto_original: ['', []],
      tipo_de_custo_planejado: ['', []],
      n_mero_de_cte: ['', []],
      opex_e_capex: ['', []],
      tipo_de_importa_o: ['', []],
      tipo_de_pagamento: ['', []],

      n_mero_afe: ['', []],
      estado_de_origem: ['', []],
      estado_de_destino: ['', []],


      certeza_que_incluiu_o_arquivo_conforme_orienta_o: ['', []],
      grc: ['', []],


      confirma_entrega_ou_prestacao: ['', [Validators.required]],
      motivo_negacao: ['', []],

      anexo_para_pagamento_de_aut_nomo: ['', []],
      cont_m_registro_na_prefeitura: ['', []],
      anexar_nota_emitida_na_prefeitura: ['', []],
      anexar_registro_do_pagamento_de_aut_nomo: ['', []]

    });

    if(environment.localhost || environment.dev) {
      this.formulario.valueChanges.subscribe( ()=> {
        let descricao = true;
          Object.getOwnPropertyNames(this.formulario.getRawValue()).forEach ( (nome, index)=> {
            const campo = this.formulario.get(nome)?.errors;
            if(!!campo) {
              if(descricao) {
                descricao = false;
                console.log("Erro nos campos abaixo:");
              }
              console.log("Campo "+ nome + ": ", campo);
            }
          });
      });
    }


    this.emailApi = this.userInfo.getUserEmail()
    this.userNameApi = this.userInfo.getName()

    this.emailApi = !!this.userInfo.getUserEmail() ? this.userInfo.getUserEmail() : this.formulario.get('email')?.value;
    this.userNameApi = !!this.userInfo.getName() ? this.userInfo.getName() : this.formulario.get('nome')?.value;

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)


    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }

    this.getDataAtual();
    this.formataValorDecimal('0');
    this.getUserEmail();
  }

  enviarFormulario() {

    this.spinner.show()


    this.horaCriacaoDoCard = Date.now()
    this.horaCriacaoDoCardFormatada = new Date()
    this.horaCriacaoDoCardFormatada = this.horaCriacaoDoCardFormatada.getHours() + ':' + this.horaCriacaoDoCardFormatada.getMinutes() + ':' + this.horaCriacaoDoCardFormatada.getSeconds()
    // console.log("hora criação do card", this.horaCriacaoDoCard)

    this.getTotalTempoAberturaDoCard(this.horaTrocaDaEmpresa, this.horaCriacaoDoCard)

    this.trocouEmpresa = false



    const criarBody = async () => {



      await this.getAnexoRegistroDePagamentoDeAutonomo(this.list_anexar_registro_do_pagamento_de_aut_nomo);
      if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        while (this.list_anexar_registro_do_pagamento_de_aut_nomo.length != this.list_anexar_registro_do_pagamento_de_aut_nomo.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        var list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = ''
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = '['
        for (var i = 0; i < this.list_anexar_registro_do_pagamento_de_aut_nomo.length; i++) {
          list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2 + '"' + this.list_anexar_registro_do_pagamento_de_aut_nomo[i] + '",'
        }
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2.slice(0, -1)
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = list_anexar_registro_do_pagamento_de_aut_nomoUrl2 + "]"

      } else {
        list_anexar_registro_do_pagamento_de_aut_nomoUrl2 = ''
      }


      await this.getAnexoParaPagamentoDeAutonomo(this.list_anexo_para_pagamento_de_aut_nomo);
      if (this.list_anexo_para_pagamento_de_aut_nomo.length > 0) {
        while (this.list_anexo_para_pagamento_de_aut_nomo.length != this.list_url_anexo_para_pagamento_de_aut_nomo.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexo_para_pagamento_de_aut_nomo.length > 0) {
        var list_anexo_para_pagamento_de_aut_nomoUrl2 = ''
        list_anexo_para_pagamento_de_aut_nomoUrl2 = '['
        for (var i = 0; i < this.list_url_anexo_para_pagamento_de_aut_nomo.length; i++) {
          list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2 + '"' + this.list_url_anexo_para_pagamento_de_aut_nomo[i] + '",'
        }
        list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2.slice(0, -1)
        list_anexo_para_pagamento_de_aut_nomoUrl2 = list_anexo_para_pagamento_de_aut_nomoUrl2 + "]"

      } else {
        list_anexo_para_pagamento_de_aut_nomoUrl2 = ''
      }


      await this.getAnexoNotaEmitidaPrefeituraParaPagamento(this.list_anexar_nota_emitida_na_prefeitura);
      if (this.list_anexar_nota_emitida_na_prefeitura.length > 0) {
        while (this.list_anexar_nota_emitida_na_prefeitura.length != this.list_url_anexar_nota_emitida_na_prefeitura.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.list_anexar_nota_emitida_na_prefeitura.length > 0) {
        var list_anexar_nota_emitida_na_prefeituraUrl2 = ''
        list_anexar_nota_emitida_na_prefeituraUrl2 = '['
        for (var i = 0; i < this.list_url_anexar_nota_emitida_na_prefeitura.length; i++) {
          list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2 + '"' + this.list_url_anexar_nota_emitida_na_prefeitura[i] + '",'
        }
        list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2.slice(0, -1)
        list_anexar_nota_emitida_na_prefeituraUrl2 = list_anexar_nota_emitida_na_prefeituraUrl2 + "]"

      } else {
        list_anexar_nota_emitida_na_prefeituraUrl2 = ''
      }



      await this.getAnexoRegistroDePagamentoDeAutonomo2(this.anexar_registro_do_pagamento_de_aut_nomo);

      console.log(this.anexar_registro_do_pagamento_de_aut_nomo)
      console.log(this.anexar_registro_do_pagamento_de_aut_nomo.length)

      if (this.anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        while (this.anexar_registro_do_pagamento_de_aut_nomo.length != this.list_url_anexar_registro_do_pagamento_de_aut_nomo.length) {
          console.log("esperando2....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }


      if (this.anexar_registro_do_pagamento_de_aut_nomo.length > 0) {
        var anexarRegistrosPagamentoAutonomoeUrl2 = ''
        anexarRegistrosPagamentoAutonomoeUrl2 = '['
        for (var i = 0; i < this.list_url_anexar_registro_do_pagamento_de_aut_nomo.length; i++) {
          anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2 + '"' + this.list_url_anexar_registro_do_pagamento_de_aut_nomo[i] + '",'
        }
        anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2.slice(0, -1)
        anexarRegistrosPagamentoAutonomoeUrl2 = anexarRegistrosPagamentoAutonomoeUrl2 + "]"

      } else {
        anexarRegistrosPagamentoAutonomoeUrl2 = ''
      }

      console.log(anexarRegistrosPagamentoAutonomoeUrl2)



      await this.getPipesUrlArquivoRateio(this.arquivosDeRateio);
      if (this.arquivosDeRateio.length > 0) {
        while (this.arquivosDeRateio.length != this.arquivosDeRateioUrl.length) {
          console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.arquivosDeRateio.length > 0) {
        var arquivosDeRateioUrl2 = ''
        arquivosDeRateioUrl2 = '['
        for (var i = 0; i < this.arquivosDeRateioUrl.length; i++) {
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + '"' + this.arquivosDeRateioUrl[i] + '",'
        }
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2.slice(0, -1)
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + "]"

      } else {
        arquivosDeRateioUrl2 = ''
      }

      await this.getPipesUrlNota(this.notas);
      // console.log(this.notas)
      // console.log(this.notas.length)
      // console.log(this.notasUrl)

      if (this.notas.length > 0) {
        while (this.notas.length != this.notasUrl.length) {
          console.log("esperando2....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }


      if (this.notas.length > 0) {
        var notasUrl2 = ''
        notasUrl2 = '['
        for (var i = 0; i < this.notasUrl.length; i++) {
          notasUrl2 = notasUrl2 + '"' + this.notasUrl[i] + '",'
        }
        notasUrl2 = notasUrl2.slice(0, -1)
        notasUrl2 = notasUrl2 + "]"

      } else {
        notasUrl2 = ''
      }

      await this.getPipesUrlBoleto(this.boletos);
      // console.log(this.boletos)
      // console.log(this.boletos.length)
      // console.log(this.boletosUrl)

      if (this.boletos.length > 0) {
        while (this.boletos.length != this.boletosUrl.length) {
          console.log("esperando3....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.boletos.length > 0) {
        var boletosUrl2 = ''
        boletosUrl2 = '['
        for (var i = 0; i < this.boletosUrl.length; i++) {
          boletosUrl2 = boletosUrl2 + '"' + this.boletosUrl[i] + '",'
        }
        boletosUrl2 = boletosUrl2.slice(0, -1)
        boletosUrl2 = boletosUrl2 + "]"

      } else {
        boletosUrl2 = ''
      }

      await this.getPipesUrlXML(this.xml);
      // console.log(this.xml)
      // console.log(this.xml.length)
      // console.log(this.xmlUrl)

      if (this.xml.length > 0) {
        while (this.xml.length != this.xmlUrl.length) {
          console.log("esperando4....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.xml.length > 0) {
        var xmlUrl2 = ''
        xmlUrl2 = '['
        for (var i = 0; i < this.xmlUrl.length; i++) {
          xmlUrl2 = xmlUrl2 + '"' + this.xmlUrl[i] + '",'
        }
        xmlUrl2 = xmlUrl2.slice(0, -1)
        xmlUrl2 = xmlUrl2 + "]"

      } else {
        xmlUrl2 = ''
      }


      await this.getPipesUrlDocumentacaoSuporte(this.documentacaoSuporte);
      if (this.documentacaoSuporte.length > 0) {
        while (this.documentacaoSuporte.length != this.documentacaoSuporteUrl.length) {
          console.log("esperando5....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.documentacaoSuporte.length > 0) {
        var documentacaoSuporteUrl2 = ''
        documentacaoSuporteUrl2 = '['
        for (var i = 0; i < this.documentacaoSuporteUrl.length; i++) {
          documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + '"' + this.documentacaoSuporteUrl[i] + '",'
        }
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2.slice(0, -1)
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + "]"

      } else {
        documentacaoSuporteUrl2 = ''
      }



      await this.getPipesUrlRegistro(this.registros);
      console.log(this.registros)
      console.log(this.registros.length)
      if (this.registros.length > 0) {
        while (this.registros.length != this.registrosUrl.length) {
          console.log("esperando registros....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.registros.length > 0) {

        var registrosUrl2 = ''
        registrosUrl2 = '['
        for (var i = 0; i < this.registrosUrl.length; i++) {
          registrosUrl2 = registrosUrl2 + '"' + this.registrosUrl[i] + '",'
        }
        registrosUrl2 = registrosUrl2.slice(0, -1)
        registrosUrl2 = registrosUrl2 + "]"

      } else {
        registrosUrl2 = ''
      }

      await this.getPipesUrlNfProdutoOriginal(this.nfProdutoOriginal);
      if (this.nfProdutoOriginal.length > 0) {
        while (this.nfProdutoOriginal.length != this.nfProdutoOriginalUrl.length) {
          console.log("esperando6....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.nfProdutoOriginal.length > 0) {
        var nfProdutoOriginalUrl2 = ''
        nfProdutoOriginalUrl2 = '['
        for (var i = 0; i < this.nfProdutoOriginalUrl.length; i++) {
          nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + '"' + this.nfProdutoOriginalUrl[i] + '",'
        }
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2.slice(0, -1)
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + "]"

      } else {
        nfProdutoOriginalUrl2 = ''
      }

      await this.getPipesUrlListaItensDaPo(this.listaDeItensDaPo);
      if (this.listaDeItensDaPo.length > 0) {
        while (this.listaDeItensDaPo.length != this.listaDeItensDaPoUrl.length) {
          console.log("esperando7....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.listaDeItensDaPo.length > 0) {
        var listaDeItensDaPoUrl2 = ''
        listaDeItensDaPoUrl2 = '['
        for (var i = 0; i < this.listaDeItensDaPoUrl.length; i++) {
          listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + '"' + this.listaDeItensDaPoUrl[i] + '",'
        }
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2.slice(0, -1)
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + "]"

      } else {
        listaDeItensDaPoUrl2 = ''
      }

      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email) + '@nutrien.com';
      // let risco_sacado = this.trataInput.removeCaracters(this.formulario.getRawValue().risco_sacado)
      let tipo_de_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_nota)
      let qual_a_quantidade_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().qual_a_quantidade_de_cte)
      let conta_contabil = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_contabil)
      let documento_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().documento_do_fornecedor)
      let cpf_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf_do_fornecedor)
      let cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj_fornecedor)
      let nome_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_fornecedor)
      let cnpj_da_empresa_nutrien = this.formulario.getRawValue().cnpj_da_empresa_nutrien.replaceAll('.', '').replaceAll("/", "").replaceAll("-", "");
      let nome_empresa_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_empresa_nutrien)
      let nro_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().nro_nota)
      let data_de_envio_de_nf = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_envio_de_nf)
      let valor_total_da_nf = this.valorTotalNfAlterado;
      let data_de_vencimento_da_nota = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento_da_nota))
      let departamento = this.trataInput.removeCaracters(this.formulario.getRawValue().departamento)
      let numero_po = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_po)
      let quantidade_de_itens_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_de_itens_do_pedido)
      let numero_do_item_da_po = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_item_da_po)

      let centro_de_custo = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_custo)
      let nota_para_pagamento_fora_da_politica = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_fora_da_politica)
      let nota_para_pagamento_urgente = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_urgente)
      let motivo_da_excecao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_excecao)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
      let informacoes_complementares_da_po = '[' + this.informacoesComplementaresPo + ']'

      let tipo_de_custo_planejado = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_custo_planejado)
      let n_mero_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_de_cte)
      let opex_e_capex = this.trataInput.removeCaracters(this.formulario.getRawValue().opex_e_capex)
      let tipo_de_importa_o = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_importa_o)
      let tipo_de_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_pagamento)

      let n_mero_afe = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_afe)
      let estado_de_origem = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_origem)
      let estado_de_destino = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_destino)
      let grc = this.trataInput.removeCaracters(this.formulario.getRawValue().grc)
      // let parcela_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().parcela_do_aluguel)
      let m_s_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().m_s_do_aluguel)
      let ano_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().ano_do_aluguel)
      let confirma_entrega_ou_prestacao = this.trataInput.removeCaracters(this.formulario.getRawValue().confirma_entrega_ou_prestacao)
      let motivo_negacao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_negacao)

      let anexo_para_pagamento_de_aut_nomo = this.trataInput.removeCaracters(this.formulario.getRawValue().anexo_para_pagamento_de_aut_nomo);
      let cont_m_registro_na_prefeitura = this.trataInput.removeCaracters(this.formulario.getRawValue().cont_m_registro_na_prefeitura);

      // console.log('departamento', departamento)

      let pipe_fields = pipe_fields_ids
      let fields_values = [
        nome,
        email,
        // this.riscoSacadoString,
        tipo_de_nota,
        qual_a_quantidade_de_cte,
        conta_contabil,
        documento_do_fornecedor,
        cpf_do_fornecedor,
        cnpj_fornecedor,
        nome_do_fornecedor,
        cnpj_da_empresa_nutrien,
        nome_empresa_nutrien,
        nro_nota,
        data_de_envio_de_nf,
        valor_total_da_nf,
        this.notaComRateioDeValoresString,
        arquivosDeRateioUrl2,
        data_de_vencimento_da_nota,
        departamento,
        numero_po,
        quantidade_de_itens_do_pedido,
        numero_do_item_da_po,
        listaDeItensDaPoUrl2,
        centro_de_custo,
        notasUrl2,
        this.dacteCorretoString,
        documentacaoSuporteUrl2,
        nota_para_pagamento_fora_da_politica,
        nota_para_pagamento_urgente,
        motivo_da_excecao,
        observacao,
        informacoes_complementares_da_po,
        boletosUrl2,
        xmlUrl2,
        nfProdutoOriginalUrl2,
        tipo_de_custo_planejado,
        n_mero_de_cte,
        opex_e_capex,
        tipo_de_importa_o,
        tipo_de_pagamento,
        n_mero_afe,

        // this.arquivosDeRateioUrl,
        // this.listaDeItensDaPoUrl,
        // this.notasUrl,
        // this.documentacaoSuporteUrl,
        // this.xmlUrl,
        // this.boletosUrl,
        // this.nfProdutoOriginalUrl,
        1,
        estado_de_origem,
        estado_de_destino,
        grc,
        // parcela_do_aluguel,
        m_s_do_aluguel,
        ano_do_aluguel,
        confirma_entrega_ou_prestacao,
        motivo_negacao,
        this.horaTrocaDaEmpresaFormatada,
        this.horaCriacaoDoCardFormatada,
        this.tempoDePreenchimentoDoFormulario,
        list_anexo_para_pagamento_de_aut_nomoUrl2,
        cont_m_registro_na_prefeitura,
        registrosUrl2,
        list_anexar_nota_emitida_na_prefeituraUrl2

      ]


      this.pipefy.criaBody(pipe_fields, fields_values, '302487539')


      this.pipefy.createCard2(this.pipefy.criaBody(pipe_fields, fields_values, '302487539')).subscribe((res) => {
        console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
          this.list_url_anexar_nota_emitida_na_prefeitura = []
          this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          this.list_anexo_para_pagamento_de_aut_nomo = []
          this.registros = []


          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi, this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
          this.list_url_anexar_nota_emitida_na_prefeitura = []
          this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          this.list_anexo_para_pagamento_de_aut_nomo = []
          this.registros = []
        }
      })

    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  saveArquivoRateio(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosDeRateio.push(files[i])
    }
  }

  removeFileArquivoRateio(file: File) {
    const index: number = this.arquivosDeRateio.indexOf(file);
    this.arquivosDeRateio.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.arquivosDeRateio.length == 0) {
      this.formulario.controls['arquivo_de_rateio'].markAsTouched()
      this.formulario.get('arquivo_de_rateio')?.setValue('')
    }
  }

  async getPipesUrlArquivoRateio(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.arquivosDeRateioUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosDeRateioUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoNotaEmitidaPrefeituraParaPagamento(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.list_url_anexar_nota_emitida_na_prefeitura.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexar_nota_emitida_na_prefeitura = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoParaPagamentoDeAutonomo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.list_url_anexo_para_pagamento_de_aut_nomo.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexo_para_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoRegistroDePagamentoDeAutonomo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);
        this.list_url_anexar_registro_do_pagamento_de_aut_nomo.push(fileUrlTemp)

      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getAnexoRegistroDePagamentoDeAutonomo2(files: any) {
    console.log("entreiiiiiiiiii")
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);
        console.log("ieieieie",fileUrlTemp)

        this.list_anexar_registro_do_pagamento_de_aut_nomo.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.list_url_anexo_para_pagamento_de_aut_nomo = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoNota(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.notas.push(files[i])
    }
  }

  removeFileNota(file: File) {
    const index: number = this.notas.indexOf(file);
    this.notas.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.notas.length == 0) {
      this.formulario.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_ou_boleto')?.setValue('')

    }
  }

  async getPipesUrlNota(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)
        // let url = res.data.attachments_url_pipefy[0]
        // this.arquivosNfBoletoUrl.push(url)
        console.log(urlResposta)
        // urlResposta = urlResposta.body.data.createPresignedUrl.url

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.notasUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.notasUrl = []
          }


        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.boletos.push(files[i])
    }
  }

  // save_anexar_nota_emitida_na_prefeitura(event: any): void {
  //   var files = event.target.files;

  //   for (var i = 0; i < files.length; i++) {
  //     this.list_anexar_nota_emitida_na_prefeitura.push(files[i])
  //   }
  // }

  // remove_anexar_nota_emitida_na_prefeitura(file: File) {
  //   const index: number = this.list_anexar_nota_emitida_na_prefeitura.indexOf(file);
  //   this.list_anexar_nota_emitida_na_prefeitura.splice(index, 1)
  //   Swal.fire({
  //     position: 'top-end',
  //     icon: 'success',
  //     title: 'Arquivo Removido com Sucesso.',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   if (this.list_anexar_nota_emitida_na_prefeitura.length == 0) {
  //     this.formulario.controls['anexar_nota_emitida_na_prefeitura'].markAsTouched()
  //     this.formulario.get('anexar_nota_emitida_na_prefeitura')?.setValue('')
  //   }
  // }


  save_anexar_registro_do_pagamento_de_aut_nomo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.list_anexar_registro_do_pagamento_de_aut_nomo.push(files[i])
    }
    console.log(this.list_anexar_registro_do_pagamento_de_aut_nomo)
  }

  remove_anexar_registro_do_pagamento_de_aut_nomo(file: File) {
    const index: number = this.list_anexar_registro_do_pagamento_de_aut_nomo.indexOf(file);
    this.list_anexar_registro_do_pagamento_de_aut_nomo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length == 0) {
      this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
      this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
    }
  }


  save_anexo_para_pagamento_de_aut_nomo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.list_anexo_para_pagamento_de_aut_nomo.push(files[i])
    }

    Object.getOwnPropertyNames(this.formulario.getRawValue()).forEach( v=> {
      console.log(v, this.formulario.get(v)?.errors)
    })
    // console.log(this.formulario.getRawValue());
  }

  remove_anexo_para_pagamento_de_aut_nomo(file: File) {
    const index: number = this.list_anexo_para_pagamento_de_aut_nomo.indexOf(file);
    this.list_anexo_para_pagamento_de_aut_nomo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.list_anexo_para_pagamento_de_aut_nomo.length == 0) {
      this.formulario.controls['anexo_para_pagamento_de_aut_nomo'].markAsTouched()
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.setValue('')
    }
  }

  removeFileBoleto(file: File) {
    const index: number = this.boletos.indexOf(file);
    this.boletos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.boletos.length == 0) {
      this.formulario.controls['anexo_boleto'].markAsTouched()
      this.formulario.get('anexo_boleto')?.setValue('')
    }
  }

  async getPipesUrlBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.boletosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.boletosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoXML(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.xml.push(files[i])
    }
  }

  removeFileXML(file: File) {
    const index: number = this.xml.indexOf(file);
    this.xml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.xml.length == 0) {
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlXML(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.xmlUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.xmlUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoDocumentacaoSuporte(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.documentacaoSuporte.push(files[i])
    }
  }

  removeFilDocumentacaoSuporte(file: File) {
    const index: number = this.documentacaoSuporte.indexOf(file);
    this.documentacaoSuporte.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.documentacaoSuporte.length == 0) {
      this.formulario.controls['anexo_documentacao_suporte'].markAsTouched()
      this.formulario.get('anexo_documentacao_suporte')?.setValue('')
    }
  }

  async getPipesUrlDocumentacaoSuporte(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentacaoSuporteUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentacaoSuporteUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoNfProdutoOriginal(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.nfProdutoOriginal.push(files[i])
    }
  }


  removeFileNfProdutoOriginal(file: File) {
    const index: number = this.nfProdutoOriginal.indexOf(file);
    this.nfProdutoOriginal.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.nfProdutoOriginal.length == 0) {
      this.formulario.controls['nfProdutoOriginal'].markAsTouched()
      this.formulario.get('nfProdutoOriginal')?.setValue('')
    }
  }

  async getPipesUrlNfProdutoOriginal(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.nfProdutoOriginalUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.nfProdutoOriginalUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveListaDeItensDaPO(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.listaDeItensDaPo.push(files[i])
    }
  }

  removeListaDeItensDaPO(file: File) {
    const index: number = this.listaDeItensDaPo.indexOf(file);
    this.listaDeItensDaPo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.listaDeItensDaPo.length == 0) {
      this.formulario.controls['lista_de_itens_da_po'].markAsTouched()
      this.formulario.get('lista_de_itens_da_po')?.setValue('')
    }
  }

  async getPipesUrlListaItensDaPo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.listaDeItensDaPoUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.listaDeItensDaPoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  inserirNoArray(event: any) {
    // console.log(this.formulario.getRawValue().informacoes_complementares_da_po)
    let valor: any = event.target.value
    // console.log(valor)
    if (this.formulario.getRawValue().informacoes_complementares_da_po) {
      let valorTratado = '"' + valor + '"'
      this.informacoesComplementaresPo.push(valorTratado)
      // console.log(this.informacoesComplementaresPo)
    }
    if (!this.formulario.getRawValue().informacoes_complementares_da_po) {
      const index: number = this.informacoesComplementaresPo.indexOf(valor);
      this.informacoesComplementaresPo.splice(index, 1)
      // console.log(this.informacoesComplementaresPo)
    }
  }

  notaComRateiosValue(event: any) {
    var valor = event.target.value;

    if (valor == 'Sim') {
      this.notaComRateioDeValoresString = 'Sim';
      this.notaComRateios = true;
    }

    if (valor == 'Não') {
      this.notaComRateioDeValoresString = 'Não';
      this.notaComRateios = false;
      this.arquivosDeRateio = [];
      this.formulario.get('arquivo_de_rateio')?.setValue('');
    }
  }

  riscoSacadoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.riscoSacadoString = 'Sim'
      // console.log(this.riscoSacadoString)
    } else {
      this.riscoSacadoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }
  }

  dacteCorretoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.dacteCorretoString = 'Sim'
      // console.log(this.dacteCorretoString)
    } else {
      this.dacteCorretoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }

  }


  onDocumentoChange(event: any) {
    var valor = event.target.value
    // console.log(valor)
    if (valor == "CPF") {
      // console.log("cpf checked")
      this.formulario.get('cpf_do_fornecedor')?.setValidators([Validators.required, Validadores.cpf])
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cnpj_fornecedor')?.clearValidators()
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();

    }

    if (valor == "CNPJ") {
      // console.log("cnpj checked")
      this.formulario.get('cnpj_fornecedor')?.setValidators([Validators.required, Validadores.cnpj])
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cpf_do_fornecedor')?.clearValidators()
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
    }
  }

  onEstadoOrigemChange(event: any) {
    this.formulario.get('estado_de_origem')?.setValue(event.target.value);
  }
  onEstadoDestinoChange(event: any) {
    this.formulario.get('estado_de_destino')?.setValue(event.target.value);
  }

  onConfirmaEntregaChange(event: any) {
    if (event.target.value == "Não se aplica") {
      this.formulario.get('motivo_negacao')?.setValidators([Validators.required, Validators.minLength(5)])
      this.formulario.get('motivo_negacao')?.updateValueAndValidity()
    } else {
      this.formulario.get('motivo_negacao')?.clearValidators()
      this.formulario.get('motivo_negacao')?.updateValueAndValidity()
      this.formulario.get('motivo_negacao')?.setValue("")
    }
  }

  onGrcChange(event: any) {
    if (event.target.value == "Sim") {
      this.formulario.get('estado_de_origem')?.setValidators([Validators.required])
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.setValidators([Validators.required])
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    } else {
      this.formulario.get('estado_de_origem')?.clearValidators();
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.clearValidators();
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    }
  }

  // onContemRegistroPrefeituraChange(event: any) {
  //   if (event.target.value == "Sim") {
  //     this.formulario.controls['anexar_nota_emitida_na_prefeitura'].markAsTouched()
  //     this.formulario.get('anexar_nota_emitida_na_prefeitura')?.setValue('')
  //     this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
  //   } else {
  //     this.list_anexar_nota_emitida_na_prefeitura = [];
  //     this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
  //     this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
  //   }
  // }

  onEmpresaChange(event: any) {

    // console.log("Entrei em empresa change")
    // console.log(this.trocouEmpresa)
    if (this.trocouEmpresa == false) {
      // console.log("entrei na hora inicial")
      this.horaTrocaDaEmpresa = Date.now()
      this.horaTrocaDaEmpresaFormatada = new Date()
      this.horaTrocaDaEmpresaFormatada = this.horaTrocaDaEmpresaFormatada.getHours() + ':' + this.horaTrocaDaEmpresaFormatada.getMinutes() + ':' + this.horaTrocaDaEmpresaFormatada.getSeconds()
      // console.log("hora inicial", this.horaTrocaDaEmpresaFormatada)
      this.trocouEmpresa = true
    }

    let empresa = event.target.value
    // console.log(empresa)
    this.formulario.get('grc')?.setValue('');
    this.formulario.get('grc')?.clearValidators();
    this.formulario.get('grc')?.updateValueAndValidity();
    this.formulario.get('estado_de_origem')?.clearValidators();
    this.formulario.get('estado_de_origem')?.updateValueAndValidity();
    this.formulario.get('estado_de_destino')?.clearValidators();
    this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    this.formulario.get('conta_contabil')?.clearValidators();
    this.formulario.get('conta_contabil')?.updateValueAndValidity();


    if (empresa === 'Agrichem') {
      this.formulario.get('departamento')?.setValidators([Validators.required])
      this.formulario.get('departamento')?.updateValueAndValidity();
      // this.formulario.get('grc')?.setValidators([Validators.required])
      // this.formulario.get('grc')?.updateValueAndValidity();
    }

    if (empresa === '' || empresa === 'TecAgro (FAG)' || empresa === 'TecAgro (SGL)' || empresa === 'TecAgro (FCR)' || empresa === 'Nutrien') {
      this.formulario.get('departamento')?.clearValidators()
      this.formulario.get('departamento')?.updateValueAndValidity();
    }


    if(empresa =='Agrichem' && this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito'){
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if(empresa =='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito'){
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if (this.formulario.getRawValue().tipo_de_nota !== 'Jurídico'
    && this.formulario.getRawValue().tipo_de_nota !== 'Aluguel'
    && this.formulario.getRawValue().tipo_de_nota !== 'Guias/Imposto/Taxas'
    && (this.formulario.getRawValue().nome_empresa_nutrien !=='Agrichem' && this.formulario.getRawValue().tipo_de_nota !=='Nota de Crédito')
    &&(this.formulario.getRawValue().nome_empresa_nutrien !=='Nutrien' && this.formulario.getRawValue().tipo_de_nota !=='Nota de Crédito')) {
      // console.log("entrei aqui REEMBOLSO")
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }
  }

  onCnpjEmpresaNutrienChange(event: any) {
    let cnpjSelecionado = event.target.value;
    let empresaSelecionada = this.listaEmpresasCnpjs.filter(nome => nome.cnpj === cnpjSelecionado);
    this.formulario.get('nome_empresa_nutrien')?.setValue(empresaSelecionada[0].nome);
  }

  onPagamentoUrgenteChange(event: any) {
    let pagamentoUrgente = event.target.value
    // console.log("Pagamento Urgente : ", pagamentoUrgente)

    if (pagamentoUrgente === 'Sim') {
      this.formulario.get('motivo_da_excecao')?.setValidators([Validators.required])
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }

    if (pagamentoUrgente === 'Não') {
      this.formulario.get('motivo_da_excecao')?.clearValidators()
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }
  }

  onTipoDeNotaChange(event: any) {


    this.formulario.get('documento_do_fornecedor')?.setValidators(Validators.required);
    this.formulario.get('documento_do_fornecedor')?.updateValueAndValidity();

    this.formulario.get('anexo_nota_ou_boleto')?.setValidators(Validators.required);
    this.formulario.get('anexo_nota_ou_boleto')?.updateValueAndValidity();

    // console.log(event)
    let tipo_de_nota = event.target.value
    // console.log(tipo_de_nota)

    // if(tipo_de_nota === 'Reembolso' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias'){



    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço'||
      tipo_de_nota == 'Pagamento de Autônomo'
    ) {

      // console.log("oi")
      this.formulario.get('conta_contabil')?.clearValidators();
      this.formulario.get('conta_contabil')?.updateValueAndValidity();
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators();
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity();
    }

    if (tipo_de_nota === 'Jurídico' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias/Imposto/Taxas'
    || (this.formulario.getRawValue().nome_empresa_nutrien =='Agrichem' && this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito')
    ||(this.formulario.getRawValue().nome_empresa_nutrien =='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito')) {
      console.log("entrei em juridico")
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }

    // if(tipo_de_nota === 'Frete' || tipo_de_nota === 'CTE') {
    if (tipo_de_nota === 'Frete/CTE') {

      // console.log("entrei aqui FRETE")
      this.formulario.get('qual_a_quantidade_de_cte')?.setValidators([Validators.required])
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    if (tipo_de_nota == 'Serviço') {
      this.nfProdutoOriginal = [];
      this.formulario.get('nf_do_produto_original')?.setValue('');
    }

    if (tipo_de_nota == 'Aluguel') {
      Swal.fire({
        // title: 'Você será redirecionado!',
        text: 'Para contas que não estiverem em nome da Nutrien, favor, anexar o contrato de referência no campo anexo documentação suporte.',
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600',
      }).then(() => {
        this.formulario.get('anexo_documentacao_suporte')?.setValidators([Validators.required])
        this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();
      })
      // this.formulario.get('parcela_do_aluguel')?.setValidators([Validators.required])
      // this.formulario.get('parcela_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('m_s_do_aluguel')?.setValidators([Validators.required])
      this.formulario.get('m_s_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('ano_do_aluguel')?.setValidators([Validators.required])
      this.formulario.get('ano_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('nro_nota')?.clearValidators();
      this.formulario.get('nro_nota')?.updateValueAndValidity();
    }

    if (tipo_de_nota != 'Aluguel') {
      this.formulario.get('anexo_documentacao_suporte')?.clearValidators();
      this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();

      this.formulario.get('nro_nota')?.setValidators([Validators.required])
      this.formulario.get('nro_nota')?.updateValueAndValidity();

      // this.formulario.get('parcela_do_aluguel')?.clearValidators();
      // this.formulario.get('parcela_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('m_s_do_aluguel')?.clearValidators();
      this.formulario.get('m_s_do_aluguel')?.updateValueAndValidity();

      this.formulario.get('ano_do_aluguel')?.clearValidators();
      this.formulario.get('ano_do_aluguel')?.updateValueAndValidity();

    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Aluguel' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota === 'Frete/CTE' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço'
    ) {
      // console.log("entrei em numero do pedido obrigatorio")
      this.formulario.get('numero_po')?.setValidators([Validators.required])
      this.formulario.get('numero_po')?.updateValueAndValidity();
    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Devolução de Cliente' ||
      tipo_de_nota == 'Guias/Imposto/Taxas' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota === 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO') {

      // console.log("entrei em numero do pedido nao obrigatorio")
      this.formulario.get('numero_po')?.setValue("");
      this.formulario.get('numero_po')?.clearValidators()
      this.formulario.get('numero_po')?.updateValueAndValidity();
    }

    if (tipo_de_nota != 'Pagamento de Autônomo') {
      this.formulario.get('documento_do_fornecedor')?.setValue("");

      this.list_anexar_nota_emitida_na_prefeitura = [];
      this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
      this.list_anexo_para_pagamento_de_aut_nomo = [];
      this.formulario.get("anexar_nota_emitida_na_prefeitura")?.setValue("");
      this.formulario.get("anexar_registro_do_pagamento_de_aut_nomo")?.setValue("");
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.clearValidators();
      this.formulario.get("anexo_para_pagamento_de_aut_nomo")?.setValue("");
      this.formulario.get('nota_com_rasteio_de_valores')?.setValue('');
    }
    if (tipo_de_nota == 'Pagamento de Autônomo') {
      this.formulario.get('documento_do_fornecedor')?.setValue("CPF");
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.setValidators(Validators.required);
      this.formulario.get('anexo_para_pagamento_de_aut_nomo')?.updateValueAndValidity();

      this.formulario.get('cpf_do_fornecedor')?.setValidators(Validators.required);
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('documento_do_fornecedor')?.clearValidators();
      this.formulario.get('documento_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('anexo_nota_ou_boleto')?.clearValidators();
      this.formulario.get('anexo_nota_ou_boleto')?.updateValueAndValidity();
      this.arquivosDeRateio = [];
      this.formulario.get('arquivo_de_rateio')?.setValue('');
    }


    if (tipo_de_nota !== 'Jurídico' && tipo_de_nota !== 'Aluguel' && tipo_de_nota !== 'Guias/Imposto/Taxas'
    && (this.formulario.getRawValue().nome_empresa_nutrien !=='Agrichem' && this.formulario.getRawValue().tipo_de_nota !=='Nota de Crédito')
    &&(this.formulario.getRawValue().nome_empresa_nutrien !=='Nutrien' && this.formulario.getRawValue().tipo_de_nota !=='Nota de Crédito')) {
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }
  }

  onDataDeVencimentoChange(event: any) {
    var data = event.target.value



    Swal.fire({
      // title: 'Atenção',
      width: 600,
      html: '<h6 class="fs-20 color-light-green fw-900">Pagamentos Gerais  (Fornecedores)</h6><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Criação de solicitação de pagamento:</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Mínimo de 10 dias de antecedência às datas de pagamento.</p>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>É requerido o envio imediato dos documentos após seu recebimento.</p><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Condição de Pagamento</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Estabelecido no pedido de compra a contar da data de emissão do documento.</p><br>' +
        '<h6 class="fs-20 color-light-green fw-900">Efetivação do Pagamento</h6>' +
        '<p class="text-justify fs-15"><span>&#8226;&nbsp;&nbsp;</span>Atendidas as condições acima e o processo de abertura no NBS/pedido não apresente inconsistências, ocorrerá na próxima data de pagamento (15 ou 25) (feriado ou fim de semana no próximo dia útil).</p>',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
      padding: '10px 30px 30px',
      iconColor: '#88D600'
    })
  }

  getDataAtual() {
    let newDate = new Date(),
      month = '' + (newDate.getMonth() + 1),
      day = '' + newDate.getDate(),
      year = newDate.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.dataAtual = [year, month, day].join('-');
  }

  pasteValorDecimal(event: any) {
    event.preventDefault();
    let clipboardData = event.clipboardData;
    this.valorTotalNfAlterado = clipboardData.getData('text').replace(/[^0-9.,]/g, '').replace(/,/g, '.');;
    this.valorTotalNfAlterado = (Number.isInteger(parseFloat(this.valorTotalNfAlterado))) ? parseFloat(this.valorTotalNfAlterado) + ".00" : this.valorTotalNfAlterado;
    this.formataValorDecimal(this.valorTotalNfAlterado)
  }

  focusValorDecimal(event: any) {
    event.target.select()
  }

  formataValorDecimal(input: any) {
    if (input == '0,0' || input == '0.0') {
      input = "";
    } else if (input == "0," || input == "0.") {
      input = "";
    }
    input = input.replace(/[^0-9]/g, ''); // remove all the characters except the numeric values
    if (input.replace(/[^0-9]/g, '').length == 1) input = "0.0" + input;
    else if (input.replace(/[^0-9]/g, '').length == 2) input = "0." + input;
    else input = input.substring(0, input.length - 2) + '.' + input.substring(input.length - 2, input.length);
    input = new Number(input); // set the precision
    input = input.toFixed(2);    // only works with the "."
    input = input.replace(/\./g, ','); // change the splitter to ","
    let x = input.split(','); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(valor)) {
      valor = valor.replace(rgx, '$1' + '.' + '$2');
    }
    valor = valor == "NaN" ? "" : valor;
    this.valorTotalNfAlterado = valor + decimal;
    this.formulario.get('valor_total_da_nf')?.setValue(this.valorTotalNfAlterado);
  }

  getUserEmail() {
    let email = this.userInfo.getUserEmail();
    if (email && email.indexOf('@accenture.com') !== -1) {
      let name = email.substring(0, email.lastIndexOf("@"));
      this.formulario.controls['email']?.setValue(name);
    }
    if (email && email.indexOf('@nutrien.com') !== -1) {
      let name = email.substring(0, email.lastIndexOf("@"));
      this.formulario.controls['email']?.setValue(name);
    }
  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().email
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if (!regex.test(input))
      input = (input.substring(0, (input.length - 1)))
    this.formulario.controls['email']?.setValue(input);
  }

  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()



    this.arquivosDeRateio = [];
    this.notas = [];
    this.boletos = []
    this.xml = []
    this.documentacaoSuporte = [];
    this.listaDeItensDaPo = [];
    this.nfProdutoOriginal = []

    this.arquivosDeRateioUrl = [];
    this.notasUrl = []
    this.boletosUrl = []
    this.xmlUrl = []
    this.documentacaoSuporteUrl = [];
    this.listaDeItensDaPoUrl = [];
    this.nfProdutoOriginalUrl = []

    this.list_anexar_nota_emitida_na_prefeitura = [];
    this.list_anexar_registro_do_pagamento_de_aut_nomo = [];
    this.list_anexo_para_pagamento_de_aut_nomo = [];
    this.registros = []


    this.list_url_anexar_nota_emitida_na_prefeitura = []
    this.list_url_anexar_registro_do_pagamento_de_aut_nomo = []
    this.list_anexo_para_pagamento_de_aut_nomo = []
    this.registros = []

    this.notaComRateios = false;

    this.formulario.get('cnpj_fornecedor')?.clearValidators()
    this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();
    this.formulario.get('cpf_do_fornecedor')?.clearValidators()
    this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
    this.formulario.get('departamento')?.clearValidators()
    this.formulario.get('departamento')?.updateValueAndValidity();
    this.formulario.get('nro_nota')?.clearValidators()
    this.formulario.get('nro_nota')?.updateValueAndValidity();

    this.formulario.get("anexar_nota_emitida_na_prefeitura")?.setValue("");
    this.formulario.get("anexar_registro_do_pagamento_de_aut_nomo")?.setValue("");
    this.formulario.get("anexo_para_pagamento_de_aut_nomo")?.setValue("");

    this.notaComRateioDeValoresString = ''
    this.riscoSacadoString = ''

    this.emailApi = !!this.userInfo.getUserEmail() ? this.userInfo.getUserEmail() : this.formulario.get('email')?.value;
    this.userNameApi = !!this.userInfo.getName() ? this.userInfo.getName() : this.formulario.get('nome')?.value;

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)

    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }
    this.getUserEmail();
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/I0t4K1gr', '_blank')
  }

  getTotalTempoAberturaDoCard(tempoInicio: any, tempoFim: any) {
    const diff = Math.max(tempoInicio, tempoFim) - Math.min(tempoInicio, tempoFim)
    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN

    const hrs = Math.floor(diff / HRS)
    const min = Math.floor((diff % HRS) / MIN).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const sec = Math.floor((diff % MIN) / SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
    const ms = Math.floor(diff % SEC).toLocaleString('pt-BR', { minimumIntegerDigits: 4, useGrouping: false })

    // console.log(`${hrs}:${min}:${sec}`)

    this.tempoDePreenchimentoDoFormulario = `${hrs}:${min}:${sec}`
  }

  // saveRegistro(event: any): void {
  //   var files = event.target.files;

  //   for (var i = 0; i < files.length; i++) {
  //     this.registros.push(files[i])
  //     // this.list_anexar_registro_do_pagamento_de_aut_nomo.push(files[i])
  //   }
  // }

  // removeFileRegistro(file: File) {
  //   const index: number = this.list_anexar_registro_do_pagamento_de_aut_nomo.indexOf(file);
  //   this.list_anexar_registro_do_pagamento_de_aut_nomo.splice(index, 1)
  //   Swal.fire({
  //     position: 'top-end',
  //     icon: 'success',
  //     title: 'Arquivo Removido com Sucesso.',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   if (this.list_anexar_registro_do_pagamento_de_aut_nomo.length == 0) {
  //     this.formulario.controls['anexar_registro_do_pagamento_de_aut_nomo'].markAsTouched()
  //     this.formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.setValue('')
  //   }
  // }

  async getPipesUrlRegistro(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.registrosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/I0t4K1gr" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.registrosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }




}
