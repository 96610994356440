<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Cancelamento, Recusa, Emissão Manual - Notas Fiscais"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="informacoes_da_prioridade" class="col-sm-4 col-form-label">*Informações da prioridade</label>
      <div class="col-sm-4 mt-2">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='informacoes_da_prioridade' value="Dentro do prazo" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Dentro do prazo
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='informacoes_da_prioridade' value='Urgente' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Urgente
        </label>
      </div>

    </div>
    <div class="mensagem-atencao" *ngIf="this.formulario.getRawValue().informacoes_da_prioridade =='Urgente'">
      <p class="text-center">Motivo da urgência deve ser o mais detalhista possível. O motivo da urgência será analisado pelo Fiscal NBS, em conjunto com time Fiscal Nutrien.</p>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().informacoes_da_prioridade =='Urgente'">
      <label for="motivo_da_urgencia" class="col-sm-4 col-form-label">*Motivo da urgência </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_urgencia" (change)="mostraValor($event)"></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label for="anexoDocumento1" class="col-sm-4 col-form-label">Anexo de documento </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexoDocumento1" id="formFileMultiple" (change)="saveanexoDocumento1($event)" (change)="mostraValor($event)"multiple="multiple">
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexoDocumentoParm.length!=0">
      <div class="card-header">Anexo do documento</div>
      <ul class="list-group list-group-flush" *ngFor="let file of anexoDocumentoParm">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileanexoDocumento1(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="operacao" class="col-sm-4 col-form-label">*Operação </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="operacao" (change)="onOperacaoChange($event)" (change)="mostraValor($event)">
          <option *ngFor="let operacao of operacoes" [value]="operacao.nome">{{operacao.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('operacao')?.errors?.required && formulario.get('operacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="mensagem-atencao" *ngIf="this.formulario.getRawValue().operacao == '02-Emissão de nota fiscal de ativo imobilizado'">
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">Para as empresas Nutrien, Sementes, Agrichem e Fronteira favor acessar o link, <a href="https://nbs.accenture.com/#/accouting/forms/baixa-imobilizado" target="_blank"> (https://nbs.accenture.com/#/accouting/forms/baixa-imobilizado) </a> preencher os campos / anexar documentos para operações de imobilizado.</p>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '14-Emissão de contra nota fiscal de energia elétrica' ||
    this.formulario.getRawValue().operacao == '15-Emissão de contra nota fiscal de produtor rural'">
      <label for="n_mero_da_migo" class="col-sm-4 col-form-label">*Número da migo</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="n_mero_da_migo" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_migo')?.errors?.required && formulario.get('n_mero_da_migo')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '03-Emissão de nota fiscal complementar' ||
     this.formulario.getRawValue().operacao == '04-Emissão de nota fiscal de remessa'||
     this.formulario.getRawValue().operacao == '05-Emissão de nota fiscal devoluções de fornecedores'||
     this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)'||
     this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)'||
     this.formulario.getRawValue().operacao == '09-Carta de correção'||
     this.formulario.getRawValue().operacao == '10-Cancelamento com a emissão de nota fiscal de entrada'||
     this.formulario.getRawValue().operacao == '11-Cancelamento extemporâneo'||
     this.formulario.getRawValue().operacao == '12-Recusas'||
     this.formulario.getRawValue().operacao == '10-Cancelamento com a emissão de nota fiscal de entrada'||
     this.formulario.getRawValue().operacao == '14-Emissão de contra nota fiscal de energia elétrica'||
     this.formulario.getRawValue().operacao == '15-Emissão de contra nota fiscal de produtor rural'">
      <label for="pdf_nota_fiscal_de_origem_em_anexo"
      class="col-sm-4 col-form-label">
      *PDF da nota fiscal de origem está em anexo?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='pdf_nota_fiscal_de_origem_em_anexo' value="Sim" (change)="mostraValor($event)"class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='pdf_nota_fiscal_de_origem_em_anexo' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '01-Emissão de nota fiscal de mercadoria' ||
     this.formulario.getRawValue().operacao == '03-Emissão de nota fiscal complementar' ||
     this.formulario.getRawValue().operacao == '04-Emissão de nota fiscal de remessa'||
     this.formulario.getRawValue().operacao == '05-Emissão de nota fiscal devoluções de fornecedores'||
     this.formulario.getRawValue().operacao == '06-Emissão de nota fiscal de serviços-prestados'||
     this.formulario.getRawValue().operacao == '10-Cancelamento com a emissão de nota fiscal de entrada'||
     this.formulario.getRawValue().operacao == '14-Emissão de contra nota fiscal de energia elétrica'||
     this.formulario.getRawValue().operacao == '15-Emissão de contra nota fiscal de produtor rural'">
      <label for="form_solicitao_emisso_nota_fiscal_anexado_e_preenchido" class="col-sm-4 col-form-label">
        *Formulário de solicitação de emissão de nota fiscal está anexado e preenchido?</label>
      <div class="col-sm-4 mt-2">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_solicitao_emisso_nota_fiscal_anexado_e_preenchido' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_solicitao_emisso_nota_fiscal_anexado_e_preenchido' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)' ||
    this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)'">
      <label for="data_de_emiss_o_da_nota_fiscal" class="col-sm-4 col-form-label">*Data de emissão da nota fiscal</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_emiss_o_da_nota_fiscal" (change)="mostraValor($event)">
        <app-mensagem class ="col-form-label mensagem-erro"
        *ngIf="formulario.get('data_de_emiss_o_da_nota_fiscal')?.errors?.required && formulario.get('data_de_emiss_o_da_nota_fiscal')?.touched"
          mensagem ="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)' ||
    this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)'">
      <label for="hor_rio_de_emiss_o_da_nota_fiscal" class="col-sm-4 col-form-label">*Horário de emissão da nota fiscal </label>
      <div class="col-sm-8">
        <input class="form-control" type="time" formControlName='hor_rio_de_emiss_o_da_nota_fiscal' (change)="mostraValor($event)" min="09:00" max="18:00">
        <app-mensagem class ="col-form-label mensagem-erro"
        *ngIf="formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.errors?.required && formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto"
      class="col-sm-4 col-form-label">
      *Formulário de autorização da contabilidade para emissão da nota fiscal está em anexo e assinado pela gestão?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '11-Cancelamento extemporâneo'">
      <label for="autoriza_o_do_fiscal_nutrien"
      class="col-sm-4 col-form-label">*Autorização do fiscal Nutrien?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='autoriza_o_do_fiscal_nutrien' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='autoriza_o_do_fiscal_nutrien' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)'||
     this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)'">
     <label for="form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto"
      class="col-sm-4 col-form-label">
      Os documentos atrelados a nota fiscal (conhecimento de transporte (CTE)) foi cancelado pela transportadora?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)'||
    this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)' ||
    this.formulario.getRawValue().operacao == '11-Cancelamento extemporâneo'">
      <label for="motivo_do_cancelamento" class="col-sm-4 col-form-label">*Motivo do cancelamento </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_do_cancelamento" (change)="mostraValor($event)"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('motivo_do_cancelamento')?.errors?.required && formulario.get('motivo_do_cancelamento')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '12-Recusas'">
      <label for="motivo_da_recusa" class="col-sm-4 col-form-label">*Motivo da recusa </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_recusa" (change)="mostraValor($event)"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('motivo_da_recusa')?.errors?.required && formulario.get('motivo_da_recusa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '01-Emissão de nota fiscal de mercadoria'
    || this.formulario.getRawValue().operacao == '03-Emissão de nota fiscal complementar'
    || this.formulario.getRawValue().operacao == '04-Emissão de nota fiscal de remessa'
    || this.formulario.getRawValue().operacao == '05-Emissão de nota fiscal devoluções de fornecedores'
    || this.formulario.getRawValue().operacao == '06-Emissão de nota fiscal de serviços-prestados'
    || this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)'
    || this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)'
    || this.formulario.getRawValue().operacao == '09-Carta de correção'
    || this.formulario.getRawValue().operacao == '10-Cancelamento com a emissão de nota fiscal de entrada'
    || this.formulario.getRawValue().operacao == '11-Cancelamento extemporâneo'
    || this.formulario.getRawValue().operacao == '12-Recusas'
    || this.formulario.getRawValue().operacao == '14-Emissão de contra nota fiscal de energia elétrica'
    || this.formulario.getRawValue().operacao == '15-Emissão de contra nota fiscal de produtor rural'
     ">
      <label for="incluirAnexos" class="col-sm-4 col-form-label">*Incluir anexos </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="incluirAnexos" id="formFileMultiple" (change)="saveIncluirAnexo($event)" (change)="mostraValor($event)" multiple="multiple">
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="incluirAnexosParm.length!=0">
      <div class="card-header">Incluir anexos</div>
      <ul class="list-group list-group-flush" *ngFor="let file of incluirAnexosParm">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileIncluirAnexo(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '13-Suporte ao monitor de nota fiscal (GRC)'">
      <label for="n_mero_da_nota_fiscal_e_s_rie" class="col-sm-4 col-form-label">*Número da nota fiscal e série</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_e_s_rie" (change)="mostraValor($event)">
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '13-Suporte ao monitor de nota fiscal (GRC)'">
      <label for="n_mero_do_documento_sap" class="col-sm-4 col-form-label">Número do documento SAP</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="n_mero_do_documento_sap" (change)="mostraValor($event)">
      </div>
    </div>
    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '13-Suporte ao monitor de nota fiscal (GRC)'">
      <label for="n_mero_do_documento_sap" class="col-sm-4 col-form-label"></label>
      <div class="mensagem-descricao col-sm-8">
        <p class="text-center">Válido <strong>apenas</strong> para Nutrien e Agrichem</p>
      </div>
    </div>


    <div class="mensagem-atencao" *ngIf="(this.formulario.getRawValue().operacao == '01-Emissão de nota fiscal de mercadoria' ||
    this.formulario.getRawValue().operacao == '06-Emissão de nota fiscal de serviços-prestados') &&
      (this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido == 'Não' ||
      this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto == 'Não' ||
      this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o formulário de solicitação de emissão de nota fiscal esteja preenchido e anexado em sua solicitação.</p>
    </div>

    <div class="mensagem-atencao" *ngIf="
    (this.formulario.getRawValue().operacao == '03-Emissão de nota fiscal complementar' ||
     this.formulario.getRawValue().operacao == '04-Emissão de nota fiscal de remessa'||
     this.formulario.getRawValue().operacao == '05-Emissão de nota fiscal devoluções de fornecedores'||
     this.formulario.getRawValue().operacao == '10-Cancelamento com a emissão de nota fiscal de entrada') &&
      (this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido == 'Não' ||
      this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto == 'Não' ||
      this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o(s) formulário(s) esteja(m) preenchido(s) e assinado(s)
        e o PDF da nota fiscal anexado para seguir com sua solicitação.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="(this.formulario.getRawValue().operacao == '07-Cancelamento para demais estados (24h)') &&
    (this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que seja solicitado a transportadora o cancelamento do conhecimento de transporte (CTE) atrelado a nota fiscal e o PDF em anexo,
         para seguir com o cancelamento.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="(this.formulario.getRawValue().operacao == '08-Cancelamento específico para Mato Grosso (3h)') &&
    (this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que seja solicitado a transportadora o cancelamento do conhecimento de transporte (CTE) atrelado a
        nota fiscal e o PDF em anexo, para seguir com o cancelamento.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="this.formulario.getRawValue().operacao == '09-Carta de correção' &&
    (this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o PDF da nota fiscal que está solicitando correção esteja anexado em sua solicitação.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="(this.formulario.getRawValue().operacao == '11-Cancelamento extemporâneo') &&
    (this.formulario.getRawValue().autoriza_o_do_fiscal_nutrien == 'Não' ||
     this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário autorização do fiscal Nutrien para cancelamentos extemporâneos (e-mail),
         e o PDF da nota fiscal em anexo para seguir com sua solicitação.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="
     this.formulario.getRawValue().operacao == '12-Recusas' && (this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não'|| incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o PDF da nota fiscal que está solicitando a recusa esteja anexado em sua solicitação.</p>
    </div>

    <div class="mensagem-atencao"
    *ngIf="(this.formulario.getRawValue().operacao == '14-Emissão de contra nota fiscal de energia elétrica' ||
    this.formulario.getRawValue().operacao == '15-Emissão de contra nota fiscal de produtor rural') &&
    (this.formulario.getRawValue().n_mero_da_migo == '' ||
    this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo == 'Não' ||
    this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido == 'Não' || incluirAnexosParm.length == 0)" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o formulário de solicitação de emissão de nota fiscal esteja preenchido, MIGO e o
        PDF da nota fiscal em anexo para seguir com sua solicitação.</p>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa" (change)="onEmpresaChange($event)" (change)="mostraValor($event)">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrichem'">
      <label for="filial_agrichem" class="col-sm-4 col-form-label">*Filiais - Agrichem </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrichem" (change)="mostraValor($event)">
          <option *ngFor="let filial of filiais_agrichem" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_agrichem')?.errors?.required && formulario.get('filial_agrichem')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrosema'">
      <label for="filial_agrosema" class="col-sm-4 col-form-label">*Filiais - Agrosema </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrosema" (change)="mostraValor($event)">
          <option *ngFor="let filial of filiais_agrosema" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_agrosema')?.errors?.required && formulario.get('filial_agrosema')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Nutrien'">
      <label for="filial_nutrien" class="col-sm-4 col-form-label">*Filiais - Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_nutrien" (change)="mostraValor($event)">
          <option *ngFor="let filial of filiais_nutrien" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_nutrien')?.errors?.required && formulario.get('filial_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro'">
      <label for="filial_tec_agro" class="col-sm-4 col-form-label">*Filiais - Tec Agro </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro" (change)="mostraValor($event)">
          <option *ngFor="let filial of filiais_tec_agro" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro')?.errors?.required && formulario.get('filial_tec_agro')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-SGL'">
      <label for="filial_tec_agro_sgl" class="col-sm-4 col-form-label">*Filial Tec Agro-SGL </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_sgl">
          <option *ngFor="let filial of filiais_tec_agro_sgl" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_sgl')?.errors?.required && formulario.get('filial_tec_agro_sgl')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-FRC'">
      <label for="filial_tec_agro_frc" class="col-sm-4 col-form-label">*Filial Tec Agro-FRC </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_frc">
          <option *ngFor="let filial of filiais_tec_agro_frc" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_frc')?.errors?.required && formulario.get('filial_tec_agro_frc')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="requisitante_e_e_mail" class="col-sm-4 col-form-label">*Nome do Solicitante principal Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="requisitante_e_e_mail" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('requisitante_e_e_mail')?.errors?.required && formulario.get('requisitante_e_e_mail')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().requisitante_e_e_mail.length > 0">
      <label for="e_mail_do_solicitante_principal_nutrien" class="col-sm-4 col-form-label">*E-mail do Solicitante principal Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="e_mail_do_solicitante_principal_nutrien" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('e_mail_do_solicitante_principal_nutrien')?.errors?.required && formulario.get('e_mail_do_solicitante_principal_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <span class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_do_solicitante_principal_nutrien')?.errors?.email && formulario.get('e_mail_do_solicitante_principal_nutrien')?.touched">Formato de e-mail inválido</span>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().e_mail_do_solicitante_principal_nutrien.length > 0 &&
      this.formulario.getRawValue().e_mail_do_solicitante_principal_nutrien.errors == null">
      <label for="copia_email_nutrien2" class="col-sm-4 col-form-label">Cópia e-mail da Nutrien – 2</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="copia_email_nutrien2">
        <span class="col-form-label mensagem-erro"
          *ngIf="formulario.get('copia_email_nutrien2')?.errors?.email && formulario.get('copia_email_nutrien2')?.touched">Formato de e-mail inválido</span>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().copia_email_nutrien2.length > 0 &&
      this.formulario.getRawValue().copia_email_nutrien2.errors == null">
      <label for="copia_email_nutrien3" class="col-sm-4 col-form-label">Cópia e-mail da Nutrien – 3</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="copia_email_nutrien3">
        <span class="col-form-label mensagem-erro"
          *ngIf="formulario.get('copia_email_nutrien3')?.errors?.email ">Formato de e-mail inválido</span>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().copia_email_nutrien3.length > 0 &&
      this.formulario.getRawValue().copia_email_nutrien3.errors == null">
      <label for="copia_email_nutrien4" class="col-sm-4 col-form-label">Cópia e-mail da Nutrien – 4</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="copia_email_nutrien4">
        <span class="col-form-label mensagem-erro"
          *ngIf="formulario.get('copia_email_nutrien4')?.errors?.email ">Formato de e-mail inválido</span>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().copia_email_nutrien4.length > 0 &&
      this.formulario.getRawValue().copia_email_nutrien4.errors == null">
      <label for="copia_email_nutrien5" class="col-sm-4 col-form-label">Cópia e-mail da Nutrien – 5</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="copia_email_nutrien5">
        <span class="col-form-label mensagem-erro"
          *ngIf="formulario.get('copia_email_nutrien5')?.errors?.email ">Formato de e-mail inválido</span>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label">Número de nota fiscal</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_de_nota_fiscal" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_de_nota_fiscal')?.errors?.required && formulario.get('numero_de_nota_fiscal')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao == '04-Carta de correção '" >
      <label for="finalidade" class="col-sm-4 col-form-label">Finalidade </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="finalidade" (change)="onFinalidadeChange($event)">
          <option *ngFor="let finalidade of finalidade" [value]="finalidade.nome">{{finalidade.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('finalidade')?.errors?.required && formulario.get('finalidade')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().finalidade =='Lote'">
      <label for="finalidade" class="col-sm-4 col-form-label">*Autorização responsavel da unidade solicitante </label>
      <div class="col-sm-8">
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexo_lote" id="formFileMultiple" (change)="saveAnexoLote($event)" (change)="mostraValor($event)" multiple="multiple">
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexoLote.length!=0">
          <div class="card-header">Autorização responsavel da unidade solicitante</div>
          <ul class="list-group list-group-flush" *ngFor="let file of anexoLote">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileAnexoLote(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexo_lote')?.errors?.required && formulario.get('anexo_lote')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().finalidade =='Peso para transporte'">
      <label for="finalidade" class="col-sm-4 col-form-label"> Peso correto para transporte </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="peso_correto">
        <!-- <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('peso_correto')?.errors?.required && formulario.get('peso_correto')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem> -->
      </div>
    </div>

    <div class="form-group row d-flex flex-column" *ngIf="this.formulario.getRawValue().finalidade =='Dados do transporte'">
      <div class="form-group row correcao-input-transportadora">
        <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label">Transportador</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="dados_transportador">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('dados_transportador')?.errors?.required && formulario.get('dados_transportador')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row correcao-input-transportadora">
        <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label">Peso</label>
        <div class="col-sm-8">
          <input type="text" class="form-control correcao-input-transportadora" formControlName="dados_transportador_peso">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('dados_transportador_peso')?.errors?.required && formulario.get('dados_transportador_peso')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row correcao-input-transportadora">
        <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label correcao-input-transportadora">Quantidade</label>
        <div class="col-sm-8">
          <input type="text" class="form-control correcao-input-transportadora" formControlName="dados_transportador_qtd">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('dados_transportador_qtd')?.errors?.required && formulario.get('dados_transportador_qtd')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row correcao-input-transportadora">
        <label for="dados_transportador_volume" class="col-sm-4 col-form-label correcao-input-transportadora">Volume</label>
        <div class="col-sm-8">
          <input type="text" class="form-control correcao-input-transportadora" formControlName="dados_transportador_volume">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('dados_transportador_volume')?.errors?.required && formulario.get('dados_transportador_volume')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>

      <div class="form-group row ">
        <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label">Carga</label>
        <div class="col-sm-8">
          <input type="text" class="form-control correcao-input-transportadora" formControlName="dados_transportador_tipo_carga">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('dados_transportador_tipo_carga')?.errors?.required && formulario.get('dados_transportador_tipo_carga')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().finalidade =='Vencimento'">
      <label for="finalidade" class="col-sm-4 col-form-label">* Anexar autorização responsavel para o time de crédito/cobrança </label>
      <div class="col-sm-8">
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexo_vencimento" id="formFileMultiple" (change)="saveAnexoVencimento($event)" multiple="multiple">
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexoVencimento.length!=0">
          <div class="card-header">Anexar autorização responsavel para o time de crédito/cobrança</div>
          <ul class="list-group list-group-flush" *ngFor="let file of anexoVencimento">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileAnexoVencimento(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
        <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('vencimento')?.errors?.required && formulario.get('vencimento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem> -->
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().finalidade =='Descrição do Produto'">
      <label for="finalidade" class="col-sm-4 col-form-label">*Descrição do Produto </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="desc_produto"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('desc_produto')?.errors?.required && formulario.get('desc_produto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="descricao_da_solicitacao_e_operacao" class="col-sm-4 col-form-label">*Descrição da solicitação e operação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descricao_da_solicitacao_e_operacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao_da_solicitacao_e_operacao')?.errors?.required && formulario.get('descricao_da_solicitacao_e_operacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <label for="e_mail_nbs" class="col-sm-4 col-form-label">E-mail NBS</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="e_mail_nbs" [attr.disabled]="true">
          <option *ngFor="let emails of emailNbs" [value]="emails.nome">{{emails.nome}}</option>
        </select>
      </div>

    </div>





    <!-- <div class="form-group row">
      <label for="numero_de_documentos" class="col-sm-4 col-form-label">*Número de documentos</label>
      <div class="col-sm-8">
        <input type="number" class="form-control" formControlName="numero_de_documentos">
        <small>Inserir apenas números</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_de_documentos')?.errors?.required && formulario.get('numero_de_documentos')?.touched"
          mensagem="Campo Obrigatório - Apenas Números"></app-mensagem>
      </div>

    </div> -->

    <div class="form-group pt-1 col-lg-3 box-botao" *ngIf="this.formulario.getRawValue().operacao != '02-Emissão de nota fiscal de ativo imobilizado'">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid
      || this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido === 'Não'
      || this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto === 'Não'
      || this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo === 'Não'
      || this.formulario.getRawValue().autoriza_o_do_fiscal_nutrien === 'Não'
      ">Enviar</button>
    </div>
  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p [style]="{'font-size': '20px','color': 'white'}">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
