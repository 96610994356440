import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { empresas } from './empresas';
import { pipe_fields_id } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-remessas-exterior',
  templateUrl: './remessas-exterior.component.html',
  styleUrls: ['./remessas-exterior.component.css']
})
export class RemessasExteriorComponent implements OnInit {

  formulario!: UntypedFormGroup;
  invoices: any[] = [];
  contratos : any[] = [];

  invociesUrl: any[] = []
  contratosUrl: any[] = []

  taxaCambioAlterado: any = 0;

  empresas = empresas

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: UntypedFormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      nome_responsavel_nutrien:['',[Validators.required]],
      email_do_responsavel_nutrien:['',[Validators.required, Validators.email]],
      empresa:['',[Validators.required]],
      data_de_vencimento_de_imposto:['',[Validators.required]],
      data_para_pagamento_da_invoice:['',[Validators.required]],
      taxa_cambio:['',[Validators.required]],
      anexar_invoice:['',[Validators.required]],
      anexar_informacoes_de_contrato_ou_gross_up:['',[]],
      observacao:['',[]]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email_do_responsavel_nutrien')?.setValue(this.emailApi)
    // this.formulario.get('nome_responsavel_nutrien')?.setValue(this.userNameApi)


    // if (this.formulario.getRawValue().email_do_responsavel_nutrien != '') {
    //   this.formulario.controls['email_do_responsavel_nutrien'].disable()
    // }
    // if (this.formulario.getRawValue().email_do_responsavel_nutrien == undefined) {
    //   this.formulario.controls['email_do_responsavel_nutrien'].enable()
    // }

    // if (this.formulario.getRawValue().nome_responsavel_nutrien != '') {
    //   this.formulario.controls['nome_responsavel_nutrien'].disable()
    // }
    // if (this.formulario.getRawValue().nome_responsavel_nutrien == undefined) {
    //   this.formulario.controls['nome_responsavel_nutrien'].enable()
    // }


  }


  enviarFormulario(){

    this.spinner.show()


    const criarBody = async () => {

      await this.getPipesUrlContratos(this.contratos);
      if(this.contratos.length >0){
        while (this.contratos.length != this.contratosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.contratos.length>0){
        var contratosUrl2 = ''
        contratosUrl2 = '['
        for(var i = 0; i < this.contratosUrl.length ; i++){
          contratosUrl2 = contratosUrl2+'"'+this.contratosUrl[i]+'",'
        }
        contratosUrl2 = contratosUrl2.slice(0, -1)
        contratosUrl2 = contratosUrl2+"]"

      }else{
        contratosUrl2 =''
      }

      await this.getPipesUrlInvoices(this.invoices);
      if(this.invoices.length >0){
        while (this.invoices.length != this.invociesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.invoices.length>0){
        var invociesUrl2 = ''
         invociesUrl2 = '['
        for(var i = 0; i < this.invociesUrl.length ; i++){
           invociesUrl2 =  invociesUrl2+'"'+this.invociesUrl[i]+'",'
        }
         invociesUrl2 = invociesUrl2.slice(0, -1)
         invociesUrl2 = invociesUrl2+"]"

      }else{
        invociesUrl2 =''
      }

    let nome_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_responsavel_nutrien)
    let email_do_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_responsavel_nutrien)
    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    let data_de_vencimento_de_imposto = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento_de_imposto))
    let data_para_pagamento_da_invoice = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_para_pagamento_da_invoice))
    let taxa_cambio = this.taxaCambioAlterado;
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = pipe_fields_id
    let fields_values = [nome_responsavel_nutrien, email_do_responsavel_nutrien, empresa,data_de_vencimento_de_imposto, data_para_pagamento_da_invoice, taxa_cambio, invociesUrl2, contratosUrl2, observacao]
    let pipe_id = "302487534"


    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.invociesUrl = []
        this.contratosUrl = []

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Fiscal', "Fiscal - criação de card -- Remessas para o exterior", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Solicitação de Pagamento Impostos Apurados - Remessas para Exterior', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.invociesUrl = []
        this.contratosUrl = []
      }
    })
    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  saveInvoices(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++){
      this.invoices.push(files[i])
    }
  }

  removeFileInvoice(file: File){
    const index: number = this.invoices.indexOf(file);
      this.invoices.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.invoices.length == 0){
        this.formulario.controls['anexar_invoice'].markAsTouched()
        this.formulario.get('anexar_invoice')?.setValue('')
      }
}

async getPipesUrlInvoices(files: any) {
  this.erro = false
  for (var i = 0; i < files.length; i++) {
    var fileContent = files[i]
    let fileName = files[i].name
    const reader = new FileReader();
    reader.readAsDataURL(fileContent);
    reader.onload = () => {
      fileContent = reader.result?.toString()
      var fileContentStart = fileContent.indexOf(",")

      fileContent = fileContent.substring(fileContentStart + 1)
      this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
        let url = res.data.attachments_url_pipefy[0]
        this.invociesUrl.push(url)
      },
      (error: any) => {
        this.erro = true
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.invociesUrl = []
        }
      })
    }
  }
  return new Promise<void>((resolve) => {resolve();});
}

saveContratos(event: any): void {
  var files = event.target.files;

  for (var i = 0; i < files.length; i++){
    // console.log(files[i].name)
    this.contratos.push(files[i])
  }

  // console.log(this.contratos)
  // console.log(this.contratos.length)

}

removeFileContratos(file: File){
  const index: number = this.contratos.indexOf(file);
    this.contratos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.contratos.length == 0){
      this.formulario.controls['anexar_informacoes_de_contrato_ou_gross_up'].markAsTouched()
      this.formulario.get('anexar_informacoes_de_contrato_ou_gross_up')?.setValue('')
    }
}

async getPipesUrlContratos(files: any) {
  this.erro = false
  for (var i = 0; i < files.length; i++) {
    var fileContent = files[i]
    let fileName = files[i].name
    const reader = new FileReader();
    reader.readAsDataURL(fileContent);
    reader.onload = () => {
      fileContent = reader.result?.toString()
      var fileContentStart = fileContent.indexOf(",")

      fileContent = fileContent.substring(fileContentStart + 1)
      this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
        let url = res.data.attachments_url_pipefy[0]
        this.contratosUrl.push(url)
      },
      (error: any) => {
        this.erro = true
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.contratosUrl = []
        }
      })
    }
  }
  return new Promise<void>((resolve) => {resolve();});
}

formataValorDecimal( input: any ) {
  input = input.replace( /[^0-9]/g, '' ); // remove all the characters except the numeric values
  if( input.length == 0 ) input = "0.00"; // set the default value
  else if( input.length == 1 ) input = "0.0" + input;
  else if( input.length == 2 ) input = "0." + input;
  else input = input.substring( 0, input.length - 2 ) + '.' + input.substring( input.length - 2, input.length );
  input = new Number( input ); // set the precision
  input = input.toFixed( 2 );    // only works with the "."
  input = input.replace( /\./g, ',' ); // change the splitter to ","
  let x = input.split( ',' ); // format the amount
  let valor = x[0];
  let decimal = x.length > 1 ? ',' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while( rgx.test( valor ) ) {
    valor = valor.replace( rgx, '$1' + '.' + '$2' );
  }
  this.taxaCambioAlterado = valor + decimal;
}


resetform(){
  for(let field in this.formulario.controls){
    this.formulario.controls[field].setValue('');
  }
  this.formulario.markAsUntouched()
  this.invoices = [];
  this.contratos  = [];
  this.invociesUrl = [];
  this.contratosUrl = [];
}

abrirPipePublico() {
  window.open('https://app.pipefy.com/public/form/3Sd_ATrN', '_blank')
}

}
